<div class="flex h-full flex-col px-8.5">
    <app-platforms-management-actions-header (searchChange)="onSearchChange($event)"></app-platforms-management-actions-header>
    @if (panelContents()) {
        @if (panelContents()!.length) {
            <ng-container [ngTemplateOutlet]="panelContentTemplate"></ng-container>
        } @else {
            <ng-container [ngTemplateOutlet]="noPanelContentTemplate"></ng-container>
        }
    } @else {
        <app-malou-spinner size="medium"></app-malou-spinner>
    }
</div>

<ng-template #panelContentTemplate>
    <div class="malou-expansion-panel">
        <mat-accordion>
            @for (panelContent of panelContents(); track panelContent.openPanelId) {
                <mat-expansion-panel
                    [expanded]="panelContent.openPanelId === currentOpenPanelId"
                    (opened)="openPanel(panelContent)"
                    (closed)="closePanel()">
                    <mat-expansion-panel-header>
                        <div class="flex justify-between">
                            <div class="flex items-center gap-x-4">
                                <img
                                    class="h-10 w-12 rounded-md object-cover"
                                    loading="lazy"
                                    [src]="panelContent.imageSrc ?? ('default_logo' | imagePathResolver)" />
                                <p class="malou-text-12--semibold">{{ panelContent.title }}</p>
                                <div class="diff-icon" [ngStyle]="{ 'background-color': panelContent.badge === 0 ? 'gray' : '' }">
                                    {{ panelContent.badge }}
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <ng-template matExpansionPanelContent>
                        @if ([InformationUpdateOptions.ACCESS, InformationUpdateOptions.BOTH] | includes: selectedOption()) {
                            <app-access
                                [accessList]="panelContent.accessList"
                                [isBusinessView]="isBusinessView()"
                                [accessUpdate]="accessUpdate()">
                            </app-access>
                        }

                        @if ([InformationUpdateOptions.UPDATE, InformationUpdateOptions.BOTH] | includes: selectedOption()) {
                            <app-updates
                                [updatesData]="panelContent.updatesData"
                                [isBusinessView]="isBusinessView()"
                                (platformUpdate)="onPlatformUpdate($event)">
                            </app-updates>
                        }
                    </ng-template>
                </mat-expansion-panel>
            }
        </mat-accordion>
    </div>
</ng-template>

<ng-template #noPanelContentTemplate>
    <div class="flex h-full flex-col items-center justify-center">
        <img class="h-32" loading="lazy" [src]="'Cook' | illustrationPathResolver" />
        <p class="malou-text-14--bold mt-9 text-center">
            {{ 'admin.platform_management.no_pending_access' | translate }}
        </p>
    </div>
</ng-template>
