import { EntityConstructor } from '@malou-io/package-utils';

import { Notification } from './notification.model';

export interface MessageNotificationData {
    restaurantIds: string[];
    conversations: {
        restaurantId: string;
        conversationId: string;
        messages: {
            id: string;
            text: string;
            senderName: string;
            senderProfilePictureUrl: string;
        }[];
    }[];
}

type MessageNotificationProps = EntityConstructor<MessageNotification>;

export class MessageNotification extends Notification {
    data: MessageNotificationData;
    constructor(props: MessageNotificationProps) {
        super(props);
    }

    getFirstMessage(): MessageNotificationData['conversations'][0]['messages'][0] {
        return this.data.conversations[0].messages[0];
    }

    getMessagesCountForRestaurant(restaurantId: string): number {
        return this.data.conversations.reduce((acc, conversation) => {
            if (conversation.restaurantId === restaurantId) {
                acc += conversation.messages.length;
            }
            return acc;
        }, 0);
    }

    hasOneMessage(): boolean {
        return this.data.conversations.length === 1 && this.data.conversations[0].messages.length === 1;
    }
}
