<ng-container [ngTemplateOutlet]="shouldOnlyDisplayScore ? scoreWithTitle : keywordsWithTips"></ng-container>

<ng-template #keywordsWithTips>
    <div class="flex h-full flex-col">
        @if (shouldDisplayTips || shouldDisplayKeywords) {
            <div
                class="malou-color-background-dark !m-0 !flex-auto !p-5"
                data-testid="keywords-score-brick-container"
                [ngClass]="withLargeDetails ? 'malou-card' : 'malou-card--medium'">
                <div class="flex h-full flex-row justify-between gap-x-2 sm:block">
                    @if (shouldDisplayTips) {
                        <div class="md:mb-4">
                            <div class="malou-text-12--bold mb-4">{{ 'keywords_score.tips' | translate }}</div>
                            <div
                                class="flex gap-2"
                                [ngClass]="{
                                    'flex-col': shouldDisplayKeywords,
                                    'flex-row': !shouldDisplayKeywords,
                                }">
                                <app-keywords-score-tips [indicationsList]="indicationsList()"></app-keywords-score-tips>
                            </div>
                        </div>
                    }
                    @if (shouldDisplayKeywords) {
                        <div class="ml-2 flex max-w-[350px] flex-col">
                            <div class="mb-4 flex items-center justify-between gap-2">
                                <ng-container [ngTemplateOutlet]="scoreWithTitle"></ng-container>
                                <app-select
                                    [testId]="'keywords-score-gauge-bricks-lang-input'"
                                    [formControl]="brickLangControl"
                                    [values]="langOptions()"
                                    [displayWith]="brickLangDisplayFn"
                                    [idPrefix]="'tracking_keywords_score_bricks_lang'"
                                    [getIdSuffixFn]="getIdSuffixFn">
                                </app-select>
                            </div>
                            @if (bricks$ | async; as bricks) {
                                <div class="grid grid-cols-[auto_auto] gap-x-2 sm:grid-cols-1" data-testid="keywords-score-grid">
                                    @for (brick of bricks; track trackByTextFn($index, brick)) {
                                        <div class="mb-2 flex" data-testid="keywords-score-brick" (click)="addBrick(brick)" #isFound>
                                            <ng-container
                                                [ngTemplateOutlet]="brickTemplate"
                                                [ngTemplateOutletContext]="{ brick }"></ng-container>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template let-brick="brick" #brickTemplate>
    @if (!isBrickChecked()(brick)) {
        <mat-icon class="relative top-[-2px] mr-5 min-w-[16px] cursor-pointer" color="primary" [svgIcon]="SvgIcon.ADD"></mat-icon>
    }
    @if (isBrickChecked()(brick)) {
        <mat-icon class="malou-color-state-success relative top-[-2px] mr-5 min-w-[16px]" [svgIcon]="SvgIcon.CHECK"></mat-icon>
    }
    <span
        class="malou-text-9--medium !text-malou-color-text-2"
        [matTooltip]="!shouldDisplayKeywordsCategory ? categoryMapping[brick.category] || '' : ''">
        {{ brickTranslated()(brick) }}
        @if (shouldDisplayKeywordsCategory && categoryMapping[brick.category]) {
            <small class="font-thin"> &nbsp;({{ categoryMapping[brick.category] }}) </small>
        }
    </span>
</ng-template>

<ng-template #scoreWithTitle>
    @if (shouldDisplayScore) {
        <div style="position: relative" [ngClass]="{ 'reduced-size': shouldOnlyDisplayScore }" #scoregauge>
            <div
                class="flex items-center"
                id="scale-content"
                [matTooltip]="formattedIndicationsList()"
                [matTooltipDisabled]="!shouldShowTipsInTooltip || !formattedIndicationsList()">
                @if (!shouldOnlyDisplayScore) {
                    <p class="malou-text-10--medium malou-color-text-2 mr-5 py-1">{{ title }}</p>
                }
                <app-score-gauge [score]="score()"></app-score-gauge>
            </div>
        </div>
    }
</ng-template>
