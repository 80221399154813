<div class="grid h-[100%] grid-cols-5 gap-3 md:grid-cols-3">
    @if (medias().length > 0) {
        @for (media of medias(); track media.id) {
            <div
                class="h-24 w-24 cursor-pointer"
                [ngClass]="{ 'cursor-pointer': canAddMedia() }"
                [matTooltip]="maxMediaReachedMessage()"
                [matTooltipDisabled]="isSelected(media) || canAddMedia()"
                (click)="toggleSelected(media)">
                <div class="relative h-full w-full">
                    @if (media | applySelfPure: 'isImage') {
                        <img
                            class="h-full w-full rounded-md object-cover"
                            [defaultImage]="'default-picture-grey' | imagePathResolver"
                            [lazyLoad]="media.urls.small || media.urls.igFit || media.urls.original"
                            [errorImage]="'default-picture-grey' | imagePathResolver" />
                    }
                    @if (media | applySelfPure: 'isVideo') {
                        <ng-container
                            [ngTemplateOutlet]="media.thumbnail ? videoWithThumbnailTemplate : videoWithoutThumbnailTemplate"
                            [ngTemplateOutletContext]="{ media }">
                        </ng-container>
                        <div class="absolute bottom-1 right-1 z-10 h-5 w-5 text-malou-color-text-white">
                            <mat-icon class="!h-4" [svgIcon]="SvgIcon.VIDEO"></mat-icon>
                        </div>
                    }
                    @if (multi()) {
                        <mat-checkbox
                            class="!absolute left-2 top-2 !cursor-pointer"
                            color="primary"
                            [ngClass]="{ 'opacity-50': !canAddMedia() && !isSelected(media) }"
                            [disabled]="!canAddMedia()"
                            [checked]="isSelected(media)"
                            (click)="onSelectCheckboxClick(media, $event)">
                        </mat-checkbox>
                    }
                </div>
            </div>
        }
    } @else {
        <p>{{ 'media_picker.no_photo_saved' | translate }}</p>
    }
</div>

<ng-template let-media="media" #videoWithThumbnailTemplate>
    <video class="h-full w-full rounded-md object-cover" preload="none" muted [poster]="media.thumbnail">
        <source type="video/mp4" [src]="media.urls.original" />
    </video>
</ng-template>

<ng-template let-media="media" #videoWithoutThumbnailTemplate>
    <video class="h-full w-full rounded-md object-cover" preload="metadata" muted>
        <source type="video/mp4" [src]="media.urls.original" />
    </video>
</ng-template>
