import { Pipe, PipeTransform } from '@angular/core';

/*
 * Use this to get date from malou date obj {year, month, day}
 */
@Pipe({
    name: 'malouDate',
    standalone: true,
})
export class MalouDatePipe implements PipeTransform {
    transform(dateObj: { year: number; month: number; day: number }): Date {
        return new Date(dateObj.year, dateObj.month, dateObj.day);
    }
}
