<div class="malou-dialog">
    <div class="malou-dialog__header !justify-end !pb-0">
        <button class="malou-btn-icon" mat-icon-button (click)="close(false)">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body !overflow-hidden !px-12 text-center">
        <div class="mb-6 grid w-full place-items-center">
            <img class="w-[90px]" [src]="illustration.Goggles | illustrationPathResolver" />
        </div>
        <div class="malou-text-18--bold mb-4 text-malou-color-text-1">
            <span>{{ getTitle() | translate }}</span>
        </div>

        <div class="malou-text-13--regular text-center text-malou-color-text-2">
            <span>{{ getText() | translate }}</span>
        </div>

        @if (data.updateDescription?.length) {
            <div class="malou-text-13--semibold mt-1 text-center text-malou-color-text-1">
                <span>{{ data.updateDescription }}</span>
            </div>
        }

        @if (restaurantNames()) {
            <button class="malou-btn-flat btn-sm !px-0" mat-button (click)="toggleShowRestaurants()">
                {{
                    showRestaurants()
                        ? ('notification-center.confirm_action_modal.special_hour.hide' | translate)
                        : ('notification-center.confirm_action_modal.special_hour.show_restaurants' | translate)
                }}
            </button>

            @if (showRestaurants()) {
                <div class="malou-text-12--semibold mt-4 text-center text-malou-color-text-1">
                    <span>{{ restaurantNames() }}</span>
                </div>
            }
        }
    </div>

    <!-- dialog footer -->
    <div class="malou-dialog__footer !justify-center gap-x-2">
        <button class="malou-btn-raised--secondary !bg-transparent" mat-raised-button (click)="close(false)">
            {{ getCancelAction() | translate }}
        </button>
        <button class="malou-btn-raised--primary" mat-raised-button (click)="close(true)">
            {{ getConfirmAction() | translate }}
        </button>
    </div>
</div>
