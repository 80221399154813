import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, signal, ViewChild } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, EMPTY, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import {
    ApplicationLanguage,
    DEFAULT_MAX_IMAGE_SIZE,
    DEFAULT_MAX_VIDEO_SIZE,
    InputMediaType,
    MalouErrorCode,
    mapLanguageStringToApplicationLanguage,
    MapstrCtaButtonType,
    MediaCategory,
    PlatformDefinitions,
    PlatformKey,
    PostPublicationStatus,
    PostSource,
    PostType,
    REEL_MAX_VIDEO_SIZE,
} from '@malou-io/package-utils';

import {
    AutoSaveState,
    bannedHashtags,
    BindingIdKey,
    mapsterPostCaptionTextLimit,
    maxHashtagInIgPosts,
    notionLinks,
    postCaptionTextLimit,
    times,
} from ':core/constants';
import { CredentialsService } from ':core/services/credentials.service';
import { DialogService } from ':core/services/dialog.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { MediaDimensionsService } from ':core/services/media-dimensions.service';
import { PlatformsService } from ':core/services/platforms.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { MediaService } from ':modules/media/media.service';
import { selectCurrentPlatforms } from ':modules/platforms/store/platforms.reducer';
import { updatePostsBindingId } from ':modules/posts/posts.actions';
import {
    AddLocationComponent,
    FbLocation,
} from ':modules/social-posts/new-social-post-modal/components/add-location/add-location.component';
import { PostCaptionComponent } from ':modules/social-posts/new-social-post-modal/components/post-form-body/post-caption/post-caption.component';
import { PostRightSidePanelComponent } from ':modules/social-posts/new-social-post-modal/components/post-right-side-panel/post-right-side-panel.component';
import { ReelsEditorComponent } from ':modules/social-posts/new-social-post-modal/components/reels-editor/reels-editor.component';
import { RightPanel } from ':modules/social-posts/new-social-post-modal/context/enums';
import { NewSocialPostAiContext } from ':modules/social-posts/new-social-post-modal/context/new-social-post-ai.context';
import { NewSocialPostHashtagsContext } from ':modules/social-posts/new-social-post-modal/context/new-social-post-hashtags.context';
import { NewSocialPostContext } from ':modules/social-posts/new-social-post-modal/context/new-social-post.context';
import { PostDateStatus } from ':modules/social-posts/new-social-post-modal/context/types';
import { UserState } from ':modules/user/store/user.reducer';
import { User } from ':modules/user/user';
import { ButtonComponent } from ':shared/components/button/button.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { FeedbacksPanelComponent } from ':shared/components/feedbacks-panel/feedbacks-panel.component';
import { InfiniteTextSlideComponent } from ':shared/components/infinite-text-slide/infinite-text-slide.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { InteractionsBrowserComponent } from ':shared/components/interactions-browser/interactions-browser.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import {
    DEFAULT_ACCEPTED_MEDIA_TYPES,
    DEFAULT_MAX_MEDIAS,
    MediaEditorComponent,
} from ':shared/components/media-editor/media-editor.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SelectBaseComponent } from ':shared/components/select-abstract/select-base.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { StaticFeedComponent } from ':shared/components/static-feed/static-feed.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { getTimeStringFromDate, isBeforeToday, isControlValueInTimeFormat, isPastHour, isSameDay } from ':shared/helpers';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { KillSubscriptions } from ':shared/interfaces';
import { ApiResult, Media, Platform, Post, Restaurant, SocialPost, UserTag } from ':shared/models';
import { MediaErrors, MediaProperties } from ':shared/models/media-errors';
import { ThumbnailChange } from ':shared/models/thumbnail-change';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { OpenaiPromptComponent } from ':shared/openai-prompt/openai-prompt.component';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { MapPipe } from ':shared/pipes/map.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

export function CheckedGreaterThan(num: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value.filter((c) => !!c.checked)?.length > num) {
            return null;
        }
        return { error: 'Wrong length' };
    };
}

interface AppState {
    user: UserState;
}

const VERYFRENCHBEANS_PAGE_ID = '100270695184218';
const WHITELISTED_PAGE_IDS = [VERYFRENCHBEANS_PAGE_ID];

@AutoUnsubscribeOnDestroy()
@Component({
    selector: 'app-new-social-post-modal',
    templateUrl: './new-social-post-modal.component.html',
    styleUrls: ['./new-social-post-modal.component.scss'],
    standalone: true,
    imports: [
        CdkOverlayOrigin,
        CdkConnectedOverlay,
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatRadioModule,
        MatSelectModule,
        MatOptionModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        TranslateModule,
        AddLocationComponent,
        ButtonComponent,
        CloseWithoutSavingModalComponent,
        FeedbacksPanelComponent,
        InputTextComponent,
        InputDatePickerComponent,
        InfiniteTextSlideComponent,
        MediaEditorComponent,
        OpenaiPromptComponent,
        PostCaptionComponent,
        PostRightSidePanelComponent,
        ReelsEditorComponent,
        SelectBaseComponent,
        SelectComponent,
        SkeletonComponent,
        StaticFeedComponent,
        TextAreaComponent,
        ApplyPurePipe,
        AsyncPipe,
        EnumTranslatePipe,
        FormatTimePipe,
        IncludesPipe,
        InteractionsBrowserComponent,
        MapPipe,
        PlatformLogoComponent,
        PluralTranslatePipe,
    ],
    providers: [EnumTranslatePipe],
})
export class NewSocialPostModalComponent implements OnInit, KillSubscriptions {
    @ViewChild('tagAccount') tagAccountBtn: ElementRef;
    @ViewChild('addLocation') addLocationBtn: ElementRef;
    @Output() changePostEmitter = new EventEmitter<number>();

    readonly SvgIcon = SvgIcon;
    readonly DEFAULT_MAX_IMAGE_SIZE = DEFAULT_MAX_IMAGE_SIZE;

    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    minDate = new Date();

    fromDuplicate: boolean = this._route.snapshot.queryParams.fromDuplicate;
    postIndex = 0;
    showDateSection = true;
    sendEmptyAttachments = false;
    restaurant$ = this._restaurantsService.restaurantSelected$.asObservable() as Observable<Restaurant>;
    mediumType: PostType;
    currentUser$ = this._store.select('user');
    socialPostTranslate = this._translate.instant('social_posts.new_social_post');
    gaugeTextType$ = of('posts');
    readonly killSubscriptions$: Subject<void> = new Subject();
    times: string[] = times;

    hideOptions = false;
    croppedImage: string | null = '';
    shouldCreateMedia = false;
    shouldDuplicatePost = false;
    chosenMedium: Media | null = null;
    isLoading = !!this.data.postId;
    currentLocation: FbLocation | null = null;
    platformSocialId: string | null = null;
    currentUserTags: UserTag[] = [];
    currentUser: UserState;
    hasBeenSavedByUser = false;
    igPlatformId: string | null = null;

    MAX_HASHTAGS_NUMBER = maxHashtagInIgPosts;
    POST_CAPTION_TEXT_LIMIT = postCaptionTextLimit;

    autoSaveState: string = AutoSaveState.NOT_SAVING;

    plannedDate: Date;

    isReel: boolean;
    acceptedFormats = 'image/png, image/jpeg, video/quicktime, video/mp4';
    shouldDisplayInFeed = true;
    duplicatedPostId: string | null;
    postScheduleType = PostDateStatus;

    isInitialLoading = true;
    isLoadingLocation$: BehaviorSubject<{ loading: boolean; location: FbLocation | null }> = new BehaviorSubject({
        loading: true,
        location: null,
    });
    showCloseModal = false;
    PostPublicationStatus = PostPublicationStatus;

    RightPanel = RightPanel;

    isPostTextEmpty = true;
    positionPairs: ConnectionPositionPair[] = [
        {
            offsetX: 10,
            offsetY: 90,
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'bottom',
        },
        {
            offsetX: 0,
            offsetY: 90,
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'bottom',
        },
    ];
    applicationLanguages = Object.values(ApplicationLanguage);

    InputMediaType = InputMediaType;
    REEL_MAX_VIDEO_SIZE = REEL_MAX_VIDEO_SIZE;
    DEFAULT_ACCEPTED_MEDIA_TYPES = DEFAULT_ACCEPTED_MEDIA_TYPES;
    DEFAULT_MAX_VIDEO_SIZE = DEFAULT_MAX_VIDEO_SIZE;
    DEFAULT_MAX_MEDIAS = DEFAULT_MAX_MEDIAS;
    MapstrCtaButtonTypeValues = Object.values(MapstrCtaButtonType);
    isMapstrPlatformChecked = false;
    areFacebookOrInstagramPlatformsChecked = false;
    isInstagramChecked = false;
    showMapstrCtaText = false;
    PlatformKey = PlatformKey;
    MAPSTR_POST_TEXT_LIMIT = mapsterPostCaptionTextLimit;

    readonly isPastHour = isPastHour;

    readonly isUpdatingPost = signal<boolean>(false);

    constructor(
        private readonly _dialogRef: MatDialogRef<NewSocialPostModalComponent>,
        private readonly _customDialogService: CustomDialogService,
        public readonly screenSizeService: ScreenSizeService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _postsService: PostsService,
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _mediaService: MediaService,
        private readonly _store: Store<AppState>,
        private readonly _translate: TranslateService,
        private readonly _platformsService: PlatformsService,
        private readonly _credentialService: CredentialsService,
        private readonly _toastService: ToastService,
        private readonly _dialogService: DialogService,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _mediaDimensionsService: MediaDimensionsService,
        public readonly newSocialPostContext: NewSocialPostContext,
        public readonly newSocialPostAiContext: NewSocialPostAiContext,
        public readonly newSocialPostHashtagsContext: NewSocialPostHashtagsContext,
        public readonly experimentationService: ExperimentationService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            postId: string;
            post: SocialPost;
            postDate: Date;
            isDisabled: boolean;
            allPostIds?: string[];
            restaurantManagers: User[];
        }
    ) {
        this.newSocialPostContext.post.update(() => this.data.post);
        this.newSocialPostContext.postInitialData.update(() => this.data);
        this.newSocialPostContext.displayedFeedbackMessages.update(() => this.data.post.feedback?.feedbackMessages ?? []);
        this.newSocialPostContext.partialPost = signal({
            _id: this.data?.post?._id,
            attachments: this.data.post?.attachments ?? [],
            keys: this.data.post?.keys,
            thumbnail: this.data.post?.thumbnail,
            thumbnailOffsetTimeInMs: this.data.post?.thumbnailOffsetTimeInMs,
            postType: this.data.post?.postType,
            isReelDisplayedInFeed: this.data.post?.isReelDisplayedInFeed,
        });
        this.newSocialPostHashtagsContext.onPostHashtagsChanges();
    }

    get postDescriptionControl(): FormControl {
        return this.newSocialPostContext.postForm().get('post.text') as FormControl;
    }

    get postGroup(): UntypedFormGroup {
        return this.newSocialPostContext.postForm().get('post') as UntypedFormGroup;
    }

    get postSchedule(): PostDateStatus {
        return this.newSocialPostContext.postForm().get('meta.date.postDateStatus')?.value;
    }

    get dateGroup(): UntypedFormGroup {
        return this.newSocialPostContext.postForm().get('meta.date') as UntypedFormGroup;
    }

    get isPostDatePast(): boolean {
        return isBeforeToday(new Date(this.newSocialPostContext.postForm().get('meta.date.postDate')?.value));
    }

    get postDate(): Date {
        return this.newSocialPostContext.postForm().get('meta.date.postDate')?.value;
    }

    get attachmentsName(): string {
        return this.newSocialPostContext.postForm().get('post.attachmentsName')?.value;
    }

    get postTime(): string {
        return this.newSocialPostContext.postForm().get('meta.date.postTime')?.value;
    }

    get userTagsList(): UserTag[][] {
        return this.newSocialPostContext.postForm().get('post.userTagsList')?.value;
    }

    get getCheckedPlatformCtrls(): { [key: string]: AbstractControl }[] {
        return this.platforms.controls.map((fg: UntypedFormGroup) => fg?.controls).filter((c) => !!c.checked.value);
    }

    get checkedPlatforms(): string[] {
        return this.getCheckedPlatformCtrls.map((c) => c.key.value);
    }

    get postText(): string {
        return this.newSocialPostContext.postForm().get('post.text')?.value;
    }

    get plannedPublicationDate(): Date {
        return this.newSocialPostContext.postForm().get('post.plannedPublicationDate')?.value;
    }

    get postCallToActionUrlControl(): AbstractControl | null {
        return this.newSocialPostContext.postForm().get('post.callToAction.url');
    }

    ngOnInit(): void {
        this.newSocialPostContext.killSubscriptions$ = this.killSubscriptions$;
        this.newSocialPostAiContext.initInteractions();
        this._screenSizeService.resize$.subscribe((elt) => {
            this.newSocialPostContext.isSmallScreen.update(() => elt.size === ScreenSize.IsSmallScreen);
        });

        this.isReel = this.data.post?.postType === PostType.REEL;
        this.newSocialPostContext.isReel.set(this.isReel);
        if (this.isReel) {
            this.shouldDisplayInFeed = this.data.post?.isReelDisplayedInFeed;
            this.acceptedFormats = 'video/quicktime, video/mp4';
        }
        this.postIndex = this.data?.allPostIds?.findIndex((postId) => this.data.postId === postId) ?? 0;
        this._translate.onLangChange.pipe(takeUntil(this.killSubscriptions$)).subscribe((res) => {
            this.newSocialPostContext.currentLang.update(() => mapLanguageStringToApplicationLanguage(res.lang));
        });

        this._initFormPost(this.data.post, this.data.isDisabled);

        this._platformsService
            .getSocialNetworksPlatforms()
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((socialNetworksPlatforms) => {
                this._computePlatformsDataForFilter(socialNetworksPlatforms);
            });

        this._dialogRef
            .backdropClick()
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe({
                next: () => this.openSaveBeforeClose(),
                error: () => {},
            });

        this._dialogRef
            .keydownEvents()
            .pipe(
                debounceTime(100),
                filter((event) => event.key === 'Escape')
            )
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe({
                next: () => this.openSaveBeforeClose(),
                error: () => {},
            });

        this.newSocialPostHashtagsContext.getRestaurantHashtags();

        this.restaurant$
            .pipe(
                tap((res) => {
                    if (res) {
                        this.newSocialPostContext.currentRestaurant.update(() => res);
                    }
                }),
                switchMap(() => {
                    const cleanRestaurantName = this.newSocialPostContext.currentRestaurant().name?.normalize('NFD').trim().toLowerCase();

                    this.newSocialPostContext.postForm().get('post.attachmentsName')?.patchValue(cleanRestaurantName);

                    if (this.data.postId) {
                        return this._postsService.getPost(this.data.postId);
                    }
                    if (this.data.postDate) {
                        this.newSocialPostContext.postForm().get('meta.date')?.get('postDateStatus')?.patchValue(PostDateStatus.LATER);
                        this.newSocialPostContext.postForm().get('meta.date')?.get('postDate')?.patchValue(this.data.postDate);
                    }
                    return of(null);
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (res) => {
                    if (res?.data) {
                        const post = new Post(res.data);
                        if (post.restaurantId !== this.newSocialPostContext.currentRestaurant()._id) {
                            this._router.navigate(['..'], { relativeTo: this._route });
                            return;
                        }
                        this.updatePostForm(post);
                        this.newSocialPostHashtagsContext.selectedHashtags.set(post.hashtags.selected);
                        this.newSocialPostHashtagsContext.generatedHashtags.set(post.hashtags.suggested);
                        this.mediumType = post.postType;
                        if (post.published === PostPublicationStatus.PUBLISHED) {
                            this.showDateSection = false;
                        }
                        this._autoSaveContent();
                        this.isLoading = false;
                    }
                    this.isInitialLoading = false;
                },
                error: (err) => {
                    console.warn('err :>> ', err);
                    this.isInitialLoading = false;
                },
            });

        this._store
            .select(selectCurrentPlatforms)
            .pipe(
                take(1),
                map((platform) => platform.filter((pl) => PlatformDefinitions.getSocialPlatformKeysWithDuplicablePosts().includes(pl.key))),
                map((socialPlatforms) => {
                    const fbPlatform = socialPlatforms.find((pl) => pl.key === PlatformKey.FACEBOOK);
                    this.igPlatformId = socialPlatforms.find((pl) => pl.key === PlatformKey.INSTAGRAM)?._id ?? null;
                    if (socialPlatforms.length) {
                        this.platformSocialId = socialPlatforms[0]._id;
                    }
                    if (!fbPlatform) {
                        this.isLoadingLocation$.next({ loading: false, location: null });
                    }
                    return fbPlatform;
                }),
                filter(Boolean),
                switchMap((platform: Platform) =>
                    // Special case, we whitelist the "Very French Beans" page because it does not contain location
                    forkJoin([
                        this._credentialService.pagesSearch(platform.name, {
                            onlyWithLocation: true,
                            whitelistedPageIds: WHITELISTED_PAGE_IDS,
                        }),
                        of(platform.socialId),
                    ])
                ),
                catchError((err) => {
                    console.warn(err);
                    this.platformSocialId = null;
                    this.isLoadingLocation$.next({ loading: false, location: null });
                    return EMPTY;
                })
            )
            .subscribe({
                next: ([res, socialId]) => {
                    const locations = res.data;
                    if (this.data.post.location) {
                        this.currentLocation = this.data.post.location;
                        this.location = this.currentLocation;
                        this.isLoadingLocation$.next({ loading: false, location: this.currentLocation });
                    } else {
                        this.currentLocation = locations.find((l) => l.id === socialId) || null;

                        if (this.currentLocation && this.data.post.attachments.length === 0) {
                            this.location = this.currentLocation;
                            this.isLoadingLocation$.next({ loading: false, location: this.currentLocation });
                        } else {
                            this.isLoadingLocation$.next({ loading: false, location: null });
                        }
                    }
                },
                error: (err) => console.warn(err),
            });

        this.newSocialPostContext.shouldAutoSave$
            .pipe(
                filter(({ field }) => !!field),
                concatMap(({ field, value }: { field: string; value: any }) => {
                    if (field === 'attachments') {
                        this.newSocialPostContext.partialPost.update((partialPost) => ({
                            ...partialPost,
                            attachments: this.attachments,
                        }));
                    }
                    if (this.hasBeenSavedByUser) {
                        return of(null);
                    }
                    if (this.data?.postId && this.data?.post?.published === PostPublicationStatus.DRAFT) {
                        const plannedPublicationDate =
                            this.newSocialPostContext.postForm().get('post.plannedPublicationDate')?.value ?? this.plannedDate;
                        this.data.post?.refreshData({ [field]: value });
                        const post = { [field]: value, plannedPublicationDate };
                        if (field === 'attachments') {
                            Object.assign(post, { attachments: value.map((media: Media) => media.id) });
                            Object.assign(post, { postType: this._getPostTypeFromAttachments() });
                        }
                        return this._postsService
                            .preparePost(this.data.postId, {
                                post,
                                keys: this.checkedPlatforms,
                                draft: true,
                            })
                            .pipe(catchError(() => of(null)));
                    }
                    return EMPTY;
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.autoSaveState = AutoSaveState.SAVED;
                    }
                },
                error: (err) => {
                    console.warn('Error: > > Error autosaving : ', err);
                },
            });

        this.currentUser$.subscribe((user) => {
            this.currentUser = user;
        });

        this.newSocialPostContext
            .postForm()
            .get('meta.platforms')
            ?.valueChanges.subscribe((platforms: { key: PlatformKey; checked: boolean }[]) => {
                this.newSocialPostContext.partialPost.update((partialPost) => ({
                    ...partialPost,
                    keys: platforms.filter((p) => !!p.checked)?.map((p) => p.key),
                }));
            });

        this.newSocialPostContext
            .postForm()
            .get('post.text')
            ?.valueChanges.subscribe((text) => {
                this.isPostTextEmpty = text?.length === 0;
            });

        this.newSocialPostContext
            .postForm()
            .get('meta.platforms')
            ?.valueChanges.subscribe((platforms: { key: PlatformKey; checked: boolean }[]) => {
                this.isMapstrPlatformChecked = platforms.some((p) => p.key === PlatformKey.MAPSTR && p.checked);
                this.areFacebookOrInstagramPlatformsChecked = platforms.some(
                    (p) => PlatformDefinitions.getPlatformKeysLinkedWithMeta().includes(p.key) && p.checked
                );
                this.isInstagramChecked = platforms.some((p) => p.key === PlatformKey.INSTAGRAM && p.checked);
            });

        this.showMapstrCtaText =
            this._mapActionTypeToMapstrCreatePostButtonType(this.newSocialPostContext.post().callToAction?.actionType) !==
            MapstrCtaButtonType.SEE;
        this.newSocialPostContext
            .postForm()
            .get('post.callToAction.actionType')
            ?.valueChanges.subscribe((buttonType: MapstrCtaButtonType) => {
                this.showMapstrCtaText = buttonType !== MapstrCtaButtonType.SEE;
            });
    }

    onTextAreaInput(_event: InputEvent): void {
        this.newSocialPostAiContext.resetBrowser.update((value) => !value);
    }

    private _computePlatformsDataForFilter(socialNetworksPlatforms: Platform[]): void {
        let platformKeysToShow = PlatformDefinitions.getSocialPlatformKeys();
        if (this.isReel) {
            platformKeysToShow = PlatformDefinitions.getPlatformKeysWithReel();
        } else {
            const mapstrPlatform = socialNetworksPlatforms.find((platform) => platform.key === PlatformKey.MAPSTR);
            if (!mapstrPlatform?.credentials?.length) {
                platformKeysToShow = platformKeysToShow.filter((platformKey) => platformKey !== PlatformKey.MAPSTR);
            }
        }
        platformKeysToShow.forEach((platformKey) => {
            const platform = socialNetworksPlatforms.find((pl) => pl.key === platformKey);
            this.platforms.push(
                this._fb.group({
                    key: platformKey,
                    connected: !!platform,
                    checked: [
                        {
                            value: !!platform,
                            disabled: !platform,
                        },
                    ],
                })
            );
        });
        this.currentUserTags = this.data.post.userTags || [];
    }

    private _mapActionTypeToMapstrCreatePostButtonType(actionType: string | undefined): MapstrCtaButtonType {
        if (this.MapstrCtaButtonTypeValues.includes(actionType as MapstrCtaButtonType)) {
            return actionType as MapstrCtaButtonType;
        }
        return MapstrCtaButtonType.SEE;
    }

    updatePostForm(post: Post): void {
        this.newSocialPostContext
            .postForm()
            .get('post')
            ?.patchValue({
                text: post.text ?? '',
                language: (post.language as ApplicationLanguage) || LocalStorage.getLang(),
                plannedPublicationDate: post.plannedPublicationDate ?? null,
                userTags: post.userTags ?? [],
                userTagsList: post.userTagsList ?? [],
                hashtags: post.hashtags,
            });
        if (post.location) {
            this.newSocialPostContext.postForm().get('post')?.patchValue({
                location: post.location,
            });
        }

        if (post.attachmentsName) {
            this.newSocialPostContext.postForm().get('post')?.patchValue({
                attachmentsName: post.attachmentsName,
            });
        }
        if (post.keys || post.key) {
            const keys = post.keys?.length ? post.keys : [post.key];
            this.platforms.controls.forEach((platformControl: UntypedFormGroup) =>
                platformControl.patchValue({
                    checked: platformControl.value.connected && keys.includes(platformControl.value.key),
                })
            );
        }
        this._initPostDateStatus(post);
        if (post.plannedPublicationDate) {
            const pubDate = new Date(post.plannedPublicationDate);
            const isPassed = Number(pubDate) - Number(new Date()) < 0;
            if (!isPassed) {
                this.newSocialPostContext.postForm().get('meta.date.postDate')?.patchValue(pubDate);
                this.newSocialPostContext.postForm().get('meta.date.postTime')?.patchValue(this._getPostTimeString(pubDate));
            }
        }
        this.reloadMedias(post);
    }

    reloadMedias(post: Post): void {
        const medias = (post.getAttachments() as Media[])?.map((attachment: Media) => new Media(attachment));

        this.onMediasSelect(medias);
    }

    changeSelectedTime(event: MatSelectChange): void {
        this.newSocialPostContext.postForm().get('meta.date.postTime')?.patchValue(event.value);
    }

    get attachments(): Media[] {
        return this.newSocialPostContext.postForm().get('post.attachments')?.value;
    }

    get platforms(): UntypedFormArray {
        return this.newSocialPostContext.postForm().get('meta.platforms') as UntypedFormArray;
    }

    getPublishButtonText(): string {
        switch (this.newSocialPostContext.postForm().get('meta.date.postDateStatus')?.value) {
            case PostDateStatus.NOW:
                return this._translate.instant('social_posts.new_social_post.publish');
            case PostDateStatus.LATER:
                return this._translate.instant('social_posts.new_social_post.later');
            default:
                return this._translate.instant('social_posts.new_social_post.save_as_draft');
        }
    }

    getTrackingNameFromPublishButtonType = (postDateStatus: PostDateStatus): string => {
        switch (postDateStatus) {
            case PostDateStatus.NOW:
                return `tracking_new_social_post_modal_publish_${this.isReel ? 'reel' : 'post'}_button`;
            case PostDateStatus.LATER:
                return `tracking_new_social_post_modal_schedule_${this.isReel ? 'reel' : 'post'}_button`;
            default:
                return `tracking_new_social_post_modal_draft_${this.isReel ? 'reel' : 'post'}_button`;
        }
    };

    getMediaErrorsAndProperties$(medium: Media): Observable<{ errors: string[]; properties: MediaProperties }> {
        return this._mediaDimensionsService.getMediaProperties$({ medium, isReel: this.isReel }).pipe(
            map((properties) => {
                const mediaErrors = new MediaErrors(properties, this.checkedPlatforms);
                const errors = mediaErrors.getErrors().map((err: string) => `- ${this._translate.instant(err)}`);
                return { errors, properties };
            })
        );
    }

    onMediasSelect(medias: Media[]): void {
        this.newSocialPostContext.postMedias.set(medias);
        forkJoin(medias.map((m) => this.getMediaErrorsAndProperties$(m))).subscribe((imgErrors) => {
            const mediasWithErrors = medias.map((m, idx) => {
                m.setErrors(imgErrors[idx].errors);
                return m;
            });
            (this.newSocialPostContext.postForm().get('post') as UntypedFormGroup).setControl(
                'attachments',
                this._fb.array(mediasWithErrors)
            );
            this.newSocialPostContext.shouldAutoSave$.next({ field: 'attachments', value: medias });
            if (!medias.length) {
                this.newSocialPostContext.postForm().get('post')?.patchValue({ thumbnailOffsetTimeInMs: null, thumbnail: null });
                this.newSocialPostContext.partialPost.update((post) => {
                    post = { ...post };
                    delete post.thumbnailOffsetTimeInMs;
                    delete post.thumbnail;
                    return post;
                });
            }
        });
    }

    onThumbnailChange(thumbnailChange: ThumbnailChange): void {
        const currentOffsetTimeInMs = this.newSocialPostContext.postForm().get('post.thumbnailOffsetTimeInMs')?.value ?? null;
        const currentThumbnailMedia = this.newSocialPostContext.postForm().get('post.thumbnail')?.value ?? null;

        const newOffsetTimeInMs = thumbnailChange.thumbnailOffsetTimeAndUrl?.thumbnailOffsetTimeInMs ?? null;
        const newThumbnailMedia = thumbnailChange.thumbnail ?? null;

        const patch: any = {};
        if (currentOffsetTimeInMs !== newOffsetTimeInMs) {
            patch.thumbnailOffsetTimeInMs = newOffsetTimeInMs;
        }
        if (currentThumbnailMedia !== newThumbnailMedia) {
            patch.thumbnail = newThumbnailMedia;
        }

        this.newSocialPostContext.postForm().get('post')?.patchValue(patch);
        this.newSocialPostContext.partialPost.update((partialPost) => ({
            ...partialPost,
            ...patch,
            postType: PostType.REEL,
        }));
    }

    onFileChange(medias: Media[]): void {
        if (medias.length === 0) {
            this.newSocialPostContext.postForm().get('post')?.patchValue({ attachmentsName: null });
            (this.newSocialPostContext.postForm().get('post.attachments') as UntypedFormArray).clear();
            this.newSocialPostContext.postForm().get('post.thumbnail')?.patchValue(null);
            this.newSocialPostContext.postForm().get('post.thumbnailOffsetTimeInMs')?.patchValue(null);
            this.newSocialPostContext.shouldAutoSave$.next({ field: 'attachments', value: [] });
            this.newSocialPostContext.postMedias.set([]);
            return;
        }

        this.onMediasSelect(medias.map((m) => new Media(m)));
    }

    clarifyError(err: any): string {
        const message: string = err?.error?.malouErrorCode ?? err?.error?.message ?? err?.message ?? String(err);
        if (message?.match(/(Permissions error|not found)/)) {
            return this._translate.instant('social_posts.new_social_post.reconnect_platform');
        }
        if (message?.match(/permission/)) {
            return this._translate.instant('social_posts.new_social_post.reconnect_with_auth');
        }
        if (message?.match(/Error validating access token/)) {
            return this._translate.instant('social_posts.new_social_post.reconnect_platform');
        }
        if (message?.match(/proportion/)) {
            return this._translate.instant('social_posts.new_social_post.out_of_proportion');
        }
        if (message?.match(MalouErrorCode.PLATFORM_CREDENTIAL_NOT_FOUND)) {
            return this._translate.instant('social_posts.new_social_post.reconnect_retry');
        }
        if (message?.match(/Instagram Business/)) {
            return this._translate.instant('social_posts.new_social_post.not_business');
        }
        if (message?.match(/bytes too small/)) {
            return this._translate.instant('social_posts.new_social_post.small_pic');
        }
        if (message?.match(/Pick one platform/)) {
            return this._translate.instant('social_posts.new_social_post.associate_post');
        }
        if (message?.match(/HEVC/)) {
            return this._translate.instant('social_posts.new_social_post.unsupported_codec');
        }
        return message;
    }

    patchPublicationDate(draft: boolean): boolean {
        const { postDate, postTime, postDateStatus } = this.newSocialPostContext.postForm().get('meta.date')?.value;
        switch (postDateStatus) {
            case PostDateStatus.NOW:
                this.newSocialPostContext.postForm().get('post')?.patchValue({
                    plannedPublicationDate: new Date(),
                });
                break;
            case PostDateStatus.LATER:
            case PostDateStatus.DRAFT:
                let publicationDate = postDate instanceof DateTime ? postDate.toJSDate() : postDate;
                const [hours, minutes] = postTime.split(':');
                if (hours && minutes) {
                    publicationDate?.setHours(parseInt(hours, 10));
                    publicationDate?.setMinutes(parseInt(minutes, 10));
                    if (publicationDate?.getTime() < new Date().getTime()) {
                        if (draft) {
                            publicationDate = new Date();
                        } else {
                            this._dialogService.open({
                                title: this._translate.instant('social_posts.error'),
                                message: this._translate.instant('social_posts.feed_view.not_previous_date'),
                                variant: DialogVariant.ERROR,
                                primaryButton: {
                                    label: this._translate.instant('common.ok'),
                                },
                            });
                            return false;
                        }
                    }
                }
                this.newSocialPostContext.postForm().get('post')?.patchValue({
                    plannedPublicationDate: publicationDate,
                });
                break;
            default:
                break;
        }
        return true;
    }

    openSaveBeforeClose(): void {
        if (this.data?.post?.published === PostPublicationStatus.DRAFT) {
            this.plannedDate = this.newSocialPostContext.postForm().get('post.plannedPublicationDate')?.value;
            this.willCreateMedium$().subscribe();
            this.cancel({ reload: false });
        } else if (this.newSocialPostContext.postForm().dirty && this.checkedPlatforms.length > 0) {
            this.showCloseModal = true;
        } else {
            this.cancel({ reload: true });
        }
    }

    close(): void {
        this.cancel({ reload: false });
    }

    willCreateMedium$(originalMediaId: string | null = null): Observable<Media | null> {
        if (this.croppedImage === null) {
            return of(null);
        }

        return this.shouldCreateMedia
            ? from(
                  fetch(this.croppedImage)
                      .then((res) => res.blob())
                      .then((blob) => new File([blob], String(Math.random()), { type: 'image/png' }))
              ).pipe(
                  switchMap((file) =>
                      this.currentUser$.pipe(
                          switchMap(() =>
                              this._mediaService.uploadAndCreateMedia([
                                  {
                                      data: file,
                                      metadata: {
                                          restaurantId: this.newSocialPostContext.currentRestaurant()._id,
                                          category: MediaCategory.ADDITIONAL,
                                          originalMediaId,
                                      },
                                  },
                              ])
                          ),
                          map((res) => res.data[0]),
                          tap((medium: Media) => {
                              if (medium) {
                                  this.onMediasSelect([new Media(medium)]);
                              }
                          }),
                          switchMap(() =>
                              this.chosenMedium
                                  ? this._mediaService
                                        .updateMediaById(this.chosenMedium.id, {
                                            title: this.newSocialPostContext.postForm().get('post.attachmentsName')?.value,
                                        })
                                        .pipe(map((res) => res.data))
                                  : of(null)
                          ),
                          takeUntil(this.killSubscriptions$)
                      )
                  )
              )
            : of(null);
    }

    updatePost$(postData: Partial<Post>, draft = false): Observable<ApiResult<Post>> {
        return this.willCreateMedium$().pipe(
            tap((newMedia) => {
                if (!this.sendEmptyAttachments && this.attachments.length === 0) {
                    // we send [] only if it is clearly asked otherwise we don't touch the platform's post medium
                    delete postData.attachments;
                }
                if (newMedia && postData.attachments?.length) {
                    postData.attachments = [newMedia];
                }
                Object.assign(postData, { postType: this._getPostTypeFromAttachments() });
            }),
            switchMap(() =>
                this._postsService.preparePost(this.data.postId, {
                    post: postData,
                    keys: this.checkedPlatforms,
                    draft,
                })
            )
        );
    }

    save(draft = this.postSchedule === PostDateStatus.DRAFT): void {
        this.hasBeenSavedByUser = true;
        const allHashtagTexts = this.newSocialPostContext.selectedHashtagsList()?.map((hashtag) => hashtag.text) || [];
        const bannedHashtagsFromText = this._extractBannedHashtags(allHashtagTexts);

        if (bannedHashtagsFromText.length) {
            this._dialogService
                .open({
                    title: this._translate.instant('hashtags.validation.warning'),
                    message: `${this._translate.instant('hashtags.validation.banned_hashtags')} <b> ${bannedHashtagsFromText} </b>`,
                    variant: DialogVariant.ALERT,
                    primaryButton: {
                        label: this._translate.instant('hashtags.validation.learn_more'),
                        action: () => true,
                    },
                    secondaryButton: {
                        label: this._translate.instant('hashtags.validation.close'),
                    },
                })
                .afterClosed()
                .subscribe((res) => {
                    if (res) {
                        window.open(notionLinks.BANNED_HASHTAGS, '_blank');
                    }
                });
            return;
        }
        const workingPublicationDate = this.patchPublicationDate(draft);
        if (!workingPublicationDate) {
            return;
        }
        this.newSocialPostContext.postForm().disable();
        this.isUpdatingPost.set(true);

        const postTextWithHashtags = this._addHashtagsToText(
            this.postText,
            this.newSocialPostHashtagsContext.selectedHashtags().map((ht) => ht.text)
        );
        const postTextWithHashtagsFormatted = this._formatSocialNetworkPostText(postTextWithHashtags);
        const postData = {
            ...this.newSocialPostContext.postForm().get('post')?.value,
            text: postTextWithHashtagsFormatted,
            userTags: this.currentUserTags,
            feedbackId: this.data.post.feedbackId,
            isReelDisplayedInFeed: this.shouldDisplayInFeed,
            hashtags: this.newSocialPostContext.postForm()?.get('post.hashtags')?.value ?? { selected: [], generated: [] },
        };

        this.updatePost$(postData, draft)
            .pipe(
                switchMap((res) =>
                    forkJoin([
                        of(res.data),
                        this.shouldDuplicatePost
                            ? this._postsService.duplicatePost$(res.data, PlatformDefinitions.getSeoPlatformKeysWithPost())
                            : of(null),
                    ])
                )
            )
            .subscribe({
                next: ([newPost, duplicatedPost]) => {
                    this.newSocialPostContext.postForm().enable();
                    this.isUpdatingPost.set(false);
                    this.duplicatedPostId = duplicatedPost?._id ?? null;
                    if (draft) {
                        this.cancel({
                            duplicatedPostId: this.duplicatedPostId,
                            savedAsDraft: true,
                        });
                    } else if (
                        newPost.published === this.PostPublicationStatus.PENDING &&
                        this.newSocialPostContext.postForm().get('meta.date.postDateStatus')?.value !== PostDateStatus.NOW
                    ) {
                        this.cancel({ reload: true, postId: newPost._id, duplicatedPostId: this.duplicatedPostId });
                    } else {
                        if (this.newSocialPostContext.postForm().get('meta.date.postDateStatus')?.value === PostDateStatus.NOW) {
                            if (newPost?.bindingId) {
                                this._store.dispatch(
                                    updatePostsBindingId({ bindingId: newPost?.bindingId, bindingIdKey: BindingIdKey.BINDING_ID })
                                );
                            }
                            this.cancel({ reload: false, duplicatedPostId: this.duplicatedPostId });
                        } else {
                            if (this.data.postId) {
                                this._toastService.openSuccessToast(this.getSaveText());
                                this.cancel({ postId: this.data.postId, duplicatedPostId: this.duplicatedPostId });
                                return;
                            }
                            this.cancel({ reload: true, duplicatedPostId: this.duplicatedPostId });
                        }
                    }
                },
                error: (err) => {
                    this.newSocialPostContext.postForm().enable();
                    this.isUpdatingPost.set(false);
                    if (err.status !== 403) {
                        this._dialogService.open({
                            title: this._translate.instant('social_posts.new_social_post.error'),
                            message: this.clarifyError(err.error?.message || err?.message),
                            variant: DialogVariant.ALERT,
                            primaryButton: {
                                label: this._translate.instant('common.ok'),
                            },
                        });
                    }
                },
            });
    }

    onTagsChange(tags: UserTag[][]): void {
        this.newSocialPostContext.postForm().get('post.userTagsList')?.setValue(tags);
        this.newSocialPostContext.postForm().markAsDirty();
        this.newSocialPostContext.shouldAutoSave$.next({ field: 'userTagsList', value: this.currentUserTags });
    }

    onLocationChange(location: FbLocation | null): void {
        this.currentLocation = location;
        this.newSocialPostContext.postForm().get('post.location')?.setValue(location);

        this.newSocialPostContext.shouldAutoSave$.next({ field: 'location', value: this.currentLocation });
    }

    getSaveText(): string {
        switch (this.newSocialPostContext.postForm().get('meta.date.postDateStatus')?.value) {
            case PostDateStatus.NOW:
                return this._translate.instant('social_posts.new_social_post.post_in_progress');
            case PostDateStatus.LATER:
                return this._translate.instant('social_posts.new_social_post.planned_post');
        }
        return this._translate.instant('social_posts.new_social_post.post_in_progress');
    }

    canPublish(): boolean {
        if (this.newSocialPostAiContext.isGeneratingPostTextFromAI()) {
            return false;
        }
        if (this.postSchedule === PostDateStatus.DRAFT) {
            return this._isPublicationDateValid();
        }
        const attachments = this.newSocialPostContext.postForm().get('post.attachments')?.value;
        if (this.postSchedule === PostDateStatus.NOW) {
            return (
                !this._isSingleVideoAttachmentPost(attachments) &&
                !this.hasMediasErrors() &&
                this.hashtagsNumber <= this.MAX_HASHTAGS_NUMBER &&
                !this._isSelectedPlatformsEmpty() &&
                this.newSocialPostContext.postForm().valid &&
                !this.formErrors.length &&
                !this.isLoading &&
                this.data.post.published !== PostPublicationStatus.PUBLISHED
            );
        }
        return (
            !this._isSingleVideoAttachmentPost(attachments) &&
            !this.hasMediasErrors() &&
            this._isPublicationDateValid() &&
            this.hashtagsNumber <= this.MAX_HASHTAGS_NUMBER &&
            !this._isSelectedPlatformsEmpty() &&
            this.newSocialPostContext.postForm().valid &&
            !this.formErrors.length &&
            !this.isLoading &&
            this.data.post.published !== PostPublicationStatus.PUBLISHED
        );
    }

    private _isPublicationDateValid(): boolean {
        if (!this.postDate) {
            return false;
        }
        return this.postDate >= new Date();
    }

    hasMediasErrors(): boolean {
        return (this.newSocialPostContext.postForm().get('post.attachments')?.value as Media[]).some((m) => !!m.hasErrors());
    }

    cancel(next: { [key: string]: string | boolean | null }): void {
        this.killSubscriptions$.next();
        this._customDialogService.closeAll();
        const shouldDeleteAfterClose = this._isPostFormEmpty();
        this._dialogRef.close({ next, shouldDeleteAfterClose });
        this.newSocialPostAiContext.closePostCaptionAiGeneration();
    }

    toggleShouldDuplicatePost(): void {
        this.shouldDuplicatePost = !this.shouldDuplicatePost;
        this.newSocialPostContext.shouldAutoSave$.next({ field: 'shouldDuplicateInOtherPlatforms', value: this.shouldDuplicatePost });
    }

    hasReachedMaxHashtags = (): boolean => this.hashtagsNumber >= this.MAX_HASHTAGS_NUMBER;

    getSelectedPlatforms(): string[] {
        return this.newSocialPostContext
            .postForm()
            .get('meta.platforms')
            ?.value.filter((p) => p.checked)
            .map((p) => p.key);
    }

    getSelectedMedias(): Media[] {
        return this.newSocialPostContext.postForm().get('post.attachments')?.value as Media[];
    }

    changePost(diff: number): void {
        this.isInitialLoading = true;
        const isFirstPost = this.postIndex === 0;
        const isLastPost = this.postIndex === (this.data.allPostIds?.length ?? 1) - 1;
        if ((isFirstPost && diff === -1) || (isLastPost && diff === 1)) {
            return;
        }
        const currentRouteWithoutParams = this._router.url.split('?')[0];
        this._router.navigate([currentRouteWithoutParams], { queryParams: { postId: this.data.allPostIds?.[this.postIndex + diff] } });
    }

    toggleDisplayedInFeed(): void {
        this.shouldDisplayInFeed = !this.shouldDisplayInFeed;
        this.newSocialPostContext.partialPost.update((partialPost) => ({
            ...partialPost,
            isReelDisplayedInFeed: this.shouldDisplayInFeed,
        }));
        this.newSocialPostContext.shouldAutoSave$.next({
            field: 'isReelDisplayedInFeed',
            value: this.shouldDisplayInFeed,
        });
    }

    togglePlatformChecked(platformKey: string): void {
        const platforms = this.newSocialPostContext.postForm().get('meta.platforms')?.value;
        const platform = platforms?.find((p) => p.key === platformKey);
        platform.checked = !platform.checked;
        this.newSocialPostContext.postForm().get('meta.platforms')?.patchValue(platforms!);
    }

    onSelectionChange(selectedText: string): void {
        this.newSocialPostContext.selectedText.update(() => selectedText);
    }

    isFirstPost(postIndex: number): boolean {
        return postIndex === 0;
    }

    isLastPost(postIndex: number, allPostsLength?: number): boolean {
        return postIndex === (allPostsLength ?? 1) - 1;
    }

    get location(): FbLocation {
        return this.newSocialPostContext.postForm().get('post.location')?.value;
    }

    set location(location: Location | FbLocation) {
        this.newSocialPostContext.postForm().get('post')?.patchValue({
            location,
        });
    }

    get hashtagsNumber(): number {
        return (
            (this.newSocialPostContext.selectedHashtagsList()?.length || 0) +
            (this.newSocialPostContext
                .postForm()
                .get('post.text')
                ?.value?.match(/#[A-Za-zÀ-ÿ\d]+ ?/g)?.length || 0)
        );
    }

    get hasTextError(): boolean {
        return (
            (this.newSocialPostContext.postForm().get('post.text')?.invalid &&
                this.newSocialPostContext.postForm().get('post.text')?.touched) ??
            false
        );
    }

    get formErrors(): string[] {
        const attachments = this.newSocialPostContext.postForm().get('post.attachments')?.value as Media[];
        const errors: string[] = [];
        if (this.data.post?.published === PostPublicationStatus.PUBLISHED) {
            return errors;
        }
        if (!this.postText?.trim()?.length) {
            errors.push(this.socialPostTranslate.write_message);
        }
        if (!this.attachments?.length) {
            errors.push(this.socialPostTranslate.add_image);
        }
        if (!this.platforms?.value?.filter((p) => !!p.checked).length) {
            errors.push(this.socialPostTranslate.choose_one_platform);
        }
        if (this.hashtagsNumber > this.MAX_HASHTAGS_NUMBER) {
            errors.push(
                this._translate.instant('social_posts.new_social_post.limit_reached_error', {
                    max_hashtags_number: this.MAX_HASHTAGS_NUMBER,
                })
            );
        }
        if (this.hasMediasErrors()) {
            errors.push(this.socialPostTranslate.edit_or_delete_medias);
        }
        if (this._isCarouselVideosAndFacebookChecked()) {
            errors.push(this.socialPostTranslate.carousel_facebook_videos_error);
        }
        if (this._isNoImageMediaPostAndMapstrChecked()) {
            errors.push(this.socialPostTranslate.no_image_and_mapstr_checked_error);
        }
        if (this._isNotOnlyOneImageMediaAndOnlyMapstrChecked()) {
            errors.push(this.socialPostTranslate.not_only_one_image_and_only_mapstr_checked_error);
        }
        if (this.isMapstrPlatformChecked && !this.newSocialPostContext.postForm().get('post.title')?.value) {
            errors.push(this.socialPostTranslate.add_title);
        }
        if (this._isSingleVideoAttachmentPost(attachments)) {
            errors.push(this.socialPostTranslate.single_video_error_message);
        }
        return errors;
    }

    private _isSingleVideoAttachmentPost(attachments: Media[]): boolean {
        return (
            attachments.length === 1 &&
            attachments[0].isVideo() &&
            !this.isReel &&
            this.getSelectedPlatforms().includes(PlatformKey.INSTAGRAM)
        );
    }

    mapstrCtaButtonTypeDisplayWith = (mapstrCtaButtonType: MapstrCtaButtonType): string =>
        this._enumTranslatePipe.transform(mapstrCtaButtonType, 'mapstr_cta_button_type');

    private _isSelectedPlatformsEmpty(): boolean {
        const selectedPlatforms = this.newSocialPostContext
            .postForm()
            .get('meta.platforms')
            ?.value.filter((platform) => platform.checked);
        return selectedPlatforms.length === 0;
    }

    private _getPostTypeFromAttachments(): PostType {
        if (this.isReel) {
            return PostType.REEL;
        }
        if (this.attachments?.[0]) {
            if (this.attachments?.length > 1) {
                return PostType.CAROUSEL;
            } else if (this.attachments[0]) {
                return this.attachments[0]?.type === 'photo' ? PostType.IMAGE : PostType.REEL;
            }
        }
        return PostType.IMAGE;
    }

    private _getPostTimeString(date: Date): string {
        const nearestQuarter = Math.ceil(date.getMinutes() / 15) * 15;
        date.setMinutes(nearestQuarter);
        const formattedDate = getTimeStringFromDate(date).split(' ')[0];
        return formattedDate;
    }

    private _initFormPost(post: Post, isDisabled: boolean): void {
        this.shouldDuplicatePost = post.published === 'error' ? false : !!post.shouldDuplicateInOtherPlatforms;
        const publicationDate =
            post.plannedPublicationDate?.getTime() > Date.now()
                ? post.plannedPublicationDate
                : DateTime.now().plus({ days: 1, hours: 1 }).toJSDate();

        this.newSocialPostContext.postForm.update(() =>
            this._fb.group({
                post: this._fb.group({
                    title: [{ value: post.title ?? '', disabled: isDisabled }],
                    callToAction: this._fb.group({
                        actionType: [
                            {
                                value: this._mapActionTypeToMapstrCreatePostButtonType(
                                    this.newSocialPostContext.post().callToAction?.actionType
                                ),
                                disabled: isDisabled,
                            },
                        ],
                        url: [{ value: post.callToAction?.url ?? '', disabled: isDisabled }, Validators.pattern('https://.*')],
                    }),
                    text: [{ value: post.text, disabled: isDisabled }, Validators.required],
                    language: [{ value: ApplicationLanguage.FR, disabled: isDisabled }, Validators.required],
                    attachments: this._fb.array(post?.attachments.map((media) => new Media(media)) || []),
                    attachmentsName: [{ value: '', disabled: isDisabled }],
                    thumbnail: [{ value: post.thumbnail, disabled: isDisabled }],
                    thumbnailOffsetTimeInMs: [{ value: post.thumbnailOffsetTimeInMs, disabled: isDisabled }],
                    location: [{ value: post.location, disabled: isDisabled }],
                    plannedPublicationDate: [{ value: publicationDate, disabled: isDisabled }],
                    category: PostSource.SOCIAL,
                    userTags: [{ value: null, disabled: isDisabled }],
                    userTagsList: [{ value: [[]], disabled: isDisabled }],
                    hashtags: this._fb.group({
                        selected: post.hashtags.selected,
                        suggested: post.hashtags.suggested,
                    }),
                }),
                meta: this._fb.group({
                    date: this._fb.group({
                        postDateStatus: [{ value: PostDateStatus.NOW, disabled: isDisabled }],
                        postDate: [publicationDate],
                        postTime: [this._getPostTimeString(publicationDate), isControlValueInTimeFormat(this._translate)],
                    }),
                    platforms: this._fb.array([], CheckedGreaterThan(0)),
                }),
            })
        );

        // update postTime if postDate is changed
        this.newSocialPostContext
            .postForm()
            .get('meta.date.postDate')
            ?.valueChanges.pipe(
                distinctUntilChanged((prev: Date | null, curr: Date | null) => prev?.getTime() === curr?.getTime()),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe((date) => {
                if (!date) {
                    const today = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
                    this.newSocialPostContext.postForm().get('meta.date.postDate')?.setValue(today);
                    return;
                }

                if (isSameDay(date, new Date())) {
                    const currentTimePlusOneHour = new Date();
                    currentTimePlusOneHour.setHours(currentTimePlusOneHour.getHours() + 1);

                    const currentTime = currentTimePlusOneHour.getHours().toString().padStart(2, '0') + ':30';
                    this.newSocialPostContext.postForm().get('meta.date.postTime')?.setValue(currentTime);
                }
            });
    }

    private _addHashtagsToText(text: string, hashtags: string[]): string {
        const hashtagsAsText = hashtags.join(' ') || '';
        return `${text}\n${hashtagsAsText}`;
    }

    /**
     * Facebook (and Instagram) make multiple formatting before publishing a post.
     *
     * 1 - remove multiple blank lines and replace them by one blank line
     * 2 - remove spaces and tabs on each lines
     * 3 - trim the posts
     */
    private _formatSocialNetworkPostText(postText: string): string {
        let result = postText;

        const twoBlankLinesRegex = /\n\s*\n\s*\n/g;
        result = result.replace(twoBlankLinesRegex, '\n\n');

        const spacesAndTabsAtStartAndEndOfLineRegex = /^[ \t]+|[ \t]+$/gm;
        result = result.replace(spacesAndTabsAtStartAndEndOfLineRegex, '');

        result = result.trim();

        return result;
    }

    private _extractBannedHashtags(hashtagsText: string[]): string[] {
        return hashtagsText.reduce(
            (acc, next: any) =>
                bannedHashtags.includes(next) || next.includes(PlatformKey.FACEBOOK) || next.includes(PlatformKey.INSTAGRAM)
                    ? acc.concat(next)
                    : acc,
            []
        );
    }

    private _autoSaveContent(): void {
        if (
            this.data.post?.published === PostPublicationStatus.DRAFT ||
            (this.data.post?.published === PostPublicationStatus.PENDING && !this.data.post?.plannedPublicationDate)
        ) {
            const postFormGroup = this.newSocialPostContext.postForm().get('post') as UntypedFormGroup;
            const dateFormGroup = this.newSocialPostContext.postForm().get('meta.date') as UntypedFormGroup;
            const platformsFormGroup = this.platforms;
            this._listenToChangesOnFormGroup(postFormGroup, ({ field, value }) => {
                this.newSocialPostContext.shouldAutoSave$.next({
                    field,
                    value,
                });
            });
            this._listenToChangesOnFormGroup(dateFormGroup, () =>
                this.patchPublicationDate(this.data.post.published === PostPublicationStatus.DRAFT)
            );
            this._listenToChangesOnFormGroup(platformsFormGroup, () => {
                this.newSocialPostContext.shouldAutoSave$.next({
                    field: 'keys',
                    value: this.checkedPlatforms,
                });
                this.reloadMedias(this.data.post);
            });
        }
    }

    private _listenToChangesOnFormGroup(formGroup: UntypedFormGroup | UntypedFormArray, onValueChanged: (args) => void): void {
        Object.keys(formGroup.controls).forEach((field) => {
            formGroup.controls[field].valueChanges
                .pipe(
                    tap(() => {
                        this.autoSaveState = AutoSaveState.SAVING;
                    }),
                    debounceTime(500)
                )
                .subscribe((value) => {
                    onValueChanged({ field, value });
                });
        });
    }

    private _isCarouselVideosAndFacebookChecked(): boolean {
        return (
            this.getSelectedMedias().length > 1 &&
            this.getSelectedMedias().some((m) => !!m.isVideo()) &&
            this.getSelectedPlatforms().includes(PlatformKey.FACEBOOK)
        );
    }

    private _isNoImageMediaPostAndMapstrChecked(): boolean {
        return (
            !this.getSelectedMedias().some((m) => !!m.isImage()) &&
            this.getSelectedPlatforms().length > 1 &&
            this.getSelectedPlatforms().includes(PlatformKey.MAPSTR)
        );
    }

    private _isNotOnlyOneImageMediaAndOnlyMapstrChecked(): boolean {
        return (
            (this.getSelectedMedias().length !== 1 || !this.getSelectedMedias()[0].isImage()) &&
            this.getSelectedPlatforms().length === 1 &&
            this.getSelectedPlatforms()[0] === PlatformKey.MAPSTR
        );
    }

    private _isPostFormEmpty(): boolean {
        const hasAttachments = this.newSocialPostContext.postForm().get('post')?.get('attachments')?.value?.length > 0;
        const hasText = this.newSocialPostContext.postForm().get('post.text')?.value?.length > 0;
        const isDraft = this.data.post.published === PostPublicationStatus.DRAFT;
        const hasFeedback = !!this.data.post.feedbackId;
        return !hasAttachments && !hasText && isDraft && !hasFeedback;
    }

    private _getPostDateStatus(post: Post): PostDateStatus {
        switch (post.published) {
            case PostPublicationStatus.PUBLISHED:
            case PostPublicationStatus.ERROR:
            case PostPublicationStatus.REJECTED:
                return PostDateStatus.NOW;
            case PostPublicationStatus.PENDING:
                return PostDateStatus.LATER;
            case PostPublicationStatus.DRAFT:
                if (this._isPostFormEmpty()) {
                    // the post is a new draft
                    return PostDateStatus.LATER;
                } else {
                    return PostDateStatus.DRAFT;
                }
            default:
                return PostDateStatus.DRAFT;
        }
    }

    private _initPostDateStatus(post: Post): void {
        const postDateStatus = this._getPostDateStatus(post);
        this.newSocialPostContext.postForm().get('meta.date.postDateStatus')?.patchValue(postDateStatus);
    }
}
