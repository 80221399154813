import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { MaintenanceService } from ':core/maintenance/maintenance.service';
import { InformationUpdatesService } from ':core/services/information-update.service';
import { RestaurantsService } from ':core/services/restaurants.service';

import * as AdminActions from './admin.actions';

@Injectable()
export class AdminEffect {
    loadUserRestaurants$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AdminActions.loadUserRestaurants),
            mergeMap(() =>
                this._restaurantsService.index('name access logo').pipe(
                    map((restaurants) => AdminActions.editUserRestaurants({ userRestaurants: restaurants })),
                    catchError((err) => {
                        console.warn('err :', err);
                        return EMPTY;
                    })
                )
            )
        )
    );

    loadMaintenance$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AdminActions.loadMaintenance),
            mergeMap(() =>
                this._maintenanceService
                    .getMaintenanceStatusFromServer()
                    .pipe(map((res) => res.data))
                    .pipe(
                        map((mode) => AdminActions.editMaintenanceMode({ maintenance: mode })),
                        catchError(() => EMPTY)
                    )
            )
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _informationUpdatesService: InformationUpdatesService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _maintenanceService: MaintenanceService
    ) {}
}
