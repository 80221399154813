import { AsyncPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    ElementRef,
    OnDestroy,
    OnInit,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import Uppy from '@uppy/core';
import { asapScheduler, BehaviorSubject, combineLatest, interval, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import {
    DEFAULT_MAX_IMAGE_SIZE,
    DEFAULT_MAX_VIDEO_SIZE,
    getTypeFromMimetype,
    isNotNil,
    MediaConvertedStatus,
    MediaType,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { CircleProgressComponent } from ':shared/components-v3/circle-progress/circle-progress.component';
import { FileError, UppyComponent, UppyFileWithError } from ':shared/components/uppy/uppy.component';
import { ErrorFileCategory } from ':shared/enums/error-file-category.enum';
import { bytesConverter, BytesUnit } from ':shared/helpers/bytes-converter';
import { Media, UppyTemporaryMedia } from ':shared/models';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

import { MediaService } from '../../media/media.service';
import * as GalleryImportMediaActions from './gallery-import-media.actions';
import { selectCurrentFolderId, selectIsGalleryOpen } from './gallery-import-media.reducer';
import { GalleryImportMediaService } from './gallery-import-media.service';

/** This component is supposed to be instanciated once for the whole application. */
@Component({
    selector: 'app-gallery-import-media',
    templateUrl: './gallery-import-media.component.html',
    standalone: true,
    imports: [TranslateModule, CircleProgressComponent, MalouSpinnerComponent, UppyComponent, AsyncPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryImportMediaComponent implements OnDestroy, OnInit {
    private _fileInput = viewChild.required<ElementRef>('fileInput');

    readonly totalFiles = signal(0);
    readonly isImporting = signal(false);

    readonly uploadProgress$ = new BehaviorSubject(0);
    loaderHeaderText = this._translateService.instant('gallery.adding_media');

    readonly showUppy = computed(() => this._isGalleryOpen() || this.isImporting());
    readonly disableDragAndDrop = computed(() => !this._isGalleryOpen());

    readonly filesErrors: WritableSignal<string[]> = signal([]);

    readonly DEFAULT_MAX_VIDEO_SIZE = DEFAULT_MAX_VIDEO_SIZE;
    readonly DEFAULT_MAX_IMAGE_SIZE = DEFAULT_MAX_IMAGE_SIZE;

    private readonly _currentFolderId$ = this._store.select(selectCurrentFolderId);
    private readonly _currentFolderId = toSignal(this._currentFolderId$);
    private _folderIdForImport: null | string | undefined;

    private readonly _isGalleryOpen$ = this._store.select(selectIsGalleryOpen);
    private readonly _isGalleryOpen = toSignal(this._isGalleryOpen$);

    private readonly _createdMedia$: Subject<Media[]> = new Subject();

    private readonly _killStatus$ = new Subject();

    constructor(
        private readonly _filePickerService: GalleryImportMediaService,
        private readonly _mediaService: MediaService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        public readonly screenSizeService: ScreenSizeService,
        private readonly _store: Store,
        private readonly _httpErrorPipe: HttpErrorPipe
    ) {
        effect(() => {
            asapScheduler.schedule(() =>
                this._store.dispatch({ type: GalleryImportMediaActions.setFilesErrors.type, filesError: this.filesErrors() })
            );
        });
    }

    public openFilePicker(): void {
        this._fileInput().nativeElement.click();
    }

    ngOnInit(): void {
        this._filePickerService.importElement = this;
    }

    ngOnDestroy(): void {
        this._killStatus$.next(true);
        this._filePickerService.importElement = undefined;
    }

    finishUpload(results: { successful: UppyTemporaryMedia[]; unsuccessful: UppyFileWithError[] }): void {
        this.loaderHeaderText = this._translateService.instant('gallery.adding_media');

        this._mediaService
            .createManyMedias(results.successful, this._folderIdForImport)
            .pipe(map((res) => res.data))
            .subscribe({
                next: (createdMedia) => {
                    this._createdMedia$.next(createdMedia);
                },
                error: (err) => {
                    console.warn('err :>> ', err);
                    this._killStatus$.next(true);
                    this.isImporting.set(false);
                    this.totalFiles.set(0);
                    if (err.status !== 403) {
                        this._toastService.openErrorToast(this._translateService.instant('gallery.download_failed'));
                    }
                },
            });
    }

    startWatcher({ uppyInstance }: { uppyInstance: Uppy }): void {
        this.isImporting.set(true);

        combineLatest([this._createdMedia$, interval(1000)])
            .pipe(
                filter(([createdMedia]) => isNotNil(createdMedia) && createdMedia.length > 0),
                switchMap(([createdMedia]) => this._mediaService.getManyMedia(createdMedia.map((m) => m.id))),
                takeUntil(this._killStatus$)
            )
            .subscribe({
                next: (res) => {
                    if (!this._isStillProcessingMediaConversion(res.data)) {
                        this.isImporting.set(false);
                        this.totalFiles.set(0);
                        this._createdMedia$.next([]);
                        this._killStatus$.next(true);
                        this._store.dispatch({ type: GalleryImportMediaActions.setCreatedMedia.type, createdMedia: res.data });
                    }
                },
                error: (err) => {
                    this._killStatus$.next(true);
                    console.warn('err :>>', err);
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
                complete: () => {
                    uppyInstance.cancelAll();
                },
            });
    }

    startUploadProcess(): void {
        this.loaderHeaderText = this._translateService.instant('gallery.import_in_progress');
    }

    updateProgress(event: number): void {
        this.uploadProgress$.next(event);
    }

    buildErrorsArray(files: FileError[]): void {
        this.filesErrors.set(
            files.map(({ file, errorCategory }) => {
                switch (errorCategory) {
                    case ErrorFileCategory.HEVC:
                        return this._translateService.instant('gallery.error_file_hevc', { fileName: file.name });

                    case ErrorFileCategory.TOO_BIG:
                        const isVideo = getTypeFromMimetype(file.type) === MediaType.VIDEO;
                        const maxFileSize = isVideo ? DEFAULT_MAX_VIDEO_SIZE : DEFAULT_MAX_IMAGE_SIZE;
                        return this._translateService.instant('gallery.file_size_too_big', {
                            fileName: file.name,
                            fileSize: this._getMegaOctetFromBytes(file.size),
                            maxSize: this._getMegaOctetFromBytes(maxFileSize),
                        });
                    default:
                        return this._translateService.instant('gallery.unknown_error_upload', { fileName: file.name });
                }
            })
        );
    }

    setTotalFiles(totalFiles: number): void {
        this.totalFiles.set(totalFiles);
    }

    beforeStart(): void {
        this.filesErrors.set([]);
        this._folderIdForImport = this._currentFolderId();
        this.isImporting.set(true);
    }

    importMediaTargetDragAndDropArea(): HTMLElement {
        return document.getElementById('importMediaTargetDragAndDropArea')!;
    }

    private _getMegaOctetFromBytes(bytes: number): number {
        return bytesConverter(bytes, BytesUnit.MO);
    }

    private _isStillProcessingMediaConversion(media: Media[]): boolean {
        if (!media.length) {
            return false;
        }
        return media.some((m) => m.convertedStatus === MediaConvertedStatus.PROGRESSING);
    }
}
