import { ChangeDetectionStrategy, Component, Inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ToastService } from ':core/services/toast.service';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { Restaurant } from ':shared/models/restaurant';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import { WheelsOfFortuneService } from '../wheels-of-fortune.service';

@Component({
    selector: 'app-subscription-request-modal',
    templateUrl: './subscription-request-modal.component.html',
    styleUrls: ['./subscription-request-modal.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatTooltipModule, TranslateModule, ImagePathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionRequestModalComponent {
    readonly SvgIcon = SvgIcon;
    readonly requestSent = signal(false);
    readonly sendingRequest = signal(false);

    constructor(
        private readonly _wheelsOfFortuneService: WheelsOfFortuneService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        private readonly _dialogRef: MatDialogRef<SubscriptionRequestModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            restaurants: Restaurant[];
        }
    ) {
        const subscriptionRequestSentRestaurantIds = JSON.parse(
            localStorage.getItem(LocalStorageKey.SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS) || '[]'
        );
        this.requestSent.set(this.data.restaurants.every((restaurant) => subscriptionRequestSentRestaurantIds.includes(restaurant.id)));
    }

    close(): void {
        this._dialogRef.close();
    }

    sendRequest(): void {
        this.sendingRequest.set(true);

        this._wheelsOfFortuneService.sendSubscriptionRequest(this.data.restaurants).subscribe(() => {
            this._toastService.openSuccessToast(
                this._translateService.instant('wheel_of_fortune.subscription_request_modal.request_successfully_sent')
            );
            this._updateStorageWithSentRestaurantIds();
            this.close();
        });
    }

    private _updateStorageWithSentRestaurantIds(): void {
        const subscriptionRequestSentRestaurantIds = JSON.parse(
            localStorage.getItem(LocalStorageKey.SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS) || '[]'
        );
        this.data.restaurants.forEach((restaurant) => {
            subscriptionRequestSentRestaurantIds.push(restaurant.id);
        });
        const uniqSubscriptionRequestSentRestaurantIds = Array.from(new Set(subscriptionRequestSentRestaurantIds));
        const uniqSubscriptionRequestSentRestaurantIdsString = JSON.stringify(uniqSubscriptionRequestSentRestaurantIds);
        localStorage.setItem(LocalStorageKey.SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS, uniqSubscriptionRequestSentRestaurantIdsString);
    }
}
