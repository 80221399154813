import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    inject,
    Injector,
    input,
    model,
    OnInit,
    output,
    signal,
    untracked,
    WritableSignal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, interval, of, Subscription, switchMap, takeWhile } from 'rxjs';

import { FAILED_MEDIA_AI_DESCRIPTION, HeapEventName, TimeInMilliseconds } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { HeapService } from ':core/services/heap.service';
import { MediaService } from ':modules/media/media.service';
import { NewPostModalAiContext } from ':modules/posts/context/new-post-modal-ai.context';
import { NewSocialPostAiContext } from ':modules/social-posts/new-social-post-modal/context/new-social-post-ai.context';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { DefaultPrompt, OpenaiPromptComponent } from ':shared/openai-prompt/openai-prompt.component';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

import { InfiniteTextSlideComponent } from '../infinite-text-slide/infinite-text-slide.component';
import { TextAreaComponent } from '../text-area/text-area.component';

export enum PostCaptionDisplayState {
    OPEN = 'open',
    CLOSED = 'closed',
}

// TODO: clean file when removing feature toggle 'release-post-lambda-advanced-settings'
@Component({
    selector: 'app-post-caption-ai-generation',
    templateUrl: './post-caption-ai-generation.component.html',
    styleUrls: ['./post-caption-ai-generation.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        TextAreaComponent,
        OpenaiPromptComponent,
        CdkTextareaAutosize,
        MatButtonModule,
        ReactiveFormsModule,
        ApplyPurePipe,
        MalouSpinnerComponent,
        SlideToggleComponent,
        InfiniteTextSlideComponent,
    ],
})
export class PostCaptionAiGenerationComponent implements OnInit {
    readonly displayState = input.required<PostCaptionDisplayState>();
    readonly defaultPrompt = input.required<DefaultPrompt>();
    readonly promptFormControl = input(new FormControl<string>(''));
    readonly isSmallScreen = input(false);
    readonly onClose = output<void>();
    readonly onGeneratePromptClick = output<{ shouldUseImageAnalysis: boolean }>();
    readonly lastPrompt = model('');
    readonly isGenerating = model(false);
    readonly isSeo = input.required<boolean>();

    private readonly _translateService = inject(TranslateService);
    private readonly _newPostModalAiContext = inject(NewPostModalAiContext);
    private readonly _newSocialPostModalAiContext = inject(NewSocialPostAiContext);
    private readonly _injector = inject(Injector);
    private readonly _mediaService = inject(MediaService);
    private readonly _heapService = inject(HeapService);

    readonly SvgIcon = SvgIcon;
    readonly isOpen = computed(() => this.displayState() === PostCaptionDisplayState.OPEN);
    readonly BasePrompt = DefaultPrompt;

    // TODO: delete when removing feature toggle 'release-post-lambda-advanced-settings'
    readonly PROMPT_IDEAS = {
        [DefaultPrompt.INTRODUCE_DISH]: this._translateService.instant('openai.introduce_dish_post'),
        [DefaultPrompt.ANNOUNCE_EVENT]: this._translateService.instant('openai.announce_event'),
        [DefaultPrompt.WRITE_CONTEST]: this._translateService.instant('openai.contest_post'),
    };

    readonly selectedPromptIndex = signal(
        Object.keys(this.PROMPT_IDEAS).findIndex((key) => this.promptFormControl().value?.trim().includes(this.PROMPT_IDEAS[key].trim()))
    );

    readonly newPostAiContext: WritableSignal<NewSocialPostAiContext | NewPostModalAiContext> = signal(this._newSocialPostModalAiContext);
    readonly isMultipleCaptionsOptionsFeatureEnabled = computed<boolean>(() =>
        this.newPostAiContext().isPostLambdaAdvancedSettingsEnabled()
    );
    readonly shouldUseImageAnalysis = signal<boolean>(true);
    readonly shouldHideImageAnalysisToggle = computed(() => !this.isSeo() && this._newSocialPostModalAiContext.isReel());
    readonly usableAiDescriptionForImageAnalysis = signal<string | null>(null);
    readonly hasFailedToGetAiDescription = signal(false);
    readonly disableImageAnalysisTooltipText = computed(() => {
        if (this.hasFailedToGetAiDescription()) {
            return this._translateService.instant('social_posts.new_social_post.image_analysis.cant_analyze_this_image');
        }
        if (!this.newPostAiContext().isPostWithAttachments()) {
            return this._translateService.instant('social_posts.new_social_post.image_analysis.add_image_to_use');
        }
        const usablePhotoForImageAnalysis = this.newPostAiContext().usablePhotoForImageAnalysis();
        if (!usablePhotoForImageAnalysis) {
            return this._translateService.instant('social_posts.new_social_post.image_analysis.cant_analyze_videos');
        }
        const usableAiDescriptionForImageAnalysis = this.usableAiDescriptionForImageAnalysis();
        if (!usableAiDescriptionForImageAnalysis) {
            return this._translateService.instant('social_posts.new_social_post.image_analysis.waiting_for_image_analysis');
        }
        if (usableAiDescriptionForImageAnalysis === FAILED_MEDIA_AI_DESCRIPTION) {
            return this._translateService.instant('social_posts.new_social_post.image_analysis.cant_analyze_this_image');
        }
        return '';
    });

    readonly isImageAnalysisToggleChecked = computed(() => !this.disableImageAnalysisTooltipText() && this.shouldUseImageAnalysis());
    readonly previousUsableImageId = signal<string | null>(null);
    private _waitForIntervalSubscription: Subscription | null = null;

    ngOnInit(): void {
        const localStorageDefaultOption = localStorage.getItem(LocalStorageKey.SHOULD_USE_IMAGE_ANALYSIS_IN_POSTS_BY_DEFAULT);
        if (localStorageDefaultOption) {
            this.shouldUseImageAnalysis.set(localStorageDefaultOption === 'true');
        }
        this.newPostAiContext.set(this.isSeo() ? this._newPostModalAiContext : this._newSocialPostModalAiContext);
        this.promptFormControl().valueChanges.subscribe((value: string) => {
            this.lastPrompt.update(() => value);
            this.selectedPromptIndex.update(() =>
                Object.keys(this.PROMPT_IDEAS).findIndex((key) => value.trim().includes(this.PROMPT_IDEAS[key].trim()))
            );
        });

        effect(
            () => {
                const media = this.newPostAiContext().usablePhotoForImageAnalysis();
                if (!media) {
                    this.usableAiDescriptionForImageAnalysis.set(null);
                    return;
                }
                if (media.aiDescription) {
                    this.usableAiDescriptionForImageAnalysis.set(media.aiDescription);
                    return;
                }

                const isSameMediaAsBefore = untracked(() => !this.previousUsableImageId() || media.id === this.previousUsableImageId());
                untracked(() => this.previousUsableImageId.set(media.id));
                if (!isSameMediaAsBefore) {
                    this.usableAiDescriptionForImageAnalysis.set(null);
                }

                this._waitForAiDescription(media.id);
            },
            { allowSignalWrites: true, injector: this._injector }
        );
    }

    toggleUseImageAnalysis(): void {
        this.shouldUseImageAnalysis.update((shouldUseImageAnalysis) => !shouldUseImageAnalysis);
        localStorage.setItem(LocalStorageKey.SHOULD_USE_IMAGE_ANALYSIS_IN_POSTS_BY_DEFAULT, this.shouldUseImageAnalysis().toString());
        this._heapService.track(HeapEventName.TOGGLE_USE_IMAGE_ANALYSIS_IN_POSTS, { isUsingImageAnalysis: this.shouldUseImageAnalysis() });
    }

    close(): void {
        this.onClose.emit();
    }

    generatePrompt(): void {
        this.isGenerating.set(true);

        this.onGeneratePromptClick.emit({ shouldUseImageAnalysis: this.shouldUseImageAnalysis() });
        if (this.isSmallScreen()) {
            this.close();
        }
    }

    getObjectKeys = (obj: object): string[] => Object.keys(obj);

    onPromptIdeaClick(promptKey: string): void {
        const prompt = this.PROMPT_IDEAS[promptKey];
        this.promptFormControl().setValue(prompt);
        this.lastPrompt.update(() => prompt);
    }

    onCaptionProposalClick(caption: string): void {
        this.newPostAiContext().onCaptionProposalSelection(caption);
    }

    private _waitForAiDescription(mediaId: string): void {
        const MAX_TIME = 30 * TimeInMilliseconds.SECOND;
        const CHECK_INTERVAL = 2 * TimeInMilliseconds.SECOND;

        if (this._waitForIntervalSubscription) {
            this._waitForIntervalSubscription.unsubscribe();
            this._waitForIntervalSubscription = null;
        }

        this._waitForIntervalSubscription = interval(CHECK_INTERVAL)
            .pipe(
                takeWhile((_, index) => index * CHECK_INTERVAL < MAX_TIME),
                switchMap(() =>
                    this._mediaService.getMediumById(mediaId).pipe(
                        catchError((error) => {
                            console.error('Fetch error:', error);
                            this.hasFailedToGetAiDescription.set(true);
                            return of(null);
                        })
                    )
                ),
                takeWhile((fetchedMedia) => !fetchedMedia?.data?.aiDescription, true)
            )
            .subscribe({
                next: (fetchedMedia) => {
                    if (fetchedMedia?.data?.aiDescription) {
                        this.hasFailedToGetAiDescription.set(false);
                        this.usableAiDescriptionForImageAnalysis.set(fetchedMedia.data.aiDescription);
                    }
                },
                complete: () => {
                    this.hasFailedToGetAiDescription.set(!this.usableAiDescriptionForImageAnalysis());
                },
            });
    }
}
