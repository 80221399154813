<ng-container *ngTemplateOutlet="showCloseModal ? closeModal : pageWrapper"></ng-container>

<ng-template #closeModal>
    <div class="h-full w-[100vw]">
        <app-close-without-saving-modal (onConfirm)="close()" (onCancel)="showCloseModal = false"></app-close-without-saving-modal>
    </div>
</ng-template>

<ng-template #pageWrapper>
    <ng-container *ngTemplateOutlet="isInitialLoading ? shimmer : page"></ng-container>
</ng-template>

<ng-template #page>
    <div class="malou-dialog malou-dialog-mobile flex h-full !w-[100vw] flex-col md:overflow-x-hidden">
        <div class="malou-dialog__header">
            <span>
                {{ isReel ? ('social_posts.create_reel' | translate) : ('social_posts.create_post' | translate) }}
            </span>
            <div class="flex items-center">
                @if (data.allPostIds?.length) {
                    <div class="flex items-center">
                        <span class="malou-text-13--semibold !mr-3.5">
                            {{ postIndex + 1 }} / {{ data.allPostIds?.length }}
                            {{ 'social_posts.new_social_post.posts_not_completed' | pluralTranslate: data.allPostIds?.length || 1 }}
                        </span>
                        @if ((data.allPostIds?.length ?? 0) > 1) {
                            <button
                                class="malou-btn-icon--secondary btn-xl !mr-3.5"
                                data-cy="arrowBackIcon"
                                mat-icon-button
                                [disabled]="isFirstPost | applyPure: postIndex"
                                (click)="changePost(-1)">
                                <mat-icon class="icon-btn" [svgIcon]="SvgIcon.CHEVRON_LEFT"></mat-icon>
                            </button>
                        }
                        @if ((data.allPostIds?.length ?? 0) > 1) {
                            <button
                                class="malou-btn-icon--secondary btn-xl !mr-3.5"
                                mat-icon-button
                                [disabled]="isLastPost | applyPure: postIndex : data.allPostIds?.length"
                                (click)="changePost(1)">
                                <mat-icon class="icon-btn" [svgIcon]="SvgIcon.CHEVRON_RIGHT"></mat-icon>
                            </button>
                        }
                    </div>
                }
                <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="openSaveBeforeClose()"></mat-icon>
            </div>
        </div>
        <hr class="border-malou-color-background-dark" />
        <div class="malou-dialog__body !m-0 flex grow gap-x-5 !pr-0 md:!flex-col md:p-0">
            <ng-container *ngTemplateOutlet="picEditor"></ng-container>
            <ng-container *ngTemplateOutlet="postFormTemplate"></ng-container>
            <app-post-right-side-panel
                class="flex-4 md:w-full md:px-5 md:pb-20"
                [hidden]="newSocialPostContext.isSmallScreen() && newSocialPostContext.showPicEditor()"></app-post-right-side-panel>
        </div>
        <ng-container *ngTemplateOutlet="stepButtons"></ng-container>
    </div>
</ng-template>

<ng-template #picEditor>
    <section class="section-size w-[28vw] p-4 md:h-[85%] md:w-full" [hidden]="isLoading || !newSocialPostContext.showPicEditor()">
        <div class="h-full">
            @if (isReel) {
                <app-reels-editor
                    [media]="getSelectedMedias()[0]"
                    [thumbnail]="newSocialPostContext.postForm().get('post.thumbnail')?.value"
                    [thumbnailOffsetTimeInMs]="newSocialPostContext.postForm().get('post.thumbnailOffsetTimeInMs')?.value"
                    [disabled]="data.isDisabled || isUpdatingPost()"
                    (fileChanged)="onFileChange($event)"
                    (mediaSelected)="onMediasSelect($event)"
                    (thumbnailChange)="onThumbnailChange($event)"></app-reels-editor>
            } @else {
                <app-media-editor
                    [acceptedMediaTypes]="DEFAULT_ACCEPTED_MEDIA_TYPES"
                    [maxVideoSize]="DEFAULT_MAX_VIDEO_SIZE"
                    [maxImageSize]="DEFAULT_MAX_IMAGE_SIZE"
                    [maxMedia]="10"
                    [disabled]="data.isDisabled || isUpdatingPost()"
                    [attachmentsName]="attachmentsName"
                    [igPlatformId]="igPlatformId"
                    [initialMedias]="getSelectedMedias()"
                    [initialUserTagsList]="userTagsList"
                    [isTaggable]="areFacebookOrInstagramPlatformsChecked"
                    (fileChanged)="onFileChange($event)"
                    (accountTagged)="onTagsChange($event)"
                    (mediasSelected)="onMediasSelect($event)"></app-media-editor>
            }
        </div>
    </section>
</ng-template>

<!-- POST BASIC FORM -->
<ng-template #postFormTemplate>
    <div class="flex-7 h-full md:h-auto" [hidden]="!newSocialPostContext.shouldShowFeedbacks()">
        <div class="h-full w-full">
            <ng-container *ngTemplateOutlet="formBody"></ng-container>
            <ng-container *ngTemplateOutlet="formFooter"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #formBody>
    <div class="h-[85%] w-full overflow-y-auto pb-5 pr-5 pt-3 md:h-auto md:p-5">
        <form [formGroup]="newSocialPostContext.postForm()">
            <span class="malou-text-10--medium text-malou-color-text-2">
                {{ 'social_posts.new_social_post.platforms' | translate }}
            </span>
            <div class="mt-3 flex items-center gap-x-3">
                @for (platform of platforms.controls; track platform) {
                    <div>
                        <app-platform-logo
                            matTooltip="{{ 'social_posts.new_social_post.platform_not_connected' | translate }}"
                            imgClasses="h-7.5 w-7.5 cursor-pointer"
                            [testId]="'social-post-platform-' + platform.value.key + '-btn'"
                            [matTooltipDisabled]="platform.value.connected || data.isDisabled"
                            [dynamicClasses]="{ 'opacity-50': !platform.value.connected || data.isDisabled || !platform.value.checked }"
                            [logo]="platform.value.key"
                            (click)="togglePlatformChecked(platform.value.key)"></app-platform-logo>
                    </div>
                }
            </div>

            <form class="mt-5 flex flex-col gap-y-5" [formGroup]="postGroup">
                @if (isMapstrPlatformChecked) {
                    <div>
                        <app-input-text
                            formControlName="title"
                            [title]="'social_posts.new_social_post.add_title' | translate"
                            [required]="true"
                            [maxLength]="40"
                            [showMaxLength]="true"
                            [inputType]="'url'"
                            [placeholder]="'social_posts.new_social_post.add_title' | translate">
                            <mat-icon
                                class="malou-color-text-2 !h-4 !w-4"
                                afterTitleContent
                                [svgIcon]="SvgIcon.INFO"
                                [matTooltip]="'social_posts.new_social_post.title_only_for_mapstr' | translate"></mat-icon>
                        </app-input-text>
                    </div>
                }

                @if (areFacebookOrInstagramPlatformsChecked) {
                    <div>
                        <div class="malou-text-10--regular malou-color-text-2 flex items-center gap-1 py-1">
                            <div>
                                {{ 'social_posts.new_social_post.add_location' | translate }}
                            </div>
                            @if (isMapstrPlatformChecked) {
                                <mat-icon
                                    class="malou-color-text-2 !h-4 !w-4"
                                    [svgIcon]="SvgIcon.INFO"
                                    [matTooltip]="
                                        'social_posts.new_social_post.location_only_for_facebook_and_instagram' | translate
                                    "></mat-icon>
                            }
                        </div>
                        <app-add-location
                            [location]="location"
                            [isLoadingLocation$]="isLoadingLocation$"
                            [platformSocialId]="platformSocialId"
                            [isDisabled]="data.isDisabled"
                            (locationChange)="onLocationChange($event)"></app-add-location>
                    </div>
                }

                <app-post-caption
                    [postDescriptionFormControl]="postDescriptionControl"
                    [isMapstrPlatformChecked]="isMapstrPlatformChecked"
                    [isReel]="isReel"></app-post-caption>

                @if (isMapstrPlatformChecked) {
                    <div formGroupName="callToAction">
                        <div class="malou-text-10--regular malou-color-text-2 flex items-center gap-1 py-1">
                            <div>
                                {{ 'social_posts.new_social_post.add_mapstr_cta_button_type' | translate }}
                            </div>
                            <div>*</div>
                            <mat-icon
                                class="malou-color-text-2 !h-4 !w-4"
                                [svgIcon]="SvgIcon.INFO"
                                [matTooltip]="'social_posts.new_social_post.button_cta_only_for_mapstr' | translate"></mat-icon>
                        </div>
                        <app-select
                            formControlName="actionType"
                            [testId]="'social-post-mapster-input'"
                            [placeholder]="'social_posts.new_social_post.add_mapstr_cta_button_type' | translate"
                            [values]="MapstrCtaButtonTypeValues"
                            [displayWith]="mapstrCtaButtonTypeDisplayWith">
                        </app-select>
                        @if (showMapstrCtaText) {
                            <div class="mt-5">
                                <app-input-text
                                    formControlName="url"
                                    placeholder="https://"
                                    [testId]="'social-post-mapster-cta-input'"
                                    [required]="true"
                                    [title]="'social_posts.new_social_post.add_mapstr_cta_text' | translate"
                                    [errorMessage]="
                                        postCallToActionUrlControl?.errors?.pattern
                                            ? ('social_posts.new_social_post.must_start_with_https' | translate)
                                            : null
                                    ">
                                </app-input-text>
                            </div>
                        }
                    </div>
                }
            </form>
            <!-- PUBLICATION DATE -->
            <form [formGroup]="dateGroup">
                <div class="malou-text-10--regular mt-7.5 w-full">
                    <span class="text-malou-color-text-1">{{ 'social_posts.new_social_post.publication_date' | translate }}</span>
                    <mat-radio-group class="mt-4 flex gap-x-4" color="primary" formControlName="postDateStatus">
                        <mat-radio-button class="malou-small-radio-button" data-testid="now-btn" [value]="postScheduleType.NOW">
                            <span>{{ 'social_posts.new_social_post.now' | translate }}</span>
                        </mat-radio-button>
                        <mat-radio-button class="malou-small-radio-button" data-testid="schedule-btn" [value]="postScheduleType.LATER">
                            <span>{{ 'social_posts.new_social_post.later' | translate }}</span>
                        </mat-radio-button>
                        <mat-radio-button class="malou-small-radio-button" data-testid="draft-btn" [value]="postScheduleType.DRAFT">
                            <span> {{ 'posts.new_post.draft' | translate }}</span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                @if ([postScheduleType.LATER, postScheduleType.DRAFT] | includes: postSchedule) {
                    <div class="mt-4 flex w-full gap-x-8">
                        <div class="grow">
                            <app-input-date-picker
                                formControlName="postDate"
                                [testId]="'social-post-date-input'"
                                [onTimeChange$]="newSocialPostContext.postForm().get('meta.date.postTime')?.valueChanges"
                                [time]="newSocialPostContext.postForm().get('meta.date.postTime')?.value"
                                [min]="minDate"></app-input-date-picker>
                        </div>
                        <div class="flex flex-col">
                            <mat-select
                                class="!m-0 mt-8 !h-0 !opacity-0"
                                formControlName="postTime"
                                panelClass="malou-select-panel"
                                placeholder="----"
                                [hideSingleSelectionIndicator]="true"
                                (selectionChange)="changeSelectedTime($event)">
                                @for (time of times; track time) {
                                    <mat-option
                                        [value]="time"
                                        [disabled]="isPastHour | applyPure: { hourWithMinute: time, date: postDate } || isUpdatingPost()">
                                        {{ time | formatTime: newSocialPostContext.currentLang() === 'en' }}
                                    </mat-option>
                                }
                            </mat-select>
                            <app-input-text
                                class="grow"
                                [svgIcon]="SvgIcon.CLOCK"
                                [errorMessage]="
                                    (isPastHour
                                    | applyPure
                                        : { hourWithMinute: newSocialPostContext.postForm().get('date.postTime')?.value, date: postDate })
                                        ? ('common.invalid_time' | translate)
                                        : ''
                                "
                                [formControlName]="'postTime'"
                                [inputType]="'time'"></app-input-text>
                        </div>
                    </div>
                }
            </form>
            <!-- END PUBLICATION DATE -->

            <!-- DUPLICATE -->
            @if (!isReel && !fromDuplicate) {
                <div class="mt-8 flex items-center gap-x-2">
                    @if (!newSocialPostContext.currentRestaurant().isBrandBusiness()) {
                        <mat-checkbox
                            data-testid="social-post-duplicate-post-input"
                            color="primary"
                            [checked]="shouldDuplicatePost"
                            [disabled]="data.isDisabled || isUpdatingPost()"
                            (change)="toggleShouldDuplicatePost()">
                        </mat-checkbox>
                        <span class="malou-text-10--regular text-malou-color-text-1">{{
                            'social_posts.new_social_post.duplicate_in' | translate
                        }}</span>
                    }
                </div>
            } @else {
                @if (isInstagramChecked) {
                    <div class="mt-8 flex items-center gap-x-2">
                        <mat-checkbox color="primary" [checked]="shouldDisplayInFeed" (change)="toggleDisplayedInFeed()"></mat-checkbox>
                        <span class="malou-text-10--regular text-malou-color-text-1">{{
                            'social_posts.new_social_post.show_in_feed' | translate
                        }}</span>
                    </div>
                }
            }

            <!-- END DUPLICATE -->
        </form>
    </div>
</ng-template>

<ng-template #formFooter>
    <div
        class="malou-text-12--regular flex h-[15%] w-full items-center justify-between py-7.5 pr-5 text-malou-color-state-success md:hidden">
        @switch (autoSaveState) {
            @case ('SAVING') {
                <div class="flex items-center gap-x-2">
                    <mat-spinner class="stroke-malou-color-text-2" diameter="16"></mat-spinner>
                    <span class="text-malou-color-text-2">{{ 'social_posts.new_social_post.autosave_state_saving' | translate }}</span>
                </div>
            }
            @case ('SAVED') {
                <div class="flex gap-x-2" data-cy="autoSaveStatusDiv">
                    <mat-icon class="!h-4 !w-4" [svgIcon]="SvgIcon.CHECK"></mat-icon>
                    <span>{{ 'social_posts.new_social_post.autosave_state_saving' | translate }}</span>
                </div>
            }
            @default {
                <div></div>
            }
        }
        <div class="flex items-center gap-x-4">
            <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="openSaveBeforeClose()">
                {{ 'common.cancel' | translate }}
            </button>
            <div class="wrapper relative">
                @if (formErrors.length) {
                    <ng-container [ngTemplateOutlet]="formErrorTooltip"></ng-container>
                }

                <app-button
                    class="md:grow"
                    buttonClasses="!h-11 md:w-full {{ getTrackingNameFromPublishButtonType | applyPure: postSchedule }}"
                    testId="social-post-save-btn"
                    [disabled]="!canPublish()"
                    [text]="getPublishButtonText()"
                    [loading]="isUpdatingPost()"
                    (buttonClick)="save()"></app-button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #shimmer>
    <div class="flex h-full w-full gap-x-5">
        <div class="flex h-full flex-3 flex-col pl-6.5 pt-6.5">
            <app-skeleton
                skeletonClass="secondary-bg w-[50px] h-[50px]"
                flexDirection="flex-row"
                gapClass="gap-x-3"
                [count]="4"></app-skeleton>
            <div class="mt-5 grow">
                <app-skeleton skeletonClass="secondary-bg w-full h-full"></app-skeleton>
            </div>
            <app-skeleton skeletonClass="secondary-bg p-3 my-3">
                <app-skeleton skeletonClass="w-[70px] h-[70px]" flexDirection="flex-row" gapClass="gap-x-3" [count]="4"></app-skeleton>
            </app-skeleton>
        </div>
        <div class="flex-6 pt-6.5">
            <app-skeleton skeletonClass="secondary-bg h-[50px] w-full" [useContainer]="false"></app-skeleton>

            <app-skeleton skeletonClass="secondary-bg h-[150px] w-full mt-5" [useContainer]="false"></app-skeleton>

            <app-skeleton skeletonClass="secondary-bg h-[50px] w-full mt-3" [useContainer]="false"></app-skeleton>

            <app-skeleton skeletonClass="secondary-bg h-[170px] w-full mt-3" [useContainer]="false"></app-skeleton>

            <app-skeleton skeletonClass="secondary-bg h-[100px] w-full mt-3" [useContainer]="false"></app-skeleton>

            <div class="mt-7 flex w-full justify-end">
                <app-skeleton skeletonClass="secondary-bg h-[50px] w-[200px]"></app-skeleton>
            </div>
        </div>
        <div class="h-full flex-2">
            <app-skeleton skeletonClass="secondary-bg w-full h-full">
                <div class="h-full w-full p-6.5">
                    <div class="flex w-full items-center justify-between">
                        <app-skeleton skeletonClass="h-[30px] w-[170px]"></app-skeleton>
                        <mat-icon
                            class="!h-4 !w-4 cursor-pointer"
                            color="primary"
                            [svgIcon]="SvgIcon.CROSS"
                            (click)="openSaveBeforeClose()"></mat-icon>
                    </div>
                    @for (item of [1, 2]; track item) {
                        <div class="mt-7">
                            <div class="flex w-full items-center gap-x-3">
                                <app-skeleton appearance="circle" skeletonClass="h-[35px] w-[35px] rounded-full"></app-skeleton>
                                <app-skeleton skeletonClass="h-[25px] w-[170px] "></app-skeleton>
                            </div>
                            <app-skeleton skeletonClass="h-[25px] w-[200px] mt-3"></app-skeleton>
                            <app-skeleton skeletonClass="h-[25px] w-[160px] mt-3"></app-skeleton>
                            <app-skeleton skeletonClass="h-[25px] w-[200px] mt-3"></app-skeleton>
                        </div>
                    }
                </div>
            </app-skeleton>
        </div>
    </div>
</ng-template>

<ng-template #formErrorTooltip>
    <div class="form-errors-tooltip flex flex-col gap-y-2">
        @for (error of formErrors; track error) {
            <span class="text-white">- {{ error }}</span>
        }
    </div>
</ng-template>

<ng-template #stepButtons>
    <ng-container>
        <div class="absolute bottom-0 z-10 hidden w-full items-center justify-between gap-x-2 bg-white p-4 md:!flex">
            @if (newSocialPostContext.dialogStep() === newSocialPostContext.DialogStep.ONE) {
                <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="openSaveBeforeClose()">
                    {{ 'common.cancel' | translate }}
                </button>
            }
            @if (newSocialPostContext.dialogStep() === newSocialPostContext.DialogStep.TWO) {
                <button
                    class="malou-btn-raised--secondary !h-11 md:grow"
                    mat-raised-button
                    (click)="newSocialPostContext.changeDialogStep(newSocialPostContext.DialogStep.ONE)">
                    {{ 'common.previous' | translate }}
                </button>
            }
            @if (newSocialPostContext.dialogStep() === newSocialPostContext.DialogStep.ONE) {
                <button
                    class="malou-btn-raised--primary !h-11 md:grow"
                    color="primary"
                    mat-raised-button
                    (click)="newSocialPostContext.changeDialogStep(newSocialPostContext.DialogStep.TWO)">
                    {{ 'common.next' | translate }}
                </button>
            }
            @if (newSocialPostContext.dialogStep() === newSocialPostContext.DialogStep.TWO) {
                <app-button
                    class="md:grow"
                    buttonClasses="!h-11 md:w-full {{ getTrackingNameFromPublishButtonType | applyPure: postSchedule }}"
                    testId="social-post-step2-save-btn"
                    [disabled]="!canPublish()"
                    [text]="getPublishButtonText()"
                    [loading]="isUpdatingPost()"
                    (buttonClick)="save()"></app-button>
            }
        </div>
    </ng-container>
</ng-template>
