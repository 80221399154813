import { createAction, props } from '@ngrx/store';

import { SidenavState } from ':modules/sidenav-router/store/sidenav.reducer';

export const open = createAction('[Sidenav] Open');

export const close = createAction('[Sidenav] Close');

export const toggle = createAction('[Sidenav] Toggle');

export const setSidenavWidth = createAction('[Sidenav] setSidenavWidth', props<{ sidenavWidth: number }>());

export const toggleSelectBusinessesPanel = createAction('[Sidenav] toggleSelectBusinessesPanel');

export const setOwnRestaurants = createAction('[Sidenav] setOwnRestaurants', props<{ ownRestaurants: SidenavState['ownRestaurants'] }>());
