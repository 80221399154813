<button
    class="malou-text-12--semibold !rounded-md !bg-malou-color-background-text-1 !text-malou-color-text-white"
    mat-flat-button
    [id]="experimentationService.isFeatureEnabled('experiment-referral-btn-text') ? 'referral-btn-win-money' : 'referral-btn-refer-friend'"
    [ngClass]="{
        'w-full !min-w-0 !px-0': showOnlyIcon(),
    }"
    (click)="openLinkInNewTab()">
    <div class="flex items-center justify-center gap-x-3">
        <img class="h-5 w-auto shrink-0" [alt]="" [src]="'gift' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
        @if (!showOnlyIcon()) {
            @if (experimentationService.isFeatureEnabled('experiment-referral-btn-text')) {
                <span class="truncate">{{ 'common.referral.win_money' | translate }}</span>
            } @else {
                <span class="truncate">{{ 'common.refer_a_friend' | translate }}</span>
            }
        }
    </div>
</button>
