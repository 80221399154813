import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { Day, DAYS } from '@malou-io/package-utils';

import { DayOrDateHoursComponent } from ':shared/components/restaurant-informations/day-or-date-hours/day-or-date-hours.component';
import { TimePeriod } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

interface RestaurantHours {
    [key: string]: TimePeriod[];
}

@Component({
    selector: 'app-restaurant-business-hours',
    templateUrl: './restaurant-business-hours.component.html',
    styleUrls: ['./restaurant-business-hours.component.scss'],
    standalone: true,
    imports: [DayOrDateHoursComponent, EnumTranslatePipe, IncludesPipe, TitleCasePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantBusinessHoursComponent {
    readonly businessHours = input<TimePeriod[]>([]);
    readonly displayUnspecifiedDays = input<boolean>(true);

    readonly DAYS = DAYS;

    readonly restaurantHours = computed<RestaurantHours>(() => {
        const restaurantHours: RestaurantHours = {};
        const businessHours = this.businessHours();
        for (const timePeriod of businessHours) {
            if (!(timePeriod.openDay in restaurantHours)) {
                restaurantHours[timePeriod.openDay] = [];
            }
            restaurantHours[timePeriod.openDay].push(timePeriod);
        }
        return restaurantHours;
    });

    readonly closedDays = computed<Day[]>(() => {
        const closedDays: Day[] = [];
        const restaurantHours = this.restaurantHours();
        for (const day of DAYS) {
            if (restaurantHours[day]?.every((timePeriod) => timePeriod.isClosed)) {
                closedDays.push(day);
            }
        }
        return closedDays;
    });
}
