<div class="mt-3 flex h-full flex-col justify-between">
    <div class="flex flex-col gap-3">
        @for (interval of restaurantSpecialHoursIntervals(); track interval) {
            @let startDate = interval.start | malouDate | formatDate: 'shortDate';
            @let endDate = (isIntervalDates | applyPure: interval) ? (interval.end | malouDate | formatDate: 'shortDate') : '';
            @let intervalDates = startDate + (endDate ? ' - ' + endDate : '');
            @let title = interval.isFromCalendarEvent ? interval.name : intervalDates;
            <app-day-or-date-hours
                [dayOrDateTitle]="title"
                [showStartDate]="interval.isFromCalendarEvent"
                [startDate]="startDateToDisplay | applyPure: interval"
                [timePeriods]="interval.hours"
                [largeTitle]="true"
                [isClosed]="isClosed | applyPure: interval"></app-day-or-date-hours>
        }
    </div>

    @if (!displayedHours().length) {
        <div class="min-h-[200px]">
            <i> -- </i>
        </div>
    }
</div>
