import { Injectable } from '@angular/core';
import { groupBy } from 'lodash';
import { DateTime } from 'luxon';

import { MyDate, SpecialTimePeriod } from ':shared/models';

export interface SpecialHourInterval {
    start: MyDate;
    end: MyDate;
    hours: SpecialTimePeriod[];
    isFromCalendarEvent: boolean;
    name: string;
}

@Injectable({
    providedIn: 'root',
})
export class SpecialHoursService {
    createIntervalsFromDates(dates): SpecialHourInterval[] {
        const groupedDates = groupBy(dates, function (el) {
            return el.startDate;
        });
        const sortedDays = Object.keys(groupedDates).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
        const intervalList: SpecialHourInterval[] = [];
        let previousDay: string | undefined;
        for (const day of sortedDays) {
            if (intervalList.length === 0) {
                intervalList.push(this._createIntervalFromTimePeriodArray(groupedDates[day]));
            } else {
                const currentInterval = intervalList[intervalList.length - 1];
                const isNextDay = this._isNextDay(previousDay, day);
                if (isNextDay) {
                    const hasSameHours = this._checkHasSameHours(groupedDates[day], currentInterval.hours);
                    if (hasSameHours) {
                        intervalList[intervalList.length - 1] = {
                            start: currentInterval.start,
                            end: groupedDates[day][0].startDate,
                            hours: currentInterval.hours,
                            isFromCalendarEvent: currentInterval.isFromCalendarEvent || groupedDates[day][0].isFromCalendarEvent,
                            name: currentInterval.name,
                        };
                    } else {
                        intervalList.push(this._createIntervalFromTimePeriodArray(groupedDates[day]));
                    }
                } else {
                    intervalList.push(this._createIntervalFromTimePeriodArray(groupedDates[day]));
                }
            }
            previousDay = day;
        }
        return intervalList;
    }

    private _isNextDay(previousDay: string | undefined, currentDay: string): boolean {
        if (!previousDay) {
            return false;
        }
        const nextDay = this._getNextDay(previousDay);
        const diffDays = DateTime.fromJSDate(new Date(currentDay)).diff(nextDay, 'days').toObject().days;
        return diffDays === 0;
    }

    private _getNextDay(dateString: string): DateTime {
        const date = DateTime.fromJSDate(new Date(dateString));
        return date.plus({ days: 1 });
    }

    private _createIntervalFromTimePeriodArray(element: SpecialTimePeriod[]): SpecialHourInterval {
        return {
            start: element[0].startDate,
            end: element[0].startDate,
            hours: element,
            isFromCalendarEvent: !!element[0].isFromCalendarEvent,
            name: element[0].name ?? '',
        };
    }

    private _checkHasSameHours(day1: SpecialTimePeriod[], day2: SpecialTimePeriod[]): boolean {
        if (day1.length !== day2.length) {
            return false;
        }
        for (let i = 0; i < day1.length; i++) {
            if (day1[i]?.openTime !== day2[i]?.openTime || day1[i]?.closeTime !== day2[i]?.closeTime) {
                return false;
            }
        }
        return true;
    }
}
