import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, contentChild, input, output, TemplateRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: true,
    imports: [NgClass, NgTemplateOutlet, MatButtonModule, MatTooltipModule, MalouSpinnerComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
    readonly id = input<string>('');
    readonly text = input<string>('');
    readonly loading = input<boolean>(false);
    readonly disabled = input<boolean>(false);
    readonly buttonClasses = input<string>('');
    readonly tooltip = input<string>('');
    readonly testId = input<string>('');
    readonly theme = input<'primary' | 'secondary' | 'secondary--alt' | 'null'>('primary');

    readonly buttonClick = output<void>();

    readonly textTemplate = contentChild<TemplateRef<any>>('textTemplate');

    readonly themeCssClass = computed(() => `malou-btn-raised--${this.theme()}`);

    onClick(): void {
        if (this.loading() || this.disabled()) {
            return;
        }

        this.buttonClick.emit();
    }
}
