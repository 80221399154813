import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, computed, ElementRef, signal, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EMPTY, switchMap, take } from 'rxjs';

import { InsightsChart, InsightsTab } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { StatisticsSeoKeywordsV3Component } from ':modules/statistics/seo/statistics-seo-keywords/statistics-seo-keywords-v3.component';
import { EngagementComponent } from ':modules/statistics/social-networks/engagement/engagement.component';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { ViewBy } from ':shared/enums/view-by.enum';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { FiltersComponent } from '../filters/filters.component';
import { selectDatesFilter } from '../store/statistics.selectors';
import { GmbActionsComponent } from './gmb-actions/gmb-actions.component';
import { GmbImpressionsComponent } from './gmb-impressions/gmb-impressions.component';

@Component({
    selector: 'app-statistics-seo',
    templateUrl: './seo.component.html',
    styleUrls: ['./seo.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        EngagementComponent,
        FiltersComponent,
        GmbActionsComponent,
        GmbImpressionsComponent,
        MatButtonModule,
        NgTemplateOutlet,
        StatisticsSeoKeywordsV3Component,
        TranslateModule,
    ],
})
export class SeoComponent implements AfterViewInit {
    @ViewChild('topOfComponent') topOfComponent: ElementRef<HTMLElement>;

    isKeywordsLoading = signal(true);
    isImpressionsLoading = signal(true);
    isActionsLoading = signal(true);

    isLoading = computed(() => this.isKeywordsLoading() || this.isImpressionsLoading() || this.isActionsLoading());

    readonly InsightsChart = InsightsChart;
    chartOptions: ChartOptions = {
        [InsightsChart.ACTIONS]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.APPARITIONS]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.KEYWORDS]: {
            tableSortOptions: undefined,
        },
    };

    constructor(
        private _customDialogService: CustomDialogService,
        public screenSizeService: ScreenSizeService,
        private _store: Store,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        public readonly experimentationService: ExperimentationService
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => this.topOfComponent?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }));
    }

    openStatisticsDownload(): void {
        this._store
            .select(selectDatesFilter)
            .pipe(
                take(1),
                switchMap(({ startDate, endDate }) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }
                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: InsightsTab.SEO,
                                filters: {
                                    dates: { startDate, endDate },
                                },
                                chartOptions: this.chartOptions,
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onViewByChange(chart: InsightsChart, value: ViewBy): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                viewBy: value,
            },
        };
    }

    onHiddenDatasetIndexesChange(chart: InsightsChart, value: number[]): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                hiddenDatasetIndexes: value,
            },
        };
    }

    onTableSortOptionsChange(chart: InsightsChart, value: Sort): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                tableSortOptions: value,
            },
        };
    }
}
