import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap } from 'rxjs';

import {
    CreateCredentialBodyDto,
    CreateCredentialResponseDto,
    IsMalouTokenValidBodyDto,
    IsMalouTokenValidResponseDto,
    PatchCredentialBodyDto,
    PatchCredentialResponseDto,
    SingleRestaurantPermissionDto,
    UserPermissionsByPlatformDto,
} from '@malou-io/package-dto';
import { ApiResultV2, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { FbLocation } from ':modules/social-posts/new-social-post-modal/components/add-location/add-location.component';
import { UsersService } from ':modules/user/users.service';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { formatArrayKeysForQueryParams } from ':shared/helpers/query-params';
import { ApiResult, Credential, PlatformAccess } from ':shared/models';

import { PlatformsService } from './platforms.service';

@Injectable({ providedIn: 'root' })
export class CredentialsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1`;

    constructor(
        private readonly _http: HttpClient,
        private readonly _usersService: UsersService,
        private readonly _platformsService: PlatformsService
    ) {}

    getUserCredentials(): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/credentials`);
    }

    getOrganizationCredentials(organizationId: string): Observable<ApiResult<Credential[]>> {
        return this._http.get<ApiResult<Credential[]>>(`${this.API_BASE_URL}/credentials/organizations/${organizationId}`);
    }

    getOrganizationCredentialsForUser(fields: (keyof Credential)[] = []): Observable<ApiResult<Credential[]>> {
        return this._http.get<ApiResult<Credential[]>>(`${this.API_BASE_URL}/credentials/me?fields=${fields}`);
    }

    getAdminCredentials(): Observable<ApiResult<Credential[]>> {
        return this._http.get<ApiResult<Credential[]>>(`${this.API_BASE_URL}/credentials/admin`);
    }

    fetchPlatformPagePermissions({ platformId }: { platformId: string }): Observable<ApiResult | null> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ platformId }));
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/credentials/permissions`, { params }).pipe(
            catchError((err) => {
                console.warn('err >', err);
                return of(null);
            })
        );
    }

    fetchPlatformsPermissionsForUser(platformKey: PlatformKey): Observable<ApiResult<SingleRestaurantPermissionDto[] | null>> {
        return this._http
            .get<ApiResult<SingleRestaurantPermissionDto[] | null>>(`${this.API_BASE_URL}/credentials/users/permissions/${platformKey}`)
            .pipe(
                catchError((err) => {
                    console.warn('err >', err);
                    return of({ msg: 'error', data: null });
                })
            );
    }

    pagesSearch(query: string, filters: { onlyWithLocation: boolean; whitelistedPageIds?: string[] }): Observable<ApiResult<FbLocation[]>> {
        return this._http.get<ApiResult<FbLocation[]>>(`${this.API_BASE_URL}/facebook/me/pages?q=${query}`, {
            params: formatArrayKeysForQueryParams(filters),
        });
    }

    updateById(id: string, params: any): Observable<ApiResult<null>> {
        return this._http.put<ApiResult<null>>(`${this.API_BASE_URL}/credentials/${id}`, params);
    }

    updateOrganizations(id: string, organizationIds: string[]): Observable<ApiResult<null>> {
        return this._http.put<ApiResult<null>>(`${this.API_BASE_URL}/credentials/${id}/organizations`, organizationIds);
    }

    getOauthLoginUrl(platformKey: string): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/credentials/${platformKey}/login`);
    }

    authenticate(
        restaurantId: string,
        platformAccess: PlatformAccess
    ): Observable<ApiResult<{ access: PlatformAccess; redirect: string }>> {
        return this._http.post<ApiResult<{ access: PlatformAccess; redirect: string }>>(
            `${this.API_BASE_URL}/${platformAccess.platformKey}/${restaurantId}/authenticate`,
            { data: platformAccess }
        );
    }

    create(body: CreateCredentialBodyDto): Observable<ApiResult<CreateCredentialResponseDto>> {
        return this._http.post<ApiResult<CreateCredentialResponseDto>>(`${this.API_BASE_URL}/credentials`, body);
    }

    patch(id: string, body: PatchCredentialBodyDto): Observable<ApiResult<PatchCredentialResponseDto>> {
        return this._http.patch<ApiResult<PatchCredentialResponseDto>>(`${this.API_BASE_URL}/credentials/${id}`, body);
    }

    delete(id: string): Observable<void> {
        return this._http.delete<void>(`${this.API_BASE_URL}/credentials/${id}`);
    }

    isMapstrMalouTokenValid(body: IsMalouTokenValidBodyDto): Observable<ApiResult<IsMalouTokenValidResponseDto>> {
        return this._http.post<ApiResult<IsMalouTokenValidResponseDto>>(
            `${this.API_BASE_URL}/credentials/mapstr/malou-token/validity`,
            body
        );
    }

    getPermissionsForAllPlatformConnectionTypes$(
        platformKey: PlatformKey,
        restaurantIds?: string[]
    ): Observable<UserPermissionsByPlatformDto> {
        if (PlatformDefinitions.isOauthPlatform(platformKey)) {
            return this.fetchPlatformsPermissionsForUser(platformKey).pipe(
                map((res): UserPermissionsByPlatformDto => ({ key: platformKey, permissions: res?.data ?? [] }))
            );
        }
        const allRestaurantIds$ = restaurantIds
            ? of(restaurantIds)
            : this._usersService.usersRestaurants().pipe(map((res) => res.data?.map((ur) => ur.restaurantId) ?? []));

        return allRestaurantIds$.pipe(
            switchMap((allRestaurantIds) =>
                this._platformsService.getPlatformsForMultipleRestaurants({ restaurantIds: allRestaurantIds, keys: [platformKey] }).pipe(
                    map((res) => ({
                        key: platformKey,
                        permissions: allRestaurantIds?.map((restaurantId) => ({
                            restaurantId,
                            isValid: !!res.data?.find((platform) => platform.restaurantId === restaurantId && platform.key === platformKey),
                        })),
                    }))
                )
            )
        );
    }

    getZenchefSuperCredentialId(): Observable<ApiResultV2<string>> {
        return this._http.post<ApiResultV2<string>>(`${this.API_BASE_URL}/zenchef/get-super-credential-id`, {});
    }
}
