import FileSaver from 'file-saver';
import * as qrcode from 'qrcode';
import { from, Observable } from 'rxjs';

interface QrCodeOptions {
    scale?: number;
}

interface QrCodeDownloadOptions extends QrCodeOptions {
    errorCallback?: (error: Error) => void;
}

const DEFAULT_QR_CODE_DOWNLOAD_OPTIONS: QrCodeDownloadOptions = {
    scale: 12,
};

export class QrCode {
    DEFAULT_QR_CODE_FILE_NAME = 'qr-code';

    downloadQrCode(qrCodeUrl: string, fileName = this.DEFAULT_QR_CODE_FILE_NAME, options?: QrCodeDownloadOptions): void {
        const finalOptions = { ...DEFAULT_QR_CODE_DOWNLOAD_OPTIONS, ...options };
        const { scale } = finalOptions;
        qrcode.toDataURL(qrCodeUrl, { type: 'image/png', scale }, (err, url) => {
            if (err && finalOptions.errorCallback) {
                finalOptions.errorCallback(err);
                return;
            }
            FileSaver(url, `${fileName}.png`);
        });
    }

    generateQrCode$(qrCodeUrl: string, options?: QrCodeOptions): Observable<string> {
        return from(qrcode.toDataURL(qrCodeUrl, options));
    }
}
