export enum LocalStorageKey {
    DO_NOT_SHOW_AGAIN_CREATE_RESTAURANT_WHEEL_CONFIRMATION = 'doNotShowAgainCreateRestaurantWheelConfirmation',
    DO_NOT_SHOW_AGAIN_EMPTY_STOCK_MODAL = 'doNotShowAgainEmptyStockModal',
    JWT_TOKEN = 'jwtToken',
    LANG = 'lang',
    LEAVED_REVIEW_RESTAURANT_WHEELS_OF_FORTUNE = 'leavedReviewRestaurantWheelsOfFortune',
    PLAYED_RESTAURANT_WHEELS_OF_FORTUNE = 'playedRestaurantWheelsOfFortune',
    SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS = 'subscriptionRequestSentRestaurantIds',
    IS_ROI_SETTINGS_POPUP_SHOWN = 'isRoiSettingsPopupShown',
    LAST_TIME_NOTIFICATION_CENTER_SHOWN = 'lastTimeNotificationCenterShown',
    ROI_SETTINGS_DUPLICATE_TOOLTIP_SHOWN_COUNT = 'roiSettingsDuplicateTooltipShownCount',
    RESTAURANT_CREATION_FORM_IN_PROGRESS = 'restaurantCreationFormInProgress',
    SHOULD_USE_IMAGE_ANALYSIS_IN_POSTS_BY_DEFAULT = 'shouldUseImageAnalysisInPostsByDefault',
}
