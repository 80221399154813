import { EntityConstructor, PlatformKey } from '@malou-io/package-utils';

import { Notification } from './notification.model';

export interface NotificationReview {
    id: string;
    isMain: boolean;
    text?: string;
    platformKey: PlatformKey;
    restaurantId: string;
    restaurantName: string;
    socialCreatedAt: Date;
    rating: number;
    reviewerName?: string;
    reviewerProfilePictureUrl?: string;
    socialAttachmentUrls: string[];
}
export interface NegativeReviewReminderData {
    reviews: NotificationReview[];
    suggestedReply?: string;
}

type NegativeReviewReminderNotificationProps = EntityConstructor<NegativeReviewReminderNotification>;

export class NegativeReviewReminderNotification extends Notification {
    data: NegativeReviewReminderData;

    constructor(props: NegativeReviewReminderNotificationProps) {
        super(props);
        this.data = props.data;
    }

    copyWith(props: Partial<NegativeReviewReminderNotificationProps>): NegativeReviewReminderNotification {
        return new NegativeReviewReminderNotification({ ...this, ...props });
    }

    getMainReviewToNotify(): NotificationReview {
        return this.data.reviews.find((review) => review.isMain)!;
    }
}
