import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, model, signal } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { clone } from 'lodash';

import { ChecklistTaskName } from '@malou-io/package-utils';

import { ChecklistService } from ':core/services/checklist.service';
import { DEFAULT_CONFETTIS_CONFIGURATION, launchConfettis } from ':shared/helpers/confettis';
import { Checklist, ChecklistTask } from ':shared/models/checklist';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

interface DisplayedChecklistTask {
    title: string;
    subtitle: string[];
    actionButtonText: string;
    actionButtonLink: string;
}
@Component({
    selector: 'app-checklist',
    templateUrl: './checklist.component.html',
    styleUrls: ['./checklist.component.scss'],
    standalone: true,
    imports: [ChecklistComponent, MatIconModule, MatCheckboxModule, AsyncPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistComponent {
    readonly checklist = model<Checklist | null>(null);
    readonly minified = signal(localStorage.getItem('checklistMinified') === 'true');
    readonly completedTasks = computed(() => this.checklist()?.tasks.filter((task) => task.completed).length);
    readonly mappedTasks = computed(() => this.checklist()?.tasks.map((task) => this.taskMapper(task.name)));
    private readonly _checklistService = inject(ChecklistService);
    private readonly _router = inject(Router);
    private readonly _translateService = inject(TranslateService);

    readonly SvgIcon = SvgIcon;

    toggleMinify(): void {
        this.minified.set(!this.minified());
        localStorage.setItem('checklistMinified', this.minified().toString());
    }

    toggleClose(): void {
        this._checklistService.updateChecklistById({ checklistId: this.checklist()!.id, isDisplayed: false }).subscribe();
        this.checklist.update((currentChecklist) => {
            currentChecklist!.isDisplayed = false;
            return clone(currentChecklist);
        });
    }

    toggleTaskCompletion(task: ChecklistTask): void {
        if (!this.checklist()) {
            return;
        }
        this.checklist.update((currentChecklist) => {
            if (currentChecklist) {
                currentChecklist.tasks = currentChecklist.tasks.map((currentTask) =>
                    currentTask.name === task.name ? { ...task, completed: !task.completed } : currentTask
                );
            }
            return clone(currentChecklist);
        });
        this._checklistService.updateChecklistById({ checklistId: this.checklist()!.id, tasks: this.checklist()!.tasks }).subscribe();
        if (this.completedTasks() === this.checklist()!.tasks.length) {
            launchConfettis(DEFAULT_CONFETTIS_CONFIGURATION);
        }
    }

    navigateTo(destination: DisplayedChecklistTask['actionButtonLink']): void {
        const { restaurantId } = this.checklist()!;
        const navigateUrl = `./restaurants/${restaurantId}/${destination}`;
        this._router.navigate([navigateUrl]);
    }

    taskMapper(taskName: ChecklistTaskName): DisplayedChecklistTask {
        switch (taskName) {
            case ChecklistTaskName.ADAPT_CONTENT_TO_OLYMPICS:
                return {
                    title: this._translateService.instant('checklist.tasks.adapt_content_to_olympics.title'),
                    subtitle: [
                        this._translateService.instant('checklist.tasks.adapt_content_to_olympics.subtitle1'),
                        this._translateService.instant('checklist.tasks.adapt_content_to_olympics.subtitle2'),
                        this._translateService.instant('checklist.tasks.adapt_content_to_olympics.subtitle3'),
                    ].filter((s) => s),
                    actionButtonText: this._translateService.instant('checklist.tasks.common.create'),
                    actionButtonLink: '/social/socialposts',
                };
            case ChecklistTaskName.COLLECT_REVIEWS_WITH_BOOSTER_PACK:
                return {
                    title: this._translateService.instant('checklist.tasks.collect_reviews_with_booster_pack.title'),
                    subtitle: [
                        this._translateService.instant('checklist.tasks.collect_reviews_with_booster_pack.subtitle1'),
                        this._translateService.instant('checklist.tasks.collect_reviews_with_booster_pack.subtitle2'),
                        this._translateService.instant('checklist.tasks.collect_reviews_with_booster_pack.subtitle3'),
                    ].filter((s) => s),
                    actionButtonText: this._translateService.instant('checklist.tasks.common.try'),
                    actionButtonLink: '/boosters/wheels-of-fortune',
                };
            case ChecklistTaskName.CONFIRM_YOUR_HOURS:
                return {
                    title: this._translateService.instant('checklist.tasks.confirm_your_hours.title'),
                    subtitle: [],
                    actionButtonText: this._translateService.instant('checklist.tasks.common.modify'),
                    actionButtonLink: '/seo/informations',
                };
            case ChecklistTaskName.CREATE_ENGLISH_POSTS:
                return {
                    title: this._translateService.instant('checklist.tasks.create_english_posts.title'),
                    subtitle: [this._translateService.instant('checklist.tasks.create_english_posts.subtitle1')],
                    actionButtonText: this._translateService.instant('checklist.tasks.common.create'),
                    actionButtonLink: '/seo/posts/list',
                };
            case ChecklistTaskName.FOREIGN_LANGUAGE_KEYWORDS:
                return {
                    title: this._translateService.instant('checklist.tasks.foreign_language_keywords.title'),
                    subtitle: [this._translateService.instant('checklist.tasks.foreign_language_keywords.subtitle1')],
                    actionButtonText: this._translateService.instant('checklist.tasks.common.modify'),
                    actionButtonLink: '/resources/keywords/list',
                };
            case ChecklistTaskName.BROADCAST_SPORT_ATTRIBUTE:
                return {
                    title: this._translateService.instant('checklist.tasks.broadcast_sport_attribute.title'),
                    subtitle: [this._translateService.instant('checklist.tasks.broadcast_sport_attribute.subtitle1')],
                    actionButtonText: this._translateService.instant('checklist.tasks.common.modify'),
                    actionButtonLink: '/seo/informations',
                };
        }
        return {
            title: '',
            subtitle: [],
            actionButtonText: '',
            actionButtonLink: '',
        };
    }
}
