import { ChangeDetectionStrategy, Component, forwardRef, input, output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ControlValueAccessorConnectorComponent } from ':shared/components/control-value-accessor-connector/control-value-accessor-connector';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import { SelectBaseComponent } from '../select-abstract/select-base.component';

@Component({
    selector: 'app-select-restaurants',
    templateUrl: 'select-restaurants.component.html',
    styleUrls: ['./select-restaurants.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectRestaurantsComponent),
        },
    ],
    standalone: true,
    imports: [
        SelectBaseComponent,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        TranslateModule,
        ImagePathResolverPipe,
        MatTooltipModule,
        ApplyPurePipe,
        ApplySelfPurePipe,
        LazyLoadImageModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectRestaurantsComponent extends ControlValueAccessorConnectorComponent {
    /** Title */
    readonly title = input<string | undefined>();

    /** Subtitle */
    readonly subtitle = input<string | undefined>();

    /** Placeholder */
    readonly placeholder = input<string>('');

    /** If true, will display an asterisk after the title */
    readonly required = input<boolean>(false);

    /** Error message, will display a colored border and the error message below the input */
    readonly errorMessage = input<string | undefined>();

    /** Values */
    readonly values = input<Restaurant[]>([]);

    readonly selectedValues = input<Restaurant[]>([]);

    readonly multiSelection = input<boolean>(true);

    readonly multiSelectionElementWrap = input<boolean>(true);

    readonly shouldSwitchToWrapModeOnClick = input<boolean>(true);

    readonly shouldUpdateValuesToDisplayAfterSelection = input<boolean>(true);

    readonly defaultEmptyValueMessage = input<string | null>(null);

    readonly showValuesSelectedCount = input<boolean>(true);

    readonly disabled = input<boolean>(false);

    readonly computeObjectHash = input<((a?: any) => any) | undefined>();

    readonly displayedOptionCount = input<number>(Number.MAX_SAFE_INTEGER);

    readonly valuesCanBeDisabled = input<boolean>(false);

    readonly testId = input<string>();

    // ------------ Event ------------//

    /** On change event */
    readonly selectRestaurantsChange = output<Restaurant[]>();

    readonly SvgIcon = SvgIcon;

    displayWith: (option: Restaurant) => string = (option: Restaurant) => option.getDisplayName();

    sortBy = (a: Restaurant, b: Restaurant): number => a.getDisplayName().localeCompare(b.getDisplayName());
}
