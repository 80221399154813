import { NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output, Signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';

import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Pagination } from ':shared/models';
import { ConversationType, ConversationWithMessages } from ':shared/models/conversation';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { ConversationCardComponent } from '../conversation-card/conversation-card.component';
import { FilterByConversationTypePipe } from '../filter-by-conversation-type.pipe';

const TAB_NAMES = [ConversationType.GENERAL, ConversationType.FAVORITE, ConversationType.ARCHIVED];

@Component({
    selector: 'app-conversations',
    templateUrl: './conversations.component.html',
    styleUrls: ['./conversations.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatTabsModule,
        TranslateModule,
        ConversationCardComponent,
        SkeletonComponent,
        ApplyPurePipe,
        FilterByConversationTypePipe,
        IllustrationPathResolverPipe,
    ],
})
export class ConversationsComponent {
    @Input() isLoading: Boolean = true;
    @Input() errorLoadingConversations: Boolean = false;
    @Input() conversationsWithMessages: ConversationWithMessages[] = [];
    @Input() areAllPlatformsDisconnected: Boolean = false;
    @Input() selectedConversation: ConversationWithMessages | null;
    @Input() selectedTabIndex: number;
    @Input() filteredConversations: ConversationWithMessages[] = [];
    @Input() paginations: Record<ConversationType, Pagination>;
    @Input() conversationsTextSearch: string;
    @Input() checkedConversations: Signal<ConversationWithMessages[]>;
    @Output() onSelectedTabChange = new EventEmitter<MatTabChangeEvent>();
    @Output() onLoadMoreConversations = new EventEmitter<void>();
    @Output() onSelectConversation = new EventEmitter<ConversationWithMessages>();
    @Output() onCheckConversation = new EventEmitter<ConversationWithMessages>();
    @Output() onChangeArchivedConversation = new EventEmitter<string>();
    @Output() onRemoveConversationFromFavorite = new EventEmitter<string>();

    ConversationType = ConversationType;

    conversationsPageSize = 20;

    trackByConversationId = (index: number, conversationWithMessages: ConversationWithMessages): string =>
        conversationWithMessages.conversation._id;

    onTabChange(event: MatTabChangeEvent): void {
        this.onSelectedTabChange.emit(event);
    }

    loadMoreConversations(): void {
        this.onLoadMoreConversations.emit();
    }

    selectConversation(conversation: ConversationWithMessages): void {
        this.onSelectConversation.emit(conversation);
    }

    checkConversation(conversation: ConversationWithMessages): void {
        this.onCheckConversation.emit(conversation);
    }

    canLoadMoreConversations = (
        paginations: Record<ConversationType, Pagination>,
        filteredConversations: ConversationWithMessages[],
        selectedTabIndex: number
    ): boolean => {
        const pagination = paginations[TAB_NAMES[selectedTabIndex]];
        return this._filterConversations(filteredConversations, TAB_NAMES[selectedTabIndex]).length < pagination?.total;
    };

    private _filterConversations(
        conversationsWithMessages: ConversationWithMessages[],
        conversationType: ConversationType
    ): ConversationWithMessages[] {
        return new FilterByConversationTypePipe().transform(conversationsWithMessages, conversationType);
    }
}
