import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterOutlet } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import 'chartjs-adapter-luxon';
// This module need to be loaded
import { environment } from 'environments/environment';
import { DateTime, Settings } from 'luxon';
import { forkJoin, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { APP_DEFAULT_LANGUAGE, ApplicationLanguage, isLangInApplicationLanguages, isNotNil, PlatformKey } from '@malou-io/package-utils';

import { JimoSupportedLanguages, JimoType } from ':core/constants';
import { BrowserService } from ':core/services/browser-detector.service';
import { CheckForUpdateService } from ':core/services/check-for-updates.service';
import { ErrorsService } from ':core/services/errors.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { MalouErrorsService } from ':core/services/malou-errors.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RealtimeMessagingService } from ':core/services/realtime-messaging/realtime-messaging.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import * as UserFiltersActions from ':core/user-filters/store/user-filters.actions';
import { CommentsService } from ':modules/comments/comments.service';
import * as CommentsActions from ':modules/comments/store/comments.actions';
import { GalleryImportMediaComponent } from ':modules/gallery/gallery-import-media/gallery-import-media.component';
import * as SuggestionActions from ':modules/informations/information-suggestions-modal/store/suggestions.actions';
import { selectJimoUserAttributes } from ':modules/jimo/jimo.selectors';
import * as keywordsActions from ':modules/keywords/store/keywords.actions';
import * as MessagesActions from ':modules/messages/messages.actions';
import { MessagesService } from ':modules/messages/messages.service';
import * as platformsActions from ':modules/platforms/store/platforms.actions';
import * as RestaurantsActions from ':modules/restaurant-list/restaurant-list.actions';
import * as UserActions from ':modules/user/store/user.actions';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { ToastComponent } from ':shared/components-v3/toast/toast.component';
import { FooterPopinComponent } from ':shared/components/footer-popin/footer-popin.component';
import { MessageNotificationSnackbarComponent } from ':shared/components/message-notification-snackbar/message-notification-snackbar.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { Restaurant } from ':shared/models';
import { RouterListenerService } from ':shared/services/router-listener.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.template.html',
    styleUrls: ['./app.style.scss'],
    standalone: true,
    imports: [RouterOutlet, GalleryImportMediaComponent, ToastComponent, FooterPopinComponent],
})
export class AppComponent implements OnInit {
    public isChrome: boolean;
    private _isJimoInitialized = false;

    constructor(
        private readonly _browserService: BrowserService,
        private readonly _store: Store,
        private readonly _translateService: TranslateService,
        private readonly _errorsService: ErrorsService,
        private readonly _malouErrorsService: MalouErrorsService,
        private readonly _toastService: ToastService,
        private readonly _experimentationService: ExperimentationService,
        private readonly _renderer2: Renderer2,
        @Inject(DOCUMENT) private readonly _document: Document,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _messagesService: MessagesService,
        private readonly _matSnackBar: MatSnackBar,
        private readonly _platformsService: PlatformsService,
        private readonly _commentsService: CommentsService,
        private readonly _routerListenerService: RouterListenerService,
        private readonly _checkForUpdateService: CheckForUpdateService,
        private readonly _realtimeMessagingService: RealtimeMessagingService
    ) {
        this.isChrome = this._browserService.isChromeBrowser();
    }

    ngOnInit(): void {
        this._realtimeMessagingService.initialize();
        this._initLang();
        this._initGoogleMapsScript();
        this._initCustomerIO();
        this._initLuxon();
        this._translateService.addLangs(Object.values(ApplicationLanguage));
        this._store.dispatch({ type: UserActions.loadUser.type });
        this._store
            .select(selectUserInfos)
            .pipe(
                filter(isNotNil),
                switchMap((infos) => {
                    this._store.dispatch({ type: UserFiltersActions.initializeState.type, userId: infos._id });
                    return forkJoin([of(infos), this._restaurantsService.getUserRestaurantsIds()]);
                }),
                switchMap(([infos, restaurantIds]) => forkJoin([of(infos), this._experimentationService.load(infos, restaurantIds)]))
            )
            .subscribe({
                next: ([userInfos]) => {
                    if (userInfos.defaultLanguage) {
                        this._setLang(userInfos.defaultLanguage);
                    }
                    if (userInfos._id) {
                        if (!this._isJimoInitialized) {
                            this._initJimo();
                        }
                    }
                    this._store.dispatch({ type: RestaurantsActions.loadRestaurants.type });
                    this._redirectIfNeeded();
                },
            });
        this._errorsService.caslForbiddenError$.subscribe({
            next: (err) => {
                if (err) {
                    this._toastService.openErrorToast(this._translateService.instant('forbidden_error.error'));
                }
            },
        });
        this._errorsService.forbiddenError$.subscribe({
            next: (err) => {
                if (err?.error?.malouErrorCode) {
                    this._toastService.openErrorToast(this._malouErrorsService.getReadableErrorForMalouErrorCode(err.error.malouErrorCode));
                } else if (err) {
                    console.warn('Dev indication: unhandled forbidden error', err);
                }
            },
        });
        this._initSnackbarMessages();
        this._initChangeRestaurantSideEffects();
        this._routerListenerService.init();
        this._checkForUpdateService.init();
        this._initLastTimeNotificationModalShown();
    }

    private _initLang(): void {
        const userLanguage =
            navigator.language ??
            (
                navigator as {
                    userLanguage?: string;
                }
            ).userLanguage;
        const slicedLang = userLanguage?.slice(0, 2);
        const lang = LocalStorage.getLang(isLangInApplicationLanguages(slicedLang) ? slicedLang : APP_DEFAULT_LANGUAGE);
        this._setLang(lang);
    }

    private _initCustomerIO(): void {
        try {
            const script = document.createElement('script');
            script.type = 'text/javascript';

            (window as any).analytics = (window as any).analytics || [];
            const analytics = (window as any).analytics;

            if (!!analytics.initialize) {
                return;
            }
            if (!!analytics.invoked) {
                console.error('Snippet included twice.');
            } else {
                analytics.invoked = !0;
                analytics.methods = [
                    'trackSubmit',
                    'trackClick',
                    'trackLink',
                    'trackForm',
                    'pageview',
                    'identify',
                    'reset',
                    'group',
                    'track',
                    'ready',
                    'alias',
                    'debug',
                    'page',
                    'once',
                    'off',
                    'on',
                    'addSourceMiddleware',
                    'addIntegrationMiddleware',
                    'setAnonymousId',
                    'addDestinationMiddleware',
                ];
                analytics.factory = function (e) {
                    return function () {
                        const t = Array.prototype.slice.call(arguments);
                        t.unshift(e);
                        analytics.push(t);
                        return analytics;
                    };
                };
                for (let e = 0; e < analytics.methods.length; e++) {
                    const key = analytics.methods[e];
                    analytics[key] = analytics.factory(key);
                }
                analytics.load = function (key, e): void {
                    const t = document.createElement('script');
                    t.type = 'text/javascript';
                    t.async = !0;
                    t.src = 'https://cdp-eu.customer.io/v1/analytics-js/snippet/' + key + '/analytics.min.js';
                    const n = document.getElementsByTagName('script')[0];
                    n.parentNode?.insertBefore(t, n);
                    analytics._writeKey = key;
                    analytics._loadOptions = e;
                };
                analytics.SNIPPET_VERSION = '4.15.3';
                const customerIOInAppPlugin = 'Customer.io In-App Plugin';
                analytics.load(environment.CUSTOMER_IO_WRITE_KEY, {
                    integrations: {
                        [customerIOInAppPlugin]: {
                            siteId: environment.CUSTOMER_IO_SITE_ID,
                        },
                    },
                });
                analytics.page();
            }

            const head = document.getElementsByTagName('head');
            if (head) {
                head[0]?.appendChild(script);
            }
        } catch (error) {}
    }

    private _setLang(lang: ApplicationLanguage): void {
        this._translateService.use(lang);
        this._translateService.setDefaultLang(lang);
        LocalStorage.setLang(lang);
    }

    private _initLuxon(): void {
        if (this._translateService.currentLang) {
            Settings.defaultLocale = this._translateService.currentLang;
        }
        this._translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            Settings.defaultLocale = langChangeEvent.lang;
        });
    }

    private _redirectIfNeeded(): void {
        const malouUriRegex = /v3.*malou\.io/;
        if (malouUriRegex.test(window.location.href)) {
            window.location.href = environment.BASE_URL;
        }
        return;
    }

    private _initJimo(): void {
        try {
            const script = this._renderer2.createElement('script');

            (window as JimoType).jimo = [];
            window['JIMO_PROJECT_ID'] = environment.JIMO_PROJECT_ID;
            window['JIMO_MANUAL_INIT'] = true;

            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://undercity.usejimo.com/jimo-invader.js';
            this._renderer2.appendChild(this._document.body, script);

            this._store.pipe(select(selectJimoUserAttributes)).subscribe((data) => {
                if (data && window['JIMO_SESSION_TOKEN']) {
                    (window as JimoType).jimo?.push([
                        'do',
                        'session:reset',
                        [
                            window['JIMO_SESSION_TOKEN'],
                            (): void => {
                                if (JimoSupportedLanguages.includes(data.language)) {
                                    (window as JimoType).jimo?.push(['set', 'core:language', [data.language]]);
                                }
                                const { email, ...dataWithoutEmail } = data;
                                (window as JimoType).jimo?.push(['set', 'user:attributes', [dataWithoutEmail]]);
                                (window as JimoType).jimo?.push(['set', 'user:email', [email]]);
                            },
                        ],
                    ]);

                    if (!this._isJimoInitialized) {
                        setTimeout(() => {
                            (window as JimoType).jimoInit?.();
                            this._isJimoInitialized = true;
                        });
                    }
                }
            });
        } catch (error) {}
    }

    private _initGoogleMapsScript(): void {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${environment.GOOGLE_FRONTEND_KEY}`;

        const head = document.getElementsByTagName('head');
        if (head) {
            head[0]?.appendChild(script);
        }
    }

    private _initSnackbarMessages(): void {
        this._messagesService.getMessage$().subscribe(({ message }) => {
            this._matSnackBar.openFromComponent(MessageNotificationSnackbarComponent, {
                verticalPosition: 'top',
                horizontalPosition: 'right',
                politeness: 'polite',
                data: message,
                duration: 50000,
            });
        });
    }

    private _initChangeRestaurantSideEffects(): void {
        this._restaurantsService.restaurantSelected$.subscribe((restaurant) => {
            if (restaurant) {
                this._launchSideEffectOnRestaurantSelected(restaurant);
            }
        });
    }

    private _launchSideEffectOnRestaurantSelected(restaurant: Restaurant): void {
        if (!restaurant) {
            return;
        }
        this._store.dispatch({ type: platformsActions.editSelectedRestaurantId.type, restaurantId: restaurant._id });
        this._store.dispatch({ type: platformsActions.loadPlatformsData.type, restaurantId: restaurant._id });
        this._store.dispatch({ type: keywordsActions.editSelectedRestaurantId.type, restaurantId: restaurant._id });
        this._store.dispatch({ type: keywordsActions.loadKeywords.type, restaurantId: restaurant._id });
        this._store.dispatch({
            type: SuggestionActions.loadInformationSuggestions.type,
            restaurant,
        });
        this._updateUnreadMessageCounts(restaurant);
    }

    private _updateUnreadMessageCounts(restaurant: Restaurant): void {
        this._platformsService
            .getConnectedMessagingPlatforms()
            .pipe(
                switchMap((platformKeys: PlatformKey[]) =>
                    forkJoin([
                        this._messagesService.getUnreadConversationsCount(restaurant._id, platformKeys),
                        this._commentsService.getUnansweredCommentsAndMentionsCount(DateTime.now().minus({ months: 6 }).toJSDate()),
                    ])
                ),
                map(
                    (results) =>
                        results.map((res) => res.data) as [
                            number,
                            {
                                unansweredCommentCount: number;
                                unansweredMentionCount: number;
                            },
                        ]
                )
            )
            .subscribe(([unreadConversations, { unansweredCommentCount, unansweredMentionCount }]) => {
                this._store.dispatch({
                    type: MessagesActions.editCount.type,
                    unreadConversationCount: unreadConversations,
                });
                this._store.dispatch({
                    type: CommentsActions.editUnansweredCommentCount.type,
                    unansweredCommentCount,
                });
                this._store.dispatch({
                    type: CommentsActions.editUnansweredMentionCount.type,
                    unansweredMentionCount,
                });
            });
    }

    private _initLastTimeNotificationModalShown(): void {
        const lastTimeNotificationCenterShown = localStorage.getItem(LocalStorageKey.LAST_TIME_NOTIFICATION_CENTER_SHOWN);
        if (!lastTimeNotificationCenterShown) {
            localStorage.setItem(
                LocalStorageKey.LAST_TIME_NOTIFICATION_CENTER_SHOWN,
                JSON.stringify({ lastPopUpDate: new Date().toISOString(), isFirstTime: true })
            );
        }
    }
}
