import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { sortBy } from 'lodash';
import { Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestaurantsService } from ':core/services/restaurants.service';
import { selectKeywordsData } from ':modules/statistics/store/statistics.selectors';
import { GeoSample, Keyword, KeywordVolumeHistory } from ':shared/models';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

interface Data {
    keywords: Keyword[];
    samples: GeoSample[];
    placeId: string | undefined;
}

@Injectable({ providedIn: 'root' })
export class KeywordsCsvInsightsService extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _restaurantsService: RestaurantsService
    ) {
        super();
    }

    protected override _getData$(): Observable<Data> {
        return this._store.select(selectKeywordsData).pipe(
            take(1),
            map(({ keywords, samples }) => ({ keywords, samples, placeId: this._restaurantsService.currentRestaurant.placeId }))
        );
    }

    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        const competitorHeaders = Array(20)
            .fill(0)
            .map((_, i) => [`Competitor ${i + 1}`, `Competitor ${i + 1} Address`])
            .flat();
        return ['Date', 'Keyword', 'Volume (average local searches)', 'Google Ranking', ...competitorHeaders];
    }

    protected override _getCsvDataRows({ keywords, samples, placeId }: Data): CsvAsStringArrays {
        const selectedKeywords = keywords.filter((keyword) => keyword.selected);
        return selectedKeywords
            .map((keyword) => {
                const keywordSamples = samples.filter((sample) => sample.keyword === keyword.text);
                const csvRows = keywordSamples.map((sample) => {
                    const date = new Date(sample.createdAt).toLocaleDateString();
                    const keywordText = keyword.text;
                    const volume =
                        this._getClosestVolumeHistoryFromDate(keyword.volumeHistory, new Date(sample.createdAt))?.toString() ?? '';
                    const position = this._getPosition(sample, placeId)?.toString() ?? '';
                    const competitors = sample.ranking.map((place) => [place.name ?? '', place.formatted_address ?? '']);
                    return [date, keywordText, volume, position, ...competitors.flat()];
                });
                return csvRows;
            })
            .flat();
    }

    private _getClosestVolumeHistoryFromDate(volumeHistory: KeywordVolumeHistory[], date: Date): number {
        const volumeHistoryWithDateDifference = volumeHistory.map((el) => ({
            value: el.volume,
            dateDifference: Math.abs(date.getTime() - new Date(el.fetchDate).getTime()),
        }));
        return sortBy(volumeHistoryWithDateDifference, (e) => e.dateDifference)[0]?.value;
    }

    private _getPosition(sample: GeoSample, restaurantPlaceId?: string): number | undefined {
        if (!sample.ranking?.length || !restaurantPlaceId) {
            return undefined;
        }
        const position = sample.ranking?.findIndex((place) => place.place_id === restaurantPlaceId);
        return position === -1 ? 20 : position;
    }
}
