import { EntityConstructor, MentionType, PlatformKey } from '@malou-io/package-utils';

import { Notification } from ':core/components/notification-center/models/notification.model';

type MentionNotificationProps = EntityConstructor<MentionNotification>;

export interface MentionNotificationData {
    mentions: {
        id: string;
        restaurantId: string;
        postSocialId: string;
        socialId: string | null;
        platformKey: PlatformKey;
        mentionType: MentionType;
        socialCreatedAt: Date;
        text: string;
        authorDisplayName: string | null;
    }[];
    restaurantIds: string[];
}

export class MentionNotification extends Notification {
    data: MentionNotificationData;

    constructor(props: MentionNotificationProps) {
        super(props);
        this.data = props.data;
    }

    copyWith(props: Partial<MentionNotificationProps>): MentionNotification {
        return new MentionNotification({ ...this, ...props });
    }
}
