import { getTimeDifferenceInDays, RoiSettingsDataGatheringStartDateForLegacyRestaurants } from '@malou-io/package-utils';

import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { Restaurant } from ':shared/models';

const DAYS_TO_SHOW_ROI_SETTINGS_MODAL = 3;

export const getShouldShowRoiSettingsModal = (latestCreatedRestaurant: Restaurant): boolean => {
    if (!latestCreatedRestaurant) {
        return false;
    }

    const isRoiSettingsPopUpAlreadyShown = localStorage.getItem(LocalStorageKey.IS_ROI_SETTINGS_POPUP_SHOWN) === 'true';

    const isLegacyRestaurant =
        new Date(latestCreatedRestaurant.createdAt).getTime() < RoiSettingsDataGatheringStartDateForLegacyRestaurants.toJSDate().getTime();

    const shouldShowModal =
        getTimeDifferenceInDays(
            new Date(),
            isLegacyRestaurant
                ? RoiSettingsDataGatheringStartDateForLegacyRestaurants.toJSDate()
                : new Date(latestCreatedRestaurant.createdAt)
        ) > DAYS_TO_SHOW_ROI_SETTINGS_MODAL;

    return isRoiSettingsPopUpAlreadyShown ? false : shouldShowModal;
};
