import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { ReviewsHeaderAutomaticReplyComponent } from ':modules/reviews/reviews-header/reviews-header-automatic-reply/reviews-header-automatic-reply.component';
import { ReviewsHeaderFiltersComponent } from ':modules/reviews/reviews-header/reviews-header-filters/reviews-header-filters.component';
import { ReviewsHeaderRestaurantsComponent } from ':modules/reviews/reviews-header/reviews-header-restaurants/reviews-header-restaurants.component';
import { ReviewsHeaderSearchComponent } from ':modules/reviews/reviews-header/reviews-header-search/reviews-header-search.component';
import { ReviewsHeaderSemanticAnalysisComponent } from ':modules/reviews/reviews-header/reviews-header-semantic-analysis/reviews-header-semantic-analysis.component';
import { ReviewsHeaderSynchronizeComponent } from ':modules/reviews/reviews-header/reviews-header-synchronize/reviews-header-synchronize.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { selectEstimatedReviewCount } from '../store/reviews.selectors';

@Component({
    selector: 'app-reviews-header',
    templateUrl: './reviews-header.component.html',
    styleUrls: ['./reviews-header.component.scss'],
    standalone: true,
    imports: [
        ReviewsHeaderAutomaticReplyComponent,
        ReviewsHeaderSearchComponent,
        ReviewsHeaderSemanticAnalysisComponent,
        ReviewsHeaderRestaurantsComponent,
        ReviewsHeaderFiltersComponent,
        ReviewsHeaderSynchronizeComponent,
        AsyncPipe,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsHeaderComponent {
    readonly isAggregatedView = input<boolean>(false);

    readonly displaySemanticFilter = input.required<boolean>();
    readonly displayAutomaticReplyFilter = input.required<boolean>();
    readonly displayRestaurantsFilter = input.required<boolean>();

    readonly searchButtonId = input<string>('');
    readonly sortButtonId = input<string>('');
    readonly filtersButtonId = input<string>('');
    readonly synchronizeButtonId = input<string>('');

    private readonly _store = inject(Store);

    readonly SvgIcon = SvgIcon;

    readonly reviewCount$ = this._store.select(selectEstimatedReviewCount);
    readonly reviewCount = toSignal(this.reviewCount$, { initialValue: undefined });

    readonly showAdvancedFilters = signal(false);

    toggleAdvancedFilters(): void {
        this.showAdvancedFilters.update((showAdvancedFilters) => !showAdvancedFilters);
    }
}
