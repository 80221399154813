<div class="malou-border-color-dark malou-background-white flex items-center justify-between rounded-[10px] border p-5">
    <span class="malou-text-18--bold malou-color-text-1">{{ title }}</span>

    <div class="flex items-center">
        <div
            class="malou-chip"
            [ngClass]="{
                'malou-chip--red': !isSuccess,
                'malou-chip--success-light text-malou-color-text-green': isSuccess,
            }">
            <span class="malou-text-12">{{ isSuccess ? successText : errorText }}</span>
        </div>

        <button class="malou-btn-icon--secondary ml-2" mat-icon-button (click)="emitClick()">
            <mat-icon [svgIcon]="icon"></mat-icon>
        </button>
    </div>
</div>
