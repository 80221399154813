import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { FromStoreToUserFiltersMapper } from ':core/user-filters/mapper/from-store-to-user-filters.mapper';
import * as UserFiltersActions from ':core/user-filters/store/user-filters.actions';
import { UserFiltersService } from ':core/user-filters/user-filters.service';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import * as ReviewsSelectors from ':modules/reviews/store/reviews.selectors';
import { selectUserInfos } from ':modules/user/store/user.selectors';

@Injectable()
export class UserFiltersEffects {
    readonly loadUserFilters$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(UserFiltersActions.initializeState),
                switchMap((action) =>
                    this._userFiltersService.getUserFilters(action.userId).pipe(
                        tap((data) => {
                            this._store.dispatch(AggregatedStatisticsActions.initializeState({ data: data.aggregatedStatisticsFilters }));
                            this._store.dispatch(ReviewsActions.initializeState({ data: data.aggregatedReviewsFilters }));
                            return of({ type: '[App] Initialize State Success' });
                        }),
                        catchError((error) => of({ type: '[App] Initialize State Failure', error }))
                    )
                )
            ),
        {
            dispatch: false,
        }
    );

    editAggregatedStatisticsFilters$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    AggregatedStatisticsActions.editDates,
                    AggregatedStatisticsActions.editPlatforms,
                    AggregatedStatisticsActions.editRestaurants,
                    AggregatedStatisticsActions.editRoiRestaurants,
                    AggregatedStatisticsActions.editTimeScale,
                    AggregatedStatisticsActions.editTotems
                ),
                withLatestFrom(
                    this._store.pipe(select(selectUserInfos)),
                    this._store.pipe(select(AggregatedStatisticsSelectors.selectFilters)),
                    this._store.pipe(select(AggregatedStatisticsSelectors.selectIsFiltersLoaded))
                ),
                switchMap(([_action, user, filters, isFiltersLoaded]) => {
                    const userId = user?._id;
                    if (!userId) {
                        return of({ type: '[App] Update Filters Failure', error: 'No user id found' });
                    }
                    if (!isFiltersLoaded) {
                        return of({ type: '[App] Update Filters Failure', error: 'Filters not loaded yet' });
                    }
                    return this._userFiltersService.updateAggregatedStatisticsFilters(
                        userId,
                        this._fromStoreToUserFilterMapper.mapToAggregatedStatisticsFiltersFromState(filters)
                    );
                })
            ),
        { dispatch: false }
    );

    editAggregatedReviewsFilters$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    ReviewsActions.editReviewsFilters,
                    ReviewsActions.editReviewsDatesFilters,
                    ReviewsActions.resetReviewsState,
                    ReviewsActions.resetReviewsStateExceptRestaurants,
                    ReviewsActions.editReviewsFiltersSearch,
                    ReviewsActions.editSemanticAnalysisToggle,
                    ReviewsActions.resetReviewsFiltersDates,
                    ReviewsActions.editReviewsFiltersRatings,
                    ReviewsActions.editReviewsFiltersPlatforms,
                    ReviewsActions.toggleReviewsFiltersPlatform,
                    ReviewsActions.toggleReviewsFiltersStatus,
                    ReviewsActions.toggleReviewsFiltersComment,
                    ReviewsActions.toggleReviewsFiltersArchive,
                    ReviewsActions.editSelectablePlatforms,
                    ReviewsActions.toggleArchived,
                    ReviewsActions.editReviewsFiltersDates,
                    ReviewsActions.editRestaurants,
                    ReviewsActions.clearFilters
                ),
                withLatestFrom(
                    this._store.pipe(select(selectUserInfos)),
                    this._store.pipe(select(ReviewsSelectors.selectReviewsFilters)),
                    this._store.pipe(select(ReviewsSelectors.selectIsFiltersLoaded))
                ),
                switchMap(([_action, user, filters, isFiltersLoaded]) => {
                    const userId = user?._id;
                    if (!userId) {
                        return of({ type: '[App] Update Filters Failure', error: 'No user id found' });
                    }
                    if (!isFiltersLoaded) {
                        return of({ type: '[App] Update Filters Failure', error: 'Filters not loaded yet' });
                    }
                    return this._userFiltersService.updateAggregatedReviewsFilters(
                        userId,
                        this._fromStoreToUserFilterMapper.mapToAggregatedReviewsFiltersFromState(filters)
                    );
                })
            ),
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _userFiltersService: UserFiltersService,
        private readonly _store: Store,
        private readonly _fromStoreToUserFilterMapper: FromStoreToUserFiltersMapper
    ) {}
}
