<input
    class="hidden-file-input"
    type="file"
    multiple="multiple"
    accept="image/png, image/jpeg, video/quicktime, video/mp4"
    (click)="fileInput.value = ''"
    #fileInput />

@if (showUppy()) {
    <app-uppy
        [fileInput]="fileInput"
        [dragAndDropArea]="importMediaTargetDragAndDropArea()"
        [disableDragAndDrop]="disableDragAndDrop()"
        [maxVideoSize]="DEFAULT_MAX_VIDEO_SIZE"
        [maxImageSize]="DEFAULT_MAX_IMAGE_SIZE"
        (onFinishUpload)="finishUpload($event)"
        (startWatcher)="startWatcher($event)"
        (beforeStart)="beforeStart()"
        (startUploadProcess)="startUploadProcess()"
        (progressChange)="updateProgress($event)"
        (filesUploadError)="buildErrorsArray($event)"
        (totalFilesChange)="setTotalFiles($event)"></app-uppy>
}

@if (isImporting()) {
    <div
        class="malou-card malou-box-shadow !fixed bottom-0 right-10 z-10 min-w-[300px] !p-0 !py-4 md:right-[50%] md:translate-x-[55%] md:transform">
        <div class="malou-text-12--bold mb-2 flex w-full justify-between px-4 text-malou-color-text-1">
            {{ 'gallery.number_of_importing_files' | translate: { length: totalFiles() } }}
            <app-malou-spinner size="small"></app-malou-spinner>
        </div>
        <hr class="w-full border-malou-color-background-dark" />
        <div class="flex items-center gap-x-4 px-4 pt-2">
            <app-circle-progress [progress$]="uploadProgress$"></app-circle-progress>
            <div class="malou-text-12--regular text-malou-color-text-1">
                {{ 'gallery.upload_in_progress_description' | translate }}
            </div>
        </div>
    </div>
}
