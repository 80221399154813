import { DateTime, Settings } from 'luxon';

import { EntityConstructor, formatEnglishDateWithSuffix, Locale } from '@malou-io/package-utils';

import { CalendarEvent } from ':shared/models/calendar-event';

import { Notification } from './notification.model';

export interface PostSuggestionNotificationData {
    restaurantIds: string[];
    event: CalendarEvent;
}

type PostSuggestionNotificationProps = EntityConstructor<PostSuggestionNotification>;

export class PostSuggestionNotification extends Notification {
    data: PostSuggestionNotificationData;

    constructor(props: PostSuggestionNotificationProps) {
        super(props);
        this.data = props.data;
        this.data.event = new CalendarEvent(props.data.event);
    }

    copyWith(props: Partial<PostSuggestionNotificationProps>): PostSuggestionNotification {
        return new PostSuggestionNotification({ ...this, ...props });
    }

    getFormattedEventDate = (): string => {
        const defaultLocale = Settings.defaultLocale;
        if (defaultLocale === Locale.EN) {
            return formatEnglishDateWithSuffix(new Date(this.data.event.startDate));
        }
        return DateTime.fromISO(this.data.event.startDate).toFormat('dd MMMM');
    };

    getNotificationName = (): string => this.data.event.getNameForLang(localStorage.getItem('lang') ?? 'fr');

    getNotificationDescription(): string {
        return this.data.event.getIdeasForLang(localStorage.getItem('lang') ?? 'fr');
    }

    getNotificationEmoji = (): string => this.data.event.emoji ?? '🎉';
}
