import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, combineLatest, debounceTime, EMPTY, filter, map, Observable, of, switchMap, tap } from 'rxjs';

import { InsightsChart, isNotNil } from '@malou-io/package-utils';

import { NfcService } from ':core/services/nfc.service';
import { AGGREGATED_STATISTICS_RESTAURANTS_COUNT_UI_LIMIT } from ':modules/aggregated-statistics/aggregated-statistics.component';
import { AggregatedBoostersScanCountComponent } from ':modules/aggregated-statistics/boosters/aggregated-boosters-scan-count/aggregated-boosters-scan-count.component';
import { AggregatedWheelOfFortuneEstimatedReviewCountComponent } from ':modules/aggregated-statistics/boosters/aggregated-wheel-of-fortune-estimated-review-count/aggregated-wheel-of-fortune-estimated-review-count.component';
import { AggregatedWheelOfFortuneGiftsDistributionComponent } from ':modules/aggregated-statistics/boosters/aggregated-wheel-of-fortune-gifts-distribution/aggregated-wheel-of-fortune-gifts-distribution.component';
import { AggregatedWheelOfFortuneGiftsKpisComponent } from ':modules/aggregated-statistics/boosters/aggregated-wheel-of-fortune-gifts-kpis/aggregated-wheel-of-fortune-gifts-kpis.component';
import {
    AggregatedBoostersStatisticsData,
    AggregatedWheelOfFortuneGiftsStatisticsData,
} from ':modules/aggregated-statistics/boosters/booster.interface';
import { BoostersAggregatedDataFetchingService } from ':modules/aggregated-statistics/boosters/services/get-boosters-aggregated-data.service';
import { FiltersComponent } from ':modules/aggregated-statistics/filters/filters.component';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { parseInsightsRouteParams } from ':shared/helpers/extract-statistics-route-data';
import { DatesAndPeriod, FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS, MalouPeriod, Nfc, Restaurant } from ':shared/models';
import { FromToDateFormatterPipe } from ':shared/pipes/from-to-date-formatter.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { StatisticsPdfRestaurantsFormatterPipe } from ':shared/pipes/statistics-pdf-restaurants-formatter.pipe';

@Component({
    selector: 'app-boosters-pdf',
    templateUrl: './boosters-pdf.component.html',
    standalone: true,
    imports: [
        FiltersComponent,
        IllustrationPathResolverPipe,
        TranslateModule,
        FromToDateFormatterPipe,
        NgTemplateOutlet,
        AsyncPipe,
        IncludesPipe,
        AggregatedBoostersScanCountComponent,
        AggregatedWheelOfFortuneEstimatedReviewCountComponent,
        AggregatedWheelOfFortuneGiftsDistributionComponent,
        AggregatedWheelOfFortuneGiftsKpisComponent,
        StatisticsPdfRestaurantsFormatterPipe,
    ],
    styleUrls: ['./boosters-pdf.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoostersPdfComponent {
    startDate: Date;
    endDate: Date;
    displayedCharts: InsightsChart[];
    chartOptions: ChartOptions;
    selectedRestaurantsTitle$: Observable<string>;
    isRestaurantsCountUiLimitExceeded$: Observable<boolean>;
    nfcIds: string[] = [];
    restaurantIds: string[] = [];

    readonly InsightsChart = InsightsChart;
    totems$: Observable<Nfc[]>;
    restaurants$: Observable<Restaurant[]>;
    restaurantsWithBoosterPackActivated$: Observable<Restaurant[]>;

    atLeastOneBoosterPackActivated: WritableSignal<Observable<Boolean>> = signal(of(false));

    isLoadingBoosters: WritableSignal<boolean> = signal(true);
    isErrorBoosters: WritableSignal<boolean> = signal(false);
    isLoadingGifts: WritableSignal<boolean> = signal(true);
    isErrorGifts: WritableSignal<boolean> = signal(false);
    boostersData$: Observable<AggregatedBoostersStatisticsData>;
    filteredBoostersData$: Observable<AggregatedBoostersStatisticsData>;
    wheelOfFortuneData$: Observable<AggregatedBoostersStatisticsData>;
    giftsData$: Observable<AggregatedWheelOfFortuneGiftsStatisticsData>;

    boostersAggregatedScanCountHasData = true;
    wheelOfFortuneAggregatedEstimatedReviewCountHasData = true;
    wheelOfFortuneAggregatedGiftsDistributionHasData = true;
    wheelOfFortuneAggregatedGiftsKpisHasData = true;

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _aggregatedFiltersContext: AggregatedStatisticsFiltersContext,
        private readonly _destroyRef: DestroyRef,
        private readonly _nfcsService: NfcService,
        private readonly _getBoostersAggregatedDataService: BoostersAggregatedDataFetchingService
    ) {
        this._getRouteParams();

        this._init();

        this._getRestaurantsTitle();

        this._boostersAggregatedData();

        this._getChartsData();
    }

    private _getRouteParams(): void {
        const parsedQueryParams = parseInsightsRouteParams(this._route.snapshot.queryParams.params);
        const { startDate, endDate, displayedCharts, chartOptions, nfcIds, restaurantIds } = parsedQueryParams;

        this.displayedCharts = displayedCharts;
        this.chartOptions = chartOptions ?? {};
        this.startDate = startDate;
        this.endDate = endDate;

        this.nfcIds = nfcIds ?? [];
        this.restaurantIds = restaurantIds ?? [];
    }

    private _init(): void {
        this.restaurants$ = this._aggregatedFiltersContext.selectedRestaurants$;
        this.restaurantsWithBoosterPackActivated$ = this.restaurants$.pipe(
            map((restaurants) =>
                restaurants.filter((restaurant) => restaurant.boosterPack?.activated && this.restaurantIds.includes(restaurant._id))
            )
        );
        this.atLeastOneBoosterPackActivated = signal(
            this.restaurantsWithBoosterPackActivated$.pipe(
                map((restaurants) => restaurants.length > 0),
                takeUntilDestroyed(this._destroyRef)
            )
        );
        this.totems$ = this._nfcsService.search({}, this.restaurantIds).pipe(
            map((res) => {
                const nfcs: Nfc[] = [];
                if (res.data) {
                    res.data.forEach((nfc) => {
                        nfcs.push(Nfc.fromNfcDto(nfc));
                    });
                }
                return nfcs;
            })
        );
    }

    private _getRestaurantsTitle(): void {
        this.selectedRestaurantsTitle$ = this.restaurants$.pipe(
            map((restaurants) => restaurants.map((restaurant) => restaurant.internalName ?? restaurant.name).join(', '))
        );
        this.isRestaurantsCountUiLimitExceeded$ = this.restaurants$.pipe(
            map((restaurants) => restaurants.length > AGGREGATED_STATISTICS_RESTAURANTS_COUNT_UI_LIMIT)
        );
    }

    private _boostersAggregatedData(): void {
        this.boostersData$ = combineLatest([
            this.restaurants$,
            of({ startDate: this.startDate, endDate: this.endDate, period: MalouPeriod.CUSTOM }),
            this.totems$,
        ]).pipe(
            filter(([restaurants, dates]) => restaurants.length > 0 && isDateSetOrGenericPeriod(dates)),
            tap(() => {
                this.isErrorBoosters.set(false);
                this.isLoadingBoosters.set(true);
            }),
            debounceTime(500),
            filter(([_, dates]) => isNotNil(dates.startDate) && isNotNil(dates.endDate)),
            switchMap(([restaurants, dates, nfcs]: [Restaurant[], DatesAndPeriod, Nfc[]]) => {
                const data = this._getBoostersAggregatedDataService.getChartsData(
                    this._getFilteredNfcs(nfcs, this.nfcIds),
                    dates,
                    restaurants
                );
                this.isLoadingBoosters.set(false);
                return data;
            }),
            catchError(() => {
                this.isErrorBoosters.set(true);
                this.isLoadingBoosters.set(false);
                return EMPTY;
            }),
            takeUntilDestroyed(this._destroyRef)
        );
    }

    private _getChartsData(): void {
        this.giftsData$ = combineLatest([
            this.restaurantsWithBoosterPackActivated$,
            of({ startDate: this.startDate, endDate: this.endDate, period: MalouPeriod.CUSTOM }),
        ]).pipe(
            filter(([restaurants, dates]) => restaurants.length > 0 && isDateSetOrGenericPeriod(dates)),
            tap(() => {
                this.isErrorGifts.set(false);
                this.isLoadingGifts.set(true);
            }),
            debounceTime(500),
            filter(([_, dates]) => isNotNil(dates.startDate) && isNotNil(dates.endDate)),
            switchMap(([restaurants, dates]: [Restaurant[], DatesAndPeriod]) => {
                const data = this._getBoostersAggregatedDataService.getGiftsData(dates, restaurants);
                this.isLoadingGifts.set(false);
                return data;
            }),
            catchError(() => {
                this.isErrorGifts.set(true);
                this.isLoadingGifts.set(false);
                return EMPTY;
            }),
            takeUntilDestroyed(this._destroyRef)
        );

        this.wheelOfFortuneData$ = this.boostersData$.pipe(
            map((data) => ({
                ...data,
                scans: data.scans.filter((scan) => scan.isWheelOfFortuneRelated()),
                previousScans: data.previousScans.filter((scan) => scan.isWheelOfFortuneRelated()),
            }))
        );
    }

    private _getFilteredNfcs(allNfcs: Nfc[], nfcs: string[] = []): Nfc[] {
        const nfcsWithWheelOfFortune = nfcs.concat([FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS]);
        if (this.displayedCharts.includes(InsightsChart.AGGREGATED_BOOSTERS_SCAN_COUNT)) {
            return allNfcs.filter((nfc: Nfc) => nfcsWithWheelOfFortune.includes(nfc.id));
        }
        return allNfcs;
    }
}
