<div (click)="onNotificationClick()">
    <div class="malou-text-9 italic text-malou-color-text-2--light">
        {{
            'notifications.at'
                | translate: { date: notification().createdAt | date: 'dd.MM.yyyy', time: notification().createdAt | date: 'HH:mm' }
        }}
    </div>
    <div class="mt-2 flex w-full items-center gap-x-3">
        <div>⭐️</div>
        <div class="flex flex-col">
            <div class="malou-text-11--semibold flex items-center gap-x-2 text-malou-color-text-1">
                {{
                    hasMultipleRestaurantsInNotification()
                        ? ('notification-center.review-notification-item.new_reviews_received'
                          | translate: { numberOfRestaurants: notification().data.restaurantIds.length })
                        : restaurant()?.getDisplayName()
                }}
            </div>
            <div class="malou-text-11 text-malou-color-text-2">
                {{
                    'notification-center.review-notification-item.new_reviews_received_number'
                        | pluralTranslate: notification().data.reviewIds.length
                }}
            </div>
        </div>
        <div class="ml-auto">
            <button class="malou-btn-flat btn-sm !px-0" mat-button>{{ 'common.see' | translate }}</button>
        </div>
    </div>
</div>
