<div (click)="onNotificationClick()">
    <div class="malou-text-9 italic text-malou-color-text-2--light">
        {{
            'notifications.at'
                | translate: { date: notification().createdAt | date: 'dd.MM.yyyy', time: notification().createdAt | date: 'HH:mm' }
        }}
    </div>
    <div class="mt-2 flex w-full gap-x-3">
        <span class="malou-text-14">💌</span>
        <ng-container [ngTemplateOutlet]="hasMultipleRestaurantsInNotification() ? multipleRestaurantsTemplate : singleRestaurantTemplate">
        </ng-container>
    </div>
</div>

<ng-template #multipleRestaurantsTemplate>
    <div class="flex flex-col gap-y-2">
        <div class="malou-text-11--semibold flex items-center gap-x-2 text-malou-color-text-1">
            {{
                'notification-center.comment_notification_item.multiple_restaurants_title'
                    | translate: { restaurantsCount: notification().data.restaurantIds.length }
            }}
        </div>
        <div>
            <button class="malou-btn-flat btn-sm !px-0" mat-button (click)="$event.stopPropagation(); toggleList()">
                {{ isListVisible() ? ('notification-center.hide_list' | translate) : ('notification-center.show_list' | translate) }}
            </button>
        </div>
        @if (isListVisible()) {
            @for (restaurantComments of commentsByRestaurants(); track restaurantComments.restaurantId) {
                <div class="flex gap-y-1">
                    <div class="flex w-full">
                        <span class="malou-text-11--semibold text-malou-color-text-1">
                            {{ restaurantComments.restaurantName | shortText: 25 }} -
                        </span>
                        &nbsp;
                        <span class="malou-text-11 text-malou-color-text-1">
                            {{
                                'notification-center.comment_notification_item.comments'
                                    | translate: { commentsCount: restaurantComments.commentsCount }
                            }}
                        </span>
                    </div>
                    <div class="flex">
                        <button
                            class="malou-btn-flat btn-sm !px-0"
                            mat-button
                            (click)="$event.stopPropagation(); onNotificationClick(restaurantComments.restaurantId)">
                            {{ 'common.reply' | translate }}
                        </button>
                    </div>
                </div>
            }
        }
    </div>
</ng-template>

<ng-template #singleRestaurantTemplate>
    <div class="flex flex-col gap-y-2">
        <div class="malou-text-11--semibold flex items-center gap-x-2 text-malou-color-text-1">
            {{
                restaurant()?.getDisplayName() +
                    ' ' +
                    ('notification-center.comment_notification_item.single_restaurant_title'
                        | pluralTranslate: notification().data.comments.length)
            }}
        </div>
        <div>
            <span class="malou-text-11 text-malou-color-text-2">
                {{ '@' }}{{ firstComment().authorDisplayName }}: {{ firstComment().text | shortText: SHORT_TEXT_LENGTH }}
            </span>
        </div>
    </div>
</ng-template>
