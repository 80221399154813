import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName, PostedStatus } from '@malou-io/package-utils';

import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { NegativeReviewReminderNotification } from ':core/components/notification-center/models/negative-review-reminder-notification.model';
import { NotificationService } from ':core/components/notification-center/services/notifications.service';
import { ToastService } from ':core/services/toast.service';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { StarGaugeComponent } from ':shared/components/star-gauge/star-gauge.component';
import { ReviewReply } from ':shared/models';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

import { MalouSpinnerComponent } from '../../../../spinner/spinner/malou-spinner.component';
import { NotificationItemComponent } from '../notification-item.component';

@Component({
    selector: 'app-negative-review-reminder-notification-item',
    templateUrl: './negative-review-reminder-notification-item.component.html',
    styleUrl: './negative-review-reminder-notification-item.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass,
        TranslateModule,
        DatePipe,
        PlatformLogoComponent,
        StarGaugeComponent,
        ShortTextPipe,
        MatButtonModule,
        MalouSpinnerComponent,
    ],
})
export class NegativeReviewReminderNotificationItemComponent extends NotificationItemComponent implements OnInit {
    private readonly _router = inject(Router);
    private readonly _reviewService = inject(ReviewsService);
    private readonly _toastService = inject(ToastService);
    private readonly _notificationService = inject(NotificationService);
    private readonly _notificationsContext = inject(NotificationCenterContext);
    readonly notification = computed(() => this.initialNotification() as NegativeReviewReminderNotification);

    readonly isFolded = signal(true);
    readonly answeringReview = signal(false);

    readonly review = computed(() => this.notification()?.getMainReviewToNotify());

    readonly restaurantName = computed(() => this.review()?.restaurantName);

    readonly SHORT_TEXT_LENGTH = 100;

    ngOnInit(): void {
        if (!this.notification().data.suggestedReply) {
            this._notificationService.initReviewReplyReminderNotification({ notificationId: this.notification().id }).subscribe({
                next: (notification) => {
                    this.updateNotification(notification);
                },
            });
        }
    }

    toggleFolded(): void {
        this.isFolded.update((old) => !old);
    }

    onNotificationClick(): void {
        this._notificationsContext.trackNotification({
            notificationId: this.notification().id,
            heapEventName: HeapEventName.NOTIFICATION_NEGATIVE_REVIEW_REMINDER_TRACKING_WEB_REDIRECT,
            properties: {
                restaurantId: this.notification().data.reviews[0].restaurantId,
                notificationType: this.notification()?.type,
            },
        });
        this.markAsRead();
        this._navigateToReviewsPage();
        this.notificationCenterContext.close();
    }

    sendReply(): void {
        this.answeringReview.set(true);
        this._reviewService
            .postReviewComment(
                this.review().id,
                new ReviewReply({
                    posted: PostedStatus.PENDING,
                    text: this.notification().data.suggestedReply,
                    isRepliedFromAggregatedView: false,
                }),
                this.review().restaurantId
            )
            .subscribe({
                next: () => {
                    this.notificationCenterContext.markNotificationAsCompleted(this.notification());
                    this.answeringReview.set(false);
                },
                error: (err) => {
                    this.answeringReview.set(false);
                    this._showReplyErrorToast(err);
                },
            });
    }

    private _navigateToReviewsPage(): void {
        this._router.navigate(['restaurants', this.review().restaurantId, 'reputation', 'reviews'], {
            queryParams: { reviewId: this.review().restaurantId },
        });
    }

    private _showReplyErrorToast(err): void {
        const errMessage = err?.error?.message || '';
        this._toastService.openErrorToast(errMessage);
    }
}
