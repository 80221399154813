import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { round } from 'lodash';
import { combineLatest, filter, map, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { RoiAdditionalCustomersWithEarningsDto } from '@malou-io/package-dto';
import { HeapEventName, PlatformKey } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { PlatformsService } from ':core/services/platforms.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsSelector from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { EstimatedCustomersDetailsModalComponent } from ':modules/roi/estimated-customers-details-modal/estimated-customers-details-modal.component';
import { RoiMissingInformationBadgeComponent } from ':modules/roi/roi-missing-information-badge/roi-missing-information-badge.component';
import { RoiContext } from ':modules/roi/roi.context';
import { RoiService } from ':modules/roi/roi.service';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { getSelectedMonthsNumberFromTimeScaleFilter, MalouTimeScalePeriod, Restaurant } from ':shared/models';
import { EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-aggregated-estimated-customers',
    templateUrl: './aggregated-estimated-customers.component.html',
    styleUrls: ['./aggregated-estimated-customers.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        EmojiPathResolverPipe,
        NumberEvolutionComponent,
        ShortNumberPipe,
        TranslateModule,
        MatIconModule,
        MatTooltipModule,
        RoiMissingInformationBadgeComponent,
        AsyncPipe,
        NgTemplateOutlet,
        SkeletonComponent,
    ],
})
export class AggregatedEstimatedCustomersComponent implements OnInit {
    readonly isParentLoading = input.required<boolean>();

    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _heapService = inject(HeapService);
    private readonly _roiService = inject(RoiService);
    private readonly _store = inject(Store);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    public readonly roiContext = inject(RoiContext);
    public readonly platformService = inject(PlatformsService);

    readonly PlatformKey = PlatformKey;

    readonly isLoading: WritableSignal<boolean> = signal(false);
    readonly isMissingData: Signal<boolean> = computed(() => this.estimatedCustomersDetails()?.isMissingData ?? false);
    readonly hasValidData: Signal<boolean> = computed(() => this.totalCustomers() > 0);

    readonly estimatedCustomersDetails: WritableSignal<RoiAdditionalCustomersWithEarningsDto | null> = signal(null);
    readonly minCustomers: Signal<number> = computed(() => this._roundToTens(this.estimatedCustomersDetails()?.minCustomers ?? 0));
    readonly maxCustomers: Signal<number> = computed(() => this._roundToTens(this.estimatedCustomersDetails()?.maxCustomers ?? 0));
    readonly minEarnings: Signal<number> = computed(() => this.estimatedCustomersDetails()?.minEarnings ?? 0);
    readonly maxEarnings: Signal<number> = computed(() => this.estimatedCustomersDetails()?.maxEarnings ?? 0);
    readonly totalCustomers: Signal<number> = computed(() => this.estimatedCustomersDetails()?.totalCustomers ?? 0);
    readonly previousPeriodTotalCustomers: Signal<number> = computed(
        () => this.estimatedCustomersDetails()?.previousPeriodTotalCustomers ?? 0
    );

    readonly totalEarningsEvolution: Signal<number> = computed(() => {
        const current = this.totalCustomers();
        const previous = this.previousPeriodTotalCustomers();
        return previous ? round(((current - previous) / previous) * 100, 0) : 0;
    });

    readonly selectedTimeScaleFilter$ = this._store.select(AggregatedStatisticsSelector.selectTimeScaleFilter);
    readonly selectedMonths$ = this.selectedTimeScaleFilter$.pipe(
        map((timeScaleFilter) => getSelectedMonthsNumberFromTimeScaleFilter(timeScaleFilter))
    );
    readonly selectedMonths: Signal<number> = toSignal(this.selectedMonths$, {
        initialValue: getSelectedMonthsNumberFromTimeScaleFilter(MalouTimeScalePeriod.LAST_SIX_MONTHS),
    });

    readonly isTotalEarningsEvolutionTooHigh: Signal<boolean> = computed(
        () => Math.abs(this.totalEarningsEvolution() ?? 0) > this._MAX_DISPLAY_EVOLUTION
    );
    private readonly _MAX_DISPLAY_EVOLUTION = 200;

    private readonly _killSubscriptions$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        this._initAggregatedAdditionalCustomers();
    }

    openDetails(): void {
        this._heapService.track(HeapEventName.TRACKING_CHECK_EARNINGS_DETAILS_MULTI_RESTAURANTS);

        this._customDialogService.open(
            EstimatedCustomersDetailsModalComponent,
            {
                height: 'unset',
                maxHeight: '90vh',
                data: {
                    additionalCustomers: this.estimatedCustomersDetails(),
                    isAggregatedView: true,
                },
            },
            { closeOnOutsideClick: true }
        );
    }

    private _initAggregatedAdditionalCustomers(): void {
        combineLatest([this._aggregatedStatisticsFiltersContext.savedRestaurantsWithRoiSettings$, this.selectedTimeScaleFilter$])
            .pipe(
                filter(([restaurants, _]: [Restaurant[], MalouTimeScalePeriod | undefined]) => !!restaurants.length),
                tap(() => this.isLoading.set(true)),
                switchMap(([restaurants, _]: [Restaurant[], MalouTimeScalePeriod | undefined]) =>
                    this._roiService
                        .getAggregatedEstimatedCustomersForRestaurantsForNLastMonths(
                            restaurants.map((restaurant) => restaurant._id),
                            this.selectedMonths()
                        )
                        .pipe(map((res) => res.data))
                ),
                takeUntil(this._killSubscriptions$)
            )
            .subscribe({
                next: (estimatedCustomersDetails) => {
                    this.estimatedCustomersDetails.set(estimatedCustomersDetails);
                    this.isLoading.set(false);
                },
                error: () => {
                    this.isLoading.set(false);
                },
            });
    }

    private _roundToTens(num: number): number {
        return num >= 10 ? round(num / 10) * 10 : num;
    }
}
