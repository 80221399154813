import { InsightsChart, PlatformKey } from '@malou-io/package-utils';

import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';

export interface DownloadInsightsParams {
    displayedCharts: InsightsChart[];
    chartOptions?: ChartOptions;
    platformKeys?: PlatformKey[];
    restaurantIds?: string[];
    nfcIds?: string[];
    startDate: Date;
    endDate: Date;
    timeZone: string;
}

export function parseInsightsRouteParams(params: string): DownloadInsightsParams {
    const parsedQueryParams = JSON.parse(params);
    const { startDate, endDate } = parsedQueryParams;

    return {
        ...parsedQueryParams,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
    };
}
