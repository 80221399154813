import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { getEphemeraRestaurantIdAndSpecialGiftIdForEnv, PlatformKey, WheelOfFortuneRedirectionPlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { environment } from ':environments/environment';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

const { restaurantId: SPECIAL_RESTAURANT_ID } = getEphemeraRestaurantIdAndSpecialGiftIdForEnv(environment.environment);

@Component({
    selector: 'app-leave-review-wheel-of-fortune-modal',
    templateUrl: './leave-review-wheel-of-fortune-modal.component.html',
    styleUrls: ['./leave-review-wheel-of-fortune-modal.component.scss'],
    standalone: true,
    imports: [
        NgStyle,
        NgTemplateOutlet,
        MatIconModule,
        MatButtonModule,
        TranslateModule,
        ImagePathResolverPipe,
        MalouSpinnerComponent,
        EnumTranslatePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaveReviewWheelOfFortuneModalComponent {
    readonly SvgIcon = SvgIcon;
    readonly PlatformKey = PlatformKey;

    readonly isEphemeraRestaurant = signal<boolean>(false);

    constructor(
        private readonly _dialogRef: MatDialogRef<LeaveReviewWheelOfFortuneModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            wheelOfFortuneId: string;
            restaurantId: string;
            primaryColor: string;
            secondaryColor: string;
            redirectionLink: string;
            redirectionPlatform: WheelOfFortuneRedirectionPlatformKey;
        }
    ) {
        this.isEphemeraRestaurant.set(this.data.restaurantId === SPECIAL_RESTAURANT_ID);
    }

    openRedirectionLink(): void {
        if (this.data.redirectionLink) {
            // eslint-disable-next-line max-len
            const queryParams = `primaryColor=${encodeURIComponent(this.data.primaryColor)}&secondaryColor=${encodeURIComponent(
                this.data.secondaryColor
            )}&redirectionPlatform=${encodeURIComponent(this.data.redirectionPlatform)}&redirectionLink=${encodeURIComponent(
                this.data.redirectionLink
            )}`;
            window.open(`${window.location.origin}/wheel-of-fortune-redirect?${queryParams}`, '_blank');
            this._pushLeavedReviewWheelOfFortuneInLocalStorage();
            this.close(true);
        }
    }

    close(hasLeftReview = false): void {
        // TODO EPHEMERA : delete when no longer need custom settings
        if (this.isEphemeraRestaurant()) {
            hasLeftReview = true;
        }
        this._dialogRef.close({ hasLeftReview });
    }

    private _pushLeavedReviewWheelOfFortuneInLocalStorage(): void {
        const leavedReviewRestaurantWheelsOfFortune = JSON.parse(
            localStorage.getItem(LocalStorageKey.LEAVED_REVIEW_RESTAURANT_WHEELS_OF_FORTUNE) || '[]'
        );
        leavedReviewRestaurantWheelsOfFortune.push({
            wheelOfFortuneId: this.data.wheelOfFortuneId,
            restaurantId: this.data.restaurantId,
            platformKey: this.data.redirectionPlatform,
        });
        localStorage.setItem(
            LocalStorageKey.LEAVED_REVIEW_RESTAURANT_WHEELS_OF_FORTUNE,
            JSON.stringify(leavedReviewRestaurantWheelsOfFortune)
        );
    }
}
