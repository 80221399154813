<div class="rounded-xl bg-malou-color-background-dark p-6.5">
    <ng-container [ngTemplateOutlet]="wheelOfFortuneHeaderTemplate"></ng-container>

    @if (areWheelDetailsOpen()) {
        <div class="mt-8 flex flex-col gap-y-8">
            <ng-container [ngTemplateOutlet]="wheelOfFortuneDetailsTemplate"></ng-container>
        </div>
    }
</div>

<ng-template #wheelOfFortuneDetailsTemplate>
    <div>
        <div class="malou-text-13--semibold malou-color-text-1 mb-3">
            {{ 'wheel_of_fortune.wheel_of_fortune_card.details.gifts' | translate }}
        </div>

        <div class="flex flex-wrap gap-x-3 gap-y-2">
            @for (gift of wheelOfFortune.gifts; track gift) {
                <div class="malou-chip malou-chip--primary">
                    {{ gift.name }} ({{
                        'wheel_of_fortune.new_wheel_modal.tabs.gifts.gift_card.win_probability.percentage'
                            | translate: { value: (wheelOfFortune | applySelfPure: 'getGiftWinProbability' : gift) }
                    }})
                </div>
            }
        </div>
    </div>

    <div>
        <div class="malou-text-13--semibold malou-color-text-1 mb-3">
            {{ 'wheel_of_fortune.wheel_of_fortune_card.details.settings.title' | translate }}
        </div>

        <ul class="ml-4 list-disc">
            <li class="mb-4">
                <div class="malou-text-12--semibold malou-color-text-1">
                    {{ 'wheel_of_fortune.wheel_of_fortune_card.details.settings.start_retrieval_date' | translate }}
                </div>

                <div class="malou-chip malou-chip--primary mt-2">
                    {{ wheelOfFortune.parameters.giftClaimStartDateOption | enumTranslate: 'gift_claim_start_date_option' }}
                </div>
            </li>

            <li>
                <div class="malou-text-12--semibold malou-color-text-1">
                    {{ 'wheel_of_fortune.wheel_of_fortune_card.details.settings.duration_for_retrieval' | translate }}
                </div>
                <div class="malou-text-10--regular malou-color-text-2 italic">
                    {{ 'wheel_of_fortune.wheel_of_fortune_card.details.settings.email_retrieval' | translate }}
                </div>

                <div class="malou-chip malou-chip--primary mt-2">
                    {{ displayGiftClaimDuration(wheelOfFortune.parameters.giftClaimDurationInDays) }}
                </div>
            </li>
        </ul>
    </div>

    @if (!isAggregatedView) {
        <div>
            <div class="malou-text-13--bold malou-color-text-1">
                {{ 'wheel_of_fortune.create_wheel_card.how_does_it_work' | translate }}
            </div>
            <app-how-wheel-of-fortune-works></app-how-wheel-of-fortune-works>
        </div>
    }
</ng-template>

<ng-template #wheelOfFortuneHeaderTemplate>
    <div class="flex items-center justify-between">
        <div class="flex items-center gap-x-3">
            <ng-container
                [ngTemplateOutlet]="isAggregatedView ? aggregatedWheelOfFortuneTitleTemplate : restaurantWheelOfFortuneTitleTemplate">
            </ng-container>
            <ng-container
                [ngTemplateOutlet]="wheelOfFortuneStateTemplate"
                [ngTemplateOutletContext]="{ state: wheelOfFortune | applySelfPure: 'getState' }"></ng-container>
        </div>
        <div class="flex gap-x-2">
            <div
                disableIfMissingCaslRole
                [neededCaslRoles]="WHEEL_OF_FORTUNE_NEEDED_ROLES"
                [disableIfMissingCaslRoleCheckType]="DisableIfMissingCaslRoleCheckType.AT_LEAST_ONE"
                [restaurantIdsToCheckOnRole]="wheelOfFortuneRestaurantIds()"
                [matTooltip]="disabledDirective.isDisabled ? ('casl.wrong_role' | translate) : ''"
                #disabledDirective="isDisabledByCasl">
                <button
                    class="malou-btn-raised--primary btn-xl !h-12.5 !min-w-fit"
                    id="tracking_wof_edit_wheel_button"
                    mat-raised-button
                    [disabled]="disabledDirective.isDisabled"
                    (click)="openWheelOfFortuneModal()">
                    {{ 'common.edit' | translate }}
                </button>
            </div>
            <button class="malou-btn-icon--secondary !h-12.5 !w-12.5" mat-icon-button [matMenuTriggerFor]="actionsMenu">
                <mat-icon color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
                    <button class="flex" mat-menu-item (click)="downloadQrCode()">
                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.QR_CODE"></mat-icon>
                        <span class="malou-text-14--regular">
                            {{ 'wheel_of_fortune.wheel_of_fortune_card.actions.download_qr_code' | translate }}
                        </span>
                    </button>
                    <button class="flex" mat-menu-item (click)="copyWheelOfFortuneUrl()">
                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.LINK"></mat-icon>
                        <span class="malou-text-14--regular">
                            {{ 'wheel_of_fortune.wheel_of_fortune_card.actions.copy_link' | translate }}
                        </span>
                    </button>
                    <button class="malou-menu-submenu-icon flex" mat-menu-item [matMenuTriggerFor]="downloadMenu">
                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.DOWNLOAD"></mat-icon>
                        <span class="malou-text-14--regular">
                            {{ 'wheel_of_fortune.wheel_of_fortune_card.actions.download_post.title' | translate }}
                        </span>
                    </button>
                    <button
                        class="flex"
                        disableIfMissingCaslRole
                        mat-menu-item
                        [neededCaslRoles]="WHEEL_OF_FORTUNE_NEEDED_ROLES"
                        [disableIfMissingCaslRoleCheckType]="DisableIfMissingCaslRoleCheckType.AT_LEAST_ONE"
                        [restaurantIdsToCheckOnRole]="wheelOfFortuneRestaurantIds()"
                        [disabled]="!hasAccessToAllWheelRestaurants()"
                        [matTooltip]="
                            !hasAccessToAllWheelRestaurants()
                                ? ('wheel_of_fortune.wheel_of_fortune_card.actions.can_not_delete' | translate)
                                : null
                        "
                        (click)="openDeleteWheelOfFortuneModal()">
                        <mat-icon class="malou-color-state-error !h-4" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                        <span class="malou-text-14--regular">
                            {{ 'wheel_of_fortune.wheel_of_fortune_card.actions.delete' | translate }}
                        </span>
                    </button>
                </mat-menu>

                <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #downloadMenu="matMenu">
                    <button class="flex" mat-menu-item (click)="downloadPoster(WheelOfFortunePosterFormat.A4_PORTRAIT)">
                        <span class="malou-text-14--regular">
                            {{ 'wheel_of_fortune.wheel_of_fortune_card.actions.download_post.portrait' | translate }}
                        </span>
                    </button>
                    <button class="flex" mat-menu-item (click)="downloadPoster(WheelOfFortunePosterFormat.A4_LANDSCAPE)">
                        <span class="malou-text-14--regular">
                            {{ 'wheel_of_fortune.wheel_of_fortune_card.actions.download_post.landscape' | translate }}
                        </span>
                    </button>
                </mat-menu>
            </button>
            <button class="malou-btn-icon--secondary !h-12.5 !w-12.5" mat-icon-button (click)="toggleWheelDetailsOpen()">
                <mat-icon class="!h-full" color="primary" [svgIcon]="areWheelDetailsOpen() ? 'chevron-up' : 'chevron-down'"></mat-icon>
            </button>
        </div>
    </div>

    @if (isAggregatedView) {
        <div>
            <div class="malou-text-13--semibold malou-color-text-1 my-3">
                {{ 'wheel_of_fortune.wheel_of_fortune_card.details.restaurants.title' | translate }}
            </div>
            @if (!hasAccessToAllWheelRestaurants()) {
                <div class="malou-text-13 mb-4 italic">
                    {{ 'wheel_of_fortune.wheel_of_fortune_card.details.restaurants.limited_access' | translate }}
                </div>
            }
            <div class="flex items-center">
                <div
                    class="flex gap-x-3 gap-y-2"
                    appHideOverflowingChildren
                    [ngClass]="{ 'flex-wrap': areWheelDetailsOpen(), 'line-clamp-2 overflow-y-auto': !areWheelDetailsOpen() }"
                    (childrenHidden)="onChildrenHidden($event)"
                    (refreshFn)="onRefreshFn($event)">
                    @for (restaurant of wheelOfFortune.restaurants; track trackByIdFn($index, restaurant)) {
                        <div class="malou-chip malou-chip--primary min-w-fit">
                            {{ restaurant | applySelfPure: 'getDisplayName' }}
                            @if (!isRestaurantManagedByUser()(restaurant.id)) {
                                <mat-icon class="small-icon ml-1" color="primary" [svgIcon]="SvgIcon.PADLOCK"></mat-icon>
                            }
                        </div>
                    }
                </div>
                @if (!areWheelDetailsOpen() && childrenHiddenCount() > 0) {
                    <div>
                        <span class="malou-text-14--semibold malou-color-text-primary ml-3"> +{{ childrenHiddenCount() }} </span>
                    </div>
                }
            </div>
        </div>
    }
    @if (displayPoster()) {
        <div>
            <div class="absolute top-0 z-[-1]">
                <!-- The component has to be displayed to be downloaded by the lib, so we display it in the back so the user dont see it with z-index -1 -->
                <app-wheel-of-fortune-poster [wheelOfFortune]="wheelOfFortune" [format]="format"></app-wheel-of-fortune-poster>
            </div>
        </div>
    }
</ng-template>

<ng-template let-state="state" #wheelOfFortuneStateTemplate>
    <div
        class="malou-chip !cursor-default"
        [ngClass]="{
            'malou-chip--warn': state === WheelOfFortuneState.PROGRAMMED,
            'malou-chip--success-light text-malou-color-text-green': state === WheelOfFortuneState.ACTIVE,
        }">
        {{ state | applySelfPure: 'toLowerCase' | enumTranslate: 'wheel_of_fortune_state' }}
    </div>
    <div class="malou-text-10--regular malou-color-text-2 italic">
        @if (state === WheelOfFortuneState.PROGRAMMED || wheelOfFortune.endDate) {
            <span>
                {{ wheelOfFortune | applySelfPure: 'getFormattedStartDate' }}
            </span>
        }
        @if (wheelOfFortune.endDate) {
            <span> - </span>
        }
        @if (wheelOfFortune.endDate) {
            <span>{{ wheelOfFortune | applySelfPure: 'getFormattedEndDate' }}</span>
        }
    </div>
</ng-template>

<ng-template #aggregatedWheelOfFortuneTitleTemplate>
    <div class="malou-text-18--bold malou-color-text-1 flex items-center">
        <ng-container [ngTemplateOutlet]="emptyStockIconTemplate"></ng-container>
        <span>{{ 'wheel_of_fortune.wheel_of_fortune' | translate: { count: index + 1 } }}</span>
    </div>
</ng-template>

<ng-template #restaurantWheelOfFortuneTitleTemplate>
    <div class="malou-text-18--bold malou-color-text-1 flex items-center">
        <ng-container [ngTemplateOutlet]="emptyStockIconTemplate"></ng-container>
        <span>{{ 'wheel_of_fortune.wheel_of_fortune_card.restaurant_wheel_of_fortune_title' | translate }}</span>
    </div>
</ng-template>

<ng-template #emptyStockIconTemplate>
    @if (hasOneStockEmptyStock) {
        <div class="malou-status--waiting mr-2" [matTooltip]="'wheel_of_fortune.wheel_of_fortune_card.empty_stock' | translate">
            <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
        </div>
    }
</ng-template>
