import { NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    contentChild,
    DestroyRef,
    effect,
    forwardRef,
    inject,
    Injector,
    input,
    OnInit,
    output,
    signal,
    TemplateRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TranslateModule } from '@ngx-translate/core';
import { MentionConfig, MentionModule } from 'angular-mentions';
import { isEqual } from 'lodash';
import { debounceTime, distinctUntilChanged, startWith, tap } from 'rxjs/operators';

import { postCaptionTextLimit } from ':core/constants';
import { createTextWithEmoji, CursorPosition, focusAfterEmoji } from ':shared/helpers/text-area-emoji.helpers';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { TextareaAutosizeDirective } from '../../directives/text-area-auto-size.directive';
import { ControlValueAccessorConnectorComponent } from '../control-value-accessor-connector/control-value-accessor-connector';
import { EmojiPickerComponent } from '../emoji-picker/emoji-picker.component';

interface TextSelectionIndices {
    start: number;
    end: number;
}
@Component({
    selector: 'app-text-area',
    templateUrl: 'text-area.component.html',
    styleUrls: ['./text-area.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TextAreaComponent),
        },
    ],
    standalone: true,
    imports: [
        FormsModule,
        TextareaAutosizeDirective,
        MentionModule,
        ReactiveFormsModule,
        EmojiPickerComponent,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAreaComponent extends ControlValueAccessorConnectorComponent implements OnInit {
    /**
     * Title
     */
    readonly title = input<string>('');

    /**
     * Placeholder
     */
    readonly placeholder = input<string>('');

    /**
     * Debounce time (in milliseconds)
     */
    readonly debounceTime = input<number>(100);

    /**
     * Placeholder
     */
    readonly maxLength = input<number>(Number.MAX_SAFE_INTEGER);

    readonly showCounterInTitle = input<boolean>(true);

    readonly showMaxLength = input<boolean>(false);

    /**
     * Required option, will add an asterix after the title
     */
    readonly required = input<boolean>(false);

    /**
     * Required option, resizable or not
     */
    readonly resizable = input<boolean>(true);

    /**
     * Error message, will add a colored border and will display the error below the input
     */
    readonly errorMessage = input<string | undefined>();

    /**
     * Default number of rows
     */
    readonly rows = input<number>(5);

    /**
     * Default max number of rows
     */
    readonly maxRows = input<number | undefined>();

    /**
     * Is emoji picker enabled
     */
    readonly isEmojiPickerEnabled = input<boolean>(true);

    /**
     * Resize textarea when text overflows
     */
    readonly autoResizeOnContentOverflow = input<boolean>(false);

    /**
     * Mention configuration
     */
    readonly mentionConfiguration = input<MentionConfig>({ mentions: [] });

    /**
     * Text area id
     */
    readonly textAreaId = input<string>('textarea');

    readonly testId = input<string>();

    readonly shouldDisplayAiInteractionHeadBand = input<boolean>(false);
    readonly defaultValue = input<string | undefined>();
    readonly shouldDisplayAiButton = input<boolean>(false);
    readonly isLoadingAnimationEnabled = input<boolean>(false);

    readonly loadingAnimationTemplate = contentChild<TemplateRef<any>>('loadingAnimationTemplate');

    readonly textAreaChange = output<string>();
    readonly onPressEnterKey = output<void>();
    readonly selectionChange = output<string>();
    readonly hideAiInteractionReply = output<void>();
    readonly input = output<InputEvent>();
    readonly onFocus = output<FocusEvent>();

    readonly POST_CAPTION_TEXT_LIMIT = postCaptionTextLimit;

    readonly isEmptyValue = signal(false);
    readonly isFocused = signal(false);

    readonly computedPlaceholder = computed(() => (this.isLoadingAnimationEnabled() ? '' : this.placeholder()));
    readonly textAreaRowsCount = computed((): number => {
        const rows = this.rows();
        const maxRows = this.maxRows();
        const textAreaId = this.textAreaId();

        if (!maxRows) {
            return rows;
        }
        // get textWidth from characters width
        const textArea = this._getTextArea(textAreaId);
        const textLength = textArea?.value.length === 0 ? 1 : textArea?.value.length;
        const textWidth = textLength * 8;
        // get line breaks from text
        const lineBreaks = textArea?.value.split('\n').length ?? 0;

        const textAreaWidth = textArea?.offsetWidth;

        // get number of rows from text width and text area width
        const effectiveRows = Math.ceil(textWidth / textAreaWidth) + (lineBreaks - 1);

        return Math.min(effectiveRows, maxRows);
    });

    readonly SvgIcon = SvgIcon;

    /**
     * For implementing ControlValueAccessor
     */

    private _previousSelectionIndices: TextSelectionIndices = { start: 0, end: 0 };

    private readonly _destroyRef = inject(DestroyRef);

    constructor(injector: Injector) {
        super(injector);

        effect(() => {
            const isLoadingAnimationEnabled = this.isLoadingAnimationEnabled();
            if (isLoadingAnimationEnabled) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        });
    }

    ngOnInit(): void {
        this.control.valueChanges
            .pipe(
                startWith(this.control.value),
                tap((value) => this.setEmptyValue(value as string)),
                distinctUntilChanged((a, b) => isEqual(a, b)),
                debounceTime(this.debounceTime()),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((value) => this.textAreaChange.emit(value));

        if (this.defaultValue()) {
            this.control.setValue(this.defaultValue());
        }
    }

    setEmptyValue(value: string): void {
        this.isEmptyValue.set(!value);
    }

    emojiSelected(event: EmojiEvent): void {
        const MAX_LENGTH = 1000;
        if (this.control?.value?.length >= (this.maxLength() ?? MAX_LENGTH) - 1 || this.control.disabled) {
            return;
        }
        const textarea = this._getTextArea(this.textAreaId());
        const currentValue = textarea.value;
        const cursorPosition: CursorPosition = {
            startPosition: textarea?.selectionStart,
            endPosition: textarea?.selectionEnd,
        };
        const textWithEmoji = createTextWithEmoji(cursorPosition, currentValue, event);
        textarea.value = textWithEmoji;
        focusAfterEmoji(textarea, cursorPosition, event);
        this.setEmptyValue(textWithEmoji);
        this.control.setValue(textWithEmoji);
    }

    pressEnterKey(): void {
        this.onPressEnterKey.emit();
    }

    onMouseUp(): void {
        const textAreaHTMLElement = this._getTextArea(this.textAreaId());
        const selectionIndices = {
            start: textAreaHTMLElement.selectionStart,
            end: textAreaHTMLElement.selectionEnd,
        };

        if (
            selectionIndices.start === this._previousSelectionIndices.start &&
            selectionIndices.end === this._previousSelectionIndices.end
        ) {
            this.selectionChange.emit('');
            return;
        }
        const selection = textAreaHTMLElement.value.substring(textAreaHTMLElement.selectionStart, textAreaHTMLElement.selectionEnd);
        this._previousSelectionIndices = selectionIndices;
        this.selectionChange.emit(selection);
    }

    emitHideAiInteractionReply(): void {
        this.hideAiInteractionReply.emit();
    }

    onInput(event: InputEvent): void {
        this.input.emit(event);
    }

    private _getTextArea(textAreaId: string): HTMLTextAreaElement {
        return document.getElementById(textAreaId) as HTMLTextAreaElement;
    }
}
