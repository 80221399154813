import { isEqual, omit } from 'lodash';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

import { Day, RemoveMethodsFromClass } from '@malou-io/package-utils';

export const TIME_24 = '24-24';

// we use the JS convention for dates (January = 0)
export class MyDate {
    day: number = new Date().getDate();
    month: number = new Date().getMonth();
    year: number = new Date().getFullYear();

    public constructor(init?: Partial<MyDate>) {
        Object.assign(this, init);
    }

    static fromDate(date: Date): MyDate {
        return new MyDate({ day: date.getDate(), month: date.getMonth(), year: date.getFullYear() });
    }

    getDate(): Date {
        return new Date(this.year, this.month, this.day);
    }

    setDate(date: Date): void {
        this.day = date.getDate();
        this.month = date.getMonth();
        this.year = date.getFullYear();
    }

    toString(): string {
        return `${this.getDate().toDateString()}`;
    }

    equals(o: unknown): boolean {
        return this._isMyDate(o) && this.day === o.day && this.month === o.month && this.year === o.year;
    }

    isBefore(o: MyDate): boolean {
        return this.getDate().getTime() <= o.getDate().getTime();
    }

    getNextDay(): MyDate {
        const nextDayDate = DateTime.fromJSDate(this.getDate()).plus({ days: 1 }).toJSDate();
        return MyDate.fromDate(nextDayDate);
    }

    _isMyDate(o: unknown): o is MyDate {
        return o instanceof MyDate;
    }
}

export class Period {
    openTime?: string | null;
    closeTime?: string | null;
    isClosed: boolean;

    constructor(init?: Partial<Period>) {
        Object.assign(this, init);
    }

    shouldBeClosed(): boolean {
        return this.openTime === '00:00' && this.closeTime === '00:00' && !this.isClosed;
    }

    close(): void {
        this.isClosed = true;
        this.openTime = null;
        this.closeTime = null;
    }

    isFullDay(): boolean {
        return this.openTime === TIME_24;
    }

    cleanFullDay(): void {
        this.openTime = '00:00';
        this.closeTime = '24:00';
    }
}

export class TimePeriod extends Period {
    openDay: Day;
    closeDay: Day;

    public constructor(init?: Partial<TimePeriod>) {
        super();
        Object.assign(this, init);
    }

    toString(): string {
        return `${this.openDay} : (${this.isClosed ? 'closed' : 'open'}): ${this.openTime} - ${this.closeTime}`;
    }

    equals(o: any): boolean {
        if (!(o instanceof TimePeriod)) {
            return false;
        }
        return isEqual(this, o);
    }

    hasSameHours(o: any): boolean {
        if (!(o instanceof TimePeriod)) {
            return false;
        }
        return isEqual(omit(this, 'openDay', 'closeDay'), omit(o, 'openDay', 'closeDay'));
    }
}

export class SpecialTimePeriod extends Period {
    startDate: MyDate;
    endDate: MyDate;
    name?: string;
    isFromCalendarEvent?: boolean;

    public constructor(
        init?: Partial<
            Period & {
                startDate: RemoveMethodsFromClass<MyDate>;
                endDate: RemoveMethodsFromClass<MyDate>;
                name?: string;
                isFromCalendarEvent?: boolean;
            }
        >
    ) {
        super();
        Object.assign(this, init);
        if (init?.startDate) {
            this.startDate = new MyDate(init.startDate);
        }
        if (init?.endDate) {
            this.endDate = new MyDate(init.endDate);
        }
    }

    toString(): string {
        return `${this.startDate.toString()} : ${this.openTime} - ${this.endDate.toString()}: ${this.closeTime} (${
            this.isClosed ? 'closed' : 'open'
        })`;
    }

    isFutureDate(): boolean {
        const now = new MyDate();
        return now.isBefore(this.startDate);
    }
}

export class SpecialDatePeriod {
    divId = uuidv4(); // used to track the div in the UI and scroll to it
    name?: string;
    startDate: MyDate | undefined;
    endDate: MyDate | undefined;
    periods: Period[];
    isClosed: boolean;
    isFromCalendarEvent?: boolean;

    public constructor(init: Omit<RemoveMethodsFromClass<SpecialDatePeriod>, 'divId'>) {
        this.name = init.name;
        this.startDate = init.startDate;
        this.endDate = init.endDate;
        this.periods = init.periods;
        this.isClosed = init.isClosed;
        this.isFromCalendarEvent = init.isFromCalendarEvent;
    }

    hasSameDates(specialTimePeriod: SpecialTimePeriod): boolean {
        return (
            ((!this.startDate && !specialTimePeriod.startDate) || !!this.startDate?.equals(specialTimePeriod.startDate)) &&
            ((!this.endDate && !specialTimePeriod.endDate) || !!this.endDate?.equals(specialTimePeriod.endDate))
        );
    }

    getDaysList(): MyDate[] {
        if (!this.startDate) {
            return [];
        }
        let currentDate = this.startDate;
        const endDate = this.endDate ?? this.startDate;
        const daysList = [new MyDate(currentDate)];
        while (!currentDate.equals(endDate)) {
            currentDate = currentDate.getNextDay();
            daysList.push(new MyDate(currentDate));
        }
        return daysList;
    }

    getDisplayStartDate(lang: string): string {
        return this.startDate ? DateTime.fromJSDate(this.startDate.getDate()).setLocale(lang).toLocaleString(DateTime.DATE_HUGE) : '';
    }

    areDatesValid(): boolean {
        return !!this.startDate && (!this.endDate || this.startDate.isBefore(this.endDate));
    }

    arePeriodsValid(): boolean {
        if (this.isClosed) {
            return true;
        }
        return this.periods.every((period) => period.openTime !== null && (period.openTime === TIME_24 || period.closeTime !== null));
    }
}

export class HoursType {
    _id: string;
    hoursType: string;
    hoursTypeName: Record<string, string>;
    platformKey: string;

    public constructor(init?: Partial<HoursType>) {
        Object.assign(this, init);
    }

    getLocaleName(lang: string): string {
        return this.hoursTypeName?.[lang] || this.hoursTypeName?.default || '';
    }
}

export interface IOtherPeriod {
    hoursType: HoursType;
    periods: TimePeriod[];
}

interface OtherPeriodProps {
    hoursType: RemoveMethodsFromClass<HoursType>;
    periods: RemoveMethodsFromClass<TimePeriod>[];
}

export class OtherPeriod implements IOtherPeriod {
    hoursType: HoursType;
    periods: TimePeriod[];

    public constructor(init?: Partial<OtherPeriodProps>) {
        this.hoursType = new HoursType(init?.hoursType);
        this.periods = (init?.periods || []).map((u: any) => new TimePeriod(u));
    }
}
