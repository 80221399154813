import { ChangeDetectionStrategy, Component, computed, inject, OnDestroy, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';

import { NotificationPopinsComponent } from ':core/components/notification-center/components/notification-popins/notification-popins.component';
import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { NotificationCenterComponent } from ':core/components/notification-center/notification-center.component';
import { FooterManagerComponent } from ':core/components/restaurant/footer-manager/footer-manager.component';
import { PermissionsHeadbandComponent } from ':core/components/restaurant/permissions-headband/permissions-headband.component';
import { ExperimentationService } from ':core/services/experimentation.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { SidenavContentComponent } from ':modules/sidenav-router/sidenav-content/sidenav-content.component';
import * as SidenavActions from ':modules/sidenav-router/store/sidenav.actions';
import * as SidenavSelectors from ':modules/sidenav-router/store/sidenav.selectors';
import { HeaderComponent } from ':shared/components/header/header.component';

@Component({
    selector: 'app-sidenav-router',
    templateUrl: './sidenav-router.component.html',
    styleUrls: ['./sidenav-router.component.scss'],
    standalone: true,
    imports: [
        HeaderComponent,
        RouterOutlet,
        MatSidenavModule,
        SidenavContentComponent,
        FooterManagerComponent,
        PermissionsHeadbandComponent,
        NotificationCenterComponent,
        NotificationPopinsComponent,
    ],
    // Do not put OnPush strategy here because this view use router-outlet and so depend on many others views
    changeDetection: ChangeDetectionStrategy.Default,
})
export class SidenavRouterComponent implements OnDestroy {
    private readonly _store = inject(Store);
    private readonly _screenSizeService = inject(ScreenSizeService);
    public readonly notificationCenterContext = inject(NotificationCenterContext);
    public readonly experimentationService = inject(ExperimentationService);

    private _observer: ResizeObserver;
    private _currentElementBeingObserved: any;
    private _sidenavWidth = this._store.selectSignal(SidenavSelectors.selectSidenavWidth);

    sidenavContentStyle = computed(() => (this.isPhoneScreen() ? '' : `margin-left: ${this._sidenavWidth()}px;`));
    isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, {
        initialValue: this._screenSizeService.isPhoneScreen,
    });
    isSidenavOpened = toSignal(this._store.select(SidenavSelectors.selectIsOpened));

    constructor() {
        this._observer = new ResizeObserver((entries) => {
            const sidenavWidth = entries[0].contentRect.width;
            this._store.dispatch(SidenavActions.setSidenavWidth({ sidenavWidth }));
        });
    }

    @ViewChild(MatSidenav) set matSidenav(matSidenav: MatSidenav) {
        if (this._currentElementBeingObserved) {
            this._observer.unobserve(this._currentElementBeingObserved);
        }
        this._currentElementBeingObserved = matSidenav._content.nativeElement;
        const sidenavWidth = matSidenav._content.nativeElement.getBoundingClientRect().width;
        this._store.dispatch(SidenavActions.setSidenavWidth({ sidenavWidth }));
        this._observer.observe(this._currentElementBeingObserved);
    }

    ngOnDestroy(): void {
        this._observer.disconnect();
    }
}
