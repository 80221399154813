import { omit } from 'lodash';

import { RestaurantKeywordDto } from '@malou-io/package-dto';
import { computePopularity, IBreakdown, KeywordPopularity, KeywordVolumeProvider } from '@malou-io/package-utils';

import { RankingTableDataRow } from '../../modules/keywords/keywords-list/keywords-list.component.interface';

/**
 * This class does not really represent a keyword but a link between a restaurant and
 * a keyword. The actual keyword object (shared between many restaurants) is referenced
 * by the field `keywordId`.
 */
export class Keyword {
    restaurantId: string;
    text: string;
    language: string;
    volume: number;
    selected: boolean;
    updatedAt: Date;
    createdAt: Date;
    bricks: IBreakdown[];
    needsCheck: boolean;
    isCustomerInput: boolean;
    volumeFromAdmin: number;
    lastRefresh: Date;
    isFetched: boolean;
    volumeHistory: KeywordVolumeHistory[];
    restaurantKeywordId: string;

    /** The ID of the actual keyword (keywords are shared between restaurants). */
    keywordId: string;

    apiLocationId: string;

    // This is only for temporary use before loading volumes
    isLoadingVolume = false;

    public constructor(init?: Partial<Keyword>) {
        // TODO improve this when deprecated keyword is removed
        Object.assign(this, omit(init, ['shouldRefetchVolume']));

        this.isFetched = this.volumeHistory?.length > 0 || (this.volumeFromAdmin ?? 0) > 0 || this.volume > 0;
    }

    static fromRestaurantKeywordDto(restaurantKeyword: RestaurantKeywordDto): Keyword {
        return new Keyword({
            restaurantKeywordId: restaurantKeyword.id,
            keywordId: restaurantKeyword.keyword.id,
            restaurantId: restaurantKeyword.restaurantId,
            text: restaurantKeyword.keyword.text,
            language: restaurantKeyword.keyword.language,
            volume: restaurantKeyword.keyword.volume,
            selected: restaurantKeyword.selected,
            updatedAt: restaurantKeyword.updatedAt ? new Date(restaurantKeyword.updatedAt) : undefined,
            createdAt: restaurantKeyword.createdAt ? new Date(restaurantKeyword.createdAt) : undefined,
            bricks: restaurantKeyword.keyword.bricks,
            isCustomerInput: restaurantKeyword.keyword.isCustomerInput,
            volumeFromAdmin: restaurantKeyword.keyword.volumeFromAdmin,
            lastRefresh: restaurantKeyword.lastRankingRefresh ? new Date(restaurantKeyword.lastRankingRefresh) : undefined,
            isFetched:
                restaurantKeyword.keyword.volumeHistory?.length > 0 ||
                (restaurantKeyword.keyword.volumeFromAdmin ?? 0) > 0 ||
                restaurantKeyword.keyword.volume > 0,
            volumeHistory: restaurantKeyword.keyword.volumeHistory.map((volumeHistory) => ({
                fetchDate: new Date(volumeHistory.fetchDate),
                volume: volumeHistory.volume,
                source: volumeHistory.source,
            })),
            apiLocationId: restaurantKeyword.keyword.apiLocationId,
        });
    }

    getNotNullVolume(): number {
        return this.volumeFromAdmin ?? this.volume;
    }

    shouldRefetchVolume(): boolean {
        return this.selected && this.volume === 0;
    }

    isVolumeFetched(): boolean {
        return this.volumeHistory?.length > 0 || this.volumeFromAdmin > 0 || this.volume > 0;
    }

    getPopularity(keywords: Keyword[]): KeywordPopularity {
        const volume = this.volumeFromAdmin ?? this.volume;

        const volumes = keywords
            .map((keyword) => keyword.volumeFromAdmin ?? keyword.volume)
            .filter((keywordVolume) => keywordVolume || keywordVolume === 0)
            .sort((a, b) => a - b);

        return computePopularity(volume, volumes);
    }

    toRankingTableDataRows(keywords: Keyword[]): RankingTableDataRow {
        return {
            keywordId: this.keywordId,
            restaurantKeywordId: this.restaurantKeywordId,
            language: this.language,
            keyword: this.text,
            volumeFromAPI: this.volume,
            volume: this.getNotNullVolume(),
            lastRefresh: this.lastRefresh,
            isWaiting: false,
            shouldRefetchVolume: this.shouldRefetchVolume(),
            popularity: this.getPopularity(keywords),
        };
    }
}

export interface KeywordVolumeHistory {
    fetchDate: Date;
    volume: number;
    source?: KeywordVolumeProvider;
}

export interface SimpleKeyword {
    keyword: string;
    volume: number | string;
    lang?: string;
}

export class KeywordFactory {
    static createTestKeyword(): Keyword {
        return new Keyword({
            text: 'halal',
            language: 'en',
            volume: 6000,
            restaurantId: 'some restaurant id',
            createdAt: new Date(),
            updatedAt: new Date(),
        });
    }
}
