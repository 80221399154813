import { Clipboard } from '@angular/cdk/clipboard';
import { NgTemplateOutlet } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isEqual, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';

import {
    DayYearMonthDto,
    InformationUpdateDataDto,
    MergedInformationUpdateDto,
    RegularHourDto,
    SpecialHourDto,
} from '@malou-io/package-dto';
import {
    configInformationUpdateSupportedKeys,
    Day,
    InformationUpdateSupportedPlatformKey,
    isNotNil,
    PartialRecord,
    PlatformDefinitions,
} from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { ToastService } from ':core/services/toast.service';
import { MergedInformationUpdateHelper } from ':modules/admin/platforms-management/updates/updates-comparison/merged-information-update.helper';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { Platform } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { BooleanTranslatePipe } from ':shared/pipes/boolean-translate.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

enum TableFieldName {
    FIELD_NAME = 'fieldName',
    PREVIOUS_VALUE = 'previousValue',
    CURRENT_VALUE = 'currentValue',
    ACTIONS = 'actions',
}

interface TableData {
    [TableFieldName.FIELD_NAME]: string;
    tooltip?: string;
    [TableFieldName.PREVIOUS_VALUE]: string;
    [TableFieldName.CURRENT_VALUE]: string;
}

export interface UpdatesComparisonModalComponentInput {
    restaurantId: string;
    mergedInformationUpdate: MergedInformationUpdateDto;
    platform?: Platform;
}

@Component({
    selector: 'app-updates-comparison-modal',
    templateUrl: './updates-comparison-modal.component.html',
    styleUrls: ['./updates-comparison-modal.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        MatTableModule,
        IncludesPipe,
        MatTooltipModule,
        EnumTranslatePipe,
        BooleanTranslatePipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    providers: [BooleanTranslatePipe],
})
export class UpdatesComparisonModalComponent {
    readonly SvgIcon = SvgIcon;
    dataSource = new MatTableDataSource<TableData>([]);

    UpdatesComparisonTableFieldName = TableFieldName;
    displayedColumns = Object.values(TableFieldName);

    translatedKeys = this._translateService.instant('admin.update.updates_comparison.keys');

    constructor(
        private readonly _clipboard: Clipboard,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _booleanTranslatePipe: BooleanTranslatePipe,
        private readonly _translateService: TranslateService,
        private readonly _platformsService: PlatformsService,
        private readonly _toastService: ToastService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: UpdatesComparisonModalComponentInput,
        private readonly _dialogRef: MatDialogRef<UpdatesComparisonModalComponent>
    ) {
        const keysToCheck = MergedInformationUpdateHelper.getInformationUpdateKeysToCheck(data.mergedInformationUpdate, {
            platformKey: data.mergedInformationUpdate.platformState.key,
            lockedFields: data.platform?.lockedFields,
        });
        const tableData: (TableData[] | null)[] = keysToCheck.map((key: keyof InformationUpdateDataDto) => {
            switch (key) {
                case 'categoryName':
                case 'shortDescription':
                case 'longDescription':
                case 'menuUrl':
                case 'name':
                case 'openingDate':
                case 'website':
                case 'isClosedTemporarily':
                    return this._mapPrimitiveToTableData(key, data.mergedInformationUpdate);
                case 'coverUrl':
                case 'logoUrl':
                    return this._mapPrimitiveToTableData(key, data.mergedInformationUpdate, 'link');
                case 'secondaryCategoriesNames':
                    return this._mapPrimitiveArrayToTableData(key, data.mergedInformationUpdate);
                case 'phone':
                    return this._mapPhoneToTableData(key, data.mergedInformationUpdate);
                case 'address':
                case 'latlng':
                    return this._mapStringRecordToTableData(key, data.mergedInformationUpdate);
                case 'regularHours':
                    return this._mapRegularHoursToTableData(data.mergedInformationUpdate);
                case 'specialHours':
                    return this._mapSpecialHoursToTableData(data.mergedInformationUpdate);
                case 'attributes':
                    return this._mapAttributesToTableData(data.mergedInformationUpdate);
                default:
                    return null;
            }
        });
        const filteredTableData = tableData.filter(isNotNil);
        const flattenedTableData = filteredTableData.reduce((acc, val) => acc.concat(val), []);
        this.dataSource.data = flattenedTableData;
    }

    close(): void {
        this._dialogRef.close();
    }

    copy(value: string): void {
        this._clipboard.copy(value || ' ');
        this._toastService.openSuccessToast(this._translateService.instant('common.copied_to_the_clipboard'));
    }

    openPlatformTab(): void {
        this._platformsService
            .getPlatformsForRestaurant(this.data.restaurantId)
            .pipe(map((apiResult) => apiResult.data))
            .subscribe((platforms) => {
                const platform = platforms.find((p) => p.key === this.data.mergedInformationUpdate.platformState.key);
                if (!platform) {
                    return;
                }

                const platformLink = PlatformDefinitions.getPlatformDefinition(platform.key)?.updateLink?.(platform.socialId);
                if (!platformLink) {
                    return;
                }
                window.open(platformLink, '_blank');
            });
    }

    _mapPrimitiveToTableData(key: string, mergedInformationUpdate: MergedInformationUpdateDto, tooltipKey?: string): TableData[] {
        const currentValue = mergedInformationUpdate.mergedData[key];
        const previousValue = mergedInformationUpdate.previousData[key];
        if (previousValue === currentValue) {
            return [];
        }
        return [
            {
                [TableFieldName.FIELD_NAME]: this.translatedKeys[key],
                tooltip: tooltipKey ? this.translatedKeys[tooltipKey] : undefined,
                [TableFieldName.PREVIOUS_VALUE]:
                    typeof previousValue === 'boolean' ? this._booleanTranslatePipe.transform(previousValue) : previousValue?.toString(),
                [TableFieldName.CURRENT_VALUE]:
                    typeof currentValue === 'boolean' ? this._booleanTranslatePipe.transform(currentValue) : currentValue?.toString(),
            },
        ];
    }

    _mapPrimitiveArrayToTableData(key: string, mergedInformationUpdate: MergedInformationUpdateDto): TableData[] {
        const currentValue = mergedInformationUpdate.mergedData[key];
        const previousValue = mergedInformationUpdate.previousData[key];
        if (isEqual(currentValue, previousValue)) {
            return [];
        }
        return [
            {
                [TableFieldName.FIELD_NAME]: this.translatedKeys[key],
                [TableFieldName.PREVIOUS_VALUE]: previousValue?.join(', '),
                [TableFieldName.CURRENT_VALUE]: currentValue?.join(', '),
            },
        ];
    }

    _mapPhoneToTableData(key: string, mergedInformationUpdate: MergedInformationUpdateDto): TableData[] {
        const currentValue = mergedInformationUpdate.mergedData.phone;
        const previousValue = mergedInformationUpdate.previousData.phone;
        if (previousValue?.prefix === currentValue?.prefix && previousValue?.digits === currentValue?.digits) {
            return [];
        }
        return [
            {
                [TableFieldName.FIELD_NAME]: this.translatedKeys[key],
                [TableFieldName.PREVIOUS_VALUE]: `${previousValue?.prefix ?? ''} ${previousValue?.digits ?? ''}`,
                [TableFieldName.CURRENT_VALUE]: `${currentValue?.prefix ?? ''} ${currentValue?.digits ?? ''}`,
            },
        ];
    }

    _mapStringRecordToTableData(key: string, mergedInformationUpdate: MergedInformationUpdateDto): TableData[] {
        const currentValue = mergedInformationUpdate.mergedData[key];
        const previousValue = mergedInformationUpdate.previousData[key];
        return Object.keys(currentValue)
            .map((objectKey) =>
                previousValue[objectKey] !== currentValue[objectKey]
                    ? {
                          [TableFieldName.FIELD_NAME]: this.translatedKeys[objectKey],
                          [TableFieldName.PREVIOUS_VALUE]: previousValue[objectKey],
                          [TableFieldName.CURRENT_VALUE]: currentValue[objectKey],
                      }
                    : null
            )
            .filter(isNotNil);
    }

    _mapRegularHoursToTableData(mergedInformationUpdate: MergedInformationUpdateDto): TableData[] {
        const previousValue = mergedInformationUpdate.previousData.regularHours;
        const currentValue = mergedInformationUpdate.mergedData.regularHours;

        const getRegularHoursByDay = (regularHours: RegularHourDto[]): PartialRecord<Day, RegularHourDto[]> =>
            regularHours.reduce((acc, val) => {
                acc[val.openDay] = acc[val.openDay] || [];
                if (val) {
                    acc[val.openDay].push(val);
                }
                return acc;
            }, {});
        const previousRegularHoursByDay: PartialRecord<Day, RegularHourDto[]> = getRegularHoursByDay(previousValue ?? []);
        const currentRegularHoursByDay: PartialRecord<Day, RegularHourDto[]> = getRegularHoursByDay(currentValue ?? []);

        const daysToCheck: Day[] = uniq([...Object.keys(previousRegularHoursByDay), ...Object.keys(currentRegularHoursByDay)]) as Day[];

        return daysToCheck
            .map((day) => {
                const previousRegularHours = previousRegularHoursByDay[day] ?? [];
                const currentRegularHours = currentRegularHoursByDay[day] ?? [];
                const isPreviousClosed = previousRegularHours.some((e) => e.isClosed);
                const isCurrentClosed = currentRegularHours.some((e) => e.isClosed);
                if (isPreviousClosed && isCurrentClosed) {
                    return null;
                }
                const previousTimes = previousRegularHours.map((e) => `${e.openTime} - ${e.closeTime}`).join(' / ');
                const currentTimes = currentRegularHours.map((e) => `${e.openTime} - ${e.closeTime}`).join(' / ');
                if (previousTimes === currentTimes) {
                    return null;
                }
                return {
                    [TableFieldName.FIELD_NAME]: this._enumTranslatePipe.transform(day, 'days'),
                    [TableFieldName.PREVIOUS_VALUE]: isPreviousClosed ? this._translateService.instant('common.closed') : previousTimes,
                    [TableFieldName.CURRENT_VALUE]: isCurrentClosed ? this._translateService.instant('common.closed') : currentTimes,
                };
            })
            .filter(isNotNil);
    }

    _mapSpecialHoursToTableData(mergedInformationUpdate: MergedInformationUpdateDto): TableData[] {
        const previousValue = mergedInformationUpdate.previousData.specialHours;
        const currentValue = mergedInformationUpdate.mergedData.specialHours;

        const getSpecialHoursByDate = (specialHours: SpecialHourDto[]): PartialRecord<string, SpecialHourDto[]> =>
            specialHours.reduce((acc, val) => {
                const dateFormatted = this._dayYearMonthDtoToString(val.startDate);
                acc[dateFormatted] = acc[dateFormatted] || [];
                if (val) {
                    acc[dateFormatted].push(val);
                }
                return acc;
            }, {});
        const previousRegularHoursByDate: PartialRecord<Day, SpecialHourDto[]> = getSpecialHoursByDate(previousValue ?? []);
        const currentRegularHoursByDate: PartialRecord<Day, SpecialHourDto[]> = getSpecialHoursByDate(currentValue ?? []);

        const datesToCheck: string[] = uniq([...Object.keys(previousRegularHoursByDate), ...Object.keys(currentRegularHoursByDate)]);

        return datesToCheck
            .filter((dateFormatted) =>
                this._areSpecialHoursValid(
                    previousRegularHoursByDate[dateFormatted] ?? [],
                    currentRegularHoursByDate[dateFormatted] ?? [],
                    mergedInformationUpdate.platformState.key
                )
            )
            .map((dateFormatted) => {
                const previousSpecialHours = previousRegularHoursByDate[dateFormatted] ?? [];
                const currentSpecialHours = currentRegularHoursByDate[dateFormatted] ?? [];
                const isPreviousClosed = previousSpecialHours.some((e) => e.isClosed);
                const isCurrentClosed = currentSpecialHours.some((e) => e.isClosed);
                if (isPreviousClosed && isCurrentClosed) {
                    return null;
                }
                const previousTimes = previousSpecialHours.map((e) => `${e.openTime} - ${e.closeTime}`).join(' / ');
                const currentTimes = currentSpecialHours.map((e) => `${e.openTime} - ${e.closeTime}`).join(' / ');
                if (previousTimes === currentTimes) {
                    return null;
                }
                return {
                    [TableFieldName.FIELD_NAME]: dateFormatted,
                    [TableFieldName.PREVIOUS_VALUE]: isPreviousClosed ? this._translateService.instant('common.closed') : previousTimes,
                    [TableFieldName.CURRENT_VALUE]: isCurrentClosed ? this._translateService.instant('common.closed') : currentTimes,
                };
            })
            .filter(isNotNil);
    }

    _areSpecialHoursValid(
        previousSpecialHours: SpecialHourDto[],
        currentSpecialHours: SpecialHourDto[],
        platformKey: InformationUpdateSupportedPlatformKey
    ): boolean {
        switch (platformKey) {
            case configInformationUpdateSupportedKeys.tripadvisor.name:
                return [...previousSpecialHours, ...currentSpecialHours].some((e) => e.isClosed);
            default:
                return true;
        }
    }

    _mapAttributesToTableData(mergedInformationUpdate: MergedInformationUpdateDto): TableData[] {
        const previousValue = mergedInformationUpdate.previousData.attributes;
        const currentValue = mergedInformationUpdate.mergedData.attributes;

        const attributeNamesToCheck: string[] = uniq([
            ...(previousValue ?? []).map((e) => e.name),
            ...(currentValue ?? []).map((e) => e.name),
        ]);

        return attributeNamesToCheck
            .map((attributeName) => {
                const previousAttribute = previousValue?.find((e) => e.name === attributeName) ?? undefined;
                const currentAttribute = currentValue?.find((e) => e.name === attributeName) ?? undefined;
                if (previousAttribute?.value === currentAttribute?.value) {
                    return null;
                }
                return {
                    [TableFieldName.FIELD_NAME]: `${this.translatedKeys.attribute} ${attributeName}`,
                    [TableFieldName.PREVIOUS_VALUE]: previousAttribute?.value
                        ? this._enumTranslatePipe.transform(previousAttribute.value, 'information_update_attribute_value')
                        : '',
                    [TableFieldName.CURRENT_VALUE]: currentAttribute?.value
                        ? this._enumTranslatePipe.transform(currentAttribute.value, 'information_update_attribute_value')
                        : '',
                };
            })
            .filter(isNotNil);
    }

    _dayYearMonthDtoToString(dayYearMonthDto: DayYearMonthDto): string {
        const date = new Date(dayYearMonthDto.year, dayYearMonthDto.month, dayYearMonthDto.day);
        return DateTime.fromJSDate(date).toFormat('dd/MM/yyyy');
    }
}
