import { EntityConstructor } from '@malou-io/package-utils';

import { Notification } from './notification.model';

export interface ReviewNotificationData {
    reviewIds: string[];
    restaurantIds: string[];
}

type ReviewNotificationProps = EntityConstructor<ReviewNotification>;

export class ReviewNotification extends Notification {
    data: ReviewNotificationData;

    constructor(props: ReviewNotificationProps) {
        super(props);
        this.data = props.data;
    }

    copyWith(props: Partial<ReviewNotificationProps>): ReviewNotification {
        return new ReviewNotification({ ...this, ...props });
    }
}
