import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';

import { ApplicationLanguage, PostSource } from '@malou-io/package-utils';

import { User } from ':modules/user/user';
import { Hashtag, Media, SocialPost } from ':shared/models';

export enum PostDateStatus {
    NOW = 'now',
    LATER = 'later',
    DRAFT = 'draft',
}

export interface NewSocialPostInitialData {
    postId: string;
    post: SocialPost;
    postDate: Date;
    isDisabled: boolean;
    allPostIds?: string[];
    restaurantManagers: User[];
}

export interface NewSocialPostInitialData {
    postId: string;
    post: SocialPost;
    postDate: Date;
    isDisabled: boolean;
    allPostIds?: string[];
    restaurantManagers: User[];
}

export interface PostForm {
    post: FormGroup<{
        title: FormControl<string>;
        callToAction: FormGroup<{
            actionType: FormControl<string>;
            url: FormControl<string>;
        }>;
        text: FormControl<string>;
        language: FormControl<ApplicationLanguage>;
        attachments: FormControl<Media[]>;
        attachmentsName: FormControl<string>;
        thumbnail: FormControl<string>;
        thumbnailOffsetTimeInMs: FormControl<number>;
        location: FormControl<string>;
        plannedPublicationDate: FormControl<Date>;
        category: FormControl<PostSource>;
        userTags: FormControl<any>;
        userTagsList: FormControl<any[]>;
        hashtags: FormControl<any>;
    }>;
    meta: FormGroup<{
        date: FormGroup<{
            postDateStatus: FormControl<PostDateStatus>;
            postDate: FormControl<Date>;
            postTime: FormControl<string>;
        }>;
        platforms: FormControl<any[]>;
    }>;
}

export const initialPostForm: PostForm = {
    post: new UntypedFormGroup({
        title: new FormControl(''),
        callToAction: new UntypedFormGroup({
            actionType: new FormControl(''),
            url: new FormControl(''),
        }),
        text: new FormControl(''),
        language: new FormControl(ApplicationLanguage.FR),
        attachments: new FormControl([]),
        attachmentsName: new FormControl(''),
        thumbnail: new FormControl(''),
        thumbnailOffsetTimeInMs: new FormControl(0),
        location: new FormControl(''),
        plannedPublicationDate: new FormControl(new Date()),
        category: new FormControl(PostSource.SOCIAL),
        userTags: new FormControl(''),
        userTagsList: new FormControl([]),
        // TODO: remove after refactoring
        hashtags: new UntypedFormGroup({ suggested: new FormControl<Hashtag[]>([]), selected: new FormControl<Hashtag[]>([]) }),
    }),
    meta: new UntypedFormGroup({
        date: new UntypedFormGroup({
            postDateStatus: new FormControl(PostDateStatus.LATER),
            postDate: new FormControl(new Date()),
            postTime: new FormControl(''),
        }),
        platforms: new FormControl([]),
    }),
};

export enum AiButton {
    GENERATE = 'GENERATE',
    RETRY = 'RETRY',
}
