import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { filter, forkJoin, Observable, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ApplicationLanguage,
    CustomerNaming,
    getLanguageFromCountryCode,
    mapLanguageStringToApplicationLanguage,
    ReplyTone,
} from '@malou-io/package-utils';

import { KeywordsService } from ':core/services/keywords.service';
import { RestaurantAiSettingsService } from ':core/services/restaurant-ai-settings.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import {
    EditRestaurantAiSettingsModalComponent,
    EditRestaurantAiSettingsModalInputData,
} from ':modules/automations/review-reply-automations/restaurant-ai-settings/edit-restaurant-ai-settings-modal/edit-restaurant-ai-settings-modal.component';
import {
    RestaurantsSelectionComponent,
    RestaurantsSelectionData,
} from ':shared/components/restaurants-selection/restaurants-selection.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { StepperModalComponent } from ':shared/components/stepper-modal/stepper-modal.component';
import { Step } from ':shared/interfaces/step.interface';
import { Keyword, Restaurant } from ':shared/models';
import { RestaurantAiSettings } from ':shared/models/restaurant-ai-settings';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import {
    PersonalizeRestaurantAiSettingsDuplicationComponent,
    PersonalizeRestaurantAiSettingsDuplicationData,
} from './personalize-restaurant-ai-settings-duplication/personalize-restaurant-ai-settings-duplication.component';

@Component({
    selector: 'app-restaurant-ai-settings',
    templateUrl: './restaurant-ai-settings.component.html',
    styleUrls: ['./restaurant-ai-settings.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        SkeletonComponent,
        EnumTranslatePipe,
        FlagPathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantAiSettingsComponent implements OnInit {
    private readonly _formBuilder = inject(FormBuilder);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _keywordsService = inject(KeywordsService);
    private readonly _restaurantAiSettingsService = inject(RestaurantAiSettingsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly SvgIcon = SvgIcon;
    readonly restaurantAiSettings = signal<RestaurantAiSettings | undefined>(undefined);

    readonly selectedRestaurantKeywords = signal<Keyword[]>([]);

    readonly hasRestaurantAiSettings = signal(false);
    readonly showDetails = signal(false);
    readonly isLoading = signal(false);

    readonly DEFAULT_DISPLAY = '-';

    readonly restaurant$ = this._restaurantsService.restaurantSelected$;

    private readonly _currentLang = LocalStorage.getLang();

    ngOnInit(): void {
        this._fetchRestaurantAiSettings();
    }

    toggleShowDetails(): void {
        this.showDetails.update((currentShowDetails) => !currentShowDetails);
    }

    openRestaurantAiSettingsModal(): void {
        const restaurantAiSettings = this.restaurantAiSettings();
        if (!restaurantAiSettings) {
            return;
        }

        const data: EditRestaurantAiSettingsModalInputData = { restaurantAiSettings, selectedKeywords: this.selectedRestaurantKeywords() };

        this._customDialogService
            .open<EditRestaurantAiSettingsModalComponent, EditRestaurantAiSettingsModalInputData>(EditRestaurantAiSettingsModalComponent, {
                height: 'unset',
                maxHeight: '90vh',
                width: '950px',
                maxWidth: '80vw',
                data,
            })
            .afterClosed()
            .subscribe((newRestaurantAiSettings: RestaurantAiSettings) => {
                if (newRestaurantAiSettings) {
                    this.restaurantAiSettings.set(newRestaurantAiSettings);
                    this.hasRestaurantAiSettings.set(true);
                }
            });
    }

    openDuplicateModal(): void {
        const restaurantId = this.restaurantAiSettings()?.restaurantId;
        if (!restaurantId) {
            console.error('No restaurant id found for AI settings duplication.');
            return;
        }

        const steps = this._getStepsForDuplication(restaurantId);

        const initialData: RestaurantsSelectionData = {
            skipOwnRestaurant: true,
            withoutBrandBusiness: false,
        };

        this._customDialogService.open(StepperModalComponent, {
            width: '900px',
            height: 'unset',
            data: {
                stepperModalData: { validateButtonId: 'tracking_restaurant_ai_settings_duplicate_validation_button' },
                steps,
                initialData,
                title: this._translateService.instant('restaurant_ai_settings.modals.duplicate.title'),
                onSuccess: (restaurantAiSettingsList: RestaurantAiSettings[]) => {
                    this._onDuplicationSuccess(restaurantAiSettingsList);
                },
                onError: (error: unknown) => {
                    this._onDuplicationError(error);
                },
            },
        });
    }

    private _getStepsForDuplication(restaurantId: string): Step[] {
        return [
            {
                component: RestaurantsSelectionComponent,
                subtitle: this._translateService.instant('restaurant_ai_settings.modals.duplicate.restaurant_selection.subtitle'),
                primaryButtonText: this._translateService.instant('common.next'),
                nextFunction$: (data: RestaurantsSelectionData): Observable<PersonalizeRestaurantAiSettingsDuplicationData[]> =>
                    this._populateSelectedRestaurantsWithDuplicationForm(data),
            },
            {
                component: PersonalizeRestaurantAiSettingsDuplicationComponent,
                subtitle: this._translateService.instant('restaurant_ai_settings.modals.duplicate.personalize_duplication.subtitle'),
                primaryButtonText: this._translateService.instant('common.duplicate'),
                nextFunction$: (data: PersonalizeRestaurantAiSettingsDuplicationData[]): Observable<RestaurantAiSettings[]> =>
                    this._duplicatePersonalizedRestaurantAiSettings(restaurantId, data),
            },
        ];
    }

    private _populateSelectedRestaurantsWithDuplicationForm(
        restaurantSelectionData: RestaurantsSelectionData
    ): Observable<PersonalizeRestaurantAiSettingsDuplicationData[]> {
        if (!restaurantSelectionData.selectedRestaurants) {
            return of([]);
        }
        const restaurantIds = restaurantSelectionData.selectedRestaurants.map((e) => e._id);
        return this._restaurantAiSettingsService.getRestaurantAiSettingsList(restaurantIds).pipe(
            map(
                (aiSettings) =>
                    restaurantSelectionData.selectedRestaurants?.map((restaurant) => {
                        const aiSetting = aiSettings.find((e) => e.restaurantId === restaurant._id);
                        const form = this._formBuilder.group({
                            restaurantName: [aiSetting?.restaurantName ?? restaurant.name, Validators.required],
                            signature: [aiSetting?.signatures?.[0] ?? ''],
                        });
                        return {
                            restaurant,
                            form,
                        };
                    }) ?? []
            )
        );
    }

    private _duplicatePersonalizedRestaurantAiSettings(
        restaurantId: string,
        restaurantAiSettingsDuplicationDataPerRestaurant: PersonalizeRestaurantAiSettingsDuplicationData[]
    ): Observable<RestaurantAiSettings[]> {
        const duplicationData = restaurantAiSettingsDuplicationDataPerRestaurant.map((data) => ({
            restaurantId: data.restaurant.id,
            restaurantName: data.form.value.restaurantName ?? data.restaurant.name,
            signature: data.form.value.signature ?? '',
        }));
        return this._restaurantAiSettingsService.duplicateRestaurantAiSettings(restaurantId, duplicationData);
    }

    private _onDuplicationSuccess = (restaurantAiSettingsList: RestaurantAiSettings[]): void => {
        if (restaurantAiSettingsList.length) {
            this._toastService.openSuccessToast(this._translateService.instant('restaurant_ai_settings.modals.duplicate.success'));
        }
        this._customDialogService.closeAll();
    };

    private _onDuplicationError = (error: any): void => {
        console.warn('err :>>', error);

        if (error['status'] === 403 && !!error['error']?.casl) {
            return;
        } else {
            this._toastService.openErrorToast(this._translateService.instant('restaurant_ai_settings.modals.duplicate.error'));
        }
    };

    private _fetchRestaurantAiSettings(): void {
        this.restaurant$
            .pipe(
                filter(Boolean),
                tap(() => this.isLoading.set(true)),
                switchMap((restaurant) =>
                    forkJoin([
                        of(restaurant),
                        this._restaurantAiSettingsService.getRestaurantAiSettings(restaurant.id),
                        this._keywordsService.getKeywordsByRestaurantId(restaurant.id),
                    ])
                ),
                tap(([_restaurant, restaurantAiSettings, keywordsResult]) => {
                    this.hasRestaurantAiSettings.set(!!restaurantAiSettings);
                    const selectedKeywords = keywordsResult.data.filter((keyword) => keyword.selected);
                    this.selectedRestaurantKeywords.set(selectedKeywords);
                }),
                switchMap(([restaurant, restaurantAiSettings]) =>
                    restaurantAiSettings
                        ? of(
                              new RestaurantAiSettings({
                                  ...restaurantAiSettings,
                                  signatures: restaurantAiSettings.signatures?.length ? restaurantAiSettings.signatures : [''],
                              })
                          )
                        : this._getDefaultRestaurantAiSettings(restaurant)
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (restaurantAiSettings) => {
                    this.restaurantAiSettings.set(restaurantAiSettings);
                    this.isLoading.set(false);
                },
                error: (err) => {
                    console.error('err >>>', err);
                    this.isLoading.set(false);
                },
            });
    }

    private _getDefaultRestaurantAiSettings(restaurant: Restaurant): Observable<RestaurantAiSettings> {
        const defaultLanguageResponse = this._extractDefaultLanguageResponseFromKeywords(this.selectedRestaurantKeywords());
        return of(
            new RestaurantAiSettings({
                restaurantId: restaurant.id,
                restaurantName: restaurant.name,
                replyTone: ReplyTone.DOES_NOT_MATTER,
                defaultLanguageResponse,
                customerNaming: CustomerNaming.FIRSTNAME,
                restaurantKeywordIds: this.selectedRestaurantKeywords().map((keyword) => keyword.restaurantKeywordId),
                forbiddenWords: [],
                catchphrase: '',
                signatures: [''],
                shouldTranslateCatchphrase: true,
                shouldTranslateSignature: true,
            })
        );
    }

    private _extractDefaultLanguageResponseFromKeywords(keywords: Keyword[]): ApplicationLanguage {
        if (keywords.length === 0) {
            const restaurantCountry = this.restaurant$.value?.address?.country;
            return restaurantCountry
                ? mapLanguageStringToApplicationLanguage(getLanguageFromCountryCode(restaurantCountry))
                : this._currentLang;
        }
        const keywordsByLanguage = groupBy(keywords, 'language');
        const languageWithMostKeywords = Object.keys(keywordsByLanguage).reduce((prev, current) =>
            keywordsByLanguage[current].length > keywordsByLanguage[prev].length ? current : prev
        );

        return mapLanguageStringToApplicationLanguage(languageWithMostKeywords);
    }
}
