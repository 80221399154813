<div class="relative max-w-[400px]" (click)="notificationCenterContext.closeNotificationToast()">
    <div class="absolute right-1 top-1">
        <mat-icon class="!h-3 !w-3" color="primary" svgIcon="cross" (click)="close()"></mat-icon>
    </div>
    @switch (notification.type) {
        @case (NotificationType.REVIEW_REPLY_REMINDER) {
            <app-negative-review-reminder-notification-item
                [initialNotification]="notification"></app-negative-review-reminder-notification-item>
        }
        @case (NotificationType.REVIEW) {
            <app-review-notification-item [initialNotification]="notification"></app-review-notification-item>
        }
        @case (NotificationType.SPECIAL_HOUR) {
            <app-special-hour-notification-item [initialNotification]="notification"></app-special-hour-notification-item>
        }
        @case (NotificationType.POST_SUGGESTION) {
            <app-post-suggestion-notification-item [initialNotification]="notification"></app-post-suggestion-notification-item>
        }
        @case (NotificationType.POST_ERROR) {
            <app-post-error-notification-item [initialNotification]="notification"></app-post-error-notification-item>
        }
        @case (NotificationType.COMMENT) {
            <app-comment-notification-item [initialNotification]="notification"></app-comment-notification-item>
        }
        @case (NotificationType.MENTION) {
            <app-mention-notification-item [initialNotification]="notification"></app-mention-notification-item>
        }
        @case (NotificationType.NEW_MESSAGE) {
            <app-message-notification-item [initialNotification]="notification"></app-message-notification-item>
        }
        @case (NotificationType.ROI_ACTIVATED) {
            <app-roi-activated-notification-item [initialNotification]="notification"></app-roi-activated-notification-item>
        }
        @default {
            <div>Not implemented yet</div>
        }
    }
</div>
