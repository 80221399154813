import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, Injector, input, OnInit, signal, Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { of, switchMap } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';

import { isNotNil, PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ToastService } from ':core/services/toast.service';
import { PlatformsConnectionAccessInformationComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-access-information/platforms-connection-access-information.component';
import { PlatformsConnectionAccountSelectorComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-account-selector/platforms-connection-account-selector.component';
import {
    ComputedValue,
    PlatformsConnectionBusinessSelectorComponent,
} from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-business-selector/platforms-connection-business-selector.component';
import {
    BaseStepComponent,
    GoToStepParam,
} from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { GetCredentialsService } from ':modules/platforms/platforms-connection-modals/shared/services/get-credentials.service';
import { GetOauthUrlService } from ':modules/platforms/platforms-connection-modals/shared/services/get-oauth-url.service';
import { SearchPlatformRestaurantsService } from ':modules/platforms/platforms-connection-modals/shared/services/search-platform-restaurants.service';
import { UpdateCredentialsBasedPlatformService } from ':modules/platforms/platforms-connection-modals/shared/services/update-credentials-based-platform.service';
import { selectCurrentPlatform } from ':modules/platforms/store/platforms.reducer';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { Credential, Platform, PlatformSearch } from ':shared/models';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

type AuthorizedPlatformKeys = PlatformKey.GMB | PlatformKey.FACEBOOK | PlatformKey.INSTAGRAM | PlatformKey.ZENCHEF | PlatformKey.DELIVEROO;

export interface SelectCredentialAndBusinessStepModalResult {
    hasDataChanged?: boolean;
}

@Component({
    selector: 'app-select-credential-and-business-step',
    templateUrl: './select-credential-and-business-step.component.html',
    styleUrls: ['./select-credential-and-business-step.component.scss'],
    standalone: true,
    imports: [
        ModalStructureComponent,
        TranslateModule,
        PlatformsConnectionAccountSelectorComponent,
        PlatformsConnectionBusinessSelectorComponent,
        MalouSpinnerComponent,
        NgTemplateOutlet,
        PlatformsConnectionAccessInformationComponent,
        IllustrationPathResolverPipe,
        StatisticsHttpErrorPipe,
        PlatformLogoPathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCredentialAndBusinessStepComponent
    extends BaseStepComponent<any, SelectCredentialAndBusinessStepModalResult>
    implements OnInit
{
    private readonly _getCredentialsService = inject(GetCredentialsService);
    private readonly _searchPlatformRestaurantsService = inject(SearchPlatformRestaurantsService);
    private readonly _updateCredentialsBasedPlatformService = inject(UpdateCredentialsBasedPlatformService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _injector = inject(Injector);
    private readonly _getOauthUrlService = inject(GetOauthUrlService);
    private readonly _store = inject(Store);

    titleTranslationKey = input.required<string>();
    goToStepParam = input.required<GoToStepParam>();
    platformKey = input.required<AuthorizedPlatformKeys>();
    showNewOauthButton = input.required<boolean>();
    selectedAuthId = input<string | undefined>(undefined);
    showPlatformIconInModalTitle = input.required<boolean>();

    readonly ButtonStyle = ButtonStyle;

    credentials: Signal<Credential[] | null>;
    readonly isCredentialsSearchInError = signal<boolean>(false);
    readonly selectedCredential = signal<Credential | null>(null);

    platformSearchResults: Signal<PlatformSearch[]>;
    readonly isPlatformSearchInError = signal<boolean>(false);
    readonly isPlatformSearchLoading = signal<boolean>(true);
    readonly selectedPlatformSearch = signal<PlatformSearch | null>(null);

    currentPlatform: Signal<Platform | undefined>;

    // Why taking the 'access' obj from first element in the list ?
    // Context : Only Google/FB/Insta return the attribute 'access' (and deliveroo, but it's hardcoded with { isValid: true })
    // For Google it's the same 'access' object on all PlatformSearch in the list
    // it contains an error if any of the PlatformSearch in the list lack the required credentials
    // For Insta/Fb the 'access' object can be different by PlatformSearch,
    // but in most of the cases, the 'access' object will be the same on all page
    // Can be refactored to show 'access' status for each page individually
    readonly platformSearchAccess = computed(() => this.platformSearchResults()[0]);

    readonly hideResults = computed(() => this.isPlatformSearchLoading() || this.isPlatformSearchInError());

    readonly isUpdatingPlatform = signal<boolean>(false);

    ngOnInit(): void {
        this._initCurrentPlatform();
        this._initCredentials();
        this._initSelectedCredential();
        this._initPlatformSearch();
        this._initSelectedPlatformSearch();
    }

    private _initCurrentPlatform(): void {
        const currentPlatform$ = this._store.select(selectCurrentPlatform({ platformKey: this.platformKey() })).pipe(filter(isNotNil));
        this.currentPlatform = toSignal(currentPlatform$, { injector: this._injector });
    }

    private _initCredentials(): void {
        const credentials$ = this._getCredentialsService.execute(this.platformKey()).pipe(
            catchError((err) => {
                console.error(err);
                this.isCredentialsSearchInError.set(true);
                return of(null);
            })
        );
        this.credentials = toSignal(credentials$, { initialValue: null, injector: this._injector });
    }

    private _initSelectedCredential(): void {
        effect(
            () => {
                const credentials = this.credentials();

                const selectedAuthId = this.selectedAuthId();
                if (selectedAuthId) {
                    const foundCredential = credentials?.find((credential) => credential.authId === selectedAuthId);
                    this.selectedCredential.set(foundCredential ?? null);
                    return;
                }

                if (this.selectedCredential() === null) {
                    const currentCredential = this.currentPlatform()?.credentials?.[0] as Credential | undefined;
                    if (currentCredential) {
                        const foundCredential = credentials?.find((credential) => credential._id === currentCredential._id);
                        this.selectedCredential.set(foundCredential ?? credentials?.[0] ?? null);
                    } else {
                        this.selectedCredential.set(credentials?.[0] ?? null);
                    }
                }
            },
            { allowSignalWrites: true, injector: this._injector }
        );
    }

    private _initPlatformSearch(): void {
        const selectedCredential$ = toObservable(this.selectedCredential, { injector: this._injector });

        const platformSearchResults$ = selectedCredential$.pipe(
            filter(isNotNil),
            tap(() => {
                this.isPlatformSearchLoading.set(true);
                this.isPlatformSearchInError.set(false);
            }),
            switchMap((credential) =>
                this._searchPlatformRestaurantsService.execute(this.platformKey(), credential._id).pipe(
                    catchError((err) => {
                        console.error(err);
                        this.isPlatformSearchInError.set(true);
                        return of([]);
                    })
                )
            ),
            tap(() => this.isPlatformSearchLoading.set(false))
        );
        this.platformSearchResults = toSignal(platformSearchResults$, { initialValue: [], injector: this._injector });
    }

    private _initSelectedPlatformSearch(): void {
        effect(
            () => {
                const platformSearchResults = this.platformSearchResults();
                const currentSocialId = this.currentPlatform()?.socialId;
                if (currentSocialId && this.selectedPlatformSearch() === null) {
                    const foundPlatformSearch = platformSearchResults.find((platformSearch) => platformSearch.socialId === currentSocialId);
                    this.selectedPlatformSearch.set(foundPlatformSearch ?? null);
                }
            },
            { allowSignalWrites: true, injector: this._injector }
        );
    }

    credentialsDisplayWith(credential: Credential): string {
        return credential.name ?? credential.authId;
    }

    platformSearchDisplayWith(value: PlatformSearch): ComputedValue {
        return {
            title: value.name ?? value.username ?? '',
            subtitle: value.formattedAddress,
            link: value.socialUrl,
        };
    }

    platformSearchCompareWith(value: PlatformSearch): string {
        return value.socialId;
    }

    onNewOauthButtonClick(): void {
        const platformKey = this.platformKey();
        if (this._gotOauthBehavior(platformKey)) {
            this._getOauthUrlService.execute(platformKey).subscribe((url) => {
                window.location.href = url;
            });
        }
    }

    private _gotOauthBehavior(platformKey: PlatformKey): platformKey is PlatformKey.GMB | PlatformKey.FACEBOOK | PlatformKey.INSTAGRAM {
        return [PlatformKey.GMB, PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM].includes(platformKey);
    }

    onValidate(): void {
        this.isUpdatingPlatform.set(true);
        const credential = this.selectedCredential();
        const platformSearch = this.selectedPlatformSearch();
        if (!credential || !platformSearch) {
            this._toastService.openErrorToast(
                this._translateService.instant('platforms.connection_new.shared.steps.select_credential_and_business.connection_error')
            );
            this.isUpdatingPlatform.set(false);
            return;
        }
        this._updateCredentialsBasedPlatformService
            .execute({ platformKey: this.platformKey(), platformSearch, credentialId: credential._id })
            .subscribe({
                next: () => {
                    this.isUpdatingPlatform.set(false);
                    this._toastService.openSuccessToast(
                        this._translateService.instant(
                            'platforms.connection_new.shared.steps.select_credential_and_business.connection_success'
                        )
                    );
                    this.close.emit({ hasDataChanged: true });
                },
                error: (err) => {
                    console.error(err);
                    this.isUpdatingPlatform.set(false);
                    this._toastService.openErrorToast(
                        this._translateService.instant(
                            'platforms.connection_new.shared.steps.select_credential_and_business.connection_error'
                        )
                    );
                    if (err?.message) {
                        this._toastService.openErrorToast(
                            err?.message // Need to map properly the error message
                        );
                    }
                },
            });
    }

    onCredentialChange(value: Credential): void {
        if (value) {
            this.selectedCredential.set(value);
        }
    }

    onBusinessChange(value: PlatformSearch): void {
        this.selectedPlatformSearch.set(value);
    }
}
