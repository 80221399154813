/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { AggregatedStatisticsFiltersDto, ReviewsFiltersDto, SuccessResponse } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { UserFilters } from ':shared/models/user-filters.model';

export interface RoiInsightsCreationState {
    wasLastResultSeen: boolean;
    creationStartDate: Date | null;
    creationEstimatedTime: number;
}

@Injectable({
    providedIn: 'root',
})
export class UserFiltersService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/user-filters`;

    private readonly _http = inject(HttpClient);

    getUserFilters(userId: string): Observable<UserFilters> {
        return this._http.get<ApiResultV2<UserFilters>>(`${this.API_BASE_URL}/${userId}`).pipe(map((res) => new UserFilters(res.data)));
    }

    updateAggregatedStatisticsFilters(userId: string, filters: AggregatedStatisticsFiltersDto): Observable<SuccessResponse> {
        return this._http
            .put<ApiResultV2<SuccessResponse>>(`${this.API_BASE_URL}/aggregated-statistics/${userId}`, filters)
            .pipe(map((res) => res.data));
    }

    updateAggregatedReviewsFilters(
        userId: string,
        data: {
            filters: ReviewsFiltersDto;
            restaurantIds: string[];
        }
    ): Observable<SuccessResponse> {
        return this._http
            .put<ApiResultV2<SuccessResponse>>(`${this.API_BASE_URL}/aggregated-reviews/${userId}`, data)
            .pipe(map((res) => res.data));
    }
}
