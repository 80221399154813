import { omit } from 'lodash';
import { Overwrite } from 'utility-types';

import { UpdateBookmarkedPostBody, UpdateRestaurantBodyDto } from '@malou-io/package-dto';
import { PostType } from '@malou-io/package-utils';

import {
    HoursType,
    IOtherPeriod,
    IRestaurant,
    Media,
    OtherPeriod,
    PostWithInsightsAndHoveredPosts,
    Restaurant,
    TimePeriod,
} from ':shared/models';

export interface IOtherPeriodGetApiResponse {
    hoursTypeId: string;
    periods: TimePeriod[];
}
export type IRestaurantAPI = Overwrite<IRestaurant, { otherHours: IOtherPeriodGetApiResponse[] }>;

export namespace RestaurantsMapper {
    export function mapToRestaurantFromRestaurantApiResponse(restaurant: IRestaurantAPI): Restaurant {
        const otherHours = restaurant.otherHours?.map((otherPeriod: IOtherPeriodGetApiResponse) => {
            const hoursType = restaurant.availableHoursTypes.find((hourType: HoursType) => hourType._id === otherPeriod.hoursTypeId);
            return new OtherPeriod({
                hoursType,
                periods: otherPeriod.periods,
            });
        });
        return new Restaurant({
            ...restaurant,
            ...(otherHours && { otherHours }),
        });
    }

    export function mapToMalouRestaurantPayload(restaurant: Partial<IRestaurant>): UpdateRestaurantBodyDto {
        const payload = {
            ...omit(restaurant, ['otherHours', 'category', 'categoryList', 'logo', 'cover', 'bookmarkedPosts']),
            ...(restaurant.otherHours && { otherHours: mapRestaurantOtherHours(restaurant.otherHours) }),
            ...(restaurant.category && { category: restaurant.category._id }),
            ...(restaurant.categoryList && { categoryList: restaurant.categoryList.map((category) => category._id) }),
            ...(restaurant.logo && { logo: mapRestaurantMedia(restaurant.logo) }),
            ...(restaurant.cover && { cover: mapRestaurantMedia(restaurant.cover) }),
            ...(restaurant.bookmarkedPosts && { bookmarkedPosts: mapRestaurantBookmarkedPosts(restaurant.bookmarkedPosts) }),
            ...(!('logoChanged' in restaurant) && restaurant.logo && { logoChanged: true }),
            ...(!('coverChanged' in restaurant) && restaurant.cover && { coverChanged: true }),
        };
        return payload;
    }

    function mapRestaurantOtherHours(otherHours: IOtherPeriod[]): {
        hoursTypeId: string;
        periods: TimePeriod[];
    }[] {
        return otherHours.map((otherPeriod: IOtherPeriod) => ({
            hoursTypeId: otherPeriod.hoursType._id,
            periods: otherPeriod.periods,
        }));
    }

    function mapRestaurantMedia(media: Media | string): string {
        return media instanceof Media ? media.id : media;
    }

    function mapRestaurantBookmarkedPosts(posts: (PostWithInsightsAndHoveredPosts & { type?: PostType })[]): UpdateBookmarkedPostBody[] {
        return posts.map((post) => ({
            ...post,
            hoveredPosts: post.hoveredPosts?.filter((hoveredPost) => hoveredPost.url?.length),
            type: post.type ?? post.postType,
        }));
    }
}
