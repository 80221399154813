<div class="mat-expansion-panel-body flex flex-col">
    <div class="flex h-12.5 items-center bg-malou-color-background-dark pl-10">
        <p class="malou-text-12--bold malou-color-text-1">{{ 'admin.access.access_to_verify' | translate }}</p>
    </div>

    <ng-container [ngTemplateOutlet]="accessList.length > 0 ? accessTableTemplate : noAccessTemplate"></ng-container>
</div>

<ng-template #accessTableTemplate>
    <mat-table class="malou-mat-table" matSort [dataSource]="dataSource" #table="matTable">
        <ng-container matColumnDef="platformKey">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ isBusinessView ? ('admin.platform' | translate) : ('admin.business' | translate) }}
            </mat-header-cell>
            <mat-cell *matCellDef="let access; table: table">
                @if (isBusinessView) {
                    <div class="flex items-center" (click)="openPlatformTab(access)">
                        <app-platform-logo imgClasses="mr-4 w-6 h-6" [logo]="access.platformKey"></app-platform-logo>
                        <span class="malou-text-13--semibold malou-color-text-1">
                            {{ access.platformKey | enumTranslate: 'platform_key' }}
                        </span>
                    </div>
                } @else {
                    <div class="flex items-center gap-x-2">
                        <img
                            class="h-10 w-12 rounded-md object-cover"
                            loading="lazy"
                            [src]="access.restaurantLogo?.urls?.original ?? ('default_logo' | imagePathResolver)" />
                        <span class="malou-text-13--medium malou-color-text-2 truncate" [matTooltip]="access.restaurantName ?? ''">
                            {{ access.restaurantName }}
                        </span>
                    </div>
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="accessType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'admin.access.type' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let access; table: table">
                <div
                    class="malou-chip"
                    [ngClass]="{
                        'malou-chip--purple-light': access.accessType === AccessType.MANAGER,
                        'malou-chip--warn': access.accessType === AccessType.CREDENTIALS,
                    }">
                    {{ access.accessType | titlecase }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastVerified">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'admin.access.checked' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let access; table: table">
                <span class="malou-text-13--medium malou-color-text-2">
                    {{ (access.lastVerified && (access.lastVerified | date)) || '-' }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastUpdated">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'admin.client_update' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let access; table: table">
                <span class="malou-text-13--medium malou-color-text-2">
                    {{ access.lastUpdated | date }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="login">
            <mat-header-cell *matHeaderCellDef>{{ 'admin.access.login' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let access; table: table" class="pr-4">
                <span class="malou-text-13--medium malou-color-text-2 truncate" [matTooltip]="access.data?.login ?? ''">
                    {{ access.data?.login }}
                </span>
                @if (access.data?.login) {
                    <mat-icon
                        class="!h-4"
                        color="primary"
                        [svgIcon]="SvgIcon.COPY"
                        [cdkCopyToClipboard]="access.data!.login"
                        (click)="copied()">
                    </mat-icon>
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="password">
            <mat-header-cell *matHeaderCellDef>{{ 'admin.access.password' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let access; table: table">
                @if (access.data?.password) {
                    <div>
                        <span class="malou-text-18--bold malou-color-text-1">&bull;&bull;&bull;&bull;&bull;&bull;</span>
                        @if (access.data?.password) {
                            <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.COPY" (click)="copyPassword(access)"> </mat-icon>
                        }
                    </div>
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef class="status-column" mat-sort-header>
                {{ 'admin.access.status' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let access; table: table" class="status-column">
                <div class="flex items-center">
                    <div
                        class="h-2 w-2 rounded-full"
                        [ngClass]="{
                            'malou-color-bg-fail':
                                (getAccessStatusGroupNameByStatus | applyPure: access.status) === AccessStatusGroupName.ERROR,
                            'malou-color-bg-warning':
                                (getAccessStatusGroupNameByStatus | applyPure: access.status) === AccessStatusGroupName.PENDING,
                            'malou-color-bg-success':
                                (getAccessStatusGroupNameByStatus | applyPure: access.status) === AccessStatusGroupName.SUCCESS,
                        }"></div>
                    <select required (change)="changeStatus($event, access)">
                        @for (group of getAvailableStatusesByPlatformKey | applyPure: access.platformKey; track $index) {
                            <optgroup [label]="group.name | enumTranslate: 'group_access_status'">
                                @for (accessStatus of group.accessStatuses; track $index) {
                                    <option [value]="accessStatus" [selected]="access.status === accessStatus">
                                        {{ accessStatus | enumTranslate: 'platform_access_status' }}
                                    </option>
                                }
                            </optgroup>
                        }
                    </select>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="active">
            <mat-header-cell *matHeaderCellDef class="active-column" mat-sort-header>{{ 'admin.active' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let access; table: table" class="active-column">
                <div
                    class="malou-chip"
                    [ngClass]="{
                        'malou-chip--green': access.active,
                        'malou-chip--warn': !access.active,
                    }">
                    {{ access.active ? ('common.yes' | translate) : ('common.no' | translate) }}
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
        <mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS; table: table"></mat-row>
    </mat-table>
</ng-template>

<ng-template #noAccessTemplate>
    <div class="malou-text-12--medium flex h-12.5 items-center px-10">
        <p>{{ 'admin.access.no_access' | translate }}</p>
    </div>
</ng-template>
