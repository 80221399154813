@if (!(screenSizeService.isPhoneScreen$ | async)) {
    <div class="mx-8 py-4">
        <ng-template
            [ngTemplateOutlet]="
                !hasBoosterPackActivated() || (hasBoosterPackActivated() && !activeRestaurantWheelOfFortune())
                    ? createWheelOfFortuneTemplate
                    : wheelOfFortuneDataCardTemplate
            "
            [ngTemplateOutletContext]="{ activeWheelOfFortune: activeRestaurantWheelOfFortune() }"></ng-template>
    </div>
}

<ng-template let-activeWheelOfFortune="activeWheelOfFortune" #wheelOfFortuneDataCardTemplate>
    <app-wheel-of-fortune-data-card
        [isAggregatedView]="false"
        [wheelOfFortune]="activeWheelOfFortune"
        (reloadWheelsOfFortune)="onReload()"></app-wheel-of-fortune-data-card>
</ng-template>

<ng-template let-activeWheelOfFortune="activeWheelOfFortune" #createWheelOfFortuneTemplate>
    <app-create-wheel-of-fortune-card
        [restaurantsIdsWithoutWheels]="restaurantsIdsWithoutWheels"
        [activeAggregatedWheelOfFortune]="activeWheelOfFortune"
        [isAggregatedView]="false"
        [hasBoosterPackActivated]="hasBoosterPackActivated"
        (reloadWheelsOfFortune)="onReload()"></app-create-wheel-of-fortune-card>
</ng-template>
