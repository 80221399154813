<div class="flex w-full flex-col">
    @if (title || subtitle || required) {
        <div class="malou-text-10--regular malou-color-text-2 flex gap-1 py-1">
            @if (title) {
                <span>{{ title }}</span>
            }
            @if (subtitle) {
                <span class="malou-text-10 italic">{{ subtitle }}</span>
            }
            @if (required) {
                <span>*</span>
            }
        </div>
    }

    <div
        class="flex h-12 self-stretch rounded-lg bg-white"
        [class.opacity-50]="control.disabled"
        [class.malou-border-primary]="shouldDisplayBorder"
        [class.malou-border-secondary]="!errorMessage && isFocused && shouldDisplayBorder"
        [class.malou-border-error]="!!errorMessage && shouldDisplayBorder">
        <div
            class="w-full pl-5 md:pr-0"
            [ngClass]="{
                'pr-3': shouldDisplayBorder,
            }">
            <input
                class="malou-text-12--medium box-border h-full w-full rounded-lg border-0 outline-none"
                type="text"
                [class.bg-white]="control.disabled"
                [class.italic]="isEmptyValue"
                [class.malou-text-12]="isEmptyValue"
                [max]="max"
                [min]="min"
                [placeholder]="placeholder"
                [formControl]="control"
                [matDatepicker]="picker"
                [attr.data-testid]="testId()"
                (focus)="isFocused = true"
                (blur)="onBlur()"
                (click)="setInputBlurred()"
                (input)="validateInput($event)"
                (dateInput)="validateDateChange($event)"
                #inputElement />
            <mat-datepicker [calendarHeaderComponent]="customHeaderComponent" #picker></mat-datepicker>
        </div>

        <div class="flex items-center pr-2 sm:pr-0">
            @if (showDatepickerButton) {
                <mat-datepicker-toggle [for]="picker" (click)="setInputBlurred()">
                    <mat-icon class="malou-color-primary small-icon" matDatepickerToggleIcon [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
                </mat-datepicker-toggle>
            }
        </div>
    </div>

    @if (errorMessage) {
        <div class="malou-text-10 malou-color-state-error py-1 italic">{{ errorMessage }}</div>
    }
</div>
