import { Component, OnInit } from '@angular/core';

import { DashboardCalendarComponent } from './dashboard-calendar/dashboard-calendar.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: true,
    imports: [DashboardCalendarComponent],
})
export class DashboardComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
