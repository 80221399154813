import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, output, Signal, signal, WritableSignal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { combineLatest, Observable } from 'rxjs';

import { isNotNil, PlatformKey, WheelOfFortuneRedirectionPlatformKey } from '@malou-io/package-utils';

import { BoostersStatisticsData } from ':modules/statistics/boosters/boosters.component';
import {
    ReviewCountData,
    ReviewsCountData,
    TotemsEstimatedReviewCountChartComponent,
} from ':modules/statistics/boosters/totems-estimated-review-count/totems-estimated-review-count-chart/totems-estimated-review-count-chart.component';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ScanForStats } from ':shared/models/scan';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-statistics-totems-estimated-review-count',
    templateUrl: './totems-estimated-review-count.component.html',
    styleUrls: ['./totems-estimated-review-count.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        FormsModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslateModule,
        MatIconModule,
        NumberEvolutionComponent,
        SelectComponent,
        SkeletonComponent,
        TotemsEstimatedReviewCountChartComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        ShortNumberPipe,
        StatisticsHttpErrorPipe,
    ],
    providers: [EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotemsEstimatedReviewCountComponent implements OnInit {
    readonly hiddenDatasetIndexes = input<number[]>([]);
    readonly totemData$ = input.required<Observable<BoostersStatisticsData>>();
    readonly wheelOfFortuneData$ = input.required<Observable<BoostersStatisticsData>>();
    readonly isParentLoading = input<boolean>(true);
    readonly isParentError = input<boolean>(false);
    readonly hiddenDatasetIndexesChange = output<number[]>();
    readonly hasDataChange = output<boolean>();

    private readonly _translateService = inject(TranslateService);

    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;
    readonly hasData: Signal<boolean> = computed(() => {
        const estimatedReviewCountOnPeriod = this.estimatedReviewCountOnPeriod();
        const estimatedWofReviewCountOnPeriod = this.estimatedWofReviewCountOnPeriod();
        return isNotNil(estimatedReviewCountOnPeriod) && isNotNil(estimatedWofReviewCountOnPeriod)
            ? estimatedReviewCountOnPeriod > 0 || estimatedWofReviewCountOnPeriod > 0
            : true;
    });
    readonly reviewsCountData: WritableSignal<ReviewsCountData> = signal([]);
    readonly estimatedReviewCountOnPeriod: WritableSignal<number | null> = signal(null);
    readonly estimatedReviewCountDifferenceWithPreviousPeriod: WritableSignal<number | null> = signal(null);
    readonly estimatedUnknownStarsReviewCountOnPeriod: WritableSignal<number> = signal(0);
    readonly estimatedWofReviewCountOnPeriod: WritableSignal<number | null> = signal(null);
    readonly estimatedWofReviewCountDifferenceWithPreviousPeriod: WritableSignal<number | null> = signal(null);
    readonly estimatedWofUnknownStarsReviewCountOnPeriod: WritableSignal<number> = signal(0);

    readonly STAR_CLICKED_BY_TRANSLATION = {
        [this._translateService.instant('statistics.totems.unknown_star')]: undefined,
        [this._translateService.instant('statistics.totems.one_star')]: 1,
        [this._translateService.instant('statistics.totems.two_stars')]: 2,
        [this._translateService.instant('statistics.totems.three_stars')]: 3,
        [this._translateService.instant('statistics.totems.four_stars')]: 4,
        [this._translateService.instant('statistics.totems.five_stars')]: 5,
    };

    ngOnInit(): void {
        combineLatest([this.totemData$(), this.wheelOfFortuneData$()]).subscribe(
            ([{ scans, previousScans }, { scans: wofScans, previousScans: previousWofScans }]) => {
                this.reviewsCountData.set([
                    {
                        name: this._translateService.instant('statistics.totems.totems'),
                        data: this._getChartDataForScans(scans),
                    },
                    {
                        name: this._translateService.instant('statistics.totems.wheel_of_fortune'),
                        data: this._getChartDataForScans(wofScans, true),
                    },
                ]);
                this.estimatedReviewCountOnPeriod.set(scans.filter((scan) => scan.matchedReviewId).length);
                this.estimatedWofReviewCountOnPeriod.set(wofScans.filter((scan) => scan.matchedReviewId).length);
                const estimatedReviewCountOnPeriod = this.estimatedReviewCountOnPeriod();
                if (estimatedReviewCountOnPeriod) {
                    this.estimatedReviewCountDifferenceWithPreviousPeriod.set(
                        estimatedReviewCountOnPeriod - previousScans.filter((scan) => scan.matchedReviewId).length
                    );
                }
                const estimatedWofReviewCountOnPeriod = this.estimatedWofReviewCountOnPeriod();
                if (estimatedWofReviewCountOnPeriod) {
                    this.estimatedWofReviewCountDifferenceWithPreviousPeriod.set(
                        estimatedWofReviewCountOnPeriod - previousWofScans.filter((scan) => scan.matchedReviewId).length
                    );
                }

                this.hasDataChange.emit(this.hasData());
                this.estimatedUnknownStarsReviewCountOnPeriod.set(scans.filter((scan) => scan.matchedReviewId && !scan.starClicked).length);
                this.estimatedWofUnknownStarsReviewCountOnPeriod.set(
                    wofScans.filter((scan) => scan.matchedReviewId && !scan.starClicked).length
                );
            }
        );
    }

    private _getChartDataForScans(nfcScans: ScanForStats[], isWof = false): ReviewCountData {
        return Object.entries(this.STAR_CLICKED_BY_TRANSLATION).map(([starName, starValue]) => {
            const scansFiltered = nfcScans.filter((scan) => {
                const condition = isWof ? scan.matchedReview?.rating === starValue : scan.starClicked === starValue;
                return (
                    scan.matchedReviewId &&
                    condition &&
                    scan.nfcSnapshot?.platformKey !== WheelOfFortuneRedirectionPlatformKey.NO_REDIRECTION
                );
            });
            const scansByNfcName = groupBy(scansFiltered, (value) => value.nfcSnapshot?.chipName ?? value.nfcSnapshot?.name);

            const scansCountByNfcName: ReviewCountData[0]['count'] = [];
            for (const scanByNfcName of Object.entries(scansByNfcName)) {
                const [nfcName, allNfcScans] = scanByNfcName;
                const scansByPlatformName = groupBy(allNfcScans, (value) => value.nfcSnapshot?.platformKey);
                const scansCountByNfcPlatform: Partial<Record<PlatformKey, number>> = Object.entries(scansByPlatformName).reduce(
                    (acc, [platformKey, scans]) =>
                        platformKey
                            ? {
                                  ...acc,
                                  [platformKey as PlatformKey]: scans.length,
                              }
                            : acc,
                    {}
                );
                scansCountByNfcName.push({
                    chipName: nfcName,
                    total: allNfcScans.length,
                    countPerPlatform: scansCountByNfcPlatform,
                });
            }

            return {
                star: starName,
                starValue: starValue ?? -1,
                total: scansFiltered.length,
                count: scansCountByNfcName,
            };
        });
    }
}
