import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-circle-progress',
    templateUrl: './circle-progress.component.html',
    styleUrls: ['./circle-progress.component.scss'],
    standalone: true,
    imports: [MatIconModule],
})
export class CircleProgressComponent implements OnInit {
    @Input() progress$: Observable<number>;

    readonly SvgIcon = SvgIcon;
    rotation = 0;

    ngOnInit(): void {
        this.progress$.subscribe((progress) => {
            this.rotation = this._transformProgressToRotateValue(progress);
            const rotations = document.getElementsByClassName('rotation');
            for (let i = 0; i < rotations.length; i++) {
                rotations[i].setAttribute('style', `transform: rotate(${this.rotation}deg)`);
            }
        });
    }

    private _transformProgressToRotateValue(progress: number): number {
        const maxRotation = 180;
        if (progress >= 100) {
            return maxRotation;
        }

        return Math.round(maxRotation * (progress / 100));
    }
}
