export enum PlatformCategory {
    SEO = 'seo',
    SOCIAL = 'social',
    DELIVERY = 'delivery',
    RESERVATION = 'reservation',
    PRIVATE = 'private',
}

export enum PlatformGroup {
    MUST_HAVE = 'must_have',
    SOCIAL = 'social',
    SEO = 'seo',
    E_REPUTATION = 'e_reputation',
    PRIVATE = 'private',
}

export const WHEEL_OF_FORTUNE_PLATFORM_KEY = 'wheel_of_fortune';

export enum PlatformKey {
    ABC = 'abc',
    DELIVEROO = 'deliveroo',
    FACEBOOK = 'facebook',
    FOURSQUARE = 'foursquare',
    GMB = 'gmb',
    INSTAGRAM = 'instagram',
    LAFOURCHETTE = 'lafourchette',
    MAPSTR = 'mapstr',
    OPENTABLE = 'opentable',
    PAGESJAUNES = 'pagesjaunes',
    PRIVATE = 'malouPrivate',
    RESY = 'resy',
    TRIPADVISOR = 'tripadvisor',
    UBEREATS = 'ubereats',
    WEBSITE = 'website',
    YELP = 'yelp',
    ZENCHEF = 'zenchef',
}

export const NfcsPlatformKey = { ...PlatformKey, WHEEL_OF_FORTUNE_PLATFORM_KEY } as const;
export type NfcsPlatformKey = (typeof NfcsPlatformKey)[keyof typeof NfcsPlatformKey];

const NO_REDIRECTION = 'no_redirection';

const WheelOfFortuneRedirectionAcceptedPlatformKeys = {
    GMB: PlatformKey.GMB,
    INSTAGRAM: PlatformKey.INSTAGRAM,
    TRIPADVISOR: PlatformKey.TRIPADVISOR,
} as const;

export const WheelOfFortuneRedirectionPlatformKey = {
    ...WheelOfFortuneRedirectionAcceptedPlatformKeys,
    NO_REDIRECTION,
} as const;

export type WheelOfFortuneRedirectionPlatformKey =
    (typeof WheelOfFortuneRedirectionPlatformKey)[keyof typeof WheelOfFortuneRedirectionPlatformKey];

export const ScanPlatformKey = { ...NfcsPlatformKey, ...WheelOfFortuneRedirectionPlatformKey } as const;
export type ScanPlatformKey = (typeof ScanPlatformKey)[keyof typeof ScanPlatformKey];

export enum PlatformAccessStatus {
    NEED_REVIEW = 'need_review',
    VERIFIED = 'verified',
    FAILED = 'failed',
    BAD_ACCESS = 'bad_access',
    UNCLAIMED_PAGE = 'unclaimed_page',
    INVALID_PAGE = 'invalid_page',
    MISSING_PERMISSIONS = 'missing_permissions',
    NOT_FOUND = 'not_found',
    IN_PROGRESS = 'in_progress',
    SUCCESS = 'success',
    UNVERIFIED = 'unverified',
}

export const failedAccessStatus = [
    PlatformAccessStatus.FAILED,
    PlatformAccessStatus.BAD_ACCESS,
    PlatformAccessStatus.UNCLAIMED_PAGE,
    PlatformAccessStatus.MISSING_PERMISSIONS,
    PlatformAccessStatus.INVALID_PAGE,
];

export enum PlatformAccessType {
    NO_UPDATE = 'no_update',
    MANAGER = 'manager',
    CREDENTIALS = 'credentials',
    CREATE = 'create', // not used anymore but kept for backward compatibility
    AUTO = 'auto',
}

export const platformInformationFields = Object.freeze([
    'address',
    'phone',
    'menu',
    'logo',
    'categoryList',
    'attributeList',
    'name',
    'descriptions',
    'website',
    'openingDate',
    'category',
    'regularHours',
    'specialHours',
    'isClosedTemporarily',
]);

export enum PlatformUpdateTime {
    REAL_TIME = 'real_time',
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
}
