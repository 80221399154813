import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, model } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';

import { CustomerNaming, ReplyTone } from '@malou-io/package-utils';

import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { AiSettingsSignatureWithId, RestaurantAiSettingsFormData } from ':shared/models/restaurant-ai-settings';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';

@Component({
    selector: 'app-general-ai-settings',
    templateUrl: './general-ai-settings.component.html',
    styleUrls: ['./general-ai-settings.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        MatRadioModule,
        MatFormFieldModule,
        TranslateModule,
        InputTextComponent,
        SimpleSelectComponent,
        EnumTranslatePipe,
        FlagPathResolverPipe,
        TextAreaComponent,
        MatCheckboxModule,
        MatIconModule,
        MatTooltipModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralAiSettingsComponent {
    readonly restaurantAiSettings = model.required<RestaurantAiSettingsFormData | undefined>();
    readonly disabled = input<boolean>(false);

    private readonly _translateService = inject(TranslateService);

    readonly CUSTOMER_NAMING_OPTIONS = Object.values(CustomerNaming);

    readonly ReplyTone = ReplyTone;
    readonly SvgIcon = SvgIcon;

    readonly restaurantNameError = computed(() => {
        const restaurantName = this.restaurantAiSettings()?.restaurantName ?? '';

        if (restaurantName.length === 0) {
            return this._translateService.instant('restaurant_ai_settings.modals.upsert.errors.missing_restaurant_name');
        }

        return undefined;
    });

    readonly customerNamingError = computed(() => {
        const customerNaming = this.restaurantAiSettings()?.customerNaming;

        if (!customerNaming) {
            return this._translateService.instant('restaurant_ai_settings.modals.upsert.errors.missing_customer_naming');
        }

        return undefined;
    });

    readonly replyToneError = computed(() => {
        const replyTone = this.restaurantAiSettings()?.replyTone;

        if (!replyTone) {
            return this._translateService.instant('restaurant_ai_settings.modals.upsert.errors.missing_reply_tone');
        }

        return undefined;
    });

    updateRestaurantName(newRestaurantName: string): void {
        this.restaurantAiSettings.update((restaurantAiSettings) =>
            restaurantAiSettings
                ? new RestaurantAiSettingsFormData({ ...restaurantAiSettings, restaurantName: newRestaurantName })
                : undefined
        );
    }

    updateCustomerNaming(newCustomerNaming: unknown): void {
        this.restaurantAiSettings.update((restaurantAiSettings) =>
            restaurantAiSettings
                ? new RestaurantAiSettingsFormData({ ...restaurantAiSettings, customerNaming: newCustomerNaming as CustomerNaming })
                : undefined
        );
    }

    updateReplyTone(event: MatRadioChange): void {
        this.restaurantAiSettings.update((restaurantAiSettings) =>
            restaurantAiSettings ? new RestaurantAiSettingsFormData({ ...restaurantAiSettings, replyTone: event.value }) : undefined
        );
    }

    updateCatchphrase(newCatchphrase: string | null): void {
        this.restaurantAiSettings.update((restaurantAiSettings) =>
            restaurantAiSettings
                ? new RestaurantAiSettingsFormData({ ...restaurantAiSettings, catchphrase: newCatchphrase ?? '' })
                : undefined
        );
    }

    addSignature(): void {
        this.restaurantAiSettings.update((restaurantAiSettings) =>
            restaurantAiSettings
                ? new RestaurantAiSettingsFormData({
                      ...restaurantAiSettings,
                      signaturesForForm: [...(restaurantAiSettings?.signaturesForForm ?? []), this._getNewSignature()],
                  })
                : undefined
        );
    }

    updateSignature(updatedSignature: string, signatureId: string): void {
        this.restaurantAiSettings.update((restaurantAiSettings) =>
            restaurantAiSettings
                ? new RestaurantAiSettingsFormData({
                      ...restaurantAiSettings,
                      signaturesForForm: restaurantAiSettings?.signaturesForForm?.map((signature) =>
                          signatureId === signature.id ? { id: signature.id, text: updatedSignature } : signature
                      ),
                  })
                : undefined
        );
    }

    removeSignature(signatureId: string): void {
        this.restaurantAiSettings.update((restaurantAiSettings) =>
            restaurantAiSettings
                ? new RestaurantAiSettingsFormData({
                      ...restaurantAiSettings,
                      signaturesForForm:
                          (restaurantAiSettings?.signaturesForForm?.length ?? 0) > 1
                              ? (restaurantAiSettings?.signaturesForForm?.filter((signature) => signature.id !== signatureId) ?? [
                                    this._getNewSignature(),
                                ])
                              : [this._getNewSignature()],
                  })
                : undefined
        );
    }

    onToggleShouldTranslateCatchphrase(): void {
        this.restaurantAiSettings.update((restaurantAiSettings) =>
            restaurantAiSettings
                ? new RestaurantAiSettingsFormData({
                      ...restaurantAiSettings,
                      shouldTranslateCatchphrase: !restaurantAiSettings.shouldTranslateCatchphrase,
                  })
                : undefined
        );
    }

    onToggleShouldTranslateSignatures(): void {
        this.restaurantAiSettings.update((restaurantAiSettings) =>
            restaurantAiSettings
                ? new RestaurantAiSettingsFormData({
                      ...restaurantAiSettings,
                      shouldTranslateSignature: !restaurantAiSettings.shouldTranslateSignature,
                  })
                : undefined
        );
    }

    private _getNewSignature(): AiSettingsSignatureWithId {
        return { id: uuid(), text: '' };
    }
}
