import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs';

import { SpinnerService } from ':core/services/malou-spinner.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { CreateWheelOfFortuneCardComponent } from ':modules/wheels-of-fortune/create-wheel-of-fortune-card/create-wheel-of-fortune-card.component';
import { UpsertWheelOfFortuneModalComponent } from ':modules/wheels-of-fortune/upsert-wheel-of-fortune-modal/upsert-wheel-of-fortune-modal.component';
import { WheelOfFortuneDataCardComponent } from ':modules/wheels-of-fortune/wheel-of-fortune-data-card/wheel-of-fortune-data-card.component';
import { WheelsOfFortuneService } from ':modules/wheels-of-fortune/wheels-of-fortune.service';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { WheelOfFortune } from ':shared/models/wheel-of-fortune';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-wheels-of-fortune',
    templateUrl: './wheels-of-fortune.component.html',
    styleUrls: ['./wheels-of-fortune.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, CreateWheelOfFortuneCardComponent, WheelOfFortuneDataCardComponent, TranslateModule, SkeletonComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelsOfFortuneComponent implements OnInit {
    readonly restaurantsIdsWithoutWheels: WritableSignal<string[]> = signal([]);
    readonly wheelsOfFortune: WritableSignal<WheelOfFortune[]> = signal([]);
    readonly isLoadingWheelsOfFortune: WritableSignal<boolean> = signal(true);
    readonly restaurantIdFromQueryParams: WritableSignal<string | undefined> = signal(undefined);
    readonly wheelOfFortuneIdFromQueryParams: WritableSignal<string | undefined> = signal(undefined);
    readonly hasBoosterPackActivated = toSignal(
        this._store
            .select(selectOwnRestaurants)
            .pipe(map((restaurants) => restaurants.some((restaurant) => restaurant.boosterPack?.activated))),
        { initialValue: false }
    );

    readonly trackByIdFn = TrackByFunctionFactory.get('id');

    constructor(
        private readonly _customDialogService: CustomDialogService,
        private readonly _spinnerService: SpinnerService,
        private readonly _wheelsOfFortuneService: WheelsOfFortuneService,
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _store: Store
    ) {
        this._route.queryParams.subscribe((params) => {
            if (params['restaurantId']) {
                this.restaurantIdFromQueryParams.set(params['restaurantId']);
            }
            if (params['wheelOfFortuneId']) {
                this.wheelOfFortuneIdFromQueryParams.set(params['wheelOfFortuneId']);
            }
            this._checkIfShouldOpenWheelOfFortune();
        });
    }

    ngOnInit(): void {
        this.reloadWheelsOfFortune();
    }

    reloadWheelsOfFortune(): void {
        this.isLoadingWheelsOfFortune.set(true);
        this._wheelsOfFortuneService.getUserActiveAggregatedWheels().subscribe({
            next: (res) => {
                const activeWheelsOfFortune = res.data.map((wheelOfFortune) => new WheelOfFortune(wheelOfFortune));
                this.wheelsOfFortune.set(activeWheelsOfFortune);
                this.isLoadingWheelsOfFortune.set(false);

                this._checkIfShouldOpenWheelOfFortune();
            },
        });
        this._wheelsOfFortuneService.getRestaurantIdsWithoutActiveWheel().subscribe({
            next: (res) => this.restaurantsIdsWithoutWheels.set(res.data),
        });
    }

    private _checkIfShouldOpenWheelOfFortune(): void {
        const restaurantIdFromQueryParams = this.restaurantIdFromQueryParams();
        const wheelOfFortuneIdFromQueryParams = this.wheelOfFortuneIdFromQueryParams();

        if (!restaurantIdFromQueryParams && !wheelOfFortuneIdFromQueryParams) {
            return;
        }

        let wheelOfFortuneToOpen: WheelOfFortune | undefined;

        if (restaurantIdFromQueryParams) {
            wheelOfFortuneToOpen = this.wheelsOfFortune().find((wheelOfFortune) =>
                wheelOfFortune.restaurants.some((restaurant) => restaurant.id === restaurantIdFromQueryParams)
            );
        }
        if (wheelOfFortuneIdFromQueryParams) {
            wheelOfFortuneToOpen = this.wheelsOfFortune().find((wheelOfFortune) => wheelOfFortune.id === wheelOfFortuneIdFromQueryParams);
        }

        if (wheelOfFortuneToOpen) {
            this._openWheelOfFortune(wheelOfFortuneToOpen);
            this.restaurantIdFromQueryParams.set(undefined);
            this.wheelOfFortuneIdFromQueryParams.set(undefined);
        }
    }

    private _openWheelOfFortune(wheelOfFortune: WheelOfFortune): void {
        this._customDialogService
            .open(UpsertWheelOfFortuneModalComponent, {
                width: '100%',
                panelClass: 'malou-dialog-panel--full',
                height: undefined,
                data: {
                    isAggregatedView: true,
                    wheelOfFortune,
                },
            })
            .afterClosed()
            .subscribe({
                next: (res) => {
                    if (res?.redirectUrl) {
                        this._router.navigate(res.redirectUrl.path, res.redirectUrl.extras);
                    }
                    if (res?.shouldReload) {
                        this.reloadWheelsOfFortune();
                    }
                    this._spinnerService.hide();
                },
                error: () => {
                    this._spinnerService.hide();
                },
            });
    }
}
