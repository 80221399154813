<div class="malou-dialog h-full">
    @if (isLoading()) {
        <ng-container [ngTemplateOutlet]="skeletonTemplate"></ng-container>
    } @else if (isError()) {
        <ng-container [ngTemplateOutlet]="errorTemplate"></ng-container>
    } @else {
        <ng-container [ngTemplateOutlet]="modalTemplate"></ng-container>
    }
</div>

<ng-template #skeletonTemplate>
    <div class="malou-dialog__header !mb-4">
        <app-skeleton skeletonClass="!h-[100px] secondary-bg"></app-skeleton>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body !overflow-hidden">
        <div class="mt-1 flex w-full flex-col gap-1 sm:justify-center">
            <app-skeleton skeletonClass="!h-[50px] secondary-bg"></app-skeleton>
            <app-skeleton skeletonClass="!h-[50px] secondary-bg"></app-skeleton>
            <app-skeleton skeletonClass="!h-[50px] secondary-bg"></app-skeleton>
            <app-skeleton skeletonClass="!h-[50px] secondary-bg"></app-skeleton>
            <app-skeleton skeletonClass="!h-[50px] secondary-bg"></app-skeleton>
        </div>
    </div>
</ng-template>

<ng-template #errorTemplate>
    <div class="flex !h-[350px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
    </div>
</ng-template>

<ng-template #modalTemplate>
    <div class="malou-dialog__header !mb-4">
        <div class="flex gap-2.5">
            @if (restaurantLogo()) {
                <img class="h-10 w-10 rounded-md" alt="restaurant logo" [src]="restaurantLogo()" />
            }
            <div class="flex flex-col">
                <span class="!malou-text-color-1 malou-text-18--bold">
                    {{ 'roi.tips.short_title' | translate }} - {{ restaurantName() }}
                </span>
                <span class="malou-text-12--regular malou-color-text-2 italic">{{ data.restaurant.fullFormattedAddress }}</span>
            </div>
        </div>

        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body !overflow-hidden">
        <div class="mt-1 flex w-full flex-col gap-1 sm:justify-center">
            @for (tip of roiTips(); track $index) {
                <app-tip-item
                    [roiTip]="tip.id"
                    [title]="tip.titleTranslationKey | translate"
                    [details]="tip.descriptionTranslationKey | translate: { value: tip.value }"
                    [emoji]="tip.emojiPath"
                    [buttonLink]="tip.actionRedirectionLink"
                    [buttonText]="tip.actionTranslationKey | translate"
                    (isActionPressed)="onActionPressed()">
                </app-tip-item>
            }
        </div>
        @if (roiTips().length <= MAX_TIPS_TO_SHOW_CONGRATS && roiTips().length > 0 && shouldShowCongrats()) {
            <div class="mt-9.5 flex h-24 items-center gap-5 rounded-md bg-malou-color-background-dark p-7">
                <img class="!h-12 !w-14" [src]="'clap' | roiTipsPathResolver" />
                <div class="flex flex-col gap-1">
                    <span class="malou-text-14--bold text-malou-color-text-1">{{ 'roi.tips.congrats' | translate }}</span>
                    <span class="malou-text-10 text-malou-color-text-2">{{ 'roi.tips.congrats_subtitle' | translate }}</span>
                </div>
            </div>
        }
        @if (!roiTips().length) {
            <div class="flex h-full flex-col items-center justify-center !pb-16">
                <img class="mb-3 !h-9 !w-9" [src]="'sad' | roiTipsPathResolver" />
                <span class="malou-text-14--bold malou-color-text-1">{{ 'roi.tips.error' | translate }}</span>
                <span class="malou-text-10 malou-color-text-2">{{ 'roi.tips.try_again' | translate }}</span>
            </div>
        }
    </div>
</ng-template>
