import { Routes } from '@angular/router';

import { RoutePath } from '@malou-io/package-utils';

import { AUTOMATIONS_ROUTES } from ':modules/automations/automations.routing';
import { PLATFORMS_ROUTES } from ':modules/platforms/platforms.routing';
import { ROLES_ROUTES } from ':modules/roles/roles.routing';

import { SettingsComponent } from './settings.component';

export const SETTINGS_ROUTES: Routes = [
    {
        path: '',
        component: SettingsComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'platforms',
            },
            {
                path: 'platforms',
                loadChildren: () => PLATFORMS_ROUTES, // sync import to avoid issue with lazy loading. See https://malou.sentry.io/issues/4525057545/?environment=development&project=4504751295430656&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=1h&stream_index=1
                data: { path: RoutePath.PLATFORMS },
            },
            {
                path: 'automations',
                loadChildren: () => AUTOMATIONS_ROUTES,
                data: { path: RoutePath.AUTOMATIONS },
            },
            {
                path: 'roles',
                loadChildren: () => ROLES_ROUTES,
                data: { path: RoutePath.USERS },
            },
        ],
    },
];
