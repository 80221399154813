import { EntityConstructor, PictureSize, PlatformKey } from '@malou-io/package-utils';

import { Notification } from './notification.model';

type NotificationPostProps = EntityConstructor<NotificationPost>;

export class NotificationPost {
    id: string;
    text: string;
    attachments?: {
        [PictureSize.SMALL]?: string;
        [PictureSize.IG_FIT]?: string;
    };
    plannedPublicationDate: Date;
    restaurantId: string;
    errorMessage: string;
    platform: {
        id: string;
        key: PlatformKey;
        name: string;
        socialLink?: string;
    };
    postCreatedAt: Date;
    postUpdatedAt: Date;

    constructor(notificationPost: NotificationPostProps & { id: string }) {
        this.id = notificationPost.id;
        this.text = notificationPost.text;
        this.attachments = notificationPost.attachments;
        this.plannedPublicationDate = notificationPost.plannedPublicationDate;
        this.restaurantId = notificationPost.restaurantId;
        this.errorMessage = notificationPost.errorMessage;
        this.platform = notificationPost.platform;
        this.postCreatedAt = notificationPost.postCreatedAt;
        this.postUpdatedAt = notificationPost.postUpdatedAt;
    }
}

export interface PostErrorNotificationData {
    restaurantIds: string[];
    post: NotificationPost;
}

type PostErrorNotificationProps = EntityConstructor<PostErrorNotification>;

export class PostErrorNotification extends Notification {
    data: PostErrorNotificationData;
    constructor(props: PostErrorNotificationProps) {
        super(props);
        this.data = {
            ...props.data,
            post: new NotificationPost(props.data.post),
        };
    }
}
