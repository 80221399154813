import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import {
    AiCompletionDto,
    AiOptimizeTextDto,
    AiPostGenerationAdvancedSettingsDto,
    AnswerReviewPreviewBodyDto,
    ChoosePostHashtagsBodyDto,
    ChoosePostHashtagsDto,
    CountAiInteractionsDto,
    KeywordsBreakdownDto,
    OptimizeTextBodyDto,
    TextTranslationBodyDto,
} from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import * as JimoActions from ':modules/jimo/jimo.actions';

@Injectable({
    providedIn: 'root',
})
export class AiService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/ai`;

    aiRemainingCredits$ = new BehaviorSubject<number>(0);

    constructor(
        private readonly _http: HttpClient,
        private readonly _store: Store
    ) {}

    answerReview(body: { reviewId: string; restaurantId: string }): Observable<ApiResultV2<AiCompletionDto>> {
        return this._http.post<ApiResultV2<AiCompletionDto>>(`${this.API_BASE_URL}/answer-review`, body);
    }

    answerReviewPreview(body: AnswerReviewPreviewBodyDto): Observable<ApiResultV2<AiCompletionDto>> {
        return this._http.post<ApiResultV2<AiCompletionDto>>(`${this.API_BASE_URL}/answer-review-preview`, body);
    }

    // TODO: remove when removing feature toggle 'release-post-lambda-advanced-settings' & mobile is up to date with new route
    generateSeoPost(body: {
        postId: string;
        description: string;
        restaurantId: string;
        lang: string;
    }): Observable<ApiResultV2<AiCompletionDto>> {
        return this._http.post<ApiResultV2<AiCompletionDto>>(`${this.API_BASE_URL}/generate-seo-post-text`, body);
    }

    generateSeoPostAdvancedSettings(body: {
        postId: string;
        description: string;
        restaurantId: string;
        lang: string;
        shouldUseImageAnalysis: boolean;
    }): Observable<ApiResultV2<AiPostGenerationAdvancedSettingsDto>> {
        return this._http.post<ApiResultV2<AiPostGenerationAdvancedSettingsDto>>(
            `${this.API_BASE_URL}/generate-seo-post-text-advanced-settings`,
            body
        );
    }

    // TODO: remove when removing feature toggle 'release-post-lambda-advanced-settings' & mobile is up to date with new route
    generateSocialNetworkPost(body: {
        postId: string;
        description: string;
        restaurantId: string;
        lang: string;
    }): Observable<ApiResultV2<AiCompletionDto>> {
        return this._http.post<ApiResultV2<AiCompletionDto>>(`${this.API_BASE_URL}/generate-social-network-post-text`, body);
    }

    generateSocialNetworkPostAdvancedSettings(body: {
        postId: string;
        description: string;
        restaurantId: string;
        lang: string;
        shouldUseImageAnalysis: boolean;
    }): Observable<ApiResultV2<AiPostGenerationAdvancedSettingsDto>> {
        return this._http.post<ApiResultV2<AiPostGenerationAdvancedSettingsDto>>(
            `${this.API_BASE_URL}/generate-social-network-post-text-advanced-settings`,
            body
        );
    }

    choosePostHashtags(body: ChoosePostHashtagsBodyDto): Observable<ApiResultV2<ChoosePostHashtagsDto>> {
        return this._http.post<ApiResultV2<ChoosePostHashtagsDto>>(`${this.API_BASE_URL}/choose-post-hashtags`, body);
    }

    translateText(body: TextTranslationBodyDto): Observable<ApiResultV2<AiCompletionDto>> {
        return this._http.post<ApiResultV2<AiCompletionDto>>(`${this.API_BASE_URL}/translate`, body);
    }

    optimizeText(body: OptimizeTextBodyDto): Observable<ApiResultV2<AiOptimizeTextDto>> {
        return this._http.post<ApiResultV2<AiOptimizeTextDto>>(`${this.API_BASE_URL}/optimize-text`, body);
    }

    setAiRemainingCredits(value: number): void {
        this.aiRemainingCredits$.next(value);
    }

    handleAiInteraction(creditCost = 1): void {
        this._decrementAiRemainingCredits(creditCost);
        this._store.dispatch({ type: JimoActions.incrementJimoUserAiInteractionsCount.type });
    }

    getUserAiInteractionCount(): Observable<ApiResultV2<CountAiInteractionsDto>> {
        return this._http.get<ApiResultV2<CountAiInteractionsDto>>(`${this.API_BASE_URL}/interactions/count`);
    }

    breakdownKeywords$(keywords: string[], restaurantId: string, lang: string): Observable<ApiResultV2<KeywordsBreakdownDto[]>> {
        return this._http.post<ApiResultV2<KeywordsBreakdownDto[]>>(`${this.API_BASE_URL}/breakdown`, { keywords, restaurantId, lang });
    }

    private _decrementAiRemainingCredits(decrement = 1): void {
        this.aiRemainingCredits$.next(Math.max(0, this.aiRemainingCredits$.value - decrement));
    }
}
