import { Component, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { UserFiltersService } from ':core/user-filters/user-filters.service';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { selectPeriodReviewsFilters } from ':modules/reviews/store/reviews.selectors';
import { MalouDateFilters, MalouPeriod } from ':shared/models';

import { ReviewsDisplayMode } from '../reviews/store/reviews.reducer';

@Component({
    selector: 'app-aggregated-reputation',
    templateUrl: './aggregated-reputation.component.html',
    standalone: true,
    imports: [RouterOutlet],
})
export class AggregatedReputationComponent implements OnDestroy {
    constructor(
        private readonly _store: Store,
        private readonly _userFiltersService: UserFiltersService
    ) {
        this._initAggregatedReviewsState();
    }

    _initAggregatedReviewsState(): void {
        this._store
            .select(selectPeriodReviewsFilters)
            .pipe(filter(isNotNil))
            .subscribe((datesFilter) => {
                if (datesFilter.period === MalouPeriod.ALL) {
                    this._store.dispatch(
                        ReviewsActions.editReviewsFiltersDates({
                            datesFilters: new MalouDateFilters().getFilter({ period: MalouPeriod.LAST_AND_COMING_THIRTY_DAYS }),
                        })
                    );
                }
                this._store.dispatch(
                    ReviewsActions.editReviewsFiltersCurrentView({ currentView: ReviewsDisplayMode.AGGREGATED_RESTAURANTS })
                );
            });
    }

    ngOnDestroy(): void {
        this._store.dispatch(ReviewsActions.resetReviewsStateExceptRestaurants());
    }
}
