import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { InsightsChart } from '@malou-io/package-utils';

import { FiltersComponent } from ':modules/aggregated-statistics/filters/filters.component';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { AggregatedTop3PostsComponent } from ':modules/aggregated-statistics/social-networks/aggregated-top-3-posts/aggregated-top-3-posts.component';
import { AggregatedPostsInsightsTableComponent } from ':modules/aggregated-statistics/social-networks/insights/aggregated-posts-insights-table.component';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import { PlatformFilterPage } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { parseInsightsRouteParams } from ':shared/helpers/extract-statistics-route-data';
import { FromToDateFormatterPipe } from ':shared/pipes/from-to-date-formatter.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { StatisticsPdfRestaurantsFormatterPipe } from ':shared/pipes/statistics-pdf-restaurants-formatter.pipe';

@Component({
    selector: 'app-social-networks-pdf',
    standalone: true,
    imports: [
        AggregatedPostsInsightsTableComponent,
        FiltersComponent,
        IllustrationPathResolverPipe,
        TranslateModule,
        AggregatedTop3PostsComponent,
        FromToDateFormatterPipe,
        AsyncPipe,
        IncludesPipe,
        StatisticsPdfRestaurantsFormatterPipe,
    ],
    templateUrl: './social-networks-pdf.component.html',
    styleUrls: ['./social-networks-pdf.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialNetworksPdfComponent {
    readonly displayedCharts: InsightsChart[];
    readonly InsightsChart = InsightsChart;
    readonly startDate: Date;
    readonly endDate: Date;
    readonly selectedRestaurantsTitle$: Observable<string>;
    readonly chartOptions: ChartOptions;

    aggregatedTop3PostsHasData = true;
    aggregatedPostsInsightsHasData = true;

    constructor(
        private _store: Store,
        private _route: ActivatedRoute,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext,
        public readonly translateService: TranslateService
    ) {
        const parsedQueryParams = parseInsightsRouteParams(this._route.snapshot.queryParams.params);
        const { startDate, endDate, displayedCharts, platformKeys, chartOptions } = parsedQueryParams;

        this.displayedCharts = displayedCharts;
        this.startDate = startDate;
        this.endDate = endDate;
        this.chartOptions = chartOptions ?? {};

        this._store.dispatch(
            AggregatedStatisticsActions.editPlatforms({
                platforms: platformKeys ?? [],
                page: PlatformFilterPage.SOCIAL_NETWORKS,
            })
        );

        this.selectedRestaurantsTitle$ = this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(
            map((restaurants) => restaurants.map((restaurant) => restaurant.internalName ?? restaurant.name).join(', '))
        );
    }
}
