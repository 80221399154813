import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    EventEmitter,
    inject,
    Injector,
    input,
    InputSignal,
    OnInit,
    Output,
    runInInjectionContext,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, minBy } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { combineLatest, filter, forkJoin, map, Observable } from 'rxjs';

import { UpsertRoiSettingsBodyDto } from '@malou-io/package-dto';
import {
    Currency,
    getCurrencySymbol,
    isNotNil,
    RevenueOption,
    RoiRevenueOptions,
    SimilarRestaurantCategory,
    sortRestaurantsByInternalNameThenName,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { SimilarRestaurantsService } from ':core/services/similar-restaurants.service';
import { RoiNotificationsContext } from ':modules/notification-center/notifications.context';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { RoiSettingsService } from ':modules/roi/roi-settings.service';
import { RoiContext } from ':modules/roi/roi.context';
import { RoiService } from ':modules/roi/roi.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { InputNumberComponent } from ':shared/components/input-number/input-number.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Restaurant } from ':shared/models';
import { IRoiSettings } from ':shared/models/roi-settings.model';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { RoiTipsPathResolver } from ':shared/pipes/roi-tips-path-resolver.pipe';
import { displayRevenue } from ':shared/services/csv-services/e-reputation/helper-functions';

interface RoiSettingsForm {
    averageTicket: FormControl<number | null>;
    revenue: FormControl<RevenueOption | null>;
    category: FormControl<SimilarRestaurantCategory | null>;
}

enum RoiFormFieldName {
    AVERAGE_TICKET = 'averageTicket',
    REVENUE = 'revenue',
    CATEGORY = 'category',
}

@Component({
    selector: 'app-upsert-aggregated-roi-settings',
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        AsyncPipe,
        FormsModule,
        MatIconModule,
        ApplyPurePipe,
        SelectComponent,
        MatButtonModule,
        TranslateModule,
        NgTemplateOutlet,
        MatTooltipModule,
        MatCheckboxModule,
        ApplySelfPurePipe,
        LazyLoadImageModule,
        ReactiveFormsModule,
        InputNumberComponent,
        ImagePathResolverPipe,
        EmojiPathResolverPipe,
        MalouSpinnerComponent,
        MatTooltipModule,
        MatExpansionModule,
        RoiTipsPathResolver,
    ],
    templateUrl: './upsert-aggregated-roi-settings.component.html',
    styleUrl: './upsert-aggregated-roi-settings.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertAggregatedRoiSettingsComponent implements OnInit {
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<{ restaurantIdForCreationWatcher: string | null }> = new EventEmitter<{
        restaurantIdForCreationWatcher: string | null;
    }>();
    readonly isFromModal: InputSignal<boolean> = input(false);
    readonly isFromNotification: InputSignal<boolean> = input(false);

    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _store = inject(Store);
    private readonly _fb = inject(FormBuilder);
    private readonly _translateService = inject(TranslateService);
    private readonly _roiSettingsService = inject(RoiSettingsService);
    private readonly _similarRestaurantsService = inject(SimilarRestaurantsService);
    private readonly _roiNotificationsContext = inject(RoiNotificationsContext);
    private readonly _roiService = inject(RoiService);
    private readonly _injector = inject(Injector);
    private readonly _screenSizeService = inject(ScreenSizeService);

    readonly roiContext = inject(RoiContext);

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });

    readonly SvgIcon = SvgIcon;
    readonly RoiFormFieldName = RoiFormFieldName;
    readonly isSavingRoiSettings = signal(false);
    readonly currencyOptions = Object.values(Currency);
    readonly revenueOptions: RevenueOption[] = RoiRevenueOptions;

    readonly currentUser$ = this._store.select(selectUserInfos);

    readonly restaurants$: Observable<Restaurant[]> = combineLatest([
        this._store.select(selectOwnRestaurants),
        this.currentUser$,
        toObservable(this.isFromModal),
    ]).pipe(
        filter(([restaurants, user]) => isNotNil(user) && !!restaurants.length),
        map(
            ([restaurants, user, isFromModal]) =>
                restaurants
                    .filter((restaurant) => !restaurant.isBrandBusiness() && (user?.isAdmin() || isFromModal || restaurant.roiActivated))
                    .sort(sortRestaurantsByInternalNameThenName) ?? []
        )
    );

    readonly restaurants: Signal<Restaurant[]> = toSignal(this.restaurants$, { initialValue: [] });
    readonly restaurantsWithoutRoiSettings: Signal<Restaurant[]> = computed(() =>
        this.restaurants().filter(
            (restaurant) =>
                !this.isFormControlFilledForRestaurant()(restaurant._id) || !this.isFormControlValidForRestaurant()(restaurant._id)
        )
    );

    public isExpansionPanelOpened: boolean;

    readonly categoriesOptions: SimilarRestaurantCategory[] = Object.values(SimilarRestaurantCategory).sort(
        (a: SimilarRestaurantCategory, b: SimilarRestaurantCategory) =>
            this._enumTranslatePipe
                .transform(a, 'similar_restaurants_categories')
                .localeCompare(this._enumTranslatePipe.transform(b, 'similar_restaurants_categories'))
    );

    readonly currencyForm: WritableSignal<FormControl> = signal(
        new FormControl<Currency>(this.roiContext.globalCurrency(), [Validators.required])
    );
    readonly roiSettingsControls: Signal<{ [key: string]: FormGroup<RoiSettingsForm> }> = computed(() => {
        const controls: { [key: string]: FormGroup<RoiSettingsForm> } = {};
        this.restaurants().forEach((restaurant) => {
            const foundRoiSettings = this.roiContext
                .restaurantsRoiSettings()
                .find((roiSettings) => roiSettings.restaurantId === restaurant._id.toString());
            const foundCategory =
                this.roiContext.restaurantsCategories().find(({ restaurantId }) => restaurantId === restaurant._id)?.category ?? null;
            const foundAssociatedRevenue =
                foundRoiSettings && isNotNil(foundRoiSettings.minRevenue) && isNotNil(foundRoiSettings.maxRevenue)
                    ? { min: foundRoiSettings.minRevenue, max: foundRoiSettings.maxRevenue }
                    : null;
            controls[restaurant._id] = this._fb.group({
                averageTicket: [(foundRoiSettings?.averageTicket ?? null) as number | null, Validators.required],
                revenue: [foundAssociatedRevenue as RevenueOption | null, Validators.required],
                category: [foundCategory as SimilarRestaurantCategory | null, Validators.required],
            });
        });
        return controls;
    });

    readonly isFormControlValidForRestaurant: Signal<(restaurantId: string) => boolean> = computed(
        () =>
            (restaurantId: string): boolean =>
                (this.roiSettingsControls().hasOwnProperty(restaurantId) && this.roiSettingsControls()[restaurantId].valid) ||
                (!this.roiSettingsControls()[restaurantId].get('averageTicket')?.value &&
                    !this.roiSettingsControls()[restaurantId].get('revenue')?.value)
    );

    readonly isFormControlFilledForRestaurant: Signal<(restaurantId: string) => boolean> = computed(
        () =>
            (restaurantId: string): boolean =>
                this.roiSettingsControls().hasOwnProperty(restaurantId) &&
                !!this.roiSettingsControls()[restaurantId].get('averageTicket')?.value &&
                !!this.roiSettingsControls()[restaurantId].get('revenue')?.value &&
                !!this.roiSettingsControls()[restaurantId].get('category')?.value
    );

    readonly requiredFieldMessage: Signal<(restaurantId: string, formControlName: RoiFormFieldName) => string | undefined> = computed(
        () =>
            (restaurantId: string, formControlName: RoiFormFieldName): string | undefined =>
                !this.isFormControlValidForRestaurant()(restaurantId) &&
                !this.roiSettingsControls()[restaurantId].get(formControlName)?.value
                    ? this._translateService.instant('common.required_field')
                    : undefined
    );

    readonly showDuplicateModal = signal(false);
    readonly currentDuplicatedRoiSettings: WritableSignal<
        (Omit<IRoiSettings, 'createdAt' | 'updatedAt'> & { category: SimilarRestaurantCategory | null }) | null
    > = signal(null);
    readonly isChecked = computed(
        () =>
            (restaurantId: string): boolean =>
                restaurantId === this.currentDuplicatedRoiSettings()?.restaurantId ||
                this._restaurantIdsToDuplicateTo().includes(restaurantId)
    );
    readonly areAllRestaurantsChecked = computed(() => {
        const currentRestaurantId = this.currentDuplicatedRoiSettings()?.restaurantId;
        if (!currentRestaurantId) {
            return false;
        }
        const checkableRestaurants = this.restaurants().filter((restaurant) => restaurant.id !== currentRestaurantId);

        return checkableRestaurants.every((checkableRestaurant) => this._restaurantIdsToDuplicateTo().includes(checkableRestaurant.id));
    });

    readonly trackByIdFn = TrackByFunctionFactory.get('id');

    readonly shouldShowDuplicateButtonTooltip: WritableSignal<boolean> = signal(false);
    readonly initialRestaurantsWithValidRoiSettings: WritableSignal<Restaurant[]> = signal(this.restaurantsWithValidRoiSettings());

    private readonly _DUPLICATE_BUTTON_TOOLTIP_DISPLAY_TIMEOUT = 2000;
    private readonly _roiSettingsDuplicateTooltipShownCount: WritableSignal<number> = signal(0);

    private readonly _restaurantIdsToDuplicateTo: WritableSignal<string[]> = signal([]);
    private readonly _roiSettingsControlsBeforeDuplication: WritableSignal<{ [key: string]: FormGroup<RoiSettingsForm> }> = signal({});

    ngOnInit(): void {
        runInInjectionContext(this._injector, () => {
            effect(
                () => {
                    const newRestaurants = this.restaurants();
                    this.initialRestaurantsWithValidRoiSettings.set(this.restaurantsWithValidRoiSettings(newRestaurants));
                },
                { allowSignalWrites: true }
            );
        });

        this._initCloseModalOnClick();
        if (this.isFromNotification() || !this.isFromModal()) {
            this._initShouldShowDuplicateButtonTooltip();
        }
    }

    cancel(): void {
        return this.onCancel.emit();
    }

    currencyDisplayWith(currency: Currency): string {
        return getCurrencySymbol(currency);
    }

    revenueDisplayWith(revenue: RevenueOption): string {
        return displayRevenue(revenue);
    }

    categoryDisplayWith = (category: SimilarRestaurantCategory): string =>
        this._enumTranslatePipe.transform(category, 'similar_restaurants_categories');

    onAverageTicketChange(restaurantId: string, newAverageTicket: number | null): void {
        this.roiSettingsControls()[restaurantId].get('averageTicket')?.setValue(newAverageTicket);
    }

    getRestaurantSubtext = (restaurant: Restaurant): string =>
        restaurant.isBrandBusiness() ? this._translateService.instant('common.brand_account') : restaurant.getFullFormattedAddress();

    saveManyRoiSettings(): void {
        this.isSavingRoiSettings.set(true);

        const currency = this.currencyForm().value;
        if (!currency) {
            return;
        }

        const previouslyHadRoiSettings = !!this.initialRestaurantsWithValidRoiSettings().length;
        const filledRestaurantIds = Object.keys(this.roiSettingsControls()).filter((key) => this.isFormControlFilledForRestaurant()(key));
        const roiSettingsData: (UpsertRoiSettingsBodyDto & { restaurantId: string; category: SimilarRestaurantCategory })[] =
            filledRestaurantIds
                .map((restaurantId) => {
                    const formControlForRestaurant = this.roiSettingsControls()[restaurantId];
                    const averageTicket = formControlForRestaurant.get('averageTicket')?.value;
                    const minRevenue = formControlForRestaurant.get('revenue')?.value?.min;
                    const maxRevenue = formControlForRestaurant.get('revenue')?.value?.max;
                    const category = formControlForRestaurant.get('category')?.value;

                    const hasChanged = this._checkSettingsHasChanged(restaurantId, {
                        currency,
                        averageTicket,
                        minRevenue,
                        maxRevenue,
                        category,
                    });

                    return averageTicket && minRevenue && maxRevenue && category && hasChanged
                        ? {
                              currency,
                              averageTicket,
                              minRevenue,
                              maxRevenue,
                              restaurantId,
                              category,
                          }
                        : null;
                })
                .filter(isNotNil);

        if (!roiSettingsData.length) {
            this.isSavingRoiSettings.set(false);
            this._roiNotificationsContext.emitReload();
            this.cancel();
            return;
        }

        forkJoin([
            ...roiSettingsData
                .map((roiSettings) => [
                    this._roiSettingsService.upsert(roiSettings.restaurantId, roiSettings),
                    this._similarRestaurantsService.update(roiSettings.restaurantId, roiSettings.category),
                ])
                .flat(),
        ]).subscribe({
            next: () => {
                this.isSavingRoiSettings.set(false);
                const oldestFilledRestaurantId = minBy(
                    this.restaurants().map(({ _id, createdAt }) => ({ _id, createdAt: new Date(createdAt).getTime() })),
                    'createdAt'
                )?._id;
                const shouldShowCreationWatcher = !previouslyHadRoiSettings && oldestFilledRestaurantId;
                this.onSave.emit({
                    restaurantIdForCreationWatcher: shouldShowCreationWatcher ? oldestFilledRestaurantId : null,
                });
                this._roiNotificationsContext.emitReload();
                if (shouldShowCreationWatcher) {
                    this._roiService.startInsightsWatcher(oldestFilledRestaurantId);
                }
            },
            error: (err) => {
                console.warn(err);
                this.isSavingRoiSettings.set(false);
            },
        });
    }

    openDuplicateDropdown(event: Partial<MouseEvent>, restaurantId: string): void {
        this._roiSettingsControlsBeforeDuplication.set(cloneDeep(this.roiSettingsControls()));
        this.showDuplicateModal.set(true);
        const associatedRoiSettingsControl = this.roiSettingsControls()[restaurantId];
        this.currentDuplicatedRoiSettings.set({
            restaurantId,
            currency: this.currencyForm().value,
            averageTicket: associatedRoiSettingsControl.get('averageTicket')?.value ?? 0,
            minRevenue: associatedRoiSettingsControl.get('revenue')?.value?.min ?? 0,
            maxRevenue: associatedRoiSettingsControl.get('revenue')?.value?.max ?? 0,
            category: associatedRoiSettingsControl.get('category')?.value ?? null,
        });
        this._setDuplicateModalPosition(event);
    }

    toggleDuplicate(checked: boolean, restaurantId: string): void {
        if (checked) {
            this._restaurantIdsToDuplicateTo.update((currentRestaurantIds) => {
                if (!currentRestaurantIds.includes(restaurantId)) {
                    currentRestaurantIds.push(restaurantId);
                }
                return [...currentRestaurantIds];
            });

            this._pasteRoiSettings(restaurantId);
        } else {
            this._restaurantIdsToDuplicateTo.update((currentRestaurantIds) => {
                const index = currentRestaurantIds.findIndex((currentRestaurantId) => currentRestaurantId === restaurantId);
                if (index >= 0) {
                    currentRestaurantIds.splice(index, 1);
                }
                return [...currentRestaurantIds];
            });

            this._resetRoiSettings(restaurantId);
        }
    }

    toggleAllDuplicate(event: MatCheckboxChange): void {
        const currentRestaurantId = this.currentDuplicatedRoiSettings()?.restaurantId;
        if (!currentRestaurantId) {
            return;
        }
        const checkableRestaurants = this.restaurants().filter((restaurant) => restaurant.id !== currentRestaurantId);

        checkableRestaurants.forEach((restaurant) => this.toggleDuplicate(event.checked, restaurant._id));
    }

    hasAtLeastTwoRestaurants(): boolean {
        return Object.keys(this.roiSettingsControls()).filter((key) => this.isFormControlFilledForRestaurant()(key)).length >= 2;
    }

    isGlobalFormValid(): boolean {
        if (!this.isFromModal()) {
            return (
                this.currencyForm().valid &&
                Object.keys(this.roiSettingsControls()).every((key) => this.isFormControlValidForRestaurant()(key)) &&
                this.hasAtLeastTwoRestaurants()
            );
        } else {
            return (
                this.currencyForm().valid &&
                Object.keys(this.roiSettingsControls()).every((key) => this.isFormControlValidForRestaurant()(key))
            );
        }
    }

    restaurantsWithValidRoiSettings(restaurants?: Restaurant[]): Restaurant[] {
        return (restaurants ?? this.restaurants()).filter(
            (restaurant) =>
                this.isFormControlFilledForRestaurant()(restaurant._id) && this.isFormControlValidForRestaurant()(restaurant._id)
        );
    }

    private _pasteRoiSettings(restaurantId: string): void {
        const newRoiSettingsToPaste = this.currentDuplicatedRoiSettings();
        if (newRoiSettingsToPaste) {
            this.roiSettingsControls()[restaurantId].get('averageTicket')?.setValue(newRoiSettingsToPaste.averageTicket);
            this.roiSettingsControls()[restaurantId].get('category')?.setValue(newRoiSettingsToPaste.category);
            this.roiSettingsControls()
                [restaurantId].get('revenue')
                ?.setValue({ min: newRoiSettingsToPaste.minRevenue, max: newRoiSettingsToPaste.maxRevenue });
        }
    }

    private _resetRoiSettings(restaurantId: string): void {
        const roiSettingsBeforeDuplication = this._roiSettingsControlsBeforeDuplication()[restaurantId];
        this.roiSettingsControls()
            [restaurantId].get('averageTicket')
            ?.setValue(roiSettingsBeforeDuplication.get('averageTicket')?.value ?? null);
        this.roiSettingsControls()
            [restaurantId].get('category')
            ?.setValue(roiSettingsBeforeDuplication.get('category')?.value ?? null);
        this.roiSettingsControls()
            [restaurantId].get('revenue')
            ?.setValue(roiSettingsBeforeDuplication.get('revenue')?.value ?? null);
    }

    private _setDuplicateModalPosition(event: Partial<MouseEvent>): void {
        setTimeout(() => {
            const duplicateModal = <HTMLElement>document.querySelector('#duplicateModalTemplate');
            const onTop = (event.clientY || 0) > 500;
            const style = onTop ? 'top: -295px;' : 'top: 36px;';
            duplicateModal.style.cssText = style;
        }, 50);
    }

    private _initCloseModalOnClick(): void {
        document?.addEventListener('click', (event) => this._onClick(event));
    }

    private _onClick(event: Event): void {
        const target = <HTMLElement>event.target;
        if (!target.closest('#duplicateModalTemplate') && !target.closest('#duplicateModalButton')) {
            this._closeDuplicateModal();
        }
        if (this.shouldShowDuplicateButtonTooltip() && !target.closest('#duplicateTooltipModal')) {
            this.shouldShowDuplicateButtonTooltip.set(false);
            localStorage.setItem(
                LocalStorageKey.ROI_SETTINGS_DUPLICATE_TOOLTIP_SHOWN_COUNT,
                `${this._roiSettingsDuplicateTooltipShownCount() + 1}`
            );
        }
    }

    private _closeDuplicateModal(): void {
        this.showDuplicateModal.set(false);
        this._roiSettingsControlsBeforeDuplication.set({});
        this._restaurantIdsToDuplicateTo.set([]);
        this.currentDuplicatedRoiSettings.set(null);
    }

    private _checkSettingsHasChanged(restaurantId: string, { averageTicket, minRevenue, maxRevenue, currency, category }): boolean {
        const foundRoiSettings = this.roiContext.restaurantsRoiSettings().find((roiSettings) => roiSettings.restaurantId === restaurantId);
        const foundCategory = this.roiContext
            .restaurantsCategories()
            .find((restaurantCategory) => restaurantCategory.restaurantId === restaurantId);
        return (
            foundRoiSettings?.currency !== currency ||
            foundRoiSettings?.averageTicket !== averageTicket ||
            foundRoiSettings?.minRevenue !== minRevenue ||
            foundRoiSettings?.maxRevenue !== maxRevenue ||
            foundCategory?.category !== category
        );
    }

    private _initShouldShowDuplicateButtonTooltip(): void {
        setTimeout(() => {
            const hasMoreThanFiveRestaurants = this.restaurantsWithoutRoiSettings().length >= 3;
            this._roiSettingsDuplicateTooltipShownCount.set(
                parseInt(localStorage.getItem(LocalStorageKey.ROI_SETTINGS_DUPLICATE_TOOLTIP_SHOWN_COUNT) ?? '0', 10)
            );
            const hasSeenPopupLessThanTwice = this._roiSettingsDuplicateTooltipShownCount() < 2;
            this.shouldShowDuplicateButtonTooltip.set(hasMoreThanFiveRestaurants && hasSeenPopupLessThanTwice);
            if (this.shouldShowDuplicateButtonTooltip()) {
                this._initCloseModalOnClick();
            }
        }, this._DUPLICATE_BUTTON_TOOLTIP_DISPLAY_TIMEOUT);
    }
}
