import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from ':environments/environment';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { ApiResult } from ':shared/models';

interface LoginInfo {
    email: string;
    password: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/`;
    _redirectUrl: string | null;

    constructor(
        private readonly _http: HttpClient,
        private readonly _jwtHelper: JwtHelperService
    ) {}

    public login(params: LoginInfo): Observable<ApiResult> {
        return this._http.post(`${this.API_BASE_URL}users/login`, params).pipe(
            map((res: ApiResult) => {
                localStorage.setItem(LocalStorageKey.JWT_TOKEN, res.data.token);
                return res;
            })
        );
    }

    public initSession(): Observable<void> {
        return this._http.get<void>(`${this.API_BASE_URL}users/init-session`, { withCredentials: true });
    }

    public create(params: LoginInfo): Observable<ApiResult> {
        return this._http.post(`${this.API_BASE_URL}users/`, params).pipe(map((res: ApiResult) => res));
    }

    public confirm(userId: string, token: string): Observable<ApiResult> {
        return this._http.post(`${this.API_BASE_URL}users/confirm`, { userId, token }).pipe(map((res: ApiResult) => res));
    }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem(LocalStorageKey.JWT_TOKEN);
        // Check whether the token is expired
        return !this._jwtHelper.isTokenExpired(token);
    }

    public logout$(): Observable<ApiResult> {
        return this._http.post<ApiResult>(`${this.API_BASE_URL}users/logout`, {}, { withCredentials: true });
    }

    public sendResetEmail(email: string): Observable<ApiResult<void>> {
        return this._http.post<ApiResult<void>>(`${this.API_BASE_URL}users/password/sendResetEmail`, { email: email });
    }

    public resetPassword(userId: string, token: string, password: string): Observable<ApiResult<void>> {
        return this._http.post<ApiResult<void>>(`${this.API_BASE_URL}users/password/${userId}/${token}/reset`, { password: password });
    }

    public setRedirectUrl(url: string | null): void {
        this._redirectUrl = url;
    }

    public getRedirectUrl(): string {
        return this._redirectUrl ? decodeURIComponent(this._redirectUrl) : '';
    }
}
