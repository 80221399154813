<div [matTooltip]="tooltip()" [ngClass]="{ 'hover:cursor-not-allowed': disabled() || loading() }">
    <button
        class="{{ themeCssClass() }} {{ buttonClasses() }}"
        mat-raised-button
        [id]="id()"
        [disabled]="disabled()"
        [ngClass]="{ 'hover:!cursor-not-allowed': disabled() || loading() }"
        [attr.data-testid]="testId()"
        (click)="onClick()">
        @if (loading()) {
            <app-malou-spinner
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                size="small"
                color="white"></app-malou-spinner>
        }

        <div [ngClass]="{ invisible: loading() }">
            @if (textTemplate(); as textTemplate) {
                <ng-container *ngTemplateOutlet="textTemplate"></ng-container>
            } @else {
                {{ text() }}
            }
        </div>
    </button>
</div>
