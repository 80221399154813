import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    Injector,
    input,
    OnInit,
    runInInjectionContext,
    signal,
    Signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BarElement, Chart, ChartDataset, ChartOptions, ChartType, LegendItem, Plugin, Tick, TooltipItem } from 'chart.js';
import { capitalize, ceil, groupBy, max, round, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { NgChartsModule } from 'ng2-charts';
import { combineLatest, filter, forkJoin, map, of, switchMap, tap } from 'rxjs';

import { isNotNil, Locale } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { RoiService } from ':modules/roi/roi.service';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import {
    ChartDataArray,
    formatDate,
    formatStringMonth,
    groupAsPeriods,
    isTodayInFirstThreeDaysOfMonth,
    malouChartColorBluePurple,
    malouChartColorLighterBlue,
    malouChartColorPrimary5Percent,
    malouChartColorPurple,
    malouChartColorRed5Percent,
    malouChartColorText2,
} from ':shared/helpers';
import { getSelectedMonthsNumberFromTimeScaleFilter, MalouTimeScalePeriod, Restaurant, SpecialTimePeriod } from ':shared/models';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import * as StatisticsSelector from '../../store/statistics.selectors';

interface EstimatedCustomersAndPerformanceData {
    performanceScores: ChartDataArray;
    estimatedCustomers: ChartDataArray;
    isLastMonthAlmostReady?: boolean;
    restaurantClosedData: RestaurantClosedForMonthData[];
}

interface RestaurantClosedForMonthData {
    hasBeenClosed: boolean;
    closedDays: Date[];
}

type LineOrBarChartType = Extract<ChartType, 'line' | 'bar'>;
const FullMonthDisplayFormat = 'MMMM';

const MEDIUM_TOOLTIP_TAB = '   ';

interface TicksSetup {
    max: number;
    min: number;
    stepSize: number;
}

@Component({
    selector: 'app-monthly-estimated-customers-and-performance-chart',
    standalone: true,
    imports: [NgClass, NgChartsModule, NgTemplateOutlet, SkeletonComponent],
    templateUrl: './monthly-estimated-customers-and-performance-chart.component.html',
    styleUrl: './monthly-estimated-customers-and-performance-chart.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthlyEstimatedCustomersAndPerformanceChartComponent implements OnInit {
    readonly isParentLoading = input.required<boolean>();

    readonly EMPTY_CHART_DATA: EstimatedCustomersAndPerformanceData = {
        estimatedCustomers: [],
        performanceScores: [],
        restaurantClosedData: [],
        isLastMonthAlmostReady: false,
    };
    readonly CHART_TYPE: LineOrBarChartType = 'line';
    readonly isChartLoading: WritableSignal<boolean> = signal(false);
    readonly chartError: WritableSignal<string | null> = signal(null);
    readonly chartMonths: WritableSignal<Date[]> = signal([]);
    readonly chartData: WritableSignal<EstimatedCustomersAndPerformanceData> = signal({
        estimatedCustomers: [],
        performanceScores: [],
        restaurantClosedData: [],
        isLastMonthAlmostReady: false,
    });

    readonly chartDataSets: Signal<ChartDataset<LineOrBarChartType, ChartDataArray>[]> = computed(() =>
        this._computeChartData(this.chartData())
    );
    readonly chartLabels: Signal<string[]> = computed(() => this._computeChartLabels(this.chartMonths()));
    readonly chartOptions: Signal<ChartOptions<LineOrBarChartType>> = computed(() => this._computeChartOptions());
    readonly shouldShowRestaurantClosedData: Signal<boolean> = computed(
        () => !this.chartData().restaurantClosedData.every(({ hasBeenClosed }) => !hasBeenClosed)
    );

    readonly CENTERED_BAR_CHART: Plugin = this._centerBarChartPlugin();
    readonly TEXT_LAST_COLUMN: Plugin = this._addTextOnLastColumnPlugin();
    readonly LEGEND_PLUGIN: Plugin = this._legendPlugin();

    private readonly _lastMonthAlmostReadyDatasetIndex: Signal<number> = computed(() => {
        const isLastDataset = !this.shouldShowRestaurantClosedData();
        const offset = isLastDataset ? 0 : -1;
        return this.chartData().isLastMonthAlmostReady ? this.chartDataSets().length - 1 + offset : -1;
    });

    private readonly _ticksSetup: WritableSignal<TicksSetup | null> = signal(null);

    private readonly _shortNumberPipe = inject(ShortNumberPipe);
    private readonly _translateService = inject(TranslateService);
    private readonly _screenSizeService = inject(ScreenSizeService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _roiService = inject(RoiService);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _store = inject(Store);
    private readonly _injector = inject(Injector);

    private readonly _selectedTimeScaleFilter$ = this._store.select(StatisticsSelector.selectTimeScaleFilter);

    private readonly _selectedMonths: Signal<number> = toSignal(
        this._selectedTimeScaleFilter$.pipe(map((timeScale) => getSelectedMonthsNumberFromTimeScaleFilter(timeScale))),
        {
            initialValue: getSelectedMonthsNumberFromTimeScaleFilter(MalouTimeScalePeriod.LAST_SIX_MONTHS),
        }
    );

    private readonly _MINIMUM_CLOSED_DAYS_TO_SHOW_IN_GRAPH = 5;

    readonly Y_AXIS_SCORE_TITLE: Plugin = this._yAxisTitlePlugin(
        this._translateService.instant('roi.estimated_customers_and_performance_chart.score_y_axis_title'),
        this._getPaddingBasedOnLanguage(true),
        true
    );
    readonly Y_AXIS_CUSTOMERS_TITLE: Plugin = this._yAxisTitlePlugin(
        this._translateService.instant('roi.estimated_customers_and_performance_chart.customer_y_axis_title'),
        this._getPaddingBasedOnLanguage()
    );

    readonly POINT_OPTIONS = {
        pointRadius: 6,
        pointHoverRadius: 8,
        pointBorderWidth: 2,
        showLine: false,
    };

    ngOnInit(): void {
        this._getEstimatedCustomersAndPerformanceChartData();

        runInInjectionContext(this._injector, () => {
            effect(
                () => {
                    this._ticksSetup.set(this._computeTicksSetup(this.chartDataSets()));
                },
                {
                    allowSignalWrites: true,
                }
            );
        });
    }

    private _getEstimatedCustomersAndPerformanceChartData(): void {
        combineLatest([this._restaurantsService.restaurantSelected$, this._selectedTimeScaleFilter$])
            .pipe(
                filter(isNotNil),
                tap(() => this.isChartLoading.set(true)),
                switchMap(([restaurant, _]: [Restaurant, MalouTimeScalePeriod | undefined]) =>
                    forkJoin([
                        this._roiService
                            .getEstimatedCustomersForRestaurantsForNLastMonths([restaurant._id], this._selectedMonths())
                            .pipe(map((res) => res.data)),
                        this._roiService
                            .getPerformanceScoreForRestaurantsForNLastMonths([restaurant._id], this._selectedMonths())
                            .pipe(map((res) => res.data)),
                        of(restaurant),
                    ])
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: ([estimatedCustomersData, performanceScoresData, restaurant]) => {
                    const performanceScoresDataForRestaurant = sortBy(performanceScoresData[0]?.performanceScoresByMonth, 'monthStartDate');
                    const additionalCustomersForRestaurant = sortBy(
                        estimatedCustomersData[0]?.additionalCustomersByMonth.map((monthlyCustomers) => ({
                            ...monthlyCustomers,
                            monthStartDate: new Date(monthlyCustomers.monthStartDate),
                        })),
                        'monthStartDate'
                    );
                    const months: Date[] = sortBy(performanceScoresDataForRestaurant.map((data) => new Date(data.monthStartDate)));
                    const restaurantClosedData = this._initRestaurantClosedData(restaurant, months);

                    const isTodayStartOfMonth = isTodayInFirstThreeDaysOfMonth();
                    if (isTodayStartOfMonth) {
                        months.push(DateTime.now().minus({ days: 4 }).startOf('month').toJSDate());
                    }
                    this.chartMonths.set(months);
                    this.chartData.set({
                        estimatedCustomers: additionalCustomersForRestaurant?.map(({ additionalCustomers }) => additionalCustomers) ?? [],
                        performanceScores: performanceScoresDataForRestaurant?.map(({ performanceScore }) => performanceScore),
                        isLastMonthAlmostReady: isTodayStartOfMonth,
                        restaurantClosedData,
                    });
                    this.chartError.set(null);
                    this.isChartLoading.set(false);
                },
                error: () => {
                    this.chartData.set(this.EMPTY_CHART_DATA);
                    this.chartError.set(this._translateService.instant('common.server_error'));
                    this.isChartLoading.set(false);
                },
            });
    }

    private _initRestaurantClosedData(restaurant: Restaurant, monthStartDates: Date[]): RestaurantClosedForMonthData[] {
        const specialHoursClosed = restaurant.specialHours.filter((specialHour) => specialHour.isClosed);
        const specialHoursClosedByMonth = groupBy(specialHoursClosed, (item: SpecialTimePeriod) => {
            const month = item.startDate.month;
            const year = item.startDate.year;
            return `${year}-${month}`;
        });

        const mappedSpecialHoursClosedByMonth = monthStartDates.map((monthStartDate) => {
            const monthKey = `${monthStartDate.getFullYear()}-${monthStartDate.getMonth()}`;
            const associatedMonthData = specialHoursClosedByMonth[monthKey];
            return {
                hasBeenClosed: associatedMonthData?.length >= this._MINIMUM_CLOSED_DAYS_TO_SHOW_IN_GRAPH,
                closedDays: associatedMonthData?.map((specialHour) => {
                    const date = specialHour.startDate;
                    return DateTime.utc(date.year, date.month + 1, date.day).toJSDate();
                }),
            };
        });
        return mappedSpecialHoursClosedByMonth;
    }

    private _computeChartData(data?: EstimatedCustomersAndPerformanceData | null): ChartDataset<LineOrBarChartType, ChartDataArray>[] {
        if (!data) {
            return [];
        }

        const defaultChartData: ChartDataset<LineOrBarChartType, ChartDataArray>[] = [
            {
                label:
                    this._translateService.instant('roi.estimated_customers_and_performance_chart.estimated_customers') +
                    MEDIUM_TOOLTIP_TAB,
                borderColor: malouChartColorPurple,
                backgroundColor: malouChartColorPurple,
                pointBorderColor: malouChartColorPurple,
                pointBackgroundColor: malouChartColorPurple,
                type: 'line',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis1',
                data: data.estimatedCustomers,
                ...(data.estimatedCustomers.length === 1 ? this.POINT_OPTIONS : {}),
            },
            {
                label:
                    this._translateService.instant('roi.estimated_customers_and_performance_chart.performance_score') + MEDIUM_TOOLTIP_TAB,
                borderColor: malouChartColorBluePurple,
                backgroundColor: malouChartColorBluePurple,
                type: 'bar',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis2',
                barThickness: 5,
                data: data.performanceScores,
            },
        ];
        if (data.isLastMonthAlmostReady) {
            const labelsLength = this.chartLabels().length;
            const fakeDataToCreateFullRectangleOnLastColumn = Array.from({ length: labelsLength }).map((_, i) =>
                i === labelsLength - 1 ? 100 : null
            );
            defaultChartData.push({
                borderColor: malouChartColorPrimary5Percent,
                backgroundColor: malouChartColorPrimary5Percent,
                type: 'bar',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis2',
                barPercentage: this.shouldShowRestaurantClosedData() ? 5 : 3.5,
                data: fakeDataToCreateFullRectangleOnLastColumn,
            });
        }
        if (this.shouldShowRestaurantClosedData()) {
            defaultChartData.push({
                label:
                    this._translateService.instant('roi.estimated_customers_and_performance_chart.restaurant_closed') + MEDIUM_TOOLTIP_TAB,
                borderColor: malouChartColorRed5Percent,
                backgroundColor: malouChartColorRed5Percent,
                type: 'bar',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis2',
                barPercentage: 1,
                data: data.restaurantClosedData.map(({ hasBeenClosed }) => (hasBeenClosed ? 100 : 0)),
            });
        }
        return defaultChartData;
    }

    private _computeChartLabels(labels?: Date[]): string[] {
        if (!labels) {
            return [];
        }
        return labels.map((date) => capitalize(formatStringMonth(date)));
    }

    private _computeChartOptions(): ChartOptions<LineOrBarChartType> {
        return {
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    filter: (tooltipItem: TooltipItem<LineOrBarChartType>): boolean =>
                        tooltipItem?.datasetIndex !== this._lastMonthAlmostReadyDatasetIndex() &&
                        !this._isTooltipForOpenedRestaurant(tooltipItem),
                    callbacks: {
                        label: (tooltipItem: TooltipItem<LineOrBarChartType>): string => this._computeTooltipLabel(tooltipItem),
                    },
                },
                legend: {
                    align: 'end',
                    labels: {
                        usePointStyle: true,
                        sort: this._sortLinesFirstInLegend,
                        filter: (legendItem: LegendItem): boolean => legendItem?.datasetIndex !== this._lastMonthAlmostReadyDatasetIndex(),
                    },
                    onClick: (): void => {},
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    time: {
                        tooltipFormat: FullMonthDisplayFormat,
                        isoWeekday: true,
                        displayFormats: {
                            month: FullMonthDisplayFormat,
                        },
                    },
                },
                yAxis1: {
                    axis: 'y',
                    type: 'linear',
                    min: this._ticksSetup()?.min,
                    max: this._ticksSetup()?.max,
                    ticks: {
                        stepSize: this._ticksSetup()?.stepSize,
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(round(tickValue as number)),
                    },
                },
                yAxis2: {
                    axis: 'y',
                    type: 'linear',
                    min: 0,
                    max: 100,
                    ticks: {
                        stepSize: 20,
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                    grid: {
                        display: true,
                        color: (context): string | undefined => {
                            if (context.tick.value === 0) {
                                return malouChartColorLighterBlue;
                            }
                        },
                    },
                    position: 'right',
                },
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 50,
                    bottom: 0,
                },
            },
        };
    }

    private _sortLinesFirstInLegend = (legendItemA: LegendItem, legendItemB: LegendItem): number => {
        const datasetA = this.chartDataSets()[legendItemA.datasetIndex ?? 0];
        const datasetB = this.chartDataSets()[legendItemB.datasetIndex ?? 0];
        if (datasetA.type === 'line' && datasetB.type !== 'line') {
            return -1;
        } else if (datasetA.type !== 'line' && datasetB.type === 'line') {
            return 1;
        } else {
            return 0;
        }
    };

    private _computeTooltipLabel = (item: TooltipItem<LineOrBarChartType>): string => {
        if (this.shouldShowRestaurantClosedData() && item.datasetIndex === this.chartDataSets().length - 1) {
            const closedDays = this.chartData().restaurantClosedData[item.dataIndex]?.closedDays;
            if (!closedDays?.length) {
                return '';
            }
            const periods = groupAsPeriods(closedDays);
            const mappedPeriods = periods
                .map(({ start, end }: { start: Date; end: Date | null }) =>
                    !!end
                        ? `${formatDate(start, false)} ${this._translateService.instant('common.until_short')} ${formatDate(end, false)}`
                        : formatDate(start, false)
                )
                .join(', ');
            return `${item.dataset.label?.replace(MEDIUM_TOOLTIP_TAB, '')}: ${mappedPeriods}`;
        }
        const valueAsNumber = parseInt(item.formattedValue, 10);
        if (item.datasetIndex === 1) {
            return `${item.dataset.label?.replace(MEDIUM_TOOLTIP_TAB, '')}: ${round(valueAsNumber, 0)}`;
        }
        return `${item.dataset.label?.replace(MEDIUM_TOOLTIP_TAB, '')}: ${this._prettyNumber(valueAsNumber)}`;
    };

    private _prettyNumber(num: number): string {
        const roundedToTens = num >= 10 ? round(num / 10) * 10 : num;
        return this._shortNumberPipe.transform(roundedToTens);
    }

    private _isTooltipForOpenedRestaurant = (item: TooltipItem<LineOrBarChartType>): boolean => {
        const isClosedRestaurantDataset = this.shouldShowRestaurantClosedData() && item.datasetIndex === this.chartDataSets().length - 1;
        return isClosedRestaurantDataset && !this.chartData().restaurantClosedData[item.dataIndex]?.hasBeenClosed;
    };

    private _computeTicksSetup(datasets: ChartDataset<LineOrBarChartType, ChartDataArray>[]): TicksSetup {
        const ticksNumber = 5;
        const similarLocationsMax = 0;
        const maxData: number = max([...datasets[0].data, similarLocationsMax]) || 1;
        const maxDataRounded = Math.ceil(maxData + 10);
        return {
            max: maxDataRounded,
            min: 0,
            stepSize: ceil(maxDataRounded / ticksNumber),
        };
    }

    private _centerBarChartPlugin(): Plugin {
        return {
            id: 'customCenterBarCharPlugin',
            beforeDatasetsDraw: (chart: Chart): void => {
                const xScale = chart.scales['xAxis'];
                chart.data.datasets.forEach((dataset, datasetIndex) => {
                    if (dataset.type === 'bar') {
                        dataset.data.forEach((_, dataIndex) => {
                            const centerOffset = xScale.getPixelForTick(dataIndex);
                            const bar = chart.getDatasetMeta(datasetIndex).data[dataIndex];
                            if (bar) {
                                const barWidth = (bar as any)?.width;
                                const isLastMonthAlmostReadyOffset =
                                    datasetIndex === this._lastMonthAlmostReadyDatasetIndex() ? barWidth / 8 : 0;
                                bar.x = centerOffset - isLastMonthAlmostReadyOffset;
                            }
                        });
                    }
                });
            },
        };
    }

    private _addTextOnLastColumnPlugin(): Plugin {
        return {
            id: 'customAddTextOnLastColumnPlugin',
            afterDraw: (chart: Chart): void => {
                if (this._screenSizeService.isPhoneScreen) {
                    return;
                }
                const datasetIndex = this._lastMonthAlmostReadyDatasetIndex();
                if (!datasetIndex || datasetIndex < 0) {
                    return;
                }
                const { ctx } = chart;
                ctx.save();
                const lastIndex = this.chartLabels()?.length - 1;
                const lastBar = chart.getDatasetMeta(datasetIndex).data[lastIndex] as BarElement;
                const centerX = lastBar.getCenterPoint().x;
                const centerY = lastBar.getCenterPoint().y;
                const maxWidth = (lastBar as any).width - 20;
                if (maxWidth < 70) {
                    return;
                }
                const offset = this._getOffsetBaseOnWidth(maxWidth);
                const fontSize = this._getFontBasedOnWidth(maxWidth);
                const lineHeight = this._getLineHeightOnWidth(maxWidth);
                ctx.textAlign = 'center';
                ctx.fillStyle = malouChartColorText2;

                const textOptions = [
                    {
                        font: `bold ${fontSize} Poppins`,
                        text: this._translateService.instant('roi.estimated_customers_and_performance_chart.few_days_left', {
                            month: this.chartLabels()[lastIndex],
                        }),
                        y: centerY - offset,
                    },
                    {
                        font: `italic ${fontSize} Poppins`,
                        text: this._translateService.instant('roi.estimated_customers_and_performance_chart.from_fourth_of_month'),
                        y: centerY + offset,
                    },
                ];

                ctx.textAlign = 'center';
                textOptions.forEach(({ font, text, y }) => {
                    ctx.beginPath();
                    ctx.font = font;
                    this._fillWithLineBreak({ context: ctx, text, x: centerX, y, fitWidth: maxWidth, lineHeight });
                    ctx.fill();
                });

                ctx.restore();
            },
        };
    }

    private _fillWithLineBreak({
        context,
        text,
        x,
        y,
        fitWidth,
        lineHeight = 20,
    }: {
        context: CanvasRenderingContext2D;
        text: string;
        x: number;
        y: number;
        fitWidth: number;
        lineHeight: number;
    }): void {
        const words = text.split(' ');
        let currentLine = 0;
        let currentText = '';

        for (const word of words) {
            const tempText = currentText ? `${currentText} ${word}` : word;
            const tempWidth = context.measureText(tempText).width;

            if (tempWidth > fitWidth) {
                context.fillText(currentText, x, y + lineHeight * currentLine);
                currentText = word;
                currentLine++;
            } else {
                currentText = tempText;
            }
        }

        if (currentText) {
            context.fillText(currentText, x, y + lineHeight * currentLine);
        }
    }

    private _getOffsetBaseOnWidth(width: number): number {
        if (width < 100) {
            return 65;
        } else if (width < 120) {
            return 55;
        } else if (width < 155) {
            return 45;
        }
        return 35;
    }

    private _getFontBasedOnWidth(width: number): string {
        return width >= 155 ? '12px' : '10px';
    }

    private _getLineHeightOnWidth(width: number): number {
        return width >= 155 ? 20 : 15;
    }

    private _yAxisTitlePlugin(title: string, padding: number, isScoreTitle: boolean = false): Plugin {
        return {
            id: 'yAxisTitlePlugin',
            afterDraw(chart: Chart): void {
                const {
                    ctx,
                    scales: { yAxis1, yAxis2 },
                } = chart;

                const yTop = yAxis1.top;
                const xStart = yAxis1.left;
                const xEnd = yAxis2.right;
                ctx.save();
                ctx.fillStyle = 'black';
                ctx.font = '10px Poppins';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                ctx.fillText(title, isScoreTitle ? xEnd - padding : xStart + padding, yTop - 10);
                ctx.restore();
            },
        };
    }

    private _legendPlugin(): Plugin {
        return {
            id: 'legendPlugin',
            afterDraw(chart: Chart): void {
                const { ctx, legend } = chart;
                if (!legend) {
                    return;
                }
                ctx.save();
                legend.top = 0;
                ctx.restore();
            },
        };
    }

    private _getPaddingBasedOnLanguage(isScore: boolean = false): number {
        switch (this._translateService.currentLang) {
            case Locale.EN:
                return 30;
            case Locale.IT:
                return isScore ? 35 : 20;
            case Locale.ES:
                return isScore ? 35 : 20;
            default:
                return 20;
        }
    }
}
