import { NotificationType } from '@malou-io/package-utils';

import { CommentNotification } from ':core/components/notification-center/models/comment-notification.model';
import { MentionNotification } from ':core/components/notification-center/models/mention-notification.mode';
import { MessageNotification } from ':core/components/notification-center/models/message-notification.model';
import { NegativeReviewReminderNotification } from ':core/components/notification-center/models/negative-review-reminder-notification.model';
import { Notification, NotificationProps } from ':core/components/notification-center/models/notification.model';
import { PostErrorNotification } from ':core/components/notification-center/models/post-error-notification.model';
import { PostSuggestionNotification } from ':core/components/notification-center/models/post-suggestion-notification.model';
import { ReviewNotification } from ':core/components/notification-center/models/review-notification.model';
import { RoiActivatedNotification } from ':core/components/notification-center/models/roi-activated-notification.model';
import { SpecialHourNotification } from ':core/components/notification-center/models/special-hour-notification.model';

export class NotificationFactory {
    static create(props: NotificationProps): Notification {
        if (props.type === NotificationType.REVIEW_REPLY_REMINDER) {
            return new NegativeReviewReminderNotification({ ...props, data: props.data });
        }

        if (props.type === NotificationType.REVIEW) {
            return new ReviewNotification({ ...props, data: props.data });
        }

        if (props.type === NotificationType.SPECIAL_HOUR) {
            return new SpecialHourNotification({ ...props, data: props.data });
        }

        if (props.type === NotificationType.POST_SUGGESTION) {
            return new PostSuggestionNotification({ ...props, data: props.data });
        }

        if (props.type === NotificationType.POST_ERROR) {
            return new PostErrorNotification({ ...props, data: props.data });
        }

        if (props.type === NotificationType.COMMENT) {
            return new CommentNotification({ ...props, data: props.data });
        }

        if (props.type === NotificationType.MENTION) {
            return new MentionNotification({ ...props, data: props.data });
        }

        if (props.type === NotificationType.NEW_MESSAGE) {
            return new MessageNotification({ ...props, data: props.data });
        }

        if (props.type === NotificationType.ROI_ACTIVATED) {
            return new RoiActivatedNotification({ ...props, data: props.data });
        }

        return new Notification(props);
    }
}
