<div
    [ngClass]="{
        'h-[90vh]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <app-close-without-saving-modal
        [ngClass]="{ hidden: !displayCloseModal }"
        (onConfirm)="confirmClose()"
        (onCancel)="displayCloseModal = false"></app-close-without-saving-modal>

    <div class="malou-dialog" [ngClass]="{ '!hidden': displayCloseModal }">
        <div class="malou-dialog__header">
            <div class="title">
                <div class="malou-text-18--bold text-malou-color-text-1">
                    {{ title }}
                </div>
                <div class="malou-text-12--regular text-malou-color-text-2">
                    {{ currentStep.subtitle }}
                </div>
            </div>
            <button class="malou-btn-icon" mat-icon-button (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body hide-scrollbar">
            <ng-template appDynamicComponent></ng-template>
        </div>

        @if (showButtons) {
            <div class="malou-dialog__footer">
                <div class="flex justify-end gap-x-3">
                    <button class="malou-btn-raised--secondary--alt !h-11 md:grow" mat-raised-button (click)="previousStep()">
                        {{ getSecondaryButtonText | applyPure: currentStepIndex }}
                    </button>
                    <button
                        class="malou-btn-raised--primary !h-11 md:grow"
                        mat-raised-button
                        [id]="currentStepIndex === steps.length - 1 ? (stepperModalData?.validateButtonId ?? '') : ''"
                        [disabled]="isNextButtonDisabled"
                        (click)="nextStep()">
                        {{ getPrimaryButtonText | applyPure: currentStepIndex }}
                    </button>
                </div>
            </div>
        }
    </div>
</div>
