import { Component, Inject, signal, WritableSignal } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { forkJoin, map, of, switchMap, take } from 'rxjs';

import { CsvInsightChart, InsightsChart, InsightsTab, isNotNil, PlatformKey } from '@malou-io/package-utils';

import { DownloadService } from ':core/services/download.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { InsightsService } from ':modules/statistics/insights.service';
import { selectDatesFilter } from ':modules/statistics/store/statistics.selectors';
import { ButtonComponent } from ':shared/components/button/button.component';
import { DownloadCsvInsightsService } from ':shared/components/download-insights-modal/download-csv-insights.service';
import { DownloadInsightsFooterPopinComponent } from ':shared/components/download-insights-modal/download-insights-footer-popin/download-insights-footer-popin.component';
import {
    chartByTab,
    ChartOptions,
    csvChartDataByTab,
    DownloadFormat,
    DownloadInsightsQueryParamParams,
    DownloadStatsFormData,
    FileExtension,
} from ':shared/components/download-insights-modal/download-insights.interface';
import { FooterPopinService } from ':shared/components/footer-popin/footer-popin.service';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { downloadFilesAsZip } from ':shared/helpers/download-files-as-zip';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

export interface DownloadInsightsModalData {
    tab: InsightsTab;
    filters: {
        dates: {
            startDate: Date;
            endDate: Date;
        };
        platforms?: PlatformKey[];
        nfcIds?: string[];
        restaurantIds?: string[];
    };
    chartOptions?: ChartOptions;
}

@Component({
    selector: 'app-download-insights-modal',
    templateUrl: './download-insights-modal.component.html',
    styleUrls: ['./download-insights-modal.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        TranslateModule,
        MatIconModule,
        ReactiveFormsModule,
        ButtonComponent,
        SelectChipListComponent,
        SelectComponent,
    ],
})
export class DownloadInsightsModalComponent {
    readonly SvgIcon = SvgIcon;
    formGroup: INullableFormGroup<DownloadStatsFormData>;
    readonly chartList: WritableSignal<InsightsChart[] | CsvInsightChart[]> = signal([]);
    readonly downloadFormat: WritableSignal<DownloadFormat> = signal(DownloadFormat.PDF);
    downloadFormatValues: DownloadFormat[] = Object.values(DownloadFormat);
    readonly chartControlName: WritableSignal<string> = signal('pdfCharts');

    readonly isDownloading = signal<boolean>(false);

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: DownloadInsightsModalData,
        private readonly _dialogRef: MatDialogRef<DownloadInsightsModalComponent>,
        private readonly _formBuilder: FormBuilder,
        private readonly _restaurantService: RestaurantsService,
        private readonly _translateService: TranslateService,
        private readonly _toastService: ToastService,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _insightsService: InsightsService,
        private readonly _downloadService: DownloadService,
        private readonly _footerPopinService: FooterPopinService,
        private readonly _downloadCsvInsightsService: DownloadCsvInsightsService,
        private readonly _store: Store
    ) {
        this.chartList.set(chartByTab[data.tab]);
        this.formGroup = this._formBuilder.group({
            pdfCharts: new FormControl<InsightsChart[]>(chartByTab[data.tab], [Validators.required]),
            csvCharts: new FormControl<CsvInsightChart[]>(csvChartDataByTab[data.tab], [Validators.required]),
            downloadFormat: new FormControl<DownloadFormat>(DownloadFormat.PDF),
        });
    }

    showFooter(): string {
        return this._footerPopinService.open(DownloadInsightsFooterPopinComponent, {});
    }

    hideFooter(footerId: string): void {
        this._footerPopinService.close(footerId);
    }

    onClose(): void {
        this._dialogRef.close();
    }

    chartsDisplayWith = (chart: InsightsChart | CsvInsightChart): string =>
        this.downloadFormat() === DownloadFormat.CSV
            ? this._enumTranslatePipe.transform(chart, 'csv_insights_chart')
            : this._enumTranslatePipe.transform(chart, 'insights_chart');

    downloadFormatDisplayWith = (downloadFormat: DownloadFormat): string =>
        this._enumTranslatePipe.transform(downloadFormat, 'download_insights_format');

    onDownloadFormatChange(format: DownloadFormat): void {
        this.downloadFormat.set(format);
        this._resetForm();
        if (format === DownloadFormat.CSV) {
            this.chartControlName.set('csvCharts');
            this.chartList.set(csvChartDataByTab[this.data.tab]);
        } else {
            this.chartControlName.set('pdfCharts');
            this.chartList.set(chartByTab[this.data.tab]);
        }
    }

    onDownload(): void {
        const downloadFormat = this.formGroup.get('downloadFormat')?.value;
        if (downloadFormat === DownloadFormat.PDF) {
            this._downloadPdf();
        } else {
            this._downloadCsv();
        }
    }

    private _resetForm(): void {
        this.formGroup.get('pdfCharts')?.setValue(chartByTab[this.data.tab]);
        this.formGroup.get('csvCharts')?.setValue(csvChartDataByTab[this.data.tab]);
    }

    private _buildCallbackUrl(): string | null {
        const restaurantId = this._restaurantService.currentRestaurant?._id;
        const rawFormData = this.formGroup.getRawValue();
        const queryParams: DownloadInsightsQueryParamParams = {
            displayedCharts: rawFormData.pdfCharts ?? [],
            chartOptions: this.data.chartOptions,
            startDate: this.data.filters.dates.startDate.toISOString(),
            endDate: this.data.filters.dates.endDate.toISOString(),
            platformKeys: this.data.filters.platforms,
            restaurantIds: this.data.filters.restaurantIds,
            nfcIds: this.data.filters.nfcIds,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        switch (this.data.tab) {
            case InsightsTab.SEO:
                return `statistics-pdf/${restaurantId}/seo?params=${JSON.stringify(queryParams)}`;
            case InsightsTab.E_REPUTATION:
                return `statistics-pdf/${restaurantId}/e-reputation?params=${JSON.stringify(queryParams)}`;
            case InsightsTab.SOCIAL_NETWORKS:
                return `statistics-pdf/${restaurantId}/social-networks?params=${JSON.stringify(queryParams)}`;
            case InsightsTab.BOOSTERS:
                return `statistics-pdf/${restaurantId}/boosters?params=${JSON.stringify(queryParams)}`;
            case InsightsTab.AGGREGATED_SEO:
                return `aggregated-statistics-pdf/seo?params=${JSON.stringify(queryParams)}`;
            case InsightsTab.AGGREGATED_E_REPUTATION:
                return `aggregated-statistics-pdf/e-reputation?params=${JSON.stringify(queryParams)}`;
            case InsightsTab.AGGREGATED_SOCIAL_NETWORKS:
                return `aggregated-statistics-pdf/social-networks?params=${JSON.stringify(queryParams)}`;
            case InsightsTab.AGGREGATED_BOOSTERS:
                return `aggregated-statistics-pdf/boosters?params=${JSON.stringify(queryParams)}`;
            default:
                return null;
        }
    }

    private _downloadPdf(): void {
        const callbackUrl = this._buildCallbackUrl();
        const jwtToken = localStorage.getItem(LocalStorageKey.JWT_TOKEN);
        if (!callbackUrl || !jwtToken) {
            this._toastService.openErrorToast('common.error');
            return;
        }

        const footerId = this.showFooter();
        this.isDownloading.set(true);
        this.formGroup.disable();
        this._insightsService
            .getInsightsPdfUrl({
                callbackUrl,
                jwtToken,
                restaurantId: this._restaurantService.currentRestaurant?._id,
                insightTab: this.data.tab,
            })
            .pipe(switchMap((pdfUrl) => this._downloadService.downloadFile(pdfUrl)))
            .subscribe({
                next: (blob) => {
                    this.hideFooter(footerId);
                    this.isDownloading.set(false);
                    this.formGroup.enable();
                    const filename = this._getInsightTabFilename(this.data.tab, FileExtension.PDF);
                    saveAs(blob, filename);
                },
                error: () => {
                    this.hideFooter(footerId);
                    this.isDownloading.set(false);
                    this.formGroup.enable();
                    this._toastService.openErrorToast(this._translateService.instant('common.unknown_error'));
                },
            });
        this.onClose();
    }

    private _downloadCsv(): void {
        const rawFormData = this.formGroup.getRawValue();
        const csvCharts = rawFormData.csvCharts as CsvInsightChart[];
        const footerId = this.showFooter();
        this.isDownloading.set(true);
        this.formGroup.disable();
        forkJoin(
            csvCharts.map((chart) =>
                forkJoin({
                    csvChart: of(chart),
                    blob: this._downloadCsvInsightsService.getCsvInsightsData$(chart).pipe(
                        map((csvStringData) => {
                            if (!csvStringData || !csvStringData.length) {
                                return null;
                            }
                            return new Blob([csvStringData], { type: 'text/csv;charset=UTF-8' });
                        })
                    ),
                })
            )
        )
            .pipe(
                switchMap((res) =>
                    forkJoin({
                        res: of(res),
                        dates: this._store.select(selectDatesFilter).pipe(take(1)),
                    })
                )
            )
            .subscribe({
                next: ({ res, dates }) => {
                    this.hideFooter(footerId);
                    this.isDownloading.set(false);
                    this.formGroup.enable();
                    const validRes = res.filter((r) => !!r.blob);
                    if (!validRes.length) {
                        this._toastService.openErrorToast(this._translateService.instant('common.error'));
                        return;
                    }
                    const startDateFormatted = dates.startDate ? DateTime.fromJSDate(dates.startDate).toISODate() : '-';
                    const endDateFormatted = dates.endDate ? DateTime.fromJSDate(dates.endDate).toISODate() : '-';
                    const suffix = `_${startDateFormatted}_${endDateFormatted}`;
                    const validBlobs = validRes.map((r) => r.blob).filter(isNotNil);
                    const filenames = validRes.map((r) => this._getCsvInsightsChartFilename(r.csvChart, suffix, FileExtension.CSV));
                    const zipFilename = this._getInsightTabFilename(this.data.tab, FileExtension.ZIP);
                    void downloadFilesAsZip(validBlobs, filenames, zipFilename);
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this.hideFooter(footerId);
                    this.isDownloading.set(false);
                    this.formGroup.enable();
                    this._toastService.openErrorToast(this._translateService.instant('common.unknown_error'));
                },
            });
        this.onClose();
    }

    private _getInsightTabFilename(tab: InsightsTab, extension: string): string {
        const base = this._enumTranslatePipe.transform(tab, 'insights_tab_filename');
        return `${base}.${extension}`;
    }

    private _getCsvInsightsChartFilename(chart: CsvInsightChart, suffix: string, extension: string): string {
        const base = this._enumTranslatePipe.transform(chart, 'csv_insights_chart_filename');
        return `${base}${suffix}.${extension}`;
    }
}
