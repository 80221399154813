import { HttpClient } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, filter, interval, Observable, switchMap, takeUntil } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { v4 as uuidv4 } from 'uuid';

import {
    AddRestaurantBricksBodyDto,
    BrickDto,
    CreateRestaurantKeywordBodyDto,
    GeoSampleDto,
    GetKeywordRankingsForManyRestaurantsV3BodyDto,
    GetKeywordRankingsForOneRestaurantV3BodyDto,
    GetKeywordRankingsForOneRestaurantV3ResponseDto,
    GetRestaurantsRankingsResponseDto,
    GetRestaurantsRankingsV3ResponseDto,
    KeywordsScoreDto,
    ProcessKeywordsScoreBodyDto,
    RestaurantKeywordCountAndAverageScoreDto,
    RestaurantKeywordDto,
    StartKeywordsGenerationResponseDto,
    UpdateRestaurantBricksBodyDto,
    WatchKeywordsGenerationResponseDto,
} from '@malou-io/package-dto';
import { ApiResultV2, ApplicationLanguage, BrickType, TimeInMilliseconds, WatcherStatus } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { ToastDuration } from ':shared/components-v3/toast/toast-item/toast-item.component';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { objectToQueryParams } from ':shared/helpers/query-params';
import { Brick, GeoSample, Keyword, Pagination } from ':shared/models';

import { ToastService } from './toast.service';

export interface KeywordsGenerationState {
    wasLastResultSeen: boolean;
    generationStartDate: Date | null;
    generationEstimatedTime: number;
    languages: ApplicationLanguage[];
}

@Injectable({
    providedIn: 'root',
})
export class KeywordsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/keywords`;

    readonly keywordsGenerationState$ = new BehaviorSubject<{ [restaurantId: string]: KeywordsGenerationState }>({});
    readonly keywordsGenerationWatchers = signal<Record<string, BehaviorSubject<boolean>>>({});
    readonly killKeywordsGenerationWatchers = computed(() => {
        const watchers: Record<string, Observable<boolean>> = {};
        Object.keys(this.keywordsGenerationWatchers()).forEach(
            (key) => (watchers[key] = this.keywordsGenerationWatchers()[key].pipe(filter((value) => value)))
        );
        return watchers;
    });
    readonly KEYWORDS_GENERATION_WATCHER_INTERVAL_IN_MS = 5000;

    constructor(
        private readonly _http: HttpClient,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService
    ) {}

    startGenerationByRestaurantId(restaurantId: string, languages: ApplicationLanguage[]): void {
        const currentKeywordsGenerationState = this.keywordsGenerationState$.value;
        const newKeywordsGenerationState = {
            ...currentKeywordsGenerationState,
            [restaurantId]: {
                wasLastResultSeen: true,
                generationStartDate: new Date(),
                languages,
                generationEstimatedTime: this._getGenerationEstimatedTime(languages),
            },
        };
        this.keywordsGenerationState$.next(newKeywordsGenerationState);
    }

    generationCompletedByRestaurantId(restaurantId: string): void {
        const currentKeywordsGenerationState = this.keywordsGenerationState$.value;
        const newKeywordsGenerationState = {
            ...currentKeywordsGenerationState,
            [restaurantId]: {
                wasLastResultSeen: false,
                generationStartDate: null,
                languages: currentKeywordsGenerationState[restaurantId].languages ?? [],
                generationEstimatedTime: currentKeywordsGenerationState[restaurantId].generationEstimatedTime ?? Number.MAX_SAFE_INTEGER,
            },
        };
        this.keywordsGenerationState$.next(newKeywordsGenerationState);
    }

    seeLastGenerationResultByRestaurantId(restaurantId: string): void {
        const currentKeywordsGenerationState = this.keywordsGenerationState$.value;
        const newKeywordsGenerationState = {
            ...currentKeywordsGenerationState,
            [restaurantId]: {
                ...currentKeywordsGenerationState[restaurantId],
                wasLastResultSeen: true,
            },
        };
        this.keywordsGenerationState$.next(newKeywordsGenerationState);
    }

    getBricksPaginated(pagination?: Pagination): Observable<Brick[]> {
        const cleanFilters = pagination ? removeNullOrUndefinedField(objectToSnakeCase(pagination)) : {};
        return this._http
            .get<ApiResultV2<BrickDto[]>>(`${this.API_BASE_URL}/bricks`, {
                params: cleanFilters,
            })
            .pipe(map((res) => res.data.map((brick) => new Brick(brick))));
    }

    getBricksCount(): Observable<ApiResultV2<number>> {
        return this._http.get<ApiResultV2<number>>(`${this.API_BASE_URL}/bricks/count`);
    }

    getGeneratedBricks(key: string, type: BrickType): Observable<Brick[]> {
        return this._http
            .get<ApiResultV2<BrickDto[]>>(`${this.API_BASE_URL}/bricks/brick-generator/${key}/${type}`)
            .pipe(map((res) => res.data.map((brick) => new Brick(brick))));
    }

    sendBricksForm(restaurantId: string, formData: UpdateRestaurantBricksBodyDto['formData']): Observable<ApiResultV2<never>> {
        return this._http.post<ApiResultV2<never>>(
            `${this.API_BASE_URL}/bricks/restaurants/${restaurantId}/`,
            { formData },
            { withCredentials: true }
        );
    }

    addBricksToRestaurant(restaurantId: string, formData: AddRestaurantBricksBodyDto['formData']): Observable<ApiResultV2<never>> {
        return this._http.patch<ApiResultV2<never>>(
            `${this.API_BASE_URL}/bricks/restaurants/${restaurantId}`,
            { formData },
            { withCredentials: true }
        );
    }

    async generateKeywordsForRestaurant(restaurantId: string, langs: ApplicationLanguage[] = [ApplicationLanguage.FR]): Promise<void> {
        this.startGenerationByRestaurantId(restaurantId, langs);
        const uuid = this._newKeywordsGenerationWatcher();
        this.startGenerateKeywords(restaurantId, langs).subscribe({
            error: (err) => {
                this.keywordsGenerationWatchers()[uuid].next(true);
                this.generationCompletedByRestaurantId(restaurantId);
                this._toastService.openErrorToast(
                    err.error?.message ?? this._translateService.instant('keywords.generator.error'),
                    ToastDuration.MEDIUM
                );
            },
        });
        this._startWatcher(uuid, restaurantId);
    }

    startGenerateKeywords(
        restaurantId: string,
        langs: ApplicationLanguage[] = [ApplicationLanguage.FR]
    ): Observable<StartKeywordsGenerationResponseDto> {
        const params = objectToQueryParams({ langs });
        return this._http
            .get<ApiResultV2<StartKeywordsGenerationResponseDto>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/generate/start`, {
                params,
                withCredentials: true,
            })
            .pipe(map((res) => res.data));
    }

    watchKeywordsGeneration(restaurantId: string): Observable<WatchKeywordsGenerationResponseDto> {
        return this._http
            .get<ApiResultV2<WatchKeywordsGenerationResponseDto>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/generate/watch`)
            .pipe(map((res) => res.data));
    }

    createKeyword(restaurantId: string, keyword: CreateRestaurantKeywordBodyDto): Observable<Keyword> {
        return this._http
            .post<
                ApiResultV2<RestaurantKeywordDto>
            >(`${this.API_BASE_URL}/restaurants/${restaurantId}/v2`, keyword, { withCredentials: true })
            .pipe(map((res) => Keyword.fromRestaurantKeywordDto(res.data)));
    }

    getKeywordsByRestaurantId(restaurantId: string): Observable<ApiResultV2<Keyword[]>> {
        return this._http.get<ApiResultV2<RestaurantKeywordDto[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`).pipe(
            map((res) => ({
                data: res.data?.map((restaurantKeyword) => Keyword.fromRestaurantKeywordDto(restaurantKeyword)) ?? [],
            }))
        );
    }
    getKeywordsForRestaurants(restaurantIds: string[]): Observable<ApiResultV2<Keyword[]>> {
        return this._http
            .post<ApiResultV2<RestaurantKeywordDto[]>>(`${this.API_BASE_URL}/restaurants`, { restaurant_ids: restaurantIds })
            .pipe(
                map((res) => ({
                    data: res.data?.map((restaurantKeyword) => Keyword.fromRestaurantKeywordDto(restaurantKeyword)) ?? [],
                }))
            );
    }

    updateKeywordLanguage(id: string, restaurantId: string, language: string): Observable<Keyword> {
        return this._http
            .put<ApiResultV2<RestaurantKeywordDto>>(`${this.API_BASE_URL}/${id}/language`, { restaurantId, language })
            .pipe(map((res) => Keyword.fromRestaurantKeywordDto(res.data)));
    }

    updateKeywordVolumeFromAdmin(keywordId: string, volumeFromAdmin: number, restaurantId: string): Observable<Keyword> {
        return this._http
            .put<
                ApiResultV2<RestaurantKeywordDto>
            >(`${this.API_BASE_URL}/${keywordId}/volume-from-admin`, { volumeFromAdmin, restaurantId })
            .pipe(map((res) => Keyword.fromRestaurantKeywordDto(res.data)));
    }

    setSelectedKeywords(restaurantId: string, keywords: Keyword[]): Observable<ApiResultV2<Keyword[]>> {
        const keywordIds = keywords.map((keyword) => keyword.keywordId);
        return this._http
            .put<
                ApiResultV2<RestaurantKeywordDto[]>
            >(`${this.API_BASE_URL}/restaurants/${restaurantId}/select/v2`, { keywordIds }, { withCredentials: true })
            .pipe(
                map((res) => ({
                    data: res.data?.map((restaurantKeyword) => Keyword.fromRestaurantKeywordDto(restaurantKeyword)) ?? [],
                }))
            );
    }

    getRankingsByRestaurantId(
        restaurantId: string,
        startDate: Date | null,
        endDate: Date | null,
        previousPeriod = false
    ): Observable<ApiResultV2<GeoSample[]>> {
        const reqUrl = `${this.API_BASE_URL}/rankings/restaurants/${restaurantId}/v2`;

        return this._http
            .get<ApiResultV2<GeoSampleDto[]>>(reqUrl, {
                params: removeNullOrUndefinedField(
                    objectToSnakeCase({
                        startDate: startDate?.toISOString() ?? null,
                        endDate: endDate?.toISOString() ?? null,
                        previousPeriod,
                    })
                ),
            })
            .pipe(
                map((res) => ({
                    data: res.data?.map((geoSample) => GeoSample.fromGeoSampleDto(geoSample)),
                }))
            );
    }

    handleGetKeywordRankingsForOneRestaurantV3(
        params: { restaurantId: string } & GetKeywordRankingsForOneRestaurantV3BodyDto
    ): Observable<GetKeywordRankingsForOneRestaurantV3ResponseDto> {
        const reqUrl = `${this.API_BASE_URL}/rankings/restaurants/${params.restaurantId}/v3`;

        return this._http
            .post<ApiResultV2<GetKeywordRankingsForOneRestaurantV3ResponseDto>>(reqUrl, {
                startDate: params.startDate.toISOString(),
                endDate: params.endDate.toISOString(),
                platformKey: params.platformKey,
            })
            .pipe(map((res) => res.data));
    }

    handleGetKeywordRankingsForManyRestaurantsV3(
        params: GetKeywordRankingsForManyRestaurantsV3BodyDto
    ): Observable<GetRestaurantsRankingsV3ResponseDto> {
        const reqUrl = `${this.API_BASE_URL}/rankings/restaurants/v3`;

        return this._http
            .post<ApiResultV2<GetRestaurantsRankingsV3ResponseDto>>(reqUrl, {
                restaurantIds: params.restaurantIds,
                startDate: params.startDate.toISOString(),
                endDate: params.endDate.toISOString(),
                platformKey: params.platformKey,
            })
            .pipe(map((res) => res.data));
    }

    getAllRankingsByKeywordText(keywordText: string, restaurantId: string): Observable<ApiResultV2<GeoSample[]>> {
        const encodedKeyword = encodeURIComponent(keywordText);
        return this._http.get<ApiResultV2<GeoSample[]>>(
            `${this.API_BASE_URL}/rankings/restaurants/${restaurantId}/keyword/${encodedKeyword}`
        );
    }

    getRankingsForUserRestaurants(
        startDate: Date,
        endDate: Date,
        restaurantIds: string[],
        previousPeriod = false
    ): Observable<ApiResultV2<GetRestaurantsRankingsResponseDto[]>> {
        const queryParams =
            startDate && endDate
                ? removeNullOrUndefinedField(objectToSnakeCase({ startDate: startDate.toISOString(), endDate: endDate.toISOString() }))
                : {};
        queryParams['previous_period'] = previousPeriod;
        queryParams['restaurant_ids'] = restaurantIds;

        const reqUrl = `${this.API_BASE_URL}/rankings/restaurants/v2`;
        return this._http.get<ApiResultV2<GetRestaurantsRankingsResponseDto[]>>(reqUrl, {
            params: queryParams,
        });
    }

    refreshRankingForKeywordId(restaurantKeywordId: string): Observable<GeoSample[]> {
        return this._http
            .get<ApiResultV2<{ result: GeoSampleDto[] }>>(`${this.API_BASE_URL}/${restaurantKeywordId}/rankings/refresh/v2`)
            .pipe(map((res) => res.data?.result?.map((geoSample) => GeoSample.fromGeoSampleDto(geoSample))));
    }

    getRestaurantKeywordsCountAndAverageScore(
        restaurantId: string,
        startDate: Date | null,
        endDate: Date | null,
        previousPeriod = false
    ): Observable<ApiResultV2<RestaurantKeywordCountAndAverageScoreDto>> {
        return this._http.get<ApiResultV2<RestaurantKeywordCountAndAverageScoreDto>>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/count_and_average_score`,
            { params: removeNullOrUndefinedField(objectToSnakeCase({ startDate, endDate, previousPeriod })) }
        );
    }

    getKeywordsCountForRestaurant(restaurantId: string): Observable<ApiResultV2<number>> {
        return this._http.get<ApiResultV2<number>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/count`);
    }

    duplicateKeywordsForRestaurants(
        restaurantId: string,
        originalKeywords: Partial<Keyword>[],
        restaurantIds: string[]
    ): Observable<ApiResultV2<never>> {
        const keywordIds = originalKeywords.map((keyword) => keyword.keywordId);
        return this._http.post<ApiResultV2<never>>(`${this.API_BASE_URL}/${restaurantId}/duplicate/v2`, {
            originalKeywordIds: keywordIds,
            restaurantIds,
        });
    }

    processKeywordsScore$(payload: ProcessKeywordsScoreBodyDto & { shouldCacheScore: boolean }): Observable<KeywordsScoreDto> {
        return this._http.post<ApiResultV2<KeywordsScoreDto>>(`${this.API_BASE_URL}/score`, payload).pipe(map((res) => res.data));
    }

    private _newKeywordsGenerationWatcher(): string {
        const uuid = uuidv4().toString();

        // clean up keywordsGenerationWatchers not useful anymore
        this.keywordsGenerationWatchers.update((watchers) => {
            for (const key in watchers) {
                if (watchers[key].value) {
                    delete watchers[key];
                }
            }

            watchers[uuid] = new BehaviorSubject<boolean>(false);
            return { ...watchers };
        });

        return uuid;
    }

    private _getGenerationEstimatedTime(languages: ApplicationLanguage[]): number {
        const langs = languages.sort().join('-'); // sort to have a unique key for each combination
        switch (langs) {
            case [ApplicationLanguage.FR].join('-'):
            case [ApplicationLanguage.EN].join('-'):
                return 35 * TimeInMilliseconds.SECOND;
            case [ApplicationLanguage.EN, ApplicationLanguage.FR].join('-'):
                return 50 * TimeInMilliseconds.SECOND;
            case [ApplicationLanguage.IT].join('-'):
            case [ApplicationLanguage.ES].join('-'):
                return 65 * TimeInMilliseconds.SECOND;
            case [ApplicationLanguage.EN, ApplicationLanguage.IT].join('-'):
            case [ApplicationLanguage.EN, ApplicationLanguage.ES].join('-'):
            case [ApplicationLanguage.FR, ApplicationLanguage.IT].join('-'):
            case [ApplicationLanguage.ES, ApplicationLanguage.FR].join('-'):
                return 90 * TimeInMilliseconds.SECOND;
            case [ApplicationLanguage.ES, ApplicationLanguage.IT].join('-'):
            case [ApplicationLanguage.EN, ApplicationLanguage.ES, ApplicationLanguage.FR].join('-'):
            case [ApplicationLanguage.EN, ApplicationLanguage.FR, ApplicationLanguage.IT].join('-'):
                return 120 * TimeInMilliseconds.SECOND;
            case [ApplicationLanguage.EN, ApplicationLanguage.ES, ApplicationLanguage.IT].join('-'):
            case [ApplicationLanguage.ES, ApplicationLanguage.FR, ApplicationLanguage.IT].join('-'):
                return 130 * TimeInMilliseconds.SECOND;
            default:
                return 140 * TimeInMilliseconds.SECOND;
        }
    }

    private _startWatcher(uuid: string, restaurantId: string): void {
        interval(this.KEYWORDS_GENERATION_WATCHER_INTERVAL_IN_MS)
            .pipe(
                switchMap(() => this.watchKeywordsGeneration(restaurantId)),
                takeUntil(this.killKeywordsGenerationWatchers()[uuid])
            )
            .subscribe({
                next: (res) => {
                    switch (res.status) {
                        case WatcherStatus.FAILED:
                            this.keywordsGenerationWatchers()[uuid].next(true);
                            this.generationCompletedByRestaurantId(restaurantId);
                            const errorMessage = res.error ?? this._translateService.instant('keywords.generator.error');
                            this._toastService.openErrorToast(errorMessage, ToastDuration.MEDIUM);
                            return;
                        case WatcherStatus.FINISHED:
                            this.keywordsGenerationWatchers()[uuid].next(true);
                            this.generationCompletedByRestaurantId(restaurantId);
                            return;
                        case WatcherStatus.RUNNING:
                        default:
                            return;
                    }
                },
                error: (error) => {
                    if ([502, 504].includes(error.status)) {
                        return this._startWatcher(uuid, restaurantId);
                    }
                    this._toastService.openErrorToast(this._translateService.instant('keywords.generator.error'), ToastDuration.MEDIUM);
                    this.generationCompletedByRestaurantId(restaurantId);
                },
            });
    }
}
