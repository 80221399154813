import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual, sortBy } from 'lodash';
import { combineLatest, distinctUntilChanged, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import {
    DEFAULT_ARCHIVES,
    DEFAULT_COMMENTS,
    DEFAULT_PERIOD,
    DEFAULT_RATINGS,
    DEFAULT_STATUSES,
} from ':modules/reviews/reviews-header/reviews-header-filters/default-filters';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import * as ReviewsSelectors from ':modules/reviews/store/reviews.selectors';
import { GroupedDateFiltersComponent } from ':shared/components/grouped-date-filters/grouped-date-filters.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { RatingsFiltersComponent } from ':shared/components/ratings-filters/ratings-filters.component';
import { ArchiveReviewsFilters, CommentReviewsFilters, DatesAndPeriod, MalouPeriod, StatusReviewsFilter } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

@Component({
    selector: 'app-reviews-header-filters',
    templateUrl: './reviews-header-filters.component.html',
    styleUrls: ['./reviews-header-filters.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        TranslateModule,
        GroupedDateFiltersComponent,
        RatingsFiltersComponent,
        ApplyPurePipe,
        AsyncPipe,
        IncludesPipe,
        PlatformLogoComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsHeaderFiltersComponent {
    readonly id = input<string>();
    readonly disabled = input<boolean>(false);

    private readonly _store = inject(Store);

    readonly SvgIcon = SvgIcon;

    // Dates
    readonly DEFAULT_PERIOD = DEFAULT_PERIOD;
    readonly periodOptions: MalouPeriod[] = [
        MalouPeriod.ALL,
        MalouPeriod.LAST_SEVEN_DAYS,
        MalouPeriod.LAST_THIRTY_DAYS,
        MalouPeriod.LAST_THREE_MONTHS,
        MalouPeriod.LAST_TWELVE_MONTHS,
    ];
    readonly MalouPeriod = MalouPeriod;
    readonly storedPeriod$ = this._store.select(ReviewsSelectors.selectPeriodReviewsFilters);

    // Ratings
    readonly selectableRatings = [1, 2, 3, 4, 5, 0];
    readonly storedRatings$ = this._store.select(ReviewsSelectors.selectRatingsReviewsFilters);
    readonly storedRatings = toSignal(this.storedRatings$, { initialValue: [] });

    // Platforms
    readonly selectablePlatformKeys$: Observable<PlatformKey[]> = this._store.select(ReviewsSelectors.selectSelectablePlatformKeys);
    readonly storedPlatforms$ = this._store.select(ReviewsSelectors.selectPlatformsReviewsFilters);

    // Statuses
    readonly selectableStatuses: StatusReviewsFilter[] = [
        StatusReviewsFilter.ANSWERED,
        StatusReviewsFilter.NOT_ANSWERED,
        StatusReviewsFilter.PENDING,
        StatusReviewsFilter.NOT_ANSWERABLE,
    ];
    readonly storedStatuses$ = this._store.select(ReviewsSelectors.selectStatusesReviewsFilters);

    // Comments
    readonly selectableCommentsFilters: CommentReviewsFilters[] = [CommentReviewsFilters.WITH_TEXT, CommentReviewsFilters.WITHOUT_TEXT];
    readonly storedComments$ = this._store.select(ReviewsSelectors.selectCommentsReviewsFilters);

    // Archive
    readonly selectableArchivedFilters: ArchiveReviewsFilters[] = [ArchiveReviewsFilters.UNARCHIVED, ArchiveReviewsFilters.ARCHIVED];
    readonly storedArchives$ = this._store.select(ReviewsSelectors.selectArchivesReviewsFilters);

    readonly nonDefaultFiltersCount$ = this._computeNonDefaultFiltersCount$(); // must be last property to be computed with defined values

    editDateFilters(filters: DatesAndPeriod): void {
        this._store.dispatch(ReviewsActions.editReviewsFilters({ filters }));
    }

    editRatingsFilter(rating: number): void {
        this._store.dispatch(ReviewsActions.editReviewsFiltersRatings({ ratings: [rating] }));
    }

    togglePlatformsFilter(platformKey: PlatformKey): void {
        this._store.dispatch(ReviewsActions.toggleReviewsFiltersPlatform({ platform: platformKey }));
    }

    toggleStatusFilter(status: StatusReviewsFilter): void {
        this._store.dispatch(ReviewsActions.toggleReviewsFiltersStatus({ status }));
    }

    toggleCommentFilter(commentFilter: CommentReviewsFilters): void {
        this._store.dispatch(ReviewsActions.toggleReviewsFiltersComment({ commentFilter }));
    }

    toggleArchivedFilters(archiveFilter: ArchiveReviewsFilters): void {
        this._store.dispatch(ReviewsActions.toggleReviewsFiltersArchive({ archiveFilter }));
    }

    clearFilters(): void {
        this._store.dispatch(ReviewsActions.clearFilters());
    }

    private _computeNonDefaultFiltersCount$(): Observable<number> {
        return combineLatest([
            this.storedPeriod$.pipe(distinctUntilChanged()),
            this.storedRatings$.pipe(distinctUntilChanged()),
            this.storedPlatforms$.pipe(distinctUntilChanged()),
            this.selectablePlatformKeys$.pipe(distinctUntilChanged()),
            this.storedStatuses$.pipe(distinctUntilChanged()),
            this.storedComments$.pipe(distinctUntilChanged()),
            this.storedArchives$.pipe(distinctUntilChanged()),
        ]).pipe(
            debounceTime(500),
            map(([period, ratings, platforms, selectablePlatformKeys, statuses, comments, archives]) => {
                const filteredPlatforms = (platforms ?? []).filter((platform) => selectablePlatformKeys.includes(platform));
                const statusesAsArray = Object.values(StatusReviewsFilter).filter((value) => statuses[value]);
                const commentsAsArray = Object.values(CommentReviewsFilters).filter((value) => comments[value]);
                const archivesAsArray = Object.values(ArchiveReviewsFilters).filter((value) => archives[value]);
                const isDefaultPeriod = period.period === DEFAULT_PERIOD;
                const isDefaultRatings = isEqual(sortBy(ratings), sortBy(DEFAULT_RATINGS));
                const isDefaultPlatforms = isEqual(sortBy(filteredPlatforms), sortBy(selectablePlatformKeys)); // because default is all selected
                const isDefaultStatuses = isEqual(sortBy(statusesAsArray), sortBy(DEFAULT_STATUSES));
                const isDefaultComments = isEqual(sortBy(commentsAsArray), sortBy(DEFAULT_COMMENTS));
                const isDefaultArchive = isEqual(sortBy(archivesAsArray), sortBy(DEFAULT_ARCHIVES));
                return [
                    !isDefaultPeriod,
                    !isDefaultPlatforms,
                    !isDefaultRatings,
                    !isDefaultStatuses,
                    !isDefaultComments,
                    !isDefaultArchive,
                ].filter(Boolean).length;
            })
        );
    }
}
