<div class="h-full">
    <div class="flex flex-col">
        <span class="malou-text-14--bold malou-color-text-1 mb-2">
            {{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.title' | translate }}
        </span>
        <span class="malou-text-10--medium italic">
            {{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.which_restaurant' | translate }}
        </span>
    </div>
    <div class="mt-4 flex flex-col gap-y-4">
        <div class="flex items-baseline">
            <mat-checkbox
                class="mx-4"
                color="primary"
                [checked]="areAllSelected()"
                [indeterminate]="!areAllSelected()"
                (change)="$event ? toggleAllRows() : null">
            </mat-checkbox>
            <span class="malou-text-12--semibold malou-color-text-1">
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.select_all' | translate }}
            </span>
        </div>
        @for (restaurant of sortedRestaurants(); track trackByIdFn($index, restaurant); let index = $index) {
            <div class="flex items-baseline" [ngClass]="{ '!cursor-not-allowed': !isSelectable()(restaurant) }">
                <mat-checkbox
                    class="mx-4"
                    color="primary"
                    [disabled]="!isSelectable()(restaurant)"
                    [checked]="isSelected(restaurant)"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? onRestaurantSelectionChange($event, restaurant) : null">
                </mat-checkbox>
                <div class="w-full" [ngClass]="{ 'cursor-not-allowed': !isSelectable()(restaurant) }">
                    <span class="malou-text-12--semibold malou-color-text-1">{{ restaurant | applySelfPure: 'getDisplayName' }}</span
                    ><span class="malou-text-10--regular malou-color-text-2 italic">
                        - {{ restaurant | applySelfPure: 'getFullFormattedAddress' }}</span
                    >
                    @if (isSelectable()(restaurant) && restaurant.totems?.length) {
                        <div class="my-1.5 flex flex-col">
                            <ng-container
                                [ngTemplateOutlet]="totemsSelect"
                                [ngTemplateOutletContext]="{ totems: restaurant.totems, restaurantId: restaurant._id }"></ng-container>
                        </div>
                    }
                    @if (!isSelectable()(restaurant)) {
                        @if (!restaurant.boosterPack.activated) {
                            <div class="mr-5 flex leading-4">
                                <div class="malou-status--waiting mr-1.5">
                                    <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                                </div>
                                <span class="malou-text-10--medium italic">
                                    {{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.no_booster_pack_1' | translate }}
                                    <span
                                        class="malou-btn-flat !malou-text-10--bold cursor-pointer"
                                        (click)="sendSubscriptionRequest(restaurant)">
                                        {{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.no_booster_pack_2' | translate }}
                                    </span>
                                    {{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.no_booster_pack_3' | translate }}
                                </span>
                            </div>
                        } @else {
                            @if (restaurant.hasAggregatedWheelOfFortune) {
                                <div class="mr-5 flex leading-4">
                                    <div class="malou-status--waiting mr-1.5">
                                        <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                                    </div>
                                    <span class="malou-text-10--medium italic">
                                        {{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.has_aggregated_wheel_1' | translate }}
                                        <span
                                            class="malou-btn-flat !malou-text-10--bold cursor-pointer"
                                            (click)="
                                                close({
                                                    shouldCheckBeforeClose: true,
                                                    restaurantIdInAggregatedWheelOfFortune: restaurant.id,
                                                })
                                            ">
                                            {{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.has_aggregated_wheel_cta' | translate }}
                                        </span>
                                        {{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.has_aggregated_wheel_2' | translate }}
                                    </span>
                                </div>
                            }
                            @if (restaurant.hasWheelOfFortune) {
                                <div class="mr-5 flex leading-4">
                                    <div class="malou-status--waiting mr-1.5">
                                        <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                                    </div>
                                    <span class="malou-text-10--medium italic">
                                        <span>{{
                                            'wheel_of_fortune.new_wheel_modal.tabs.restaurants.has_single_wheel_1' | translate
                                        }}</span>
                                        <span
                                            class="malou-btn-flat !malou-text-10--bold cursor-pointer"
                                            (click)="close({ shouldCheckBeforeClose: true, restaurantId: restaurant._id })">
                                            {{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.has_single_wheel_cta' | translate }}
                                        </span>
                                        <span>{{
                                            'wheel_of_fortune.new_wheel_modal.tabs.restaurants.has_single_wheel_2' | translate
                                        }}</span>
                                    </span>
                                </div>
                            }
                            @if (
                                !restaurant.hasWheelOfFortune &&
                                !restaurant.hasAggregatedWheelOfFortune &&
                                isSelectableBasedOnCaslRoles()(restaurant) &&
                                !isSelectableBasedOnRedirectionPlatforms()(restaurant)
                            ) {
                                <div class="mr-5 flex leading-4">
                                    <div class="malou-status--waiting mr-1.5">
                                        <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                                    </div>
                                    <span class="malou-text-10--medium italic">
                                        {{
                                            'wheel_of_fortune.new_wheel_modal.tabs.restaurants.connect_gmb_1'
                                                | translate: { platformKey: invalidRedirectionPlatformsForRestaurantAsString()(restaurant) }
                                        }}
                                        <span
                                            class="malou-btn-flat !malou-text-10--bold cursor-pointer"
                                            (click)="
                                                close({
                                                    shouldCheckBeforeClose: true,
                                                    restaurantId: restaurant.id,
                                                    redirection: AppRedirection.SETTINGS,
                                                })
                                            ">
                                            {{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.settings_page' | translate }}
                                        </span>
                                        {{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.connect_gmb_2' | translate }}
                                    </span>
                                </div>
                            }
                            @if (
                                !restaurant.hasWheelOfFortune &&
                                !restaurant.hasAggregatedWheelOfFortune &&
                                !isSelectableBasedOnCaslRoles()(restaurant)
                            ) {
                                <div class="mr-5 flex leading-4">
                                    <div class="malou-status--waiting mr-1.5">
                                        <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                                    </div>
                                    <span class="malou-text-10--medium italic">
                                        {{ 'casl.wrong_role' | translate }}
                                    </span>
                                </div>
                            }
                        }
                    }
                </div>
            </div>
        }
        @for (restaurant of sortedNonOwnedRestaurants(); track trackByIdFn($index, restaurant); let index = $index) {
            <div class="flex !cursor-not-allowed opacity-50">
                <mat-checkbox class="mx-4" color="primary" [disabled]="true" [checked]="true"> </mat-checkbox>
                <div class="flex w-full cursor-not-allowed items-center">
                    <span class="malou-text-12--semibold malou-color-text-1">{{ restaurant | applySelfPure: 'getDisplayName' }}</span
                    ><span class="malou-text-10--regular malou-color-text-2 italic">
                        - {{ restaurant | applySelfPure: 'getFullFormattedAddress' }}</span
                    >
                    <mat-icon class="small-icon ml-1" color="primary" [svgIcon]="SvgIcon.PADLOCK"></mat-icon>
                </div>
            </div>
        }
    </div>
</div>

<ng-template let-totems="totems" let-restaurantId="restaurantId" #totemsSelect>
    <span class="malou-text-12--semibold malou-color-text-1">
        {{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.which_totems' | translate }}
    </span>
    <span class="malou-text-10--medium italic">{{ 'wheel_of_fortune.new_wheel_modal.tabs.restaurants.will_redirect' | translate }}</span>
    <app-select-base
        class="mr-5 mt-4 max-h-[50px] max-w-[98%] overflow-x-hidden"
        [disabled]="!selectedRestaurantIds().includes(restaurantId)"
        [values]="totems"
        [selectedValues]="getDisplayedTotems() | applyPure: restaurantId"
        [displayWith]="totemDisplayWith"
        [sortBy]="totemsSortBy"
        [defaultEmptyValueMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.no_totem' | translate"
        [placeholder]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.no_totem_selected' | translate"
        [selectAllCheckboxMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.all_totems' | translate"
        [multiSelection]="true"
        [checkboxOption]="true"
        [showSelectAllCheckbox]="true"
        [showValuesSelectedCount]="true"
        [groupSelectedValuesAtTop]="true"
        [multiSelectionElementWrap]="false"
        [shouldSwitchToWrapModeOnClick]="false"
        [shouldUpdateValuesToDisplayAfterSelection]="true"
        [valuesSelectedCountMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.totems' | translate"
        (selectBaseChange)="$event ? onTotemSelectionChange($event, restaurantId) : null">
        <ng-template let-value="value" let-index="index" let-deleteValueAt="deleteValueAt" #selectedValueTemplate>
            <div class="malou-chip malou-chip--primary">
                <span>{{ totemDisplayWith | applyPure: value }}</span>
                <mat-icon
                    class="malou-chip-icon--right malou-color-primary"
                    [svgIcon]="SvgIcon.REMOVE"
                    (click)="deleteValueAt(index)"></mat-icon>
            </div>
        </ng-template>
        <ng-template let-value="value" let-isValueSelected="isValueSelected" #optionTemplate>
            <div class="malou-text-12--semibold" [class.malou-color-text-1]="isValueSelected">
                {{ totemDisplayWith | applyPure: value }}
            </div>
        </ng-template>
    </app-select-base>
</ng-template>
