import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { asapScheduler, delay, of, switchMap, tap } from 'rxjs';

import { errorReplacer, TimeInMilliseconds, WHEEL_OF_FORTUNE_NEEDED_ROLES } from '@malou-io/package-utils';

import { DialogService } from ':core/services/dialog.service';
import { ToastService } from ':core/services/toast.service';
import * as JimoActions from ':modules/jimo/jimo.actions';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { HowWheelOfFortuneWorksComponent } from ':shared/components/how-wheel-of-fortune-works/how-wheel-of-fortune-works.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import {
    DisableIfMissingCaslRoleCheckType,
    DisableIfMissingCaslRoleDirective,
} from ':shared/directives/disable-if-missing-casl-role.directive';
import { HideOverflowingChildrenDirective } from ':shared/directives/hide-overflowing-children.directive';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { QrCode } from ':shared/helpers/qr-code';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Restaurant } from ':shared/models';
import { EmptyGiftData, WheelOfFortune, WheelOfFortuneState } from ':shared/models/wheel-of-fortune';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { WheelOfFortunePosterComponent } from '../../wheel-of-fortune-poster/wheel-of-fortune-poster.component';
import { GiftOutOfStockModalComponent } from '../gift-out-of-stock-modal/gift-out-of-stock-modal.component';
import { UpsertWheelOfFortuneModalComponent } from '../upsert-wheel-of-fortune-modal/upsert-wheel-of-fortune-modal.component';
import { WheelsOfFortuneService } from '../wheels-of-fortune.service';

enum WheelOfFortunePosterFormat {
    A4_LANDSCAPE = 'A4_LANDSCAPE',
    A4_PORTRAIT = 'A4_PORTRAIT',
}

@Component({
    selector: 'app-wheel-of-fortune-data-card',
    templateUrl: './wheel-of-fortune-data-card.component.html',
    styleUrls: ['./wheel-of-fortune-data-card.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatMenuModule,
        TranslateModule,
        HideOverflowingChildrenDirective,
        DisableIfMissingCaslRoleDirective,
        HowWheelOfFortuneWorksComponent,
        WheelOfFortunePosterComponent,
        ApplySelfPurePipe,
        EnumTranslatePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelOfFortuneDataCardComponent implements OnInit {
    @Input() wheelOfFortune: WheelOfFortune;
    @Input() index: number;
    @Input() isAggregatedView = true;
    @Output() reloadWheelsOfFortune: EventEmitter<void> = new EventEmitter();

    readonly trackByIdFn = TrackByFunctionFactory.get('id');
    readonly SvgIcon = SvgIcon;
    readonly WheelOfFortuneState = WheelOfFortuneState;
    readonly WheelOfFortunePosterFormat = WheelOfFortunePosterFormat;
    readonly DisableIfMissingCaslRoleCheckType = DisableIfMissingCaslRoleCheckType;
    readonly WHEEL_OF_FORTUNE_NEEDED_ROLES = WHEEL_OF_FORTUNE_NEEDED_ROLES;

    readonly areWheelDetailsOpen = signal(false);
    readonly wheelOfFortuneRestaurantIds = computed(() => this.wheelOfFortune.restaurants.map((restaurant) => restaurant.id));
    readonly hasAccessToAllWheelRestaurants = computed(() => {
        const userRestaurants = this._userRestaurants();
        if (!userRestaurants) {
            return false;
        }
        const userRestaurantIds = userRestaurants.map((restaurant) => restaurant._id);
        const wheelOfFortuneRestaurantsManagedByUser = this.wheelOfFortuneRestaurantIds().filter((restaurantId) =>
            userRestaurantIds.includes(restaurantId)
        );
        return wheelOfFortuneRestaurantsManagedByUser.length === this.wheelOfFortune.restaurants.length;
    });
    readonly childrenHiddenCount = signal(0);
    readonly refreshRestaurantsDisplayFn = signal<() => void>(() => {});

    readonly displayPoster: WritableSignal<boolean> = signal(false);
    readonly format: WritableSignal<WheelOfFortunePosterFormat> = signal(WheelOfFortunePosterFormat.A4_PORTRAIT);
    readonly isRestaurantManagedByUser = computed(() => (restaurantId: string): boolean => {
        const userRestaurants = this._userRestaurants();
        if (!userRestaurants) {
            return false;
        }
        return userRestaurants.some((restaurant) => restaurant._id === restaurantId);
    });

    giftIdFromUrl: string;
    hasOneStockEmptyStock = false;

    private readonly _userRestaurants: Signal<Restaurant[] | undefined> = toSignal(this._store.select(selectOwnRestaurants));
    constructor(
        private readonly _customDialogService: CustomDialogService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        private readonly _wheelsOfFortuneService: WheelsOfFortuneService,
        private readonly _store: Store,
        private readonly _dialogService: DialogService
    ) {
        effect(() => {
            asapScheduler.schedule(() => this.refreshRestaurantsDisplayFn()());
        });
    }

    ngOnInit(): void {
        this.giftIdFromUrl = this._route.snapshot.queryParams.giftId;
        if (this.giftIdFromUrl && this.wheelOfFortune.gifts.find((gift) => gift.id === this.giftIdFromUrl)) {
            this._router.navigate(['.'], { relativeTo: this._route });
            this.openWheelOfFortuneModal(this.giftIdFromUrl);
        }
        if (!this.giftIdFromUrl) {
            const emptyGiftData: EmptyGiftData | null = this.wheelOfFortune.getFirstGiftWithEmptyStock();
            this.hasOneStockEmptyStock = !!emptyGiftData;

            if (emptyGiftData) {
                this._openEmptyGiftStockModal({ ...emptyGiftData });
            }
        }

        if (!this.isAggregatedView) {
            this.areWheelDetailsOpen.set(true);
        }
    }

    toggleWheelDetailsOpen(): void {
        this.areWheelDetailsOpen.set(!this.areWheelDetailsOpen());
    }

    openWheelOfFortuneModal(giftId?: string): void {
        this._customDialogService
            .open(UpsertWheelOfFortuneModalComponent, {
                width: '100%',
                panelClass: 'malou-dialog-panel--full',
                height: undefined,
                data: {
                    isAggregatedView: this.isAggregatedView,
                    wheelOfFortune: this.wheelOfFortune,
                    giftId,
                },
            })
            .afterClosed()
            .subscribe({
                next: (res) => {
                    if (res?.redirectUrl) {
                        this._router.navigate(res.redirectUrl.path, res.redirectUrl.extras);
                    }
                    if (res?.shouldReload) {
                        this.reloadWheelsOfFortune.emit();
                    }
                },
            });
    }

    displayGiftClaimDuration(giftClaimDurationInDays: number): string {
        switch (giftClaimDurationInDays) {
            // values of GiftClaimDurationInDaysOption
            case 2:
            case 7:
            case 15:
                return this._translateService.instant(
                    'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.gift_claim_duration_in_days_option',
                    { count: giftClaimDurationInDays }
                );
                break;
            case 30:
                return this._translateService.instant(
                    'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.one_month'
                );
            case 60:
                return this._translateService.instant(
                    'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.two_month'
                );
            case 182:
                return this._translateService.instant(
                    'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.six_month'
                );
                break;
        }
        return '';
    }

    openDeleteWheelOfFortuneModal(): void {
        const wheelOfFortuneId = this.wheelOfFortune.id;
        if (!wheelOfFortuneId) {
            this._toastService.openErrorToast(
                this._translateService.instant('wheel_of_fortune.wheel_of_fortune_card.errors.could_not_delete')
            );
            return;
        }

        const title = this.isAggregatedView
            ? this._translateService.instant('wheel_of_fortune.wheel_of_fortune_card.are_you_sure_delete')
            : this._translateService.instant('wheel_of_fortune.wheel_of_fortune_card.are_you_sure_delete_restaurant_wheel');

        const message = this.isAggregatedView
            ? this._translateService.instant('wheel_of_fortune.wheel_of_fortune_card.delete_will_be_for_all')
            : '';

        this._dialogService.open({
            illustration: Illustration.Cook,
            variant: DialogVariant.INFO,
            title,
            message,
            primaryButton: {
                label: this._translateService.instant('common.delete'),
                action: () => {
                    this.deleteWheelOfFortune(wheelOfFortuneId);
                    this._customDialogService.closeAll();
                },
            },
            secondaryButton: {
                label: this._translateService.instant('common.cancel'),
            },
        });
    }

    deleteWheelOfFortune(wheelOfFortuneId: string): void {
        this._wheelsOfFortuneService
            .deleteWheelOfFortuneById(wheelOfFortuneId)
            .pipe(switchMap(() => this._wheelsOfFortuneService.getUserWheelOfFortuneInformations()))
            .subscribe({
                next: (res) => {
                    this.reloadWheelsOfFortune.emit();
                    this._toastService.openSuccessToast(
                        this._translateService.instant('wheel_of_fortune.wheel_of_fortune_card.wheel_delete')
                    );
                    this._store.dispatch(
                        JimoActions.changeWheelOfFortuneStatus({
                            hasCreatedAWheel: res.data.hasCreatedAWheel,
                            firstWheelCreatedAt: res.data.firstWheelCreatedAt,
                            hasAtLeastOneActiveOrProgrammedWheel: res.data.hasAtLeastOneActiveOrProgrammedWheel,
                            latestEndDateForActiveWheels: res.data.latestEndDateForActiveWheels,
                        })
                    );
                },
                error: (err) => {
                    this._toastService.openErrorToast(
                        this._translateService.instant('wheel_of_fortune.wheel_of_fortune_card.errors.could_not_delete')
                    );
                    console.error(`Error getting user wof informations for Jimo: ${JSON.stringify(err)}`);
                },
            });
    }

    onChildrenHidden(childrenHiddenCount: number): void {
        this.childrenHiddenCount.set(childrenHiddenCount);
    }

    onRefreshFn(refreshFn: () => void): void {
        this.refreshRestaurantsDisplayFn.set(refreshFn);
    }

    downloadQrCode(): void {
        const url = this.wheelOfFortune.getWheelOfFortuneUrl({ isFromTotem: false });
        const fileName = this.isAggregatedView
            ? this._translateService.instant('wheel_of_fortune.wheel_of_fortune', { count: this.index + 1 })
            : this._translateService.instant('wheel_of_fortune.wheel_of_fortune_card.restaurant_wheel_of_fortune_title');
        const handleErrorCallback = (err: Error): void => {
            this._toastService.openErrorToast(JSON.stringify(err, errorReplacer));
            return;
        };
        const qrCodeDownloader = new QrCode();
        qrCodeDownloader.downloadQrCode(url, fileName, { errorCallback: handleErrorCallback });
    }

    copyWheelOfFortuneUrl(): void {
        if (!this.wheelOfFortune.id) {
            this._toastService.openErrorToast(
                this._translateService.instant('wheel_of_fortune.wheel_of_fortune_card.errors.could_not_copy')
            );
        }
        const url = this.wheelOfFortune.getWheelOfFortuneUrl({ isFromTotem: false });
        navigator.clipboard.writeText(url);
        this._toastService.openSuccessToast(this._translateService.instant('wheel_of_fortune.wheel_of_fortune_card.link_copied'));
    }

    downloadPoster(wheelOfFortunePosterFormat: WheelOfFortunePosterFormat): void {
        this.format.set(wheelOfFortunePosterFormat);
        of(true)
            .pipe(
                tap(() => this.displayPoster.set(true)),
                delay(2 * TimeInMilliseconds.SECOND)
            )
            .subscribe({
                next: () => this.displayPoster.set(false),
            });
    }

    private _openEmptyGiftStockModal({ stockId, gift }: EmptyGiftData): void {
        this._pushGiftStockZeroInLocalStorage(stockId);
        this._customDialogService
            .open(GiftOutOfStockModalComponent, {
                height: 'fit-content',
                width: '550px',
                data: {
                    giftName: gift.name,
                },
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (!result) {
                        return;
                    }
                    if (result.shouldRedirect) {
                        if (!this.isAggregatedView) {
                            this._router.navigate(['/groups/boosters/wheels-of-fortune'], {
                                queryParams: { giftId: gift.id },
                            });
                        } else {
                            this.openWheelOfFortuneModal(gift.id);
                        }
                    }
                },
            });
    }

    private _pushGiftStockZeroInLocalStorage(stockId: string): void {
        const stockIds: string[] = JSON.parse(localStorage.getItem(LocalStorageKey.DO_NOT_SHOW_AGAIN_EMPTY_STOCK_MODAL) || '[]');
        stockIds.push(stockId);
        localStorage.setItem(LocalStorageKey.DO_NOT_SHOW_AGAIN_EMPTY_STOCK_MODAL, JSON.stringify(stockIds));
    }
}
