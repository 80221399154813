import { EntityConstructor, PlatformKey } from '@malou-io/package-utils';

import { Notification } from ':core/components/notification-center/models/notification.model';

type CommentNotificationProps = EntityConstructor<CommentNotification>;

export interface CommentNotificationData {
    comments: {
        id: string;
        socialId: string;
        postSocialId: string;
        restaurantId: string;
        socialCreatedAt: Date;
        text: string;
        authorDisplayName: string;
        platformKey: PlatformKey;
    }[];
    restaurantIds: string[];
}

export class CommentNotification extends Notification {
    data: CommentNotificationData;

    constructor(props: CommentNotificationProps) {
        super(props);
        this.data = props.data;
    }

    copyWith(props: Partial<CommentNotificationProps>): CommentNotification {
        return new CommentNotification({ ...this, ...props });
    }
}
