import { AsyncPipe } from '@angular/common';
import { Component, computed, inject, input, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';

import { InformationsContext } from ':modules/informations/informations.context';
import { RestaurantBusinessHoursComponent } from ':shared/components/restaurant-informations/restaurant-business-hours/restaurant-business-hours.component';
import { RestaurantOtherHoursComponent } from ':shared/components/restaurant-informations/restaurant-other-hours/restaurant-other-hours.component';
import { RestaurantSpecialHoursComponent } from ':shared/components/restaurant-informations/restaurant-special-hours/restaurant-special-hours.component';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { HoursModalComponent, HoursModalTabs, HoursUpdateData } from '../hours-modal/hours-modal.component';

@Component({
    selector: 'app-hours',
    templateUrl: './hours.component.html',
    styleUrls: ['./hours.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        RestaurantBusinessHoursComponent,
        RestaurantSpecialHoursComponent,
        TranslateModule,
        RestaurantOtherHoursComponent,
        AsyncPipe,
    ],
})
export class HoursComponent {
    readonly restaurant = input.required<Restaurant>();
    readonly hoursChange = output<HoursUpdateData>();
    readonly prepareHoursDuplication = output<HoursModalTabs>();

    readonly SvgIcon = SvgIcon;

    readonly selectedTab = signal<HoursModalTabs>(HoursModalTabs.REGULAR_HOURS);

    private readonly _informationsContext = inject(InformationsContext);

    readonly isDuplicateBtnDisabled = computed(() => {
        const selectedTab = this.selectedTab();
        const restaurant = this.restaurant();
        if (selectedTab === HoursModalTabs.REGULAR_HOURS) {
            return !restaurant.regularHours || restaurant.regularHours.length === 0;
        }

        if (selectedTab === HoursModalTabs.SPECIAL_HOURS) {
            return (
                !restaurant.specialHours ||
                restaurant.specialHours.length === 0 ||
                restaurant.specialHours.filter((hour) => hour.startDate.getDate().setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0))
                    .length === 0
            );
        }

        if (selectedTab === HoursModalTabs.OTHER_HOURS) {
            return !restaurant.otherHours || restaurant.otherHours.length === 0;
        }
    });

    constructor(private readonly _customDialogService: CustomDialogService) {
        this._informationsContext.openRestaurantHoursModal$.pipe(takeUntilDestroyed()).subscribe(() => this.openHoursModal());
    }

    openHoursModal(): void {
        this._customDialogService
            .open<HoursModalComponent, any, HoursUpdateData>(HoursModalComponent, {
                panelClass: ['malou-dialog-panel'],
                height: undefined,
                width: '750px',
                data: { restaurant: this.restaurant(), selectedTab: this.selectedTab() },
            })
            .afterClosed()
            .subscribe((data) => {
                if (data && Object.keys(data).length > 0) {
                    this.hoursChange.emit(data);
                }
            });
    }

    handleTabChange(tabIndex: HoursModalTabs): void {
        this.selectedTab.set(tabIndex);
    }

    openHoursDuplicationModal(): void {
        this.prepareHoursDuplication.emit(this.selectedTab());
    }
}
