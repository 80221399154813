import { createAction, props } from '@ngrx/store';

import { ApplicationLanguage, PlatformDataFetchedStatus, PlatformKey, TranslationSource } from '@malou-io/package-utils';

import {
    ArchiveReviewsFilters,
    CommentReviewsFilters,
    DatesAndPeriod,
    FetchedState,
    Pagination,
    Review,
    ReviewsFilters,
    StatusReviewsFilter,
} from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';
import { UserFilters } from ':shared/models/user-filters.model';

import { PendingReviewReply } from '../answer-review-container/answer-review.interface';
import { ReviewStrategyType } from '../reviews/reviews.strategy';
import { SynchronizationStatus } from './reviews.interface';
import { ReviewsDisplayMode } from './reviews.reducer';

export const initializeState = createAction(
    '[Aggregated Reviews] Initialize_State',
    props<{ data: UserFilters['aggregatedReviewsFilters'] }>()
);

export const editReviewsFilters = createAction('[Reviews] Edit_Reviews_Filters', props<{ filters: ReviewsFilters }>());

export const editReviewsDatesFilters = createAction('[Reviews] Edit_Reviews_Date_Filters', props<{ datesAndPeriod: DatesAndPeriod }>());

export const resetReviewsState = createAction('[Reviews] Reset_Reviews_State');

export const resetReviewsStateExceptRestaurants = createAction('[Reviews] Reset_Reviews_State_Except_Restaurants');

export const editReviewsFiltersSearch = createAction('[Reviews] Edit_Reviews_Filters_Search', props<{ search: string }>());

export const editSemanticAnalysisToggle = createAction('[Reviews] Edit_Semantic_Analysis_Toggle', props<{ toggle: boolean }>());

export const resetReviewsFiltersDates = createAction('[Reviews] Reset_Reviews_Filters_Dates');

export const editReviewsFiltersRatings = createAction('[Reviews] Edit_Reviews_Filters_Ratings', props<{ ratings: number[] }>());

export const editReviewsFiltersPlatforms = createAction('[Reviews] Edit_Reviews_Filters_Platforms', props<{ platforms: PlatformKey[] }>());

export const toggleReviewsFiltersPlatform = createAction('[Reviews] Toggle_Reviews_Filters_Platform', props<{ platform: PlatformKey }>());

export const toggleReviewsFiltersStatus = createAction('[Reviews] Toggle_Reviews_Filters_Status', props<{ status: StatusReviewsFilter }>());

export const toggleReviewsFiltersComment = createAction(
    '[Reviews] Toggle_Reviews_Filters_Comments',
    props<{ commentFilter: CommentReviewsFilters }>()
);

export const toggleReviewsFiltersArchive = createAction(
    '[Reviews] Toggle_Reviews_Filters_Archive',
    props<{ archiveFilter: ArchiveReviewsFilters }>()
);

export const editSelectablePlatforms = createAction('[Reviews] Edit_Selectable_Platform_Keys', props<{ platforms: PlatformKey[] }>());

export const setReviews = createAction(
    '[Reviews] Set_Reviews',
    props<{
        reviews: (Review | PrivateReview)[];
        strategyType: ReviewStrategyType;
    }>()
);

export const editReview = createAction(
    '[Reviews] Edit_Review',
    props<{
        review: Review | PrivateReview;
    }>()
);

export const toggleArchived = createAction('[Reviews] Toggle_Archived', props<{ review: Review }>());

export const setEstimatedReviewCount = createAction('[Reviews] Set_Estimated_Review_Count', props<{ count: number | undefined }>());
export const fetchEstimatedReviewCount = createAction('[Reviews] Fetch_Estimated_Review_Count');

export const setHasLoadedAllReviews = createAction('[Reviews] Set_Has_Loaded_All_Reviews', props<{ hasLoadedAllReviews: boolean }>());

export const editReviewsReply = createAction('[Reviews] Edit_Reviews_Reply', props<{ reply: PendingReviewReply }>());

export const editCurrentReplyReviewId = createAction('[Reviews] Edit_Current_Reply_Review_Id', props<{ reviewId: string }>());

export const resetReplies = createAction('[Reviews] Reset_Replies');

export const synchronizeReviews = createAction('[Reviews] Synchronize_Reviews', props<{ forceSynchronize: boolean }>());

export const cancelCurrentReviewsSynchronization = createAction('[Reviews] Cancel_Current_Reviews_Synchronization');

export const setFetchStates = createAction(
    '[Reviews] Set_Data_States',
    props<{ fetchStates: Record<string, FetchedState<PlatformDataFetchedStatus>> }>()
);

export const setSynchronizationStatus = createAction(
    '[Reviews] Set_Synchronization_Status',
    props<{ synchronizationStatus: SynchronizationStatus }>()
);

export const setPagination = createAction('[Reviews] Set_Pagination', props<{ pagination: Pagination }>());

export const resetPagination = createAction('[Reviews] Reset_Pagination');

export const increasePaginationNumber = createAction('[Reviews] Increase_Pagination_Number');

export const fetchReviews = createAction('[Reviews] Fetch_Reviews', props<{ strategyType: ReviewStrategyType }>());

export const getOrFetchReviews = createAction(
    '[Reviews] Get_Or_Fetch_Reviews',
    props<{ strategyType: ReviewStrategyType; reviewId: string }>()
);

export const editReviewsFiltersDates = createAction('[Reviews] Edit_Reviews_Filters_Dates', props<{ datesFilters: DatesAndPeriod }>());

export const getPageAndFetchReviews = createAction(
    '[Reviews] Get_Page_And_Get_Review',
    props<{ reviewId: string; strategyType: ReviewStrategyType }>()
);

export const fetchUnansweredReviewCount = createAction('[Reviews] Fetch_Unanswered_Review_Count', props<{ filters?: ReviewsFilters }>());

export const setUnansweredReviewCount = createAction('[Reviews] Set_Unanswered_Review_Count', props<{ count: number }>());

export const editRestaurants = createAction('[Reviews] Edit_Restaurants', props<{ restaurantsIds: string[] }>());

export const editReviewsFiltersCurrentView = createAction(
    '[Reviews] Edit_Reviews_Filters_Current_View',
    props<{ currentView: ReviewsDisplayMode }>()
);

export const couldNotGetPageNumberFromReviewId = createAction(
    '[Reviews] Could_Not_Get_Page_Number_From_Review_Id',
    props<{ errorMessage: string }>()
);

export const deleteReviewById = createAction('[Reviews] Delete_Review_By_Id', props<{ reviewId: string }>());

export const clearFilters = createAction('[Reviews] Clear_Filters');

export const addTranslationToReview = createAction(
    '[Reviews] Add_Translation_To_Review',
    props<{ reviewId: string; language: ApplicationLanguage; text: string; source: TranslationSource }>()
);
