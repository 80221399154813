@if (shouldDisplayDailyView) {
    <ng-container [ngTemplateOutlet]="dailyView"></ng-container>
} @else {
    <ng-container [ngTemplateOutlet]="weeklyOrMonthlyView"></ng-container>
}

<ng-template #weeklyOrMonthlyView>
    <div class="flex h-full flex-col gap-4 py-5">
        <div class="flex items-center justify-between px-7.5">
            <div class="flex items-center">
                <span class="malou-text-section-title malou-color-text-1 mr-3.5" data-cy="currentWeekSpan">{{
                    getTitle | applyPure: MonthStringSize.long : isWeeklyView : weekDays : monthWeeks
                }}</span>
                <div class="mr-4 w-44">
                    @if (hasPostsWithOpenedFeedback) {
                        <button
                            class="malou-text-13--semibold !p-0 !text-malou-color-state-warn"
                            mat-button
                            (click)="openPostsWithOpenedFeedback()">
                            {{ openedFeedbacks.length }}
                            {{ 'dashboard.calendar.feedbacks_not_completed' | pluralTranslate: openedFeedbacks.length }}
                        </button>
                    }
                </div>
            </div>
            <div class="flex items-center">
                <div class="mr-3.5">
                    <mat-button-toggle-group class="malou-group-toggle-btn md:!hidden">
                        <mat-button-toggle data-cy="weeklyViewSpan" [checked]="isWeeklyView" (click)="changeView(ViewMode.WEEK)">
                            {{ 'dashboard.calendar.week' | translate }}
                        </mat-button-toggle>
                        <mat-button-toggle data-cy="monthlyViewSpan" [checked]="!isWeeklyView" (click)="changeView(ViewMode.MONTH)">
                            {{ 'dashboard.calendar.month' | translate }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="mr-3.5">
                    <button class="malou-btn-raised--primary !h-12.5" data-cy="todayBtn" mat-raised-button (click)="resetViewTime()">
                        {{ 'dashboard.calendar.today' | translate }}
                    </button>
                </div>
                <button class="malou-btn-icon--secondary btn-xl !mr-3.5" data-cy="arrowBackIcon" mat-icon-button (click)="change(-1)">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.CHEVRON_LEFT"></mat-icon>
                </button>
                <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="change(1)">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.CHEVRON_RIGHT"></mat-icon>
                </button>
            </div>
            <div class="flex items-center">
                <button class="malou-btn-raised--secondary--alt btn-xl !h-12.5" mat-raised-button [matMenuTriggerFor]="countriesMenu">
                    <div class="flex items-center gap-x-4">
                        <span class="xl:hidden">{{ 'dashboard.calendar.choose_country' | translate }}</span>
                        <img
                            class="!h-4 !w-4"
                            [src]="selectedEventsCountry.toLowerCase() | flagPathResolver"
                            [alt]="getPrettyLang | applyPure: selectedEventsCountry" />
                    </div>
                </button>
            </div>
        </div>
        @if (!isChangingViewLoading) {
            <div class="min-h-0 grow px-7.5">
                <div class="malou-simple-card relative h-full py-4">
                    @if (isLoading()) {
                        <mat-progress-bar class="!absolute top-0" mode="indeterminate"></mat-progress-bar>
                    }
                    @if (isWeeklyView) {
                        <ng-container [ngTemplateOutlet]="weeklyView"></ng-container>
                    } @else {
                        <ng-container [ngTemplateOutlet]="monthlyView"></ng-container>
                    }

                    <!-- expand modal -->
                    <div>
                        @if (displayedPost) {
                            <app-calendar-post
                                class="!w-[155px]"
                                id="postModal"
                                [post]="displayedPost"
                                (delete)="deletePost($event)"
                                (open)="openPost($event)"
                                (duplicate)="duplicatePost($event)"></app-calendar-post>
                        }
                    </div>

                    @if (allElementsDisplayed) {
                        <div
                            class="malou-card malou-card__body custom-shadow invisible !absolute max-h-[220px] !border-none !p-[3px] !pr-0"
                            id="allElements">
                            <div class="overflow-y-auto !p-3.5">
                                @if (displayMorePosts) {
                                    <div class="flex w-[380px] flex-col justify-center">
                                        @for (post of getElementsModalDay()?.posts; track trackByIdFn($index, post)) {
                                            <app-calendar-post
                                                id="postCard"
                                                [post]="post"
                                                [postFormat]="PostDisplayFormat.ROW"
                                                (delete)="deletePost($event)"
                                                (open)="openPost($event)"
                                                (duplicate)="duplicatePost($event)">
                                            </app-calendar-post>
                                        }
                                    </div>
                                }

                                @if (displayMoreEvents) {
                                    <div class="flex flex-col">
                                        @for (
                                            event of getElementsModalDay()?.specialEvents;
                                            track trackByIdFn($index, event);
                                            let isLast = $last
                                        ) {
                                            <div class="malou-text-10--semibold flex flex-col justify-center">
                                                <div class="flex items-center justify-between" [ngClass]="{ 'mb-2': !isLast }">
                                                    <span class="pr-5 text-center" [matTooltip]="formatEventText | applyPure: event">
                                                        {{ event?.emoji }} {{ event?.getNameForLang(currentLang) }}
                                                    </span>
                                                    <button
                                                        class="malou-btn-icon--secondary btn-xl"
                                                        mat-icon-button
                                                        matTooltip="{{ 'dashboard.calendar.delete' | translate }}"
                                                        (click)="deleteEvent(event)">
                                                        <mat-icon [svgIcon]="SvgIcon.TRASH"></mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        } @else {
            <ng-container *ngTemplateOutlet="loadingPageTemplate"></ng-container>
        }
    </div>
</ng-template>

<ng-template #weeklyView>
    <div class="flex h-full">
        @for (day of weekDays; track trackByKeyFn($index, day); let isLast = $last) {
            <div class="calendar-day flex h-full flex-1 flex-col">
                <div
                    class="relative flex flex-col items-center justify-center p-3.5 text-malou-color-text-2"
                    [ngClass]="{
                        '!text-malou-color-text-1': isToday(day.date),
                    }">
                    <span class="malou-text-14--regular mb-1" [ngClass]="{ '!font-bold': isToday(day.date) }">{{ day.value }}</span>
                    <span class="malou-text-13--semibold">{{ getDateAndMonth(day.date) }}</span>
                    @if (day.isBankHoliday) {
                        <div
                            class="malou-status--waiting absolute left-[10px] ml-2 !h-3.5 !w-3.5"
                            [matTooltip]="
                                restaurant?.isBrandBusiness()
                                    ? ('dashboard.calendar.bank_holiday_title' | translate)
                                    : ('dashboard.calendar.bank_holiday' | translate)
                            ">
                            <span class="mt-0.5 text-xs">!</span>
                        </div>
                    }

                    <div class="show-on-hover absolute bottom-[20px] right-[10px] flex p-0.5">
                        @if (day.isBankHoliday && isAfterToday(day.date) && !restaurant?.isBrandBusiness()) {
                            <button
                                class="malou-btn-icon--secondary btn-sm !mr-1.5"
                                data-cy="editHoursIcon"
                                matTooltip="{{ 'dashboard.calendar.edit_hours' | translate }}"
                                mat-icon-button
                                (click)="editHours(day.date)">
                                <mat-icon class="!h-3.5 !w-3.5" [svgIcon]="SvgIcon.ALARM"></mat-icon>
                            </button>
                        }
                        <button
                            class="malou-btn-icon--secondary btn-sm"
                            data-cy="addEventIcon"
                            mat-icon-button
                            matTooltip="{{ 'dashboard.calendar.add_event' | translate }}"
                            (click)="openCreateEventModal(day.date)">
                            <mat-icon class="!h-3.5 !w-3.5" [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
                        </button>
                    </div>
                </div>
                <div
                    class="hide-scrollbar flex min-h-0 grow flex-col items-center overflow-y-auto border-malou-color-border-primary hover:bg-malou-color-gradient-background--hover"
                    [ngClass]="{ 'border-r': !isLast }">
                    @for (event of day.specialEvents; track trackByIdFn($index, event); let isLastEvent = $last) {
                        <div class="relative mb-1.5 flex w-full items-center justify-between" [ngClass]="{ '!mb-3.5': isLastEvent }">
                            <span
                                class="malou-text-10--semibold mx-3.5 flex min-h-[44px] w-full items-center justify-center rounded-[10px] border border-malou-color-border-primary bg-white p-1.5 text-center"
                                data-cy="eventNameSpan"
                                [matTooltip]="formatEventText | applyPure: event">
                                {{ event.emoji }} {{ event.getNameForLang(currentLang) }}</span
                            >
                            <div class="show-on-hover absolute right-[19px] p-0.5">
                                <button
                                    class="malou-btn-icon--secondary btn-sm"
                                    data-cy="deleteEventIcon"
                                    mat-icon-button
                                    matTooltip="{{ 'dashboard.calendar.delete' | translate }}"
                                    (click)="deleteEvent(event)">
                                    <mat-icon class="!h-3.5 !w-3.5" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                                </button>
                            </div>
                        </div>
                    }
                    <div class="flex w-full flex-col items-center" data-cy="postColumnDiv">
                        @if (day.posts) {
                            <div class="mb-4 flex w-full flex-col">
                                <div class="flex flex-col" [ngStyle]="getPostContainerMaxHeight | applyPure: day">
                                    @for (post of day.posts; track trackByIdFn($index, post); let isLastPost = $last) {
                                        <app-calendar-post
                                            class="hover-post-day"
                                            id="postCard"
                                            [post]="post"
                                            [ngClass]="{ 'pb-9': isLastPost }"
                                            (delete)="deletePost($event)"
                                            (open)="openPost($event)"
                                            (duplicate)="duplicatePost($event)">
                                        </app-calendar-post>
                                    }
                                </div>
                            </div>
                        }
                        @if (isToday(day.date) || isAfterToday(day.date)) {
                            <div class="show-on-hover mt-auto flex w-full justify-center">
                                <button
                                    class="malou-btn-raised--primary !fixed bottom-[35px] !mx-3.5 !mb-3.5 !h-11 w-[132px]"
                                    mat-raised-button
                                    [matMenuTriggerFor]="createPostWeekly">
                                    {{ 'dashboard.calendar.create_post' | translate }}
                                </button>
                            </div>
                        }

                        <mat-menu class="malou-mat-menu malou-box-shadow custom-option-panel" #createPostWeekly="matMenu">
                            <div matTooltip="{{ 'posts.connect_gmb' | translate }}" [matTooltipDisabled]="isGmbConnected$ | async">
                                @if (!restaurant?.isBrandBusiness()) {
                                    <button
                                        id="tracking_new_seo_post_from_calendar"
                                        mat-menu-item
                                        [disabled]="!(isGmbConnected$ | async)"
                                        (click)="createPost(PostSource.SEO, day.date)">
                                        <mat-icon class="!h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.IMAGE"></mat-icon>
                                        <span class="malou-text-14--regular">{{ 'dashboard.calendar.create_in_seo' | translate }}</span>
                                    </button>
                                }
                            </div>
                            <div>
                                <button
                                    id="tracking_new_social_post_from_calendar"
                                    mat-menu-item
                                    (click)="createPost(PostSource.SOCIAL, day.date)">
                                    <mat-icon class="!h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.IMAGE"></mat-icon>
                                    <span class="malou-text-14--regular">{{ 'dashboard.calendar.create_in_social' | translate }}</span>
                                </button>
                            </div>
                            <div>
                                <button
                                    id="tracking_new_reel_from_calendar"
                                    mat-menu-item
                                    (click)="createPost(PostSource.SOCIAL, day.date, PostType.REEL)">
                                    <mat-icon class="!h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.VIDEO"></mat-icon>
                                    <span class="malou-text-14--regular">{{ 'dashboard.calendar.create_reel' | translate }}</span>
                                </button>
                            </div>
                            <div>
                                <button id="tracking_new_story_from_calendar" mat-menu-item (click)="createStory(day.date)">
                                    <mat-icon class="!h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.IMAGE"></mat-icon>
                                    <span class="malou-text-14--regular">{{ 'dashboard.calendar.create_story' | translate }}</span>
                                </button>
                            </div>
                        </mat-menu>
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #monthlyView>
    <div class="flex h-full flex-col">
        <div class="flex">
            @for (day of weekDays; track trackByKeyFn($index, day)) {
                <div class="flex-1 p-3.5 text-center opacity-50">
                    <span class="malou-color-text-2 malou-text-14--regular">{{ day.value }}</span>
                </div>
            }
        </div>

        <div class="hide-scrollbar flex min-h-0 grow flex-col overflow-y-auto" id="monthlyScrollContainer">
            @for (week of monthWeeks; track week; let isLastRow = $last; let y = $index) {
                <div class="flex">
                    @for (day of week; track trackByDateFn($index, day); let isLastColumn = $last; let x = $index) {
                        <div
                            class="calendar-day flex min-h-[150px] flex-1 flex-col justify-center rounded-[10px] hover:bg-malou-color-gradient-background--hover">
                            <div class="flex h-full flex-col items-center">
                                <div class="flex h-full w-full items-center justify-between">
                                    <ng-container
                                        [ngTemplateOutlet]="monthlyCard"
                                        [ngTemplateOutletContext]="{ week, day, y, x }"></ng-container>
                                    @if (!isLastColumn) {
                                        <div class="h-5/6 border-r border-malou-color-border-primary"></div>
                                    }
                                </div>
                                @if (!isLastRow) {
                                    <div class="w-5/6 border-b border-malou-color-border-primary"></div>
                                }
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template let-week="week" let-day="day" let-y="y" let-x="x" let-isLastRow="isLastRow" let-isLastColumn="isLastColumn" #monthlyCard>
    <div class="flex h-full w-full flex-col gap-2 p-2" id="monthlyCalendarColumn">
        <div
            class="malou-text-14--semibold relative flex items-center text-malou-color-text-2--light"
            [ngClass]="{
                '!text-malou-color-text-1': isTodayForMalouDate(day.date),
                '!font-bold': isTodayForMalouDate(day.date),
            }">
            {{ day.date.day }}
            @if (day.isBankHoliday) {
                <div
                    class="malou-status--waiting ml-2 !h-3.5 !w-3.5"
                    [matTooltip]="
                        restaurant?.isBrandBusiness()
                            ? ('dashboard.calendar.bank_holiday_title' | translate)
                            : ('dashboard.calendar.bank_holiday' | translate)
                    ">
                    <span class="mt-0.5 text-xs">!</span>
                </div>
            }
            <div class="show-on-hover absolute -top-1 right-0 flex">
                @if (day.isBankHoliday && isAfterTodayForMalouDate(day.date)) {
                    <button
                        class="malou-btn-icon--secondary btn-sm !mr-1.5"
                        data-cy="editHoursIcon"
                        matTooltip="{{ 'dashboard.calendar.edit_hours' | translate }}"
                        mat-icon-button
                        (click)="monthlyEditHours(y, x)">
                        <mat-icon class="!h-3.5 !w-3.5" [svgIcon]="SvgIcon.ALARM"></mat-icon>
                    </button>
                }
                <button
                    class="malou-btn-icon--secondary btn-sm !mr-1.5"
                    data-cy="addEventIcon"
                    mat-icon-button
                    matTooltip="{{ 'dashboard.calendar.add_event' | translate }}"
                    (click)="monthlyOpenCreateEventModal(day.date)">
                    <mat-icon class="!h-3.5 !w-3.5" [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
                </button>
                @if ([0, 1].includes(compareDates(day.date))) {
                    <button
                        class="malou-btn-icon--primary btn-sm"
                        mat-icon-button
                        matTooltip="{{ 'dashboard.calendar.create_post' | translate }}"
                        [matMenuTriggerFor]="createPostMonthly">
                        <mat-icon class="!h-3.5 !w-3.5" [svgIcon]="SvgIcon.ADD"></mat-icon>
                    </button>
                }
                <mat-menu class="malou-mat-menu malou-box-shadow custom-option-panel" #createPostMonthly="matMenu">
                    <div matTooltip="{{ 'posts.connect_gmb' | translate }}" [matTooltipDisabled]="isGmbConnected$ | async">
                        @if (!restaurant?.isBrandBusiness()) {
                            <button
                                id="tracking_new_seo_post_from_monthly_calendar"
                                mat-menu-item
                                [disabled]="!(isGmbConnected$ | async)"
                                (click)="monthlyCreatePost(PostSource.SEO, day.date)">
                                <mat-icon class="!h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.IMAGE"></mat-icon>
                                <span class="malou-text-14--regular">{{ 'dashboard.calendar.create_in_seo' | translate }}</span>
                            </button>
                        }
                    </div>
                    <div>
                        <button
                            id="tracking_new_social_post_from_monthly_calendar"
                            mat-menu-item
                            (click)="monthlyCreatePost(PostSource.SOCIAL, day.date)">
                            <mat-icon class="!h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.IMAGE"></mat-icon>
                            <span class="malou-text-14--regular">{{ 'dashboard.calendar.create_in_social' | translate }}</span>
                        </button>
                    </div>
                    <div>
                        <button
                            id="tracking_new_reel_from_monthly_calendar"
                            mat-menu-item
                            (click)="monthlyCreatePost(PostSource.SOCIAL, day.date, PostType.REEL)">
                            <mat-icon class="!h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.VIDEO"></mat-icon>
                            <span class="malou-text-14--regular">{{ 'dashboard.calendar.create_reel' | translate }}</span>
                        </button>
                    </div>
                    <div>
                        <button id="tracking_new_story_from_monthly_calendar" mat-menu-item (click)="monthlyCreateStory(day.date)">
                            <mat-icon class="!h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.IMAGE"></mat-icon>
                            <span class="malou-text-14--regular">{{ 'dashboard.calendar.create_story' | translate }}</span>
                        </button>
                    </div>
                </mat-menu>
            </div>
        </div>

        @if (day.specialEvents.length > 0) {
            <div class="mt-3 flex justify-center">
                <div class="relative flex w-full justify-center">
                    <span
                        class="malou-text-10--semibold flex min-h-[44px] w-full items-center justify-center rounded-[10px] border border-malou-color-border-primary bg-white p-1.5 text-center"
                        [matTooltip]="formatEventText | applyPure: day.specialEvents[0]">
                        {{ day.specialEvents[0]?.emoji }}
                        {{ day.specialEvents[0]?.getNameForLang(currentLang) | shortText: getShortTextSizeForScreenSize() }}
                    </span>
                    <div class="show-on-hover absolute bottom-[5px] right-[5px] p-0.5">
                        @if (day.specialEvents.length === 1) {
                            <button
                                class="malou-btn-icon--secondary btn-sm"
                                data-cy="deleteEventIcon"
                                mat-icon-button
                                matTooltip="{{ 'dashboard.calendar.delete' | translate }}"
                                (click)="deleteEvent(day.specialEvents[0])">
                                <mat-icon class="!h-3.5 !w-3.5" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                            </button>
                        }
                    </div>
                </div>
                <div
                    class="malou-text-10--semibold malou-color-primary m-1 flex items-center justify-center"
                    id="showMoreEventsBtn"
                    [ngClass]="{
                        hidden: day.specialEvents.length === 0,
                        invisible: day.specialEvents.length === 1,
                        '!m-0': day.specialEvents.length === 1,
                        '!w-2': day.specialEvents.length === 1,
                        'cursor-pointer': day.specialEvents.length > 1,
                    }"
                    (click)="displayAllElements($event, 'events', y, x, isLastRow)">
                    +{{ day.specialEvents.length - 1 }}
                </div>
            </div>
        }

        <div class="grow-1 flex flex-col justify-around">
            @if (hasPostsWithFeedback(day.posts)) {
                <div class="malou-text-9--semibold malou-color-state-warn pl-1">
                    {{ 'dashboard.calendar_post.feedback_not_completed' | translate }}
                </div>
            }
            <div class="relative ml-2 mt-2.5 flex h-5 w-full flex-wrap items-center justify-start gap-x-3 gap-y-2">
                @for (post of getPostsToShow(day, 3); track trackByIdFn($index, post)) {
                    <div id="postModalImg" (click)="displayPost(post, y, x)">
                        <div class="relative w-[30px] cursor-pointer" [ngClass]="{ '!w-[37px]': post.keys.length > 1 }">
                            @for (key of getPostKeys(post); track key; let i = $index) {
                                <app-platform-logo
                                    [withBorder]="post.isStory"
                                    [borderWidthClass]="key === PlatformKey.FACEBOOK ? 'border-2' : 'border'"
                                    [imgClasses]="'absolute top-[-10px] !h-5 !w-5 left-[' + i * 10 * 7 + 'px]'"
                                    [logo]="getPostLogoSrc | applyPure: { key, post }"></app-platform-logo>
                            }
                            <span
                                class="absolute right-[0px] top-[2px] ml-2 flex h-2 w-2 justify-center rounded-full"
                                [ngStyle]="{ 'left.px': post.keys.length > 1 ? post.keys.length * 10 + 3 : 13 }"
                                [ngClass]="{
                                    'bg-malou-color-primary': post.postStatus.type === ExtendedPostPublicationStatus.DRAFT,
                                    'bg-malou-color-state-warn': post.postStatus.type === ExtendedPostPublicationStatus.PENDING,
                                    'bg-malou-color-state-success': post.postStatus.type === ExtendedPostPublicationStatus.PUBLISHED,
                                    'bg-malou-color-state-error': post.postStatus.type === ExtendedPostPublicationStatus.ERROR,
                                }"></span>
                        </div>
                    </div>
                }
                @if (day.posts.length > 3) {
                    <span
                        class="malou-text-10--semibold malou-color-primary flex h-5 cursor-pointer leading-6"
                        id="showMorePostsBtn"
                        matTooltip="{{ 'dashboard.calendar.see_posts' | translate }}"
                        (click)="displayAllElements($event, 'posts', y, x, isLastRow)">
                        +{{ day.posts.length - 3 }}
                    </span>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #dailyView>
    <div class="flex h-full flex-col gap-4 py-2">
        <div class="m-2.5 flex items-center justify-between">
            <div class="flex items-center">
                <span class="malou-text-section-title mr-3.5">{{ getTitle(MonthStringSize.short) }}</span>
            </div>
            <div class="flex items-center justify-between">
                <button class="malou-btn-icon--secondary btn-xl !mr-1.5" data-cy="arrowBackIcon" mat-icon-button (click)="change(-1)">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.CHEVRON_LEFT"></mat-icon>
                </button>
                <button class="malou-btn-icon--secondary btn-xl !mr-1.5" mat-icon-button (click)="change(1)">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.CHEVRON_RIGHT"></mat-icon>
                </button>
                <button class="malou-btn-icon--secondary btn-xl !mr-1.5" mat-icon-button [matMenuTriggerFor]="countriesMenu">
                    <img
                        class="!h-4 !w-4"
                        [src]="selectedEventsCountry.toLowerCase() | flagPathResolver"
                        [alt]="getPrettyLang | applyPure: selectedEventsCountry" />
                </button>
                <button class="malou-btn-icon--secondary btn-xl !mr-1.5" mat-icon-button (click)="goToToday({ delayMs: 0 })">
                    <mat-icon svgIcon="calendar-2"></mat-icon>
                </button>
            </div>
        </div>
        @if (!isChangingViewLoading) {
            <ng-container>
                <div class="relative min-h-0 grow overflow-y-auto px-3" id="days">
                    <div class="malou-simple-card mb-[80px] flex px-3 pt-3">
                        <div class="flex w-full flex-col">
                            @for (day of getAllMonthDays(); track trackByDateFn($index, day); let i = $index) {
                                <div class="displayed-days pb-8" [id]="isTodayForMalouDate(day.date) ? 'today' : i">
                                    <div
                                        class="flex justify-between text-malou-color-text-2--light"
                                        [ngClass]="{
                                            '!text-malou-color-text-1': isTodayForMalouDate(day.date),
                                        }">
                                        <span class="malou-text-17--regular"
                                            >{{ getDayValue(day.date) }} <span class="font-semibold"> {{ day.date.day }}</span></span
                                        >
                                        <div class="flex">
                                            @if (day.isBankHoliday && isAfterTodayForMalouDate(day.date)) {
                                                <button
                                                    class="malou-btn-icon--secondary !mr-1.5"
                                                    mat-icon-button
                                                    (click)="monthlyEditHoursByDate(day.date)">
                                                    <mat-icon class="!h-3.5 !w-3.5" [svgIcon]="SvgIcon.ALARM"></mat-icon>
                                                </button>
                                            }
                                            <button
                                                class="malou-btn-icon--secondary"
                                                mat-icon-button
                                                matTooltip="{{ 'dashboard.calendar.add_event' | translate }}"
                                                (click)="monthlyOpenCreateEventModal(day.date)">
                                                <mat-icon class="!h-3.5 !w-3.5" [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col">
                                        @if (day.isBankHoliday) {
                                            <div
                                                class="mt-2.5 flex min-h-[42px] w-full items-center justify-start rounded-[10px] border border-malou-color-border-primary p-1.5">
                                                <div class="malou-status--waiting ml-2 mr-2.5 !h-3.5 !w-3.5">
                                                    <span class="mt-0.5 text-xs">!</span>
                                                </div>
                                                <div class="malou-text-10--regular flex flex-col">
                                                    <span class="font-semibold">{{
                                                        'dashboard.calendar.bank_holiday_title' | translate
                                                    }}</span>
                                                    @if (!restaurant?.isBrandBusiness()) {
                                                        <span>
                                                            {{ 'dashboard.calendar.bank_holiday_text' | translate }}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        @for (event of day.specialEvents; track trackByIdFn($index, event)) {
                                            <div
                                                class="mt-2.5 flex min-h-[42px] w-full items-center justify-between rounded-[10px] border border-malou-color-border-primary p-1.5">
                                                <span class="malou-text-10--semibold ml-2">
                                                    {{ event.emoji }} {{ event.getNameForLang(currentLang) }}</span
                                                >
                                                <mat-icon
                                                    class="!h-[15px] !w-[15px]"
                                                    color="primary"
                                                    [svgIcon]="SvgIcon.TRASH"
                                                    (click)="deleteEvent(event)"></mat-icon>
                                            </div>
                                        }
                                    </div>
                                    <div class="flex flex-col justify-center">
                                        @for (post of day.posts; track trackByIdFn($index, post)) {
                                            <app-calendar-post
                                                id="postCard"
                                                [post]="post"
                                                (delete)="deletePost($event)"
                                                (open)="openPost($event)"
                                                (duplicate)="duplicatePost($event)"></app-calendar-post>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div class="absolute bottom-16 flex w-full justify-center">
                    @if (!showCreatePostMenuMobile) {
                        <button
                            class="malou-btn-raised--primary !mx-3.5 !mb-3.5 !h-12.5 w-[90%]"
                            id="createPostBtnMobile"
                            mat-raised-button
                            (click)="openAddPostModal()">
                            <span class="malou-text-14--semibold">{{ 'dashboard.calendar.create_post' | translate }}</span>
                        </button>
                    }
                </div>
            </ng-container>
        } @else {
            <ng-container *ngTemplateOutlet="loadingPageTemplate"></ng-container>
        }
    </div>
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow custom-option-panel !mt-0" #countriesMenu="matMenu">
    @for (country of countriesWithEvents; track country) {
        <button
            class="malou-text-12--semibold !flex min-w-[250px] cursor-pointer"
            mat-menu-item
            [ngClass]="{ '!bg-malou-color-background-dark': selectedEventsCountry === country }"
            (click)="changeEventsCountry(country)">
            <div class="!flex !items-center">
                <img
                    class="mr-3 !h-4 !w-4 rounded-sm"
                    [src]="country.toLowerCase() | flagPathResolver"
                    [alt]="getPrettyLang | applyPure: country" />
                {{ getPrettyLang | applyPure: country }}
            </div>
        </button>
    }
</mat-menu>

<ng-template #loadingPageTemplate>
    <div class="min-h-0 grow overflow-y-hidden px-7.5">
        @if (isWeeklyView || isSmallScreen) {
            <div class="flex h-full justify-between">
                @for (item of getColumnNumberForSize(); track item; let isLast = $last) {
                    <app-skeleton
                        class="h-full w-full"
                        skeletonClass="w-full h-full secondary-bg p-3.5 flex flex-col"
                        [ngClass]="{ 'mr-0.5': !isLast }"
                        [animate]="false">
                        <ng-template #nestedSkeletonTemplate>
                            <app-skeleton skeletonClass="h-[46px]"></app-skeleton>
                            <div>
                                @switch (item) {
                                    @case (0) {
                                        <app-skeleton skeletonClass="h-[28px] mt-3" heightClass="h-auto"></app-skeleton>
                                        <app-skeleton skeletonClass="h-[165px] mt-3" heightClass="h-auto"></app-skeleton>
                                    }
                                    @case (1) {
                                        <app-skeleton skeletonClass="h-[28px] mt-3" heightClass="h-auto"></app-skeleton>
                                        <app-skeleton skeletonClass="h-[165px] mt-3" heightClass="h-auto"></app-skeleton>
                                        <app-skeleton skeletonClass="h-[165px] mt-3" heightClass="h-auto"></app-skeleton>
                                    }
                                    @case (2) {
                                        <app-skeleton skeletonClass="h-[165px] mt-3" heightClass="h-auto"></app-skeleton>
                                    }
                                    @case (3) {
                                        <app-skeleton skeletonClass="h-[28px] mt-3" heightClass="h-auto"></app-skeleton>
                                    }
                                    @case (4) {
                                        <app-skeleton skeletonClass="h-[165px] mt-3" heightClass="h-auto"></app-skeleton>
                                        <app-skeleton skeletonClass="h-[165px] mt-3" heightClass="h-auto"></app-skeleton>
                                        <app-skeleton skeletonClass="h-[165px] mt-3" heightClass="h-auto"></app-skeleton>
                                    }
                                    @case (5) {
                                        <app-skeleton skeletonClass="h-[28px] mt-3" heightClass="h-auto"></app-skeleton>
                                        <app-skeleton skeletonClass="h-[165px] mt-3" heightClass="h-auto"></app-skeleton>
                                        <app-skeleton skeletonClass="h-[165px] mt-3" heightClass="h-auto"></app-skeleton>
                                    }
                                    @case (6) {
                                        <app-skeleton skeletonClass="h-[165px] mt-3" heightClass="h-auto"></app-skeleton>
                                    }
                                }
                            </div>
                        </ng-template>
                    </app-skeleton>
                }
            </div>
        } @else {
            <div class="mt-5 flex h-full w-full flex-col">
                @for (row of [0, 1, 2, 3]; track row) {
                    <div class="mb-0.5 flex">
                        @for (column of [0, 1, 2, 3, 4, 5, 6]; track column) {
                            <div class="mr-0.5 w-full">
                                <app-skeleton
                                    class="h-[140px]"
                                    skeletonClass="secondary-bg p-3.5 flex flex-col h-[140px] w-full"
                                    [animate]="false">
                                    <ng-template #nestedSkeletonTemplate>
                                        @if (showSkeletonBox(row, column)) {
                                            <app-skeleton class="h-[46px] w-full" skeletonClass="h-[46px]"></app-skeleton>
                                        }
                                        @if (showSkeletonBox(row, column, MonthlyCalendarSkeletonBoxType.EVENT)) {
                                            <app-skeleton
                                                class="h-[28px] w-full"
                                                skeletonClass="h-[28px] mt-3"
                                                heightClass="h-auto"></app-skeleton>
                                        }
                                    </ng-template>
                                </app-skeleton>
                            </div>
                        }
                    </div>
                }
            </div>
        }
    </div>
</ng-template>
