import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-close-without-saving-modal',
    templateUrl: './close-without-saving-modal.component.html',
    styleUrls: ['./close-without-saving-modal.component.scss'],
    standalone: true,
    imports: [MatButtonModule, TranslateModule, IllustrationPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseWithoutSavingModalComponent {
    readonly onCancel = output<void>();
    readonly onConfirm = output<void>();

    onCancelFn(): void {
        this.onCancel.emit();
    }

    onConfirmFn(): void {
        this.onConfirm.emit();
    }
}
