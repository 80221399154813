import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { MAX_TIPS_TO_SHOW_CONGRATS, MIN_PERFORMANCE_SCORE_TO_SHOW_CONGRATS } from '@malou-io/package-utils';

import { TipItemComponent } from ':modules/roi/roi-tip-item/tip-item.component';
import { RoiContext } from ':modules/roi/roi.context';
import { RoiService } from ':modules/roi/roi.service';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Restaurant } from ':shared/models';
import { RoiTipItem } from ':shared/models/roi-tip.model';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { RoiTipsPathResolver } from ':shared/pipes/roi-tips-path-resolver.pipe';

@Component({
    selector: 'app-aggregated-tips-modal',
    standalone: true,
    imports: [
        MatIconModule,
        TranslateModule,
        TipItemComponent,
        IllustrationPathResolverPipe,
        SkeletonComponent,
        NgTemplateOutlet,
        RoiTipsPathResolver,
    ],
    templateUrl: './aggregated-tips-modal.component.html',
    styleUrl: './aggregated-tips-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedTipsModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly isLoading: WritableSignal<boolean> = signal(true);
    readonly isError: WritableSignal<boolean> = signal(false);
    readonly roiTips: WritableSignal<RoiTipItem[]> = signal([]);
    readonly restaurantName: WritableSignal<string> = signal('');
    readonly restaurantLogo: WritableSignal<string> = signal('');

    readonly MAX_TIPS_TO_SHOW_CONGRATS = MAX_TIPS_TO_SHOW_CONGRATS;
    readonly MIN_PERFORMANCE_SCORE_TO_SHOW_CONGRATS = MIN_PERFORMANCE_SCORE_TO_SHOW_CONGRATS;

    readonly shouldShowCongrats: WritableSignal<boolean> = signal(true);

    constructor(
        private readonly _dialogRef: MatDialogRef<AggregatedTipsModalComponent>,
        private readonly _roiService: RoiService,
        private readonly _roiContext: RoiContext,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            restaurant: Restaurant;
        }
    ) {}

    ngOnInit(): void {
        this._initRestaurantTips();
    }

    onActionPressed(): void {
        this.close();
    }

    close(): void {
        this._dialogRef.close();
    }

    private _initRestaurantTips(): void {
        this._roiService.getTipsForRestaurant(this.data.restaurant.id).subscribe({
            next: (res) => {
                this.restaurantLogo.set(this.data.restaurant.logo?.urls?.small ?? this.data.restaurant.logo?.urls?.original ?? '');
                this.restaurantName.set(this.data.restaurant.name ?? this.data.restaurant.internalName);
                this.isLoading.set(false);
                this.roiTips.set(res.data.map((tip) => new RoiTipItem({ ...tip, restaurantId: this.data.restaurant.id })));

                this.shouldShowCongrats.set(
                    (this._roiContext.performanceScorePerRestaurant().find((r) => r.restaurantId === this.data.restaurant.id)
                        ?.performanceScore ?? 0) >= this.MIN_PERFORMANCE_SCORE_TO_SHOW_CONGRATS
                );
            },
            error: () => {
                this.isError.set(true);
                this.isLoading.set(false);
            },
        });
    }
}
