<div class="align-center flex h-full w-full flex-col gap-4 overflow-y-auto px-8.5 py-4 pb-8" #scrollContainer>
    <ng-container [ngTemplateOutlet]="isCreatingRoiInsights() ? generatingRoiInsights : roiPageTemplate"></ng-container>
</div>

<ng-template #roiPageTemplate>
    @if (isBeforeLimitDate()) {
        <ng-container [ngTemplateOutlet]="noInsightsPage"></ng-container>
    } @else {
        <ng-container [ngTemplateOutlet]="alreadyHasSettings() ? checkPlatformsTemplate : addSettingsTemplate"></ng-container>
    }
</ng-template>

<ng-template #checkPlatformsTemplate>
    <ng-container
        [ngTemplateOutlet]="
            (platformsService.isOneOfThesePlatformsConnected$([PlatformKey.INSTAGRAM, PlatformKey.GMB, PlatformKey.FACEBOOK]) | async)
                ? roiStatisticsTemplate
                : platformsNotConnected
        "></ng-container
></ng-template>

<ng-template #platformsNotConnected>
    <app-no-connected-platforms
        [illustration]="Illustration.Cook"
        [message]="'roi.platforms_not_connected' | translate"></app-no-connected-platforms
    >>
</ng-template>

<ng-template #addSettingsTemplate>
    <div class="align-center flex h-full w-full flex-col justify-center">
        <app-upsert-roi-settings
            class="flex items-center justify-center sm:items-start"
            (onSave)="reload$.next(true)"></app-upsert-roi-settings>
    </div>
</ng-template>

<ng-template #roiStatisticsTemplate>
    <ng-container [ngTemplateOutlet]="hasRoiInsights() ? roiInsightsPage : noInsightsPage"></ng-container>
</ng-template>

<ng-template #noInsightsPage>
    <div class="grid min-h-[68vh] place-items-center">
        <div class="flex flex-col items-center">
            <div class="mb-10 w-32 md:mb-5 md:w-24">
                <img alt="Googles illustration" [src]="Illustration.Goggles | illustrationPathResolver" />
            </div>
            @if (isBeforeLimitDate()) {
                <div class="px-6 text-center">
                    <h3 class="malou-text-14--bold malou-color-text-1 mb-2">
                        {{ 'roi.no_access_before_three_months' | translate }}
                    </h3>
                    <p class="malou-text-10--regular">{{ 'roi.contact_us_if_unusual' | translate }}</p>
                </div>
            } @else {
                <div class="px-6 text-center">
                    <h3 class="malou-text-14--bold malou-color-text-1 mb-2">
                        {{ 'roi.no_insights_error' | translate }}
                    </h3>
                    <p class="malou-text-10--regular">{{ 'roi.contact_us' | translate }}</p>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #roiInsightsPage>
    <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
    <div class="flex w-full gap-4 sm:flex-col">
        <div class="h-full flex-1">
            <app-estimated-customers [isParentLoading]="isLoading()"></app-estimated-customers>
        </div>
        <div class="h-full w-[30%] sm:w-full sm:flex-1" [ngClass]="{ 'saved-time-container': !isLoading() }">
            <app-saved-time [isParentLoading]="isLoading()"></app-saved-time>
        </div>
    </div>

    <div class="flex gap-4 sm:flex-col">
        <div class="w-[32%] sm:w-full">
            <app-performance-score [isParentLoading]="isLoading()"></app-performance-score>
        </div>
        <div class="w-[68%] sm:w-full">
            <app-tips [isParentLoading]="isLoading()"></app-tips>
        </div>
    </div>
    <app-monthly-estimated-customers-and-performance-chart
        [isParentLoading]="isLoading()"></app-monthly-estimated-customers-and-performance-chart>
</ng-template>

<ng-template #filtersTemplate>
    <div class="flex items-center justify-between sm:flex-col sm:items-center">
        <div class="w-full flex-1">
            <app-statistics-filters
                [isRecentRestaurant]="isRecentRestaurant()"
                [showTimeScaleFilter]="true"
                [timeScaleMinAcceptedDate]="restaurantLimitDate()"></app-statistics-filters>
        </div>
        <button
            class="malou-btn-raised--secondary--alt btn-xl !h-12.5 sm:mt-2 sm:w-full"
            mat-raised-button
            (click)="openUpdateRoiSettingsModal()">
            {{ 'roi.settings.settings' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #generatingRoiInsights>
    <app-loader-page
        class="h-full w-full"
        [generationStartDate]="creationStartDate()"
        [generationEstimatedTime]="creationEstimatedTime()"
        [title]="'roi.settings.creation_in_progress' | translate"
        [footerText]="'roi.settings.you_can_leave_while_creating' | translate"></app-loader-page>
</ng-template>
