/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectionStrategy, Component, computed, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EventType, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, map, of, switchMap } from 'rxjs';

import { Role } from '@malou-io/package-utils';

import { NfcService } from ':core/services/nfc.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { WheelsOfFortuneService } from ':modules/wheels-of-fortune/wheels-of-fortune.service';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ConcatPipe } from ':shared/pipes/concat.pipe';

import { SidenavContentRouteGroupComponent } from '../../sidenav-content-route-group/sidenav-content-route-group.component';
import {
    ROUTER_LINK_ACTIVE_OPTIONS,
    SidenavContentRouteOptions,
} from '../../sidenav-content-route-group/sidenav-content-route/sidenav-content-route.component';

@Component({
    selector: 'app-sidenav-content-insights-routes',
    templateUrl: './sidenav-content-insights-routes.component.html',
    standalone: true,
    imports: [SidenavContentRouteGroupComponent, TranslateModule, ConcatPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavContentInsightsRoutesComponent {
    private readonly _store = inject(Store);
    private readonly _wheelsOfFortuneService = inject(WheelsOfFortuneService);
    private readonly _nfcService = inject(NfcService);
    private readonly _translateService = inject(TranslateService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _router = inject(Router);

    private readonly _selectedRestaurant$ = this._restaurantsService.restaurantSelected$;
    private readonly _selectedRestaurant = toSignal(this._selectedRestaurant$);
    private readonly _isBrand$ = this._selectedRestaurant$.pipe(map((restaurant) => restaurant?.isBrandBusiness()));
    private readonly _baseUrl$ = this._selectedRestaurant$.pipe(map((restaurant) => ['/restaurants', restaurant?._id ?? '']));
    readonly baseUrl = toSignal(this._baseUrl$);
    private readonly _roiRouterLink$ = this._baseUrl$.pipe(map((baseUrl) => baseUrl.concat(['statistics', 'roi'])));
    private readonly _roiRouterLink = toSignal(this._roiRouterLink$);
    private readonly _seoRouterLink$ = this._baseUrl$.pipe(map((baseUrl) => baseUrl.concat(['statistics', 'seo'])));
    private readonly _seoRouterLink = toSignal(this._seoRouterLink$);
    private readonly _eReputationRouterLink$ = this._baseUrl$.pipe(map((baseUrl) => baseUrl.concat(['statistics', 'e-reputation'])));
    private readonly _eReputationRouterLink = toSignal(this._eReputationRouterLink$);
    private readonly _socialNetworksRouterLink$ = this._baseUrl$.pipe(map((baseUrl) => baseUrl.concat(['statistics', 'social-networks'])));
    private readonly _socialNetworksRouterLink = toSignal(this._socialNetworksRouterLink$);
    private readonly _boostersRouterLink$ = this._baseUrl$.pipe(map((baseUrl) => baseUrl.concat(['statistics', 'boosters'])));
    private readonly _boostersRouterLink = toSignal(this._boostersRouterLink$);

    private readonly _roiOptions = computed(() => ({
        text: this._translateService.instant('sidenav_content.roi'),
        routerLink: this._roiRouterLink() ?? [],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    }));
    private readonly _seoOptions = computed(() => ({
        text: this._translateService.instant('sidenav_content.seo'),
        routerLink: this._seoRouterLink() ?? [],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    }));
    private readonly _eReputationOptions = computed(() => ({
        text: this._translateService.instant('sidenav_content.e_reputation'),
        routerLink: this._eReputationRouterLink() ?? [],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    }));
    private readonly _socialNetworksOptions = computed(() => ({
        text: this._translateService.instant('sidenav_content.social_network'),
        routerLink: this._socialNetworksRouterLink() ?? [],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    }));
    private readonly _boostersOptions = computed(() => ({
        text: this._translateService.instant('sidenav_content.boosters'),
        routerLink: this._boostersRouterLink() ?? [],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    }));

    private readonly _haveAtLeastOneWheelOfFortune$ = this._selectedRestaurant$.pipe(
        switchMap((restaurant) =>
            restaurant ? this._wheelsOfFortuneService.haveAtLeastOneWheelOfFortune([restaurant._id]) : of({ data: false })
        ),
        map((res) => res.data)
    );
    private readonly _haveAtLeastOneNfc$ = this._selectedRestaurant$.pipe(
        switchMap((restaurant) => (restaurant ? this._nfcService.search({ limit: 1, restaurantId: restaurant._id }) : of({ data: [] }))),
        map((res) => res.data),
        map((nfcs) => nfcs.length > 0)
    );
    private readonly _shouldShowBoostersNavigation$ = combineLatest({
        haveAtLeastOneNfc: this._haveAtLeastOneNfc$,
        haveAtLeastOneWheelOfFortune: this._haveAtLeastOneWheelOfFortune$,
    }).pipe(map(({ haveAtLeastOneNfc, haveAtLeastOneWheelOfFortune }) => haveAtLeastOneNfc || haveAtLeastOneWheelOfFortune));

    private readonly _isRoiActivated$ = this._selectedRestaurant$.pipe(map((restaurant) => restaurant?.roiActivated));
    private readonly _user$ = this._store.select(selectUserInfos);
    private readonly _isUserAdmin$ = this._user$.pipe(map((user) => user?.role === Role.ADMIN));
    private readonly _shouldShowRoiNavigation$ = combineLatest({
        isUserAdmin: this._isUserAdmin$,
        isRoiActivated: this._isRoiActivated$,
        isBrand: this._isBrand$,
    }).pipe(map(({ isUserAdmin, isRoiActivated, isBrand }) => !isBrand && (isUserAdmin || isRoiActivated)));

    private readonly _shouldShowSeoNavigation$ = this._isBrand$.pipe(map((isBrand) => !isBrand));
    private readonly _shouldShowEReputationNavigation$ = this._isBrand$.pipe(map((isBrand) => !isBrand));

    private readonly _shouldShowSeoNavigation = toSignal(this._shouldShowSeoNavigation$);
    private readonly _shouldShowEReputationNavigation = toSignal(this._shouldShowEReputationNavigation$);
    private readonly _shouldShowBoostersNavigation = toSignal(this._shouldShowBoostersNavigation$);
    private readonly _shouldShowRoiNavigation = toSignal(this._shouldShowRoiNavigation$);
    readonly childrenOptions = computed(() => {
        const options: SidenavContentRouteOptions[] = [];
        if (this._shouldShowRoiNavigation()) {
            options.push(this._roiOptions());
        }
        if (this._shouldShowSeoNavigation()) {
            options.push(this._seoOptions());
        }
        if (this._shouldShowEReputationNavigation()) {
            options.push(this._eReputationOptions());
        }
        options.push(this._socialNetworksOptions());
        if (this._shouldShowBoostersNavigation()) {
            options.push(this._boostersOptions());
        }
        return options;
    });

    private readonly _onNavigationEnd$ = this._router.events.pipe(filter((event) => event.type === EventType.NavigationEnd));
    private readonly _onNavigationEnd = toSignal(this._onNavigationEnd$);

    readonly SvgIcon = SvgIcon;
    constructor() {
        this._initEffectRedirection();
    }

    private _initEffectRedirection(): void {
        effect(() => {
            if (!this._selectedRestaurant()) {
                return;
            }
            this._onNavigationEnd(); // Used to trigger this effect
            const roiUrl = this._roiRouterLink()?.join('/');
            const isRoiRouteActive = roiUrl ? this._router.isActive(roiUrl, ROUTER_LINK_ACTIVE_OPTIONS) : false;
            const shouldRedirectFromRoi = !this._shouldShowRoiNavigation() && isRoiRouteActive;

            const seoUrl = this._seoRouterLink()?.join('/');
            const isSeoRouteActive = seoUrl ? this._router.isActive(seoUrl, ROUTER_LINK_ACTIVE_OPTIONS) : false;
            const shouldRedirectFromSeo = !this._shouldShowSeoNavigation() && isSeoRouteActive;

            const eReputationUrl = this._eReputationRouterLink()?.join('/');
            const isEReputationRouteActive = eReputationUrl ? this._router.isActive(eReputationUrl, ROUTER_LINK_ACTIVE_OPTIONS) : false;
            const shouldRedirectFromEReputation = !this._shouldShowEReputationNavigation() && isEReputationRouteActive;

            const boostersUrl = this._roiRouterLink()?.join('/');
            const isBoostersRouteActive = boostersUrl ? this._router.isActive(boostersUrl, ROUTER_LINK_ACTIVE_OPTIONS) : false;
            const shouldRedirectFromBoosters = !this._shouldShowBoostersNavigation() && isBoostersRouteActive;
            if (shouldRedirectFromRoi || shouldRedirectFromSeo || shouldRedirectFromEReputation || shouldRedirectFromBoosters) {
                const redirectRouterLink = this._socialNetworksRouterLink(); // because it's the only route accessible without condition
                this._router.navigate(redirectRouterLink ?? []);
            }
        });
    }
}
