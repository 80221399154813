@let currentRestaurant = restaurant();

@if (!isLoading()) {
    @if (currentRestaurant) {
        <ng-container [ngTemplateOutlet]="platformsUpdates"></ng-container>
    }
} @else {
    <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
}

<ng-template #platformsUpdates>
    <div class="hide-scrollbar h-full overflow-y-auto">
        <div class="m-5 flex flex-col gap-6">
            @if (!restaurantInformationUpdateStatesIsLoading()) {
                <div class="flex flex-wrap justify-between rounded-[10px] bg-malou-color-background-dark p-6 sm:gap-5">
                    <app-informations-gauge class="flex-2"></app-informations-gauge>
                    <app-informations-updates-state class="flex flex-3 justify-center"></app-informations-updates-state>
                </div>

                @if (suggestionsCount() > 0) {
                    <app-information-suggestions (openSuggestions)="openSuggestions($event)"></app-information-suggestions>
                }

                @if (updateState() === UpdateState.DIFFERENT && !currentRestaurant?.isBrandBusiness() && showDifferencesButton()) {
                    <app-detected-inconsistencies
                        [totalDifferencesCount]="totalDifferencesCount()"
                        (openPlatformsComparisons)="openPlatformsComparisons()"></app-detected-inconsistencies>
                }
            } @else {
                <ng-container [ngTemplateOutlet]="informationUpdatesLoadingTemplate"></ng-container>
            }

            <ng-container
                [ngTemplateOutlet]="currentRestaurant?.isBrandBusiness() ? brandBusinessTemplate : locationBusinessTemplate"
                [ngTemplateOutletContext]="{ restaurant: currentRestaurant }">
            </ng-container>
        </div>
    </div>

    <ng-template let-restaurant="restaurant" #brandBusinessTemplate>
        <div class="flex flex-wrap gap-6">
            <app-infos-restaurant class="flex-1" [restaurant]="restaurant" (updated)="updateRestaurantInformation($event)">
            </app-infos-restaurant>
            <app-description
                class="flex-2"
                [restaurant]="restaurant"
                (descriptionsChange)="updateRestaurantDescription($event)"
                (prepareDescriptionsDuplication)="onPrepareDescriptionsDuplication($event)">
            </app-description>
        </div>
    </ng-template>

    <ng-template let-restaurant="restaurant" #locationBusinessTemplate>
        <div class="flex flex-wrap gap-6">
            <app-infos-restaurant class="flex-3" [restaurant]="restaurant" (updated)="updateRestaurantInformation($event)">
            </app-infos-restaurant>
            <app-hours
                class="flex-5"
                [restaurant]="restaurant"
                (prepareHoursDuplication)="onPrepareHoursDuplication($event)"
                (hoursChange)="updateRestaurantHours($event)">
            </app-hours>
        </div>
        <div class="flex flex-wrap gap-6">
            <app-description
                class="flex-1"
                [restaurant]="restaurant"
                (descriptionsChange)="updateRestaurantDescription($event)"
                (prepareDescriptionsDuplication)="onPrepareDescriptionsDuplication($event)">
            </app-description>
            @if (restaurantHasAccessToGmb()) {
                <app-attributes
                    class="flex-1"
                    [restaurant]="restaurant"
                    (attributesChange)="updateRestaurantAttributes($event)"
                    (prepareAttributesDuplication)="onPrepareAttributesDuplication()">
                </app-attributes>
            }
        </div>
    </ng-template>
</ng-template>

<ng-template #loadingTemplate>
    <div class="m-5 flex h-full flex-col gap-6">
        <ng-container [ngTemplateOutlet]="informationUpdatesLoadingTemplate"></ng-container>
        <ng-container
            [ngTemplateOutlet]="
                currentRestaurant?.isBrandBusiness() ? brandBusinessLoadingTemplate : locationBusinessLoadingTemplate
            "></ng-container>
    </div>
</ng-template>

<ng-template #informationUpdatesLoadingTemplate>
    <app-skeleton skeletonClass="secondary-bg h-[130px] !w-full rounded-[10px]"></app-skeleton>
</ng-template>

<ng-template #brandBusinessLoadingTemplate>
    <div class="flex h-fit flex-wrap gap-6">
        <app-skeleton class="flex-1" skeletonClass="secondary-bg rounded-[10px]"></app-skeleton>
        <app-skeleton class="flex-2" skeletonClass="secondary-bg rounded-[10px]"></app-skeleton>
    </div>
</ng-template>

<ng-template #locationBusinessLoadingTemplate>
    <div class="flex h-fit flex-wrap gap-6">
        <app-skeleton class="flex-3" skeletonClass="secondary-bg !h-[500px] rounded-[10px]"></app-skeleton>
        <app-skeleton class="flex-5" skeletonClass="secondary-bg !h-[500px] rounded-[10px]"></app-skeleton>
    </div>
    <div class="flex h-fit flex-wrap gap-6">
        <app-skeleton class="flex-1" skeletonClass="secondary-bg !h-[300px] rounded-[10px]"> </app-skeleton>
        <app-skeleton class="flex-1" skeletonClass="secondary-bg !h-[300px] rounded-[10px]"> </app-skeleton>
    </div>
</ng-template>
