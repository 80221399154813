import { NgClass } from '@angular/common';
import {
    Component,
    DestroyRef,
    effect,
    ElementRef,
    EventEmitter,
    forwardRef,
    inject,
    input,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { distinctUntilChanged, Observable } from 'rxjs';

import { CustomMatCalendarHeaderComponent } from ':shared/components/custom-mat-calendar-header/custom-mat-calendar-header.component';
import { CustomLuxonDateAdapter } from ':shared/components/input-date-picker/custom-luxon-date-adapter';
import { MyDate } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-input-date-picker',
    templateUrl: 'input-date-picker.component.html',
    styleUrls: ['./input-date-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputDatePickerComponent),
        },
        {
            provide: DateAdapter,
            useClass: CustomLuxonDateAdapter,
        },
    ],
    standalone: true,
    imports: [NgClass, FormsModule, MatDatepickerModule, ReactiveFormsModule, MatIconModule],
})
export class InputDatePickerComponent implements OnInit, ControlValueAccessor {
    /**
     * Title
     *
     * @required
     */
    @Input()
    title = '';

    /**
     * Placeholder
     *
     * @optional
     */
    @Input()
    placeholder = '--/--/----';

    /**
     * Required option, will add an asterix after the title
     *
     * @optional
     */
    @Input()
    required = false;

    /**
     * @optional
     */
    @Input()
    showDatepickerButton = true;

    /**
     * Error message, will add a colored border and will display the error below the input
     *
     * @optional
     */
    @Input()
    errorMessage: string | null;

    /**
     * Subtitle
     *
     * @required
     */
    @Input()
    subtitle?: string;

    /**
     * Max date
     *
     */
    @Input()
    max?: Date;

    /**
     * Min date
     *
     */
    @Input()
    min?: Date;

    /**
     * Time date
     *
     */
    @Input()
    time?: string;

    /**
     * Time date
     *
     */
    @Input()
    onTimeChange$?: Observable<string>;

    /**
     * Should display border or not
     *
     */
    @Input()
    shouldDisplayBorder = true;

    /**
     * Default value
     */
    @Input()
    defaultValue?: Date | MyDate | null;

    readonly disabled = input<boolean>(false);

    /**
     * On value change output
     */
    @Output()
    inputDatePickerChange: EventEmitter<Date | null> = new EventEmitter();

    readonly SvgIcon = SvgIcon;

    @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;

    /**
     * To easily retrieve changed value event form input
     */
    control = new UntypedFormControl();

    isFocused = false;
    isEmptyValue = true;

    inputBlurred = false;

    /**
     * For implementing ControlValueAccessor
     */
    isTouched = false;

    readonly testId = input<string>();

    lastPropagatedDate: Date | null;
    customHeaderComponent = CustomMatCalendarHeaderComponent;

    private readonly _destroyRef = inject(DestroyRef);
    private readonly _translateService = inject(TranslateService);

    constructor() {
        effect(() => {
            const disabled = this.disabled();
            if (disabled) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        });
    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe({
            next: (e) => (this.isEmptyValue = !e),
        });
        this.onTimeChange$?.pipe(distinctUntilChanged(), takeUntilDestroyed(this._destroyRef)).subscribe({
            next: (time) => {
                this.time = time;
                if (!this.inputBlurred) {
                    this.inputBlurred = true;
                }
                this.validateInput(null, this.control.value);
            },
        });

        if (this.defaultValue !== undefined) {
            if (this.defaultValue instanceof MyDate) {
                const date = this.defaultValue.getDate();
                this.control.setValue(date);
            } else {
                this.control.setValue(this.defaultValue);
            }
        }
    }

    onTouched: any = () => {};
    onChange: any = () => {};

    /**
     * Triggered when user interact with the input
     * @param event
     */
    validateInput(event: Event | null, date?: DateTime | Date): void {
        if (!this.inputBlurred) {
            return;
        }
        let parsedTime = ['0', '0'];
        const value: string = (event?.target as HTMLInputElement)?.value;
        let dateTimeValue: DateTime | undefined;
        if (value) {
            dateTimeValue = DateTime.fromFormat(value, 'D');
        } else if (date instanceof Date) {
            date = DateTime.fromJSDate(date);
            dateTimeValue = date;
        } else if (date) {
            dateTimeValue = date;
        }

        if (this.time) {
            parsedTime = this.time.split(':');
        }

        if (
            dateTimeValue?.isValid &&
            !!parsedTime[0] &&
            !!parsedTime[1] &&
            !isNaN(Number(parsedTime[0])) &&
            !isNaN(Number(parsedTime[1]))
        ) {
            dateTimeValue = dateTimeValue.set({ hour: Number(parsedTime[0]), minute: Number(parsedTime[1]) });
        }

        if (!dateTimeValue?.isValid) {
            this.errorMessage = this._translateService.instant('input_date_picker.invalid_date');
            this.propagateValue(null);
        } else if (
            this.min &&
            dateTimeValue.toJSDate() < this.min &&
            dateTimeValue.toJSDate().toLocaleDateString() === this.min.toLocaleDateString()
        ) {
            this.errorMessage = this._translateService.instant('common.date_past');
            this.propagateValue(null);
        } else if (this.min && dateTimeValue.toJSDate() < this.min) {
            const displayDate = this.min.toLocaleDateString();
            this.errorMessage = this._translateService.instant('input_date_picker.invalid_min_date', { date: displayDate });
            this.propagateValue(null);
        } else if (this.max && dateTimeValue.toJSDate() > this.max) {
            const displayDate = this.max.toLocaleDateString();
            this.errorMessage = this._translateService.instant('input_date_picker.invalid_max_date', { date: displayDate });
            this.propagateValue(null);
        } else {
            this.errorMessage = null;
            this.propagateValue(dateTimeValue.toJSDate());
        }
    }

    /**
     * Triggered when user interact with the input and the string is a valid date (as material pov),
     * or when the user select a date with the picker.
     * @param event
     */
    validateDateChange(event: MatDatepickerInputEvent<any>): void {
        const value: DateTime | undefined = event.value;
        if (!value) {
            // this case is proceeded by the validateInput fn
            // because to delete a date we need to interact with the input
            return;
        }
        this.validateInput(null, value);
    }

    propagateValue(value: Date | null): void {
        this.markAsTouched();

        // When the user write a good formatted date while typing,
        // both (input) and (dateInput) are triggered,
        // so we limit the propagation here to not propagate two times the same value
        if (this.lastPropagatedDate && value && this.lastPropagatedDate.getTime() === value.getTime()) {
            return;
        }

        this.onChange(value);
        this.inputDatePickerChange.emit(value);
        this.lastPropagatedDate = value;
    }

    onBlur(): void {
        // if input is not yet blurred, the validation is triggered here
        // Otherwise, the validation is triggered by the input events : (input) or (dateInput)
        if (!this.inputBlurred) {
            this.inputBlurred = true;
            const value = this.inputElement.nativeElement.value;
            const event: Event = { target: { value: value } } as unknown as Event;
            this.validateInput(event);
        }
        this.isFocused = false;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    markAsTouched(): void {
        if (!this.isTouched) {
            this.onTouched();
            this.isTouched = true;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    writeValue(value: Date): void {
        this.control.setValue(value);
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.control.disable() : this.control.enable();
    }

    setInputBlurred(): void {
        this.inputBlurred = true;
    }
}
