<app-select-base
    [formControl]="control"
    [title]="title()"
    [subtitle]="subtitle()"
    [placeholder]="placeholder()"
    [required]="required()"
    [errorMessage]="errorMessage()"
    [values]="values()"
    [displayWith]="displayWith"
    [multiSelection]="multiSelection()"
    [checkboxOption]="multiSelection()"
    [showSelectAllCheckbox]="multiSelection()"
    [selectAllCheckboxMessage]="'app_select_restaurants.your_business' | translate"
    [showValuesSelectedCount]="showValuesSelectedCount()"
    [valuesSelectedCountMessage]="'app_select_restaurants.business' | translate"
    [groupSelectedValuesAtTop]="true"
    [multiSelectionElementWrap]="multiSelectionElementWrap()"
    [shouldSwitchToWrapModeOnClick]="shouldSwitchToWrapModeOnClick()"
    [defaultEmptyValueMessage]="defaultEmptyValueMessage()"
    [shouldUpdateValuesToDisplayAfterSelection]="shouldUpdateValuesToDisplayAfterSelection()"
    [sortBy]="sortBy"
    [selectedValues]="selectedValues()"
    [disabled]="disabled()"
    [computeObjectHash]="computeObjectHash()"
    [displayedOptionCount]="displayedOptionCount()"
    [valuesCanBeDisabled]="valuesCanBeDisabled()"
    [testId]="testId()"
    (selectBaseChange)="selectRestaurantsChange.emit($event)">
    <ng-template let-value="value" let-index="index" let-deleteValueAt="deleteValueAt" #selectedValueTemplate>
        <div class="malou-chip malou-chip--primary">
            <span>{{ displayWith | applyPure: value }}</span>
            <mat-icon
                class="malou-chip-icon--right malou-color-primary"
                [svgIcon]="SvgIcon.REMOVE"
                (click)="deleteValueAt(index)"></mat-icon>
        </div>
    </ng-template>
    <ng-template let-value="value" let-isValueSelected="isValueSelected" #optionTemplate>
        @if (value) {
            <div class="py-1">
                <div class="flex items-center gap-x-2">
                    <div class="malou-text-12--semibold" [class.malou-color-text-1]="isValueSelected">
                        {{ value | applySelfPure: 'getDisplayName' }}
                    </div>
                    <div>
                        <img
                            class="h-4 w-4"
                            [alt]="
                                ((value | applySelfPure: 'isBrandBusiness') ? 'common.brand_account' : 'common.business_account')
                                    | translate
                            "
                            [matTooltip]="
                                ((value | applySelfPure: 'isBrandBusiness') ? 'common.brand_account' : 'common.business_account')
                                    | translate
                            "
                            [matTooltipTouchGestures]="'off'"
                            [src]="((value | applySelfPure: 'isBrandBusiness') ? 'brand' : 'business') | imagePathResolver" />
                    </div>
                </div>
                <div class="malou-text-10--regular font-normal italic text-malou-color-text-2">
                    {{
                        (value | applySelfPure: 'isBrandBusiness') ? ('common.brand_account' | translate) : value?.getFullFormattedAddress()
                    }}
                </div>
            </div>
        }
    </ng-template>
</app-select-base>
