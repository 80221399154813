import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-automation-card',
    templateUrl: './automation-card.component.html',
    standalone: true,
    imports: [NgClass, MatButtonModule, MatIconModule],
})
export class AutomationCardComponent {
    @Input() title: string;
    @Input() isSuccess: boolean;
    @Input() successText: string;
    @Input() errorText: string;
    @Input() icon: string;
    @Output() onClick: EventEmitter<void> = new EventEmitter();

    emitClick(): void {
        this.onClick.emit();
    }
}
