import { Component, computed, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { CredentialsService } from ':core/services/credentials.service';
import { ToastService } from ':core/services/toast.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { NoopMatCheckboxComponent } from ':shared/components/noop-mat-checkbox/noop-mat-checkbox.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Organization } from ':shared/models/organization';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

interface SelectableOrganization extends Organization {
    selected: boolean;
}

@Component({
    selector: 'app-validate-credentials',
    templateUrl: './validate-credentials.component.html',
    styleUrls: ['./validate-credentials.component.scss'],
    standalone: true,
    imports: [
        MatCheckboxModule,
        FormsModule,
        MatButtonModule,
        TranslateModule,
        MalouSpinnerComponent,
        NoopMatCheckboxComponent,
        ApplyPurePipe,
    ],
})
export class ValidateCredentialsComponent implements OnInit {
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');
    readonly showOrganizationSelect = signal(false);
    readonly credentialId = this._route.snapshot.queryParams._id;
    readonly userOrganizations = signal<SelectableOrganization[]>([]);
    readonly selectedOrganizationIds = signal<string[]>([]);

    readonly isOrganizationSelected = computed((): boolean => this.selectedOrganizationIds().length > 0);
    readonly isLoading = signal(false);
    private _redirect: string;
    private _params = {};

    private readonly _userOrganizations$ = this._store.select(selectUserInfos).pipe(map((user) => user?.organizations ?? []));

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _credentialsService: CredentialsService,
        private readonly _store: Store,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe
    ) {}

    /**
     * This component has for goal to link a set of credentials obtained from a platform to a user.
     * The process is the next one:
     * - Ask for credentials to a platform
     * - Save them to our database and render this component with a key and credential _id url params
     * - Update the freshly saved credentials by linking them to the logged in user using its JWT token
     * - Redirect to credential list
     */
    ngOnInit(): void {
        const {
            key: platformKey,
            redirect: redirectRaw,
            no_accounts: noAccounts,
            auth_id: authId,
            should_choose_organization: shouldChooseOrganization,
        } = this._route.snapshot.queryParams;

        const restaurantCreationFormInProgress = localStorage.getItem(LocalStorageKey.RESTAURANT_CREATION_FORM_IN_PROGRESS);
        if (restaurantCreationFormInProgress) {
            this._validateOrganizationAndRedirectToRestaurantCreation(restaurantCreationFormInProgress, authId);
            return;
        }

        const redirect = redirectRaw
            ? redirectRaw.replace(/__/g, '/') // redirect url should be encoded as restaurants__123__platforms
            : null;
        const shouldRedirectImmediately = shouldChooseOrganization !== 'true';
        this.showOrganizationSelect.set(!shouldRedirectImmediately);
        if (noAccounts) {
            this._redirect = redirect ?? '/restaurants/list';
            this._params = { relativeTo: this._route, queryParams: { open_modal: true } };
            if (shouldRedirectImmediately) {
                this._router.navigate([this._redirect], { relativeTo: this._route, queryParams: { open_modal: true } });
            }
            return;
        }
        switch (platformKey) {
            case PlatformKey.FOURSQUARE:
            case PlatformKey.GMB:
                this._redirect = redirect ?? '/restaurants/list';
                this._params = { relativeTo: this._route, queryParams: { open_platform: platformKey, auth_id: authId } };
                if (shouldRedirectImmediately) {
                    this._router.navigate([this._redirect], {
                        relativeTo: this._route,
                        queryParams: { open_platform: platformKey, auth_id: authId },
                    });
                }
                break;
            case PlatformKey.INSTAGRAM:
            case PlatformKey.FACEBOOK:
            case PlatformKey.ZENCHEF:
            case PlatformKey.DELIVEROO:
            case PlatformKey.UBEREATS:
                if (redirect) {
                    if (redirect.endsWith('connection') || redirect === '/restaurants/list') {
                        this._redirect = redirect;
                        this._params = { relativeTo: this._route, queryParams: { open_platform: platformKey, auth_id: authId } };
                    } else {
                        this._redirect = redirect;
                        this._params = { relativeTo: this._route };
                    }
                } else {
                    this._redirect = '/restaurants/list';
                }
                if (shouldRedirectImmediately) {
                    this._router.navigate([this._redirect], this._params);
                }
                break;
            default:
                this._toastService.openErrorToast('Not implemented yet');
                return;
        }

        this._userOrganizations$.subscribe((organizations) => {
            this.userOrganizations.set(organizations.map((org) => ({ ...org, selected: false })));
        });
    }

    onValueChange(organization: SelectableOrganization): void {
        this.selectedOrganizationIds.update((selectedOrganizationIds) => {
            if (selectedOrganizationIds.includes(organization._id)) {
                return [...selectedOrganizationIds.filter((id) => id !== organization._id)];
            } else {
                return [...selectedOrganizationIds, organization._id];
            }
        });
    }

    isChecked(organization: SelectableOrganization, selectedOrganizationIds: string[]): boolean {
        return selectedOrganizationIds.includes(organization._id);
    }

    validateOrganization(organizationIds: string[] = this.selectedOrganizationIds()): void {
        if (!organizationIds.length) {
            return;
        }
        this.isLoading.set(true);
        this._credentialsService.updateOrganizations(this.credentialId, organizationIds).subscribe({
            next: () => {
                this.isLoading.set(false);
                this._router.navigate([this._redirect], this._params);
            },
            error: (err) => {
                this.isLoading.set(false);
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    private _validateOrganizationAndRedirectToRestaurantCreation(restaurantCreationFormInProgressAsString: string, authId: string): void {
        const restaurantCreationFormInProgress = JSON.parse(restaurantCreationFormInProgressAsString);
        const organizationId = restaurantCreationFormInProgress.organizationId;

        // will redirect to the restaurant creation form in validateOrganization()
        this._redirect = '/restaurants/list';
        this._params = { queryParams: { auth_id: authId } };

        // validate the organization and redirect to the restaurant creation form
        this.validateOrganization([organizationId]);
    }
}
