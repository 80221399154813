import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GetFrontChatUserEmailHashResponseDto, OrganizationUserDto, UpdateUserProfileInputBodyDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { ApiResult } from ':shared/models';

import { MessagesNotificationsSettings, User, UserRestaurant } from './user';

@Injectable({ providedIn: 'root' })
export class UsersService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/users/`;

    constructor(private readonly _http: HttpClient) {}

    getUser(userId: string): Observable<User> {
        return this._http.get(this.API_BASE_URL + userId).pipe(map((res: any) => new User(res.data)));
    }

    updateUser$(userId: string, params: UpdateUserProfileInputBodyDto): Observable<any> {
        return this._http.put(this.API_BASE_URL + userId, params);
    }

    updateUserOrganizations(userId: string, params: any): Observable<ApiResult<User>> {
        return this._http.put<ApiResult<User>>(`${this.API_BASE_URL}organizations/${userId}`, params, { withCredentials: true }).pipe(
            map((res) => {
                res.data = new User(res.data);
                return res;
            })
        );
    }

    createUser(params: Partial<User>): Observable<ApiResult<User>> {
        return this._http.post<ApiResult<User>>(this.API_BASE_URL, params, {
            withCredentials: true,
        });
    }

    createGuest(params: Object): Observable<ApiResult> {
        return this._http.post<ApiResult>(this.API_BASE_URL + 'guest', params);
    }

    index(): Observable<User[]> {
        return this._http.get<ApiResult<User[]>>(this.API_BASE_URL).pipe(map((res: ApiResult<User[]>) => res.data.map((u) => new User(u))));
    }

    getOrganizationUsers(organizationId: string): Observable<User[]> {
        return this._http
            .get<ApiResult<OrganizationUserDto[]>>(`${this.API_BASE_URL}organizations/${organizationId}`, { withCredentials: true })
            .pipe(map((res: ApiResult<OrganizationUserDto[]>) => res.data.map((u) => new User(u))));
    }

    usersRestaurants(): Observable<ApiResult<UserRestaurant[]>> {
        return this._http.get<ApiResult<UserRestaurant[]>>(this.API_BASE_URL + 'users_restaurants');
    }

    getUsersForRestaurant(restaurantId: string): Observable<ApiResult<UserRestaurant[]>> {
        return this._http.get<ApiResult<UserRestaurant[]>>(this.API_BASE_URL + 'restaurants/' + restaurantId, { withCredentials: true });
    }

    updateUserRestaurantById(userRestaurantId: string, update: Partial<UserRestaurant>): Observable<ApiResult> {
        return this._http.put<ApiResult>(`${this.API_BASE_URL}restaurants/${userRestaurantId}`, update, { withCredentials: true });
    }

    updateUserLastVisitedRestaurant(restaurantId: string): Observable<ApiResult> {
        return this._http.put<ApiResult>(`${this.API_BASE_URL}restaurants/${restaurantId}/last_visited`, { withCredentials: true });
    }

    delete(id: string): Observable<ApiResult> {
        return this._http.delete<ApiResult>(this.API_BASE_URL + id, { withCredentials: true });
    }

    deleteUserRestaurant(urId: string): Observable<ApiResult> {
        return this._http.delete<ApiResult>(`${this.API_BASE_URL}restaurants/` + urId, { withCredentials: true });
    }

    getUserByEmail$(email: string): Observable<User> {
        return this._http
            .get<ApiResult<User>>(`${this.API_BASE_URL}email/${email}`, { withCredentials: true })
            .pipe(map((res: any) => new User(res.data)));
    }

    getUserNotificationsSettings(userId: string): Observable<ApiResult<MessagesNotificationsSettings>> {
        return this._http.get<ApiResult<MessagesNotificationsSettings>>(`${this.API_BASE_URL}${userId}/settings`, {
            withCredentials: true,
        });
    }

    getFrontChatUserEmailHash(userEmail: string): Observable<ApiResultV2<GetFrontChatUserEmailHashResponseDto>> {
        return this._http.post<ApiResultV2<GetFrontChatUserEmailHashResponseDto>>(`${this.API_BASE_URL}front-chat/email-hash/`, {
            email: userEmail,
        });
    }
}
