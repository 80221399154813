import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { Pagination, ReviewsFilters, StatusReviewsFilter } from ':shared/models';

import { PendingReviewReply } from '../answer-review-container/answer-review.interface';
import { ReviewsDisplayMode, ReviewsState } from './reviews.reducer';

export const selectReviewsState = createFeatureSelector<ReviewsState>('reviews');

// Filters

export const selectReviewsFilters = createSelector(selectReviewsState, (state: ReviewsState) => state.filters);

export const selectTextReviewsFilters = createSelector(selectReviewsFilters, (state: ReviewsFilters) => state.text || '');

export const selectPeriodReviewsFilters = createSelector(selectReviewsFilters, (filters: ReviewsFilters) => ({
    period: filters.period,
    startDate: filters.startDate,
    endDate: filters.endDate,
}));

export const selectPlatformsReviewsFilters = createSelector(selectReviewsFilters, (filters: ReviewsFilters) => filters.platforms);

export const selectRatingsReviewsFilters = createSelector(selectReviewsFilters, (filters: ReviewsFilters) => filters.ratings ?? []);

export const selectStatusesReviewsFilters = createSelector(
    selectReviewsFilters,
    (filters: ReviewsFilters): Record<StatusReviewsFilter, boolean | undefined> => ({
        answered: filters.answered,
        notAnswered: filters.notAnswered,
        pending: filters.pending,
        notAnswerable: filters.notAnswerable,
    })
);

export const selectCommentsReviewsFilters = createSelector(selectReviewsFilters, (filters: ReviewsFilters) => ({
    withText: filters.withText,
    withoutText: filters.withoutText,
}));

export const selectArchivesReviewsFilters = createSelector(selectReviewsFilters, (filters: ReviewsFilters) => ({
    archived: filters.archived,
    unarchived: filters.unarchived,
}));

// End filters

export const selectSelectablePlatformKeys = createSelector(selectReviewsState, (state: ReviewsState) => state.selectablePlatforms);

export const selectCouldNotGetPageNumberFromReviewIdError = createSelector(
    selectReviewsState,
    (state: ReviewsState) => state.couldNotGetPageNumberFromReviewId
);

export const selectReviews = createSelector(selectReviewsState, (state: ReviewsState) => state.reviews);

export const selectReviewsById = (reviewId: string): MemoizedSelector<object, any, DefaultProjectorFn<any>> =>
    createSelector(selectReviewsState, (state: ReviewsState) => state.reviews.find((review) => review._id === reviewId));

export const selectEstimatedReviewCount = createSelector(selectReviewsState, (state: ReviewsState) => state.estimatedReviewCount);

export const selectHasLoadedAllReviews = createSelector(selectReviewsState, (state: ReviewsState) => state.hasLoadedAllReviews);

export const selectReviewsReplies = createSelector(selectReviewsState, (state: ReviewsState) => state.replies);

export const selectReviewsRepliesById = (id: string): MemoizedSelector<object, PendingReviewReply, DefaultProjectorFn<any>> =>
    createSelector(selectReviewsState, (state: ReviewsState) => state.replies[id]);

const selectCurrentReplyReviewId = createSelector(selectReviewsState, (state: ReviewsState) => state.currentReplyReviewId);

export const selectCurrentReviewReply = createSelector(
    selectCurrentReplyReviewId,
    selectReviewsReplies,
    (id: string, replies) => replies[id]
);

export const selectReviewsFetchStates = createSelector(selectReviewsState, (state: ReviewsState) => state.fetchStates);

export const selectReviewsFooterVisibility = createSelector(selectReviewsState, (state: ReviewsState) => state.isFooterVisible);

export const selectReviewsSynchronizationStatus = createSelector(selectReviewsState, (state: ReviewsState) => state.synchronizationStatus);

export const selectReviewsPagination = createSelector(selectReviewsState, (state: ReviewsState) => state.pagination);

export const selectReviewsPaginationWithFilters = createSelector(
    selectReviewsPagination,
    selectReviewsFilters,
    (pagination: Pagination, filters: ReviewsFilters) => ({
        pagination,
        filters,
    })
);

export const selectUnansweredReviewCount = createSelector(selectReviewsState, (state: ReviewsState) => state.unansweredReviewCount);

export const selectRestaurantsFilter = createSelector(selectReviewsState, (state): string[] =>
    state.currentView === ReviewsDisplayMode.SINGLE_RESTAURANT
        ? (state.filters.restaurantIds ?? [])
        : (state.filters.aggregatedViewRestaurantIds ?? [])
);

export const selectSemanticAnalysisToggle = createSelector(selectReviewsState, (state) => state.semanticAnalysisToggle);

export const selectIsFiltersLoaded = createSelector(selectReviewsState, (state: ReviewsState) => state.loaded);
