import { Chart } from 'chart.js';

export const malouChartColorABC = '#040707';
export const malouChartColorDeliveroo = '#20CFBE';
export const malouChartColorFacebook = '#1977F3';
export const malouChartColorFoursquare = '#F94877';
export const malouChartColorGMB = '#EA4335';
export const malouChartColorInstagram = '#D82E77';
export const malouChartColorLaFourchette = '#006659';
export const malouChartColorMapstr = '#FEC22B';
export const malouChartColorOpentable = '#DA3743';
export const malouChartColorPagesjaunes = '#F4E422';
export const malouChartColorResy = '#FF2910';
export const malouChartColorTripadvisor = '#01E2A0';
export const malouChartColorUberEats = '#0BC26E';
export const malouChartColorYelp = '#D22325';
export const malouChartColorZenchef = '#F2E8E3';

export const malouChartColorPink = '#EE116E';
export const malouChartColorPurple = '#AC32B7';
export const malouChartColorBluePurple = '#6A52FD';
export const malouChartColorLightPurple = '#C4B4FE';
export const malouChartColorLightPink = '#F788B7';
export const malouChartColorLighterPink = '#FFBBC7';
export const malouChartColorLighterBlue = '#F2F2FF';
export const malouChartColorGreen = '#9AEABA';
export const malouChartColorRed = '#FFBBC7';
export const malouChartColorBlue = '#3F34C4';
export const malouChartColorLightBlue = '#544ace';
export const malouChartColorPurpleDark = '#5926AC';
export const malouChartColorPurpleMedium = '#9171ED';
export const malouChartColorPurplePink = '#C784E7';
export const malouChartColorPinkBarbie = '#EB6CB5';
export const malouChartColorPinkMedium = '#FF8FBE';
export const malouChartColorPrimary5Percent = 'rgba(106, 82, 253, 0.05)';
export const malouChartColorRed5Percent = 'rgba(238, 17, 110, 0.15)';
export const malouChartColorPurple50Percent = 'rgba(172, 50, 183, 0.5)';
export const malouChartColorPinkLight60Percent = 'rgba(247, 136, 183, 0.6)';
export const malouChartColorPurpleLight60Percent = 'rgba(196, 180, 254, 0.6)';
export const malouChartColorPurpleLight30Percent = 'rgba(196, 180, 254, 0.3)';

export const malouChartBackgroundColorRed = '#FFEBEE';
export const malouChartBackgroundColorGreen = '#E1FAEA';

export const malouChartColorText1 = '#0A2540';
export const malouChartColorText2 = '#4A5E73';

export const DEFAULT_POINT_RADIUS = 5;
export const DEFAULT_DASHED_LINE_OPTIONS = [6, 7];

export type ChartDataElement = number | null;
export type ChartDataArray = ChartDataElement[];

/**
 * Compute points radius useful for a line chart.
 * For each data element
 * Return DEFAULT_POINT_RADIUS if the current point is after or before a null element
 * Return 0 otherwise
 * Special case for first and last point
 */
export function computePointsRadius(data: (number | null)[]): number[] {
    if (data.length === 1) {
        return [DEFAULT_POINT_RADIUS];
    }
    return data.map((e, index) => {
        const isFirstIndex = index === 0;
        const isLastIndex = index === data.length - 1;
        const isSecondValueNull = data[1] === null;
        const isBeforeLastValueNull = data[data.length - 2] === null;

        if (isFirstIndex) {
            if (isSecondValueNull) {
                return DEFAULT_POINT_RADIUS;
            } else {
                return 0;
            }
        }
        if (isLastIndex) {
            if (isBeforeLastValueNull) {
                return DEFAULT_POINT_RADIUS;
            } else {
                return 0;
            }
        }

        return data[index - 1] === null || data[index + 1] === null ? DEFAULT_POINT_RADIUS : 0;
    });
}

/**
 * Merge 2 array by adding value at same index
 * If 2 value at the same index are null, the resulting value will be null
 * If at least one value at the same index is not null, the other value is considered equal to 0
 */
export function mergeArrays(arr1: ChartDataArray, arr2: ChartDataArray): ChartDataArray {
    if (!arr1.length) {
        return arr2;
    } else if (!arr2.length) {
        return arr1;
    }
    return (arr1 ?? []).map((val1, index) => {
        const val2 = arr2?.[index];
        if (val1 === null && val2 === null) {
            return null;
        }
        return (val1 ?? 0) + (val2 ?? 0);
    });
}

export function toggleVisibilityOnMultipleDatasets(chart: Chart, datasetIndexes: number[], visible: boolean): void {
    const mode = visible ? 'show' : 'hide';
    const metas = datasetIndexes.map((datasetIndex) => chart.getDatasetMeta(datasetIndex));
    const anims = metas.map((meta) => meta.controller['_resolveAnimations'](undefined, mode));
    datasetIndexes.map((datasetIndex) => chart.setDatasetVisibility(datasetIndex, visible));
    anims.map((anim, index) => anim.update(metas[index], { visible }));

    chart.update((ctx) => (datasetIndexes.includes(ctx.datasetIndex) ? mode : 'none'));
}
