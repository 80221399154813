import { NgClass, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { Period } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

@Component({
    selector: 'app-day-or-date-hours',
    templateUrl: './day-or-date-hours.component.html',
    styleUrls: ['./day-or-date-hours.component.scss'],
    standalone: true,
    imports: [NgClass, MatIconModule, TranslateModule, FormatTimePipe, IncludesPipe, TitleCasePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayOrDateHoursComponent {
    readonly isClosed = input<boolean>(false);
    readonly timePeriods = input<Period[] | undefined>();
    readonly showStartDate = input<boolean>(false);
    readonly startDate = input<string | undefined>();
    readonly dayOrDateTitle = input.required<string>();
    readonly largeTitle = input<boolean>(false);

    readonly SvgIcon = SvgIcon;
}
