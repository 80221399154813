import { sortBy, take } from 'lodash';

import { RestaurantRankingFormat, RestaurantRankingFormatWithScore } from './restaurant-ranking-format';

/**
 * For a given search, Google Maps gives us a list of ordered search results.
 * We assign a score to each result. The first results have a high score, the last
 * ones have a smaller score. Scores are decreasing a bit exponentially because it’s
 * much more beneficial for a restaurant to go from the second place to the first place
 * than it is to go from the 19th place to the 18th.
 *
 * rankScores[0] is the score assigned to the first search result (50), rankScores[1]
 * is for the second result (43), rankScores[2] for the third (37), etc.
 */
const rankScores = [50, 43, 37, 31, 27, 23, 19, 17, 15, 13, 11, 9, 8, 7, 6, 5, 4, 3, 2, 1];

export type GeoSample = {
    ranking: RestaurantRankingFormat[];
};

export class KeywordRanking {
    /** This list contains one entry for each place ID, order by score in descending order. */
    private readonly _restaurants: RestaurantRankingFormatWithScore[];

    constructor(readonly geosamples: GeoSample[]) {
        this._restaurants = this._createRanking(geosamples);
    }

    /** The caller must not update the returned list. */
    getRestaurants(): RestaurantRankingFormatWithScore[] {
        return this._restaurants;
    }

    /**
     * The returned rank is an integer greater than or equal to 1 (the very first position is 1).
     * Infinity means that the placeId was not found in search results.
     *
     * The returned rank is approximately the average rank over time of the restaurant in
     * search results (even if it’s a bit more complicated actually, we do our own ranking
     * with funny coefficients…).
     */
    getPositionByPlaceId(placeId?: string): { rank: number; outOf: number } | null {
        if (this._restaurants.length < 1 || !placeId) {
            return null;
        }
        const rank = this._restaurants.findIndex((rest) => rest.place_id === placeId) + 1 || Infinity;
        return { rank, outOf: this._restaurants.length };
    }

    private _createRanking(geoSamples: GeoSample[]): RestaurantRankingFormatWithScore[] {
        if (!geoSamples || geoSamples.length < 0) {
            return [];
        }

        // This list contains one entry for each place ID.
        const placeIdToRanking: RestaurantRankingFormatWithScore[] = [];

        geoSamples.forEach((geo) => {
            (geo.ranking || []).forEach((searchResult, searchResultPosition) => {
                const score = rankScores[searchResultPosition] ?? 0;
                const placeRanking = placeIdToRanking.find((r) => r.place_id === searchResult.place_id);
                if (placeRanking) {
                    placeRanking.addToScore(score);
                } else {
                    placeIdToRanking.push(
                        new RestaurantRankingFormatWithScore(
                            score,
                            searchResult.name,
                            searchResult.place_id,
                            searchResult.vicinity,
                            searchResult.formatted_address
                        )
                    );
                }
            });
        });

        return take(
            sortBy(placeIdToRanking, (r) => -r.score),
            20
        );
    }
}
