<div class="malou-color-background-purple flex gap-3 rounded-lg p-6">
    @if (restaurantKeywords$ && restaurant$) {
        <div class="flex flex-col justify-end">
            <label class="malou-text-12--semibold malou-color-text-1">{{ 'keywords_score.score' | translate }}</label>
            <app-keywords-score-gauge
                [text$]="replyText$"
                [restaurant$]="restaurant$"
                [textType$]="gaugeTextType$"
                [lang$]="lang$"
                [keywords$]="restaurantKeywords$"
                [reviewerName$]="reviewerName$"
                [responseTime$]="responseTime$"
                [restaurantAiSettings$]="restaurantAiSettings$"
                [shouldOnlyDisplayScore]="true">
            </app-keywords-score-gauge>
        </div>
    }
    <div class="malou-card__vertical-separator"></div>
    <div class="malou-text-10--regular malou-color-text-2 italic">
        <span [innerHTML]="replyText()"></span>
    </div>
</div>
