import { APP_DEFAULT_LANGUAGE, ApplicationLanguage } from '@malou-io/package-utils';

import { LocalStorageKey } from ':shared/enums/local-storage-key';

export namespace LocalStorage {
    let isLocalStorageSupported: boolean | null = null;

    export function isSupported(): boolean {
        if (isLocalStorageSupported === null) {
            isLocalStorageSupported = checkLocalStorageSupport();
        }
        return isLocalStorageSupported;
    }

    export function setLang(lang: ApplicationLanguage): void {
        if (!isSupported()) {
            return;
        }
        localStorage.setItem(LocalStorageKey.LANG, lang);
    }

    export function getLang(defaultLang = APP_DEFAULT_LANGUAGE): ApplicationLanguage {
        if (!isSupported()) {
            return APP_DEFAULT_LANGUAGE;
        }

        const lang = localStorage.getItem(LocalStorageKey.LANG);
        const availableLangs: string[] = Object.values(ApplicationLanguage);
        return lang && availableLangs.includes(lang) ? (lang as ApplicationLanguage) : defaultLang;
    }

    function checkLocalStorageSupport(): boolean {
        // We may have an error when trying to access local storage on some Firefox versions : https://stackoverflow.com/q/23269532/19313155
        // or if the user has blocked cookies in Safari : https://stackoverflow.com/q/46632093/19313155

        // So we need to check if it is supported before using it
        // Solution found from this post https://stackoverflow.com/a/39584377/19313155
        try {
            localStorage.setItem('testLocalStorage', 'testLocalStorage');
            localStorage.removeItem('testLocalStorage');
            return true;
        } catch (e) {
            return false;
        }
    }
}
