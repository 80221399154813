import {
    AggregatedStatisticsFiltersDto,
    ReviewsFiltersDto,
    statisticsPerRestaurantFiltersDto,
    UserFiltersDto,
} from '@malou-io/package-dto';

export class UserFilters {
    id: string;
    userId: string;
    aggregatedStatisticsFilters: AggregatedStatisticsFiltersDto;
    statisticsFiltersPerRestaurant: statisticsPerRestaurantFiltersDto[];
    aggregatedReviewsFilters: {
        filters: ReviewsFiltersDto;
        restaurantIds: string[];
    };
    reviewsFiltersPerRestaurant: {
        restaurantId: string;
        filters: ReviewsFiltersDto;
    }[];
    createdAt: Date;
    updatedAt: Date;

    constructor(init: UserFiltersDto) {
        this.id = init.id;
        this.userId = init.userId;
        this.aggregatedStatisticsFilters = {
            dates: {
                startDate: this._toDate(init.aggregatedStatisticsFilters.dates.startDate),
                endDate: this._toDate(init.aggregatedStatisticsFilters.dates.endDate),
                period: init.aggregatedStatisticsFilters.dates.period,
            },
            platforms: init.aggregatedStatisticsFilters.platforms,
            restaurantIds: init.aggregatedStatisticsFilters.restaurantIds,
            roiRestaurantIds: init.aggregatedStatisticsFilters.roiRestaurantIds,
            totemIds: init.aggregatedStatisticsFilters.totemIds,
            timeScale: init.aggregatedStatisticsFilters.timeScale,
        };
        this.statisticsFiltersPerRestaurant = init.statisticsFiltersPerRestaurant.map((restaurantStatisticsFilter) => ({
            restaurantId: restaurantStatisticsFilter.restaurantId,
            filters: {
                dates: {
                    startDate: this._toDate(restaurantStatisticsFilter.filters.dates.startDate),
                    endDate: this._toDate(restaurantStatisticsFilter.filters.dates.endDate),
                    period: restaurantStatisticsFilter.filters.dates.period,
                },
                platforms: restaurantStatisticsFilter.filters.platforms,
                totemIds: restaurantStatisticsFilter.filters.totemIds,
                timeScale: restaurantStatisticsFilter.filters.timeScale,
            },
        }));
        this.aggregatedReviewsFilters = init.aggregatedReviewsFilters;
        this.reviewsFiltersPerRestaurant = init.reviewsFiltersPerRestaurant;
        this.createdAt = init.createdAt;
        this.updatedAt = init.updatedAt;
    }

    private _toDate(date: Date | null): Date | null {
        return date ? new Date(date) : null;
    }
}
