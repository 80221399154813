import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { InsightsChart } from '@malou-io/package-utils';

import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { ReviewAnalysesComponent } from ':shared/components/review-analyses/review-analyses.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { parseInsightsRouteParams } from ':shared/helpers/extract-statistics-route-data';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FromToDateFormatterPipe } from ':shared/pipes/from-to-date-formatter.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { StatisticsPdfRestaurantsFormatterPipe } from ':shared/pipes/statistics-pdf-restaurants-formatter.pipe';

import { ReviewsCountComponent } from '../../aggregated-statistics/e-reputation/reviews-count/reviews-count.component';
import { ReviewsRatingKpisComponent } from '../../aggregated-statistics/e-reputation/reviews-rating-kpis/reviews-rating-kpis.component';
import { ReviewsRatingsAverageComponent } from '../../aggregated-statistics/e-reputation/reviews-ratings-average/reviews-ratings-average.component';
import * as AggregatedStatisticsActions from '../../aggregated-statistics/store/aggregated-statistics.actions';
import { PlatformFilterPage } from '../../aggregated-statistics/store/aggregated-statistics.interface';

@Component({
    selector: 'app-e-reputation-pdf',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        ApplyPurePipe,
        ReviewsCountComponent,
        ReviewsRatingsAverageComponent,
        ReviewAnalysesComponent,
        ReviewsRatingKpisComponent,
        FromToDateFormatterPipe,
        IncludesPipe,
        StatisticsPdfRestaurantsFormatterPipe,
    ],
    templateUrl: './e-reputation-pdf.component.html',
    styleUrls: ['./e-reputation-pdf.component.scss'],
})
export class EReputationPdfComponent {
    readonly startDate: Date;
    readonly endDate: Date;
    readonly displayedCharts: InsightsChart[];
    readonly chartOptions: ChartOptions;
    readonly InsightsChart = InsightsChart;
    readonly selectedRestaurantsTitle$: Observable<string>;

    SortBy = ChartSortBy;
    reviewCountHasData = true;
    reviewRatingsAverageHasData = true;
    reviewAnalysesHasData = true;
    reviewRatingKpisHasData = true;

    constructor(
        private _store: Store,
        private _route: ActivatedRoute,
        private readonly _aggregatedFiltersContext: AggregatedStatisticsFiltersContext,
        public readonly translateService: TranslateService
    ) {
        const parsedQueryParams = parseInsightsRouteParams(this._route.snapshot.queryParams.params);
        const { startDate, endDate, displayedCharts, chartOptions, platformKeys } = parsedQueryParams;

        this.displayedCharts = displayedCharts;
        this.chartOptions = chartOptions ?? {};
        this.startDate = startDate;
        this.endDate = endDate;

        this._store.dispatch(
            AggregatedStatisticsActions.editPlatforms({ page: PlatformFilterPage.E_REPUTATION, platforms: platformKeys ?? [] })
        );

        const restaurants$ = this._aggregatedFiltersContext.selectedRestaurants$;
        this.selectedRestaurantsTitle$ = restaurants$.pipe(
            map((restaurants) => restaurants.map((restaurant) => restaurant.internalName ?? restaurant.name).join(', '))
        );
    }
}
