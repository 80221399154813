import { Injectable } from '@angular/core';

import { AggregatedStatisticsState } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import {
    DEFAULT_ARCHIVES,
    DEFAULT_COMMENTS,
    DEFAULT_PERIOD,
    DEFAULT_RATINGS,
    DEFAULT_STATUSES,
} from ':modules/reviews/reviews-header/reviews-header-filters/default-filters';
import { ArchiveReviewsFilters, CommentReviewsFilters, ReviewsFilters, StatusReviewsFilter } from ':shared/models';
import { UserFilters } from ':shared/models/user-filters.model';

@Injectable({ providedIn: 'root' })
export class FromStoreToUserFiltersMapper {
    mapToAggregatedStatisticsFiltersFromState(filters: AggregatedStatisticsState['filters']): UserFilters['aggregatedStatisticsFilters'] {
        return {
            dates: filters.dates,
            platforms: filters.platforms,
            restaurantIds: filters.restaurantIds,
            roiRestaurantIds: filters.roiRestaurantIds,
            totemIds: filters.totemIds,
            timeScale: filters.timeScale,
        };
    }

    mapToAggregatedReviewsFiltersFromState(filters: ReviewsFilters): UserFilters['aggregatedReviewsFilters'] {
        return {
            filters: {
                period: filters.period ?? DEFAULT_PERIOD,
                platforms: filters.platforms ?? [],
                text: filters.text ?? '',
                ratings: filters.ratings ?? DEFAULT_RATINGS.slice(),
                answered: filters.answered ?? DEFAULT_STATUSES.some((e) => e === StatusReviewsFilter.ANSWERED),
                notAnswered: filters.notAnswered ?? DEFAULT_STATUSES.some((e) => e === StatusReviewsFilter.NOT_ANSWERED),
                pending: filters.pending ?? DEFAULT_STATUSES.some((e) => e === StatusReviewsFilter.PENDING),
                notAnswerable: filters.notAnswerable ?? DEFAULT_STATUSES.some((e) => e === StatusReviewsFilter.NOT_ANSWERABLE),
                showPrivate: filters.showPrivate ?? true,
                withText: filters.withText ?? DEFAULT_COMMENTS.some((e) => e === CommentReviewsFilters.WITH_TEXT),
                withoutText: filters.withoutText ?? DEFAULT_COMMENTS.some((e) => e === CommentReviewsFilters.WITHOUT_TEXT),
                archived: filters.archived ?? DEFAULT_ARCHIVES.some((e) => e === ArchiveReviewsFilters.ARCHIVED),
                unarchived: filters.unarchived ?? DEFAULT_ARCHIVES.some((e) => e === ArchiveReviewsFilters.UNARCHIVED),
            },
            restaurantIds: filters.aggregatedViewRestaurantIds ?? [],
        };
    }
}
