<div class="h-full">
    <div class="flex flex-col">
        <span class="malou-text-14--bold malou-color-text-1 mb-2">
            {{ 'wheel_of_fortune.new_wheel_modal.tabs.appearance.color_choice' | translate }}
        </span>
    </div>
    <div class="mb-8 mt-4 flex w-full gap-x-4">
        <app-input-color
            class="w-full"
            position="bottom-right"
            [title]="'wheel_of_fortune.new_wheel_modal.tabs.appearance.primary_color' | translate"
            [value]="primaryColor()"
            [presetColors]="defaultColors"
            [showAlphaCursor]="false"
            [disabled]="isDisabled"
            (inputColorChange)="primaryColor.set($event ?? DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR)"></app-input-color>
        <app-input-color
            class="w-full"
            position="bottom"
            [positionOffset]="'50%'"
            [positionRelativeToArrow]="true"
            [title]="'wheel_of_fortune.new_wheel_modal.tabs.appearance.secondary_color' | translate"
            [value]="secondaryColor()"
            [presetColors]="defaultColors"
            [showAlphaCursor]="false"
            [disabled]="isDisabled"
            (inputColorChange)="secondaryColor.set($event ?? DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR)"></app-input-color>
    </div>
    <div class="flex flex-col">
        <span class="malou-text-14--bold malou-color-text-1 mb-1">{{
            (isAggregatedView
                ? 'wheel_of_fortune.new_wheel_modal.tabs.appearance.add_logo_for_all_restaurants'
                : 'wheel_of_fortune.new_wheel_modal.tabs.appearance.add_logo'
            ) | translate
        }}</span>
        @if (isAggregatedView) {
            <span class="malou-text-10--regular mb-2 italic">
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.appearance.default_logo' | translate }}
            </span>
        }
    </div>
    @if (media()) {
        <div>
            <div
                class="relative flex w-fit min-w-[140px] items-center justify-center rounded-lg border border-malou-color-border-primary p-4">
                @if (!isDisabled) {
                    <button
                        class="malou-btn-icon--secondary btn-sm !absolute right-[6px] top-[6px] !rounded-full"
                        mat-icon-button
                        matTooltip="{{ 'common.delete' | translate }}"
                        (click)="media.set(null)">
                        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                    </button>
                }
                <img class="h-[140px]" alt="logo" [src]="media() | applySelfPure: 'getMediaUrl'" />
            </div>
        </div>
    } @else {
        @if (isDisabled) {
            <div class="opacity-50" (click)="$event.stopPropagation(); $event.preventDefault()">
                <ng-container [ngTemplateOutlet]="wheelOfFortuneLogoUploader"></ng-container>
            </div>
        }
        @if (!isDisabled) {
            <ng-container [ngTemplateOutlet]="wheelOfFortuneLogoUploader"></ng-container>
        }
    }
</div>

<ng-template #wheelOfFortuneLogoUploader>
    <input class="hidden-file-input" type="file" [accept]="acceptedInputFileTypes" (change)="onFileChange($event)" #fileInput />
    <div
        class="mt-4 h-[170px] w-full"
        id="dragarea"
        ondragover="event.stopPropagation(); event.preventDefault()"
        appDnd
        (fileDropped)="!isDisabled ? onFileChange($event) : null"
        (dragenter)="!isDisabled ? onDragEnter() : null"
        (dragleave)="!isDisabled ? onDragLeave($event) : null">
        <div
            class="grid h-full w-full place-items-center rounded-lg border border-dashed border-malou-color-border-secondary"
            [ngClass]="{ 'border-malou-color-primary p-5': isDraggingOver(), 'border-malou-color-state-error': errorMessage() }"
            (click)="$event.stopPropagation()">
            <div
                class="flex h-full w-full items-center justify-center gap-y-4 rounded-lg"
                [ngClass]="{ 'bg-malou-color-background-light': isDraggingOver() }">
                <img class="mr-6 w-[115px]" [src]="'ManTaster' | illustrationPathResolver" />
                <div class="min-w-[30%]">
                    @if (!isDraggingOver() && !isImporting()) {
                        <div class="flex items-center">
                            <div class="malou-btn-flat" [ngClass]="{ 'cursor-pointer': !isDisabled }" (click)="fileInput.click()">
                                {{ 'wheel_of_fortune.new_wheel_modal.tabs.appearance.upload_file.click_to_download' | translate }}
                            </div>
                            &nbsp;
                            <button class="malou-text-12--semibold text-malou-color-text-1">
                                {{ 'wheel_of_fortune.new_wheel_modal.tabs.appearance.upload_file.or_drag_drop' | translate }}
                            </button>
                        </div>
                    }
                    @if (isImporting()) {
                        <div class="malou-text-12--semibold flex items-center text-malou-color-text-1">
                            <app-malou-spinner class="mr-3" size="small"></app-malou-spinner>
                            <span>{{ uploadedFile()?.name || 'wheel_of_fortune.new_wheel_modal.tabs.appearance.file' | translate }}</span
                            >&nbsp;
                            <span class="malou-text-12--semibold malou-color-text-1">{{
                                'wheel_of_fortune.new_wheel_modal.tabs.appearance.importing' | translate
                            }}</span>
                        </div>
                    }
                    @if (isDraggingOver()) {
                        <div>
                            <span class="malou-text-12--semibold malou-color-text-1">{{
                                'wheel_of_fortune.new_wheel_modal.tabs.appearance.drop_logo' | translate
                            }}</span>
                        </div>
                    }
                    <span class="malou-text-10--regular italic" [ngClass]="{ 'ml-9': isImporting() }">
                        {{
                            isImporting()
                                ? uploadedFileSize()
                                : ('wheel_of_fortune.new_wheel_modal.tabs.appearance.upload_file.accepted_files' | translate)
                        }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    @if (errorMessage()) {
        <span class="malou-color-state-error malou-text-10--regular italic">{{ errorMessage() }}</span>
    }
</ng-template>
