import { MalouErrorCode } from './constants';
import { generateDbId } from './id.provider';

export type ApiResultSuccess<T> = {
    data: T;
    message?: string;
    msg?: string;
};

export type ApiResultFailure = {
    err?: any;
    error?: any;
    msg?: string;
    errorData?: any;
};

export type ApiResult<T = undefined> = ApiResultSuccess<T> | ApiResultFailure;

export function isApiResultSuccess<T>(apiResult: ApiResult<T>): apiResult is ApiResultSuccess<T> {
    return (apiResult as ApiResultSuccess<T>).data !== undefined;
}

// We use '[T] extends [never]' instead of 'T extends never' because of distributive conditional types
// https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
// type Generic<T = never> = T extends never ? 0 : 1;
// type A = Generic<number | string> => number extends never ? 0 : 1 | string extends never ? 0 : 1;
// type B = Generic<number>          => number extends never ? 0 : 1;
// type C = Generic                  => never; !!! nor 0 or 1
// TODO IMPROVE TYPE SO WE DON'T HAVE metadata? BUT CAN DO SOMETHING LIKE ApiResultV2<T, U | undefined> IN CONTROLLERS TO MAKE IT OPTIONAL
export type ApiResultV2<T = never, U = never> = ([T] extends [never] ? {} : { data: T }) & ([U] extends [never] ? {} : { metadata?: U });

export interface ApiResultError {
    errorMessage?: string;
    errorCode?: MalouErrorCode;
}

export interface ApiResultMetadataPagination {
    pagination: {
        total: number;
    };
}

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

// https://stackoverflow.com/a/49725198
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
    {
        [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
    }[Keys];

export interface Timestampeable {
    createdAt: Date;
    updatedAt: Date;
}

export type DefaultEntityProperties = {
    id: string;
    createdAt: Date;
    updatedAt: Date;
};

export type RemoveMethodsFromClass<T, ChildEntities = {}> = Omit<
    {
        [K in keyof T as T[K] extends Function ? never : K extends keyof ChildEntities ? never : K]: T[K];
    },
    keyof BaseEntity
> &
    Partial<BaseEntity> &
    ChildEntities;

export interface Timestampeable {
    createdAt: Date;
    updatedAt: Date;
}

export type EntityConstructor<Entity, ChildEntities = {}> = RemoveMethodsFromClass<Entity, ChildEntities>;

export type MakeUndefinedKeysOptional<T extends object> = {
    [K in keyof T as undefined extends T[K] ? never : K]: T[K];
} & {
    [K in keyof T as undefined extends T[K] ? K : never]+?: T[K];
};

export type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

export interface IBaseEntity {
    id: string;
    createdAt: Date;
    updatedAt: Date;
}

export class BaseEntity implements IBaseEntity {
    readonly id: string;
    readonly createdAt: Date;
    updatedAt: Date;

    constructor(data: Partial<IBaseEntity>) {
        this.id = data.id ?? generateDbId().toString();
        this.createdAt = data.createdAt ?? new Date();
        this.updatedAt = data.updatedAt ?? new Date();
    }
}

export type ObjectKeys<T extends object> = `${Exclude<keyof T, symbol>}`;

/**
https://github.com/sindresorhus/ts-extras/blob/main/source/object-keys.ts
A strongly-typed version of `Object.keys()`.

This is useful since `Object.keys()` always returns an array of strings. This function returns a strongly-typed array of the keys of the given object.

- [Explanation](https://stackoverflow.com/questions/55012174/why-doesnt-object-keys-return-a-keyof-type-in-typescript)
- [TypeScript issues about this](https://github.com/microsoft/TypeScript/issues/45390)

@example
```
const stronglyTypedItems = objectKeys({a: 1, b: 2, c: 3}); // => Array<'a' | 'b' | 'c'>
const untypedItems = Object.keys(items); // => Array<string>
```

@category Improved builtin
@category Type guard
*/
export const objectKeys = Object.keys as <Type extends object>(value: Type) => Array<ObjectKeys<Type>>;

/* EXPALANATION OF THE TYPE
 * this type is a recursive type that will go through all the nested objects and return the keys of the leaf objects
 * for example, if we have the following type:
 */
interface UserSettings {
    receiveFeedbacks: boolean;
    receiveMessagesNotifications: { active: boolean; restaurantsIds: string[] };
    notificationSettings: {
        userDevicesTokens: string[];
        active: boolean;
        reviews: {
            active: boolean;
            realtime: boolean;
            receivingWeekDays: number[];
            concernedRatings: number[];
            includeAutoRepliedReviews: boolean;
        };
        messages: { active: boolean; realtime: boolean; receivingWeekDays: number[] };
        posts: { noMoreScheduledPosts: { active: boolean; lastNotificationSentDate: Date }; publishError: { active: boolean } };
    };
    notifications: {
        email: {
            reviewReplyReminder: { active: boolean };
            specialHourReminder: { active: boolean };
            postSuggestion: { active: boolean };
        };
        web: {
            filters: { restaurantIds: string[] };
            newReviews: { active: boolean };
            reviewReplyReminder: { active: boolean };
            specialHourReminder: { active: boolean };
            postSuggestion: { active: boolean };
        };
    };
}
// Utility type to check if a type is an object (not primitive, not array)
type IsObject<T> = T extends object ? (T extends any[] ? false : true) : false;

// Recursive type to collect all leaf keys with full paths
type CollectKeys<T, Parent extends string = ''> = {
    [K in keyof T]: K extends string // Ensure K is a string
        ? IsObject<T[K]> extends true
            ? `${Parent}${K}` | `${Parent}${K}.${CollectKeys<T[K], ''>}` // Collect deeper keys
            : `${Parent}${K}` // Base case: leaf key
        : never;
}[keyof T];

// Utility type to split a dot-separated path into its parts
type Split<S extends string, Delimiter extends string = '.'> = S extends `${infer T}${Delimiter}${infer U}`
    ? [T, ...Split<U, Delimiter>]
    : [S];

// Recursive type to find the type of a nested key
type ResolvePath<T, Path extends string[]> = Path extends [infer Key, ...infer Rest]
    ? Key extends keyof T
        ? Rest extends []
            ? T[Key] // Base case: end of the path
            : ResolvePath<T[Key], Extract<Rest, string[]>> // Recursive case: go deeper
        : never
    : never;

// Type to extract the full leaf keys of an object (no prefix removal)
export type DeepLeafKeys<T> = CollectKeys<T>;

// Type to find the type for a specific leaf key from DeepLeafKeys
export type LeafKeyType<T, K extends DeepLeafKeys<T>> = ResolvePath<T, Split<K>>;

// Example usage with UserSettings
type _UserSettingsLeafKeys = DeepLeafKeys<UserSettings>;

// Test cases
type _Example1 = LeafKeyType<UserSettings, 'notifications.web.filters.restaurantIds'>; // string[]
type _Example2 = LeafKeyType<UserSettings, 'notificationSettings.reviews.active'>; // boolean
type _Example3 = LeafKeyType<UserSettings, 'receiveFeedbacks'>; // boolean
