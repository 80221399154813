import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ChatbotService } from ':core/services/chatbot.service';
import { ErrorsService } from ':core/services/errors.service';

@Injectable()
export class MyErrorInterceptor implements HttpInterceptor {
    constructor(
        private readonly _errorsService: ErrorsService,
        private readonly _chatbotService: ChatbotService,
        private _route: ActivatedRoute,
        private _router: Router
    ) {}

    // Http interceptor to handle errors
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err) => {
                if (err.status === 401) {
                    // passport error: only error that does not go in our custom error handler
                    localStorage.removeItem('jwtToken');
                    this._chatbotService.shutdownAndClearSession();

                    if (this._route.snapshot.url?.[0]?.path !== 'login') {
                        this._router.navigate(['auth/login']);
                    }
                } else if (err.status === 403) {
                    if (err.error?.casl) {
                        // WARNING: removing this condition will display unintentional toast of forbiddens for every 403 (like instagram pics). Need to find a better way if you want to do it anyhow
                        this._errorsService.caslForbiddenError$.next(err);
                    } else {
                        this._errorsService.forbiddenError$.next(err);
                    }
                    // passport error: only error that does not go in our custom error handler
                }

                return throwError(() => err);
            })
        );
    }
}
