@let base = ['/restaurants', selectedRestaurant()?._id ?? ''];

<app-sidenav-content-route-group
    [options]="{
        text: 'sidenav_content.settings' | translate,
        routerLink: base | concat: ['settings'],
        svgIcon: SvgIcon.SETTINGS,
    }"
    [childrenOptions]="[
        {
            text: 'sidenav_content.platforms' | translate,
            routerLink: base | concat: ['settings', 'platforms'],
            svgIcon: SvgIcon.DOT,
            isSmallSvgIcon: true,
            shouldHideSvgIconOnOpenedSidenav: true,
        },
        {
            text: 'sidenav_content.ai_and_automations' | translate,
            routerLink: base | concat: ['settings', 'automations'],
            svgIcon: SvgIcon.DOT,
            isSmallSvgIcon: true,
            shouldHideSvgIconOnOpenedSidenav: true,
        },
        {
            text: 'sidenav_content.users' | translate,
            routerLink: base | concat: ['settings', 'roles'],
            svgIcon: SvgIcon.DOT,
            isSmallSvgIcon: true,
            shouldHideSvgIconOnOpenedSidenav: true,
        },
    ]" />
