<div class="my-4 flex flex-col gap-y-3">
    <ng-container [ngTemplateOutlet]="thumbnailPickerWithSliderTemplate"></ng-container>
</div>

<ng-template #thumbnailPickerWithSliderTemplate>
    <div class="relative flex w-full max-w-full items-center gap-x-3 rounded-[10px] bg-malou-color-background-light p-3">
        <div class="flex h-[85px] w-full items-center" id="slide-bar-container">
            <div
                class="flex h-[75px] w-full overflow-hidden rounded-[10px] bg-malou-color-background-dark"
                (mousedown)="onSlideBarBackgroundClick($event)"
                #sliderBackgroundContainer>
                @for (imageUrl of sliderBackgroundImageUrls(); track imageUrl) {
                    <div class="flex-1">
                        <img class="h-[75px] w-full object-cover opacity-70" [src]="imageUrl" />
                    </div>
                }
            </div>

            @let t = thumbnail();
            @if (!t || t.type === ThumbnailType.VIDEO_FRAME) {
                <div
                    class="slider-bar"
                    cdkDragLockAxis="x"
                    cdkDragBoundary="#slide-bar-container"
                    cdkDrag
                    [ngStyle]="{ visibility: sliderBackgroundImageUrls().length ? 'visible' : 'hidden' }"
                    (cdkDragEnded)="onDragEnded($event)"
                    #dragElement="cdkDrag">
                    @if (sliderThumbnailUrl()) {
                        <img class="h-full w-full rounded-[4px] object-cover" [src]="sliderThumbnailUrl()" #slider />
                    } @else {
                        <div class="h-full w-full rounded-[4px] bg-malou-color-background-dark" #slider></div>
                    }
                </div>
            }
        </div>
        <div [ngClass]="{ 'small-media': thumbnailUrl() && thumbnail()?.type === ThumbnailType.CUSTOM }">
            <ng-container [ngTemplateOutlet]="thumbnailUrl() ? thumbnailPreviewWithImageTemplate : defaultThumbnailPreviewTemplate">
            </ng-container>
            <div
                class="absolute right-1 top-2 hidden transition-all hover:scale-105"
                [ngClass]="{ 'cursor-pointer': !isLoadingSliderImages() }"
                (click)="!isLoadingSliderImages() && resetThumbnail()">
                <div class="grid h-5 w-5 place-items-center rounded-full bg-white">
                    <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </div>
            </div>
        </div>
    </div>

    <div
        class="malou-color-text-1 malou-text-13--semibold flex justify-center gap-x-1"
        [ngClass]="{ 'opacity-50': isLoadingSliderImages() }">
        {{ 'common.or' | translate }}
        <app-media-file-uploader
            id="thumbnailUppyId"
            accept="image/png, image/jpeg"
            [multiple]="false"
            [disabled]="isLoadingSliderImages()"
            [maxImageSize]="DEFAULT_MAX_IMAGE_SIZE"
            (finishUpload)="onFinishUpload($event)"
            (startReadingFile)="startReadingFile()"
            (processError)="processError($event)"
            #mediaUploader>
            <button
                class="malou-btn-flat malou-text-13--semibold"
                mat-flat-button
                [ngClass]="{ 'cursor-default': isLoadingSliderImages() }"
                (click)="mediaUploader.openFilePicker()">
                {{ 'media_thumbnail_slider.choose_photo' | translate }}
            </button>
        </app-media-file-uploader>
        {{ 'common.or' | translate | lowercase }}
        <button
            class="malou-btn-flat malou-text-13--semibold"
            mat-flat-button
            [ngClass]="{ 'cursor-default': isLoadingSliderImages() }"
            [disabled]="isLoadingSliderImages()"
            (click)="openMediaPickerModal()">
            {{ 'media_thumbnail_slider.see_gallery' | translate }}
        </button>
    </div></ng-template
>

<ng-template #thumbnailPreviewWithImageTemplate>
    <img class="h-[75px] min-w-[75px] rounded-[10px] object-cover" width="75" height="75" [src]="thumbnailUrl()" />
</ng-template>

<ng-template #defaultThumbnailPreviewTemplate>
    <div class="h-[75px] min-w-[75px] rounded-[10px] bg-malou-color-background-dark"></div>
</ng-template>
