import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { AuthService } from ':core/auth/auth.service';
import { ChatbotService } from ':core/services/chatbot.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { LOGOUT_ACTION } from ':core/storage/storage.metareducer';
import { ButtonComponent } from ':shared/components/button/button.component';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-welcome-modal',
    standalone: true,
    imports: [NgTemplateOutlet, ButtonComponent, IllustrationPathResolverPipe, TranslateModule],
    templateUrl: './welcome-modal.component.html',
    styleUrl: './welcome-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeModalComponent {
    private readonly _dialogRef = inject(MatDialogRef<WelcomeModalComponent>);
    private readonly _authService = inject(AuthService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _store = inject(Store);
    private readonly _router = inject(Router);
    private readonly _chatbotService = inject(ChatbotService);

    close(preventOpenRestaurantModal = false): void {
        this._dialogRef.close({ preventOpenRestaurantModal });
    }

    logout(): void {
        this._authService.logout$().subscribe(() => {
            this._restaurantsService.setSelectedRestaurant(null);
            localStorage.removeItem('jwtToken');
            this._chatbotService.shutdownAndClearSession();
            window.sessionStorage.clear();
            this._store.dispatch({ type: LOGOUT_ACTION });
            this.close(true);
            void this._router.navigate(['auth/login']);
        });
    }
}
