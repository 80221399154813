@if (restaurantAiSettings(); as restaurantAiSettings) {
    <div class="flex flex-col gap-y-4">
        <!-- Default language response -->
        <app-simple-select
            [title]="'restaurant_ai_settings.modals.upsert.tabs.general.default_language_response.title' | translate"
            [required]="true"
            [values]="DEFAULT_LANGUAGE_RESPONSE_OPTIONS"
            [defaultValue]="restaurantAiSettings.defaultLanguageResponse"
            [disabled]="disabled()"
            [errorMessage]="defaultLanguageResponseError()"
            (simpleSelectChange)="updateDefaultLanguageResponse($event)">
            <ng-template let-value="value" #selectedValueTemplate>
                <div class="flex items-center gap-x-2">
                    <img class="!h-6 !w-6" [src]="value | flagPathResolver" [alt]="value" />
                    <span class="malou-text-12--medium text-malou-color-text-1">{{ value | enumTranslate: 'application_language' }}</span>
                </div>
            </ng-template>

            <ng-template let-option="option" #optionTemplate>
                <div class="flex items-center gap-x-2">
                    <img class="!h-4 !w-4" [src]="option | flagPathResolver" [alt]="option" />
                    <span class="malou-text-13--regular text-malou-color-text-2">{{ option | enumTranslate: 'application_language' }}</span>
                </div>
            </ng-template>
        </app-simple-select>

        <!-- Keywords -->
        <div>
            <div class="malou-text-10--regular malou-color-text-2 mb-2">
                {{ 'restaurant_ai_settings.modals.upsert.tabs.advanced.keywords.title' | translate }}
            </div>

            @if (selectedKeywords().length) {
                <div class="grid grid-cols-2 gap-2">
                    @for (keyword of selectedKeywords(); track keyword.restaurantKeywordId) {
                        <div class="flex items-center gap-x-2">
                            <mat-checkbox
                                color="primary"
                                [disabled]="disabled()"
                                [checked]="isKeywordSelected()(keyword)"
                                (change)="toggleKeywordSelected(keyword)"></mat-checkbox>
                            <span class="malou-text-10--regular malou-color-text-1">
                                {{ keyword.text }}
                            </span>
                        </div>
                    }
                </div>
            } @else {
                <div>
                    <span class="malou-text-10--regular malou-color-text-2 italic">{{
                        'restaurant_ai_settings.modals.upsert.tabs.advanced.keywords.empty' | translate
                    }}</span>
                    <span class="malou-text-10--semibold malou-color-text-primary cursor-pointer" (click)="onKeywordsCtaClick()">{{
                        'restaurant_ai_settings.modals.upsert.tabs.advanced.keywords.empty_cta' | translate
                    }}</span>
                </div>
            }
        </div>

        <!-- Forbidden words -->
        <app-select-chip-list
            [title]="'restaurant_ai_settings.modals.upsert.tabs.advanced.forbidden_words.title' | translate"
            [multiSelectionElementWrap]="true"
            [displayWith]="displayForbiddenWordWith"
            [values]="[]"
            [buildValueFromText]="buildForbiddenWordFromText"
            [selectedValues]="restaurantAiSettings.forbiddenWords"
            [maxSelectableValues]="MAX_FORBIDDEN_WORDS"
            [placeholder]="'restaurant_ai_settings.modals.upsert.tabs.advanced.forbidden_words.placeholder' | translate"
            [errorMessage]="forbiddenWordsError()"
            [hideArrow]="true"
            (selectChipListChange)="updateForbiddenWords($event)">
            <ng-template let-value="value" #optionTemplate>
                <div class="malou-chip malou-chip--primary flex items-center gap-x-3">
                    <mat-icon class="malou-color-primary z-10 !h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                    <span>
                        {{ displayForbiddenWordWith | applyPure: value }}
                    </span>
                </div>
            </ng-template>
        </app-select-chip-list>
    </div>
}
