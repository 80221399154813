import { Component } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Settings } from 'luxon';

import { setLuxonDefaultTimeZone } from '@malou-io/package-utils';

import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import * as UserSelectors from ':modules/user/store/user.selectors';
import { parseInsightsRouteParams } from ':shared/helpers/extract-statistics-route-data';
import { MalouPeriod } from ':shared/models';

@Component({
    selector: 'app-statistics-pdf',
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './statistics-pdf.component.html',
    styleUrls: ['./statistics-pdf.component.scss'],
})
export class StatisticsPdfComponent {
    constructor(
        private _store: Store,
        private _route: ActivatedRoute,
        private _translateService: TranslateService
    ) {
        const parsedQueryParams = parseInsightsRouteParams(this._route.snapshot.queryParams.params);
        const { startDate, endDate, timeZone } = parsedQueryParams;

        this._changeLuxonDefaultZone(timeZone);
        setLuxonDefaultTimeZone(timeZone);
        this._setLanguage();

        this._store.dispatch(
            StatisticsActions.editDates({
                dates: { startDate, endDate, period: MalouPeriod.CUSTOM },
            })
        );
    }

    private _changeLuxonDefaultZone(timeZone: string): void {
        Settings.defaultZone = timeZone;
    }

    private _setLanguage(): void {
        this._store.select(UserSelectors.selectUserInfos).subscribe((user) => {
            if (user?.defaultLanguage) {
                this._translateService.use(user.defaultLanguage);
            }
        });
    }
}
