import { Component, computed, inject, input } from '@angular/core';
import { DateTime } from 'luxon';

import { LocalStorage } from ':core/storage/local-storage';
import {
    SpecialHourInterval,
    SpecialHoursService,
} from ':modules/informations/hours-modal/special-hours-form/special-hours-interval.service';
import { DayOrDateHoursComponent } from ':shared/components/restaurant-informations/day-or-date-hours/day-or-date-hours.component';
import { Restaurant, SpecialTimePeriod } from ':shared/models';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';
import { MalouDatePipe } from ':shared/pipes/malou-date.pipe';

@Component({
    selector: 'app-restaurant-special-hours',
    templateUrl: './restaurant-special-hours.component.html',
    styleUrls: ['./restaurant-special-hours.component.scss'],
    standalone: true,
    imports: [DayOrDateHoursComponent, ApplyPurePipe, FormatDatePipe, MalouDatePipe],
    providers: [FormatTimePipe],
})
export class RestaurantSpecialHoursComponent {
    readonly restaurant = input.required<Restaurant>();

    private readonly _specialHoursService = inject(SpecialHoursService);

    readonly displayedHours = computed((): SpecialTimePeriod[] => this._sortHours(this.restaurant().specialHours));
    readonly restaurantSpecialHoursIntervals = computed((): SpecialHourInterval[] =>
        this._specialHoursService.createIntervalsFromDates(this.displayedHours())
    );

    readonly LANG = LocalStorage.getLang();

    isIntervalDates = (interval: SpecialHourInterval): boolean => interval.start.getDate().getTime() !== interval.end.getDate().getTime();

    isClosed = (interval: SpecialHourInterval): boolean => interval.hours.every((hour) => hour.isClosed);

    startDateToDisplay = (interval: SpecialHourInterval): string =>
        interval.isFromCalendarEvent
            ? DateTime.fromJSDate(interval.start.getDate()).setLocale(this.LANG).toLocaleString(DateTime.DATE_FULL)
            : '';

    private _sortHours(hours: SpecialTimePeriod[]): SpecialTimePeriod[] {
        if (!hours || hours.length === 0) {
            return [];
        }
        return [...hours].sort((h1, h2) => {
            if (h1.startDate.getDate() < h2.startDate.getDate()) {
                return -1;
            }
            if (h1.startDate.getDate() > h2.startDate.getDate()) {
                return 1;
            }
            if (!h1.openTime || !h2.openTime) {
                return 0;
            }
            if (h1.openTime < h2.openTime) {
                return -1;
            }
            if (h1.openTime > h2.openTime) {
                return 1;
            }
            return 0;
        });
    }
}
