@if (isEditingText) {
    @if (selectedRestaurantAndPost) {
        <form class="custom-h-full relative" [formGroup]="selectedRestaurantAndPost.post">
            <div class="editing-restaurant flex w-full items-center gap-x-2 rounded-[10px] py-2 pl-3">
                <img
                    class="malou-avatar--medium h-[45px] w-[45px]"
                    [lazyLoad]="
                        (selectedRestaurantAndPost.restaurant.logo | applySelfPure: 'getMediaUrl' : 'small') ??
                        ('default-picture-grey' | imagePathResolver)
                    " />
                <div class="ml-2">
                    <div class="flex items-center">
                        <div
                            class="mr-1 flex !h-3 !w-3 items-center justify-center rounded-[50%]"
                            [matTooltip]="
                                ((selectedRestaurantAndPost.restaurant | applySelfPure: 'isBrandBusiness')
                                    ? 'common.brand_account'
                                    : 'common.business'
                                ) | translate
                            "
                            [ngClass]="
                                (selectedRestaurantAndPost.restaurant | applySelfPure: 'isBrandBusiness')
                                    ? 'bg-malou-color-state-warn'
                                    : 'bg-malou-color-chart-pink--light'
                            ">
                            <mat-icon
                                class="!h-2 text-white"
                                [svgIcon]="
                                    (selectedRestaurantAndPost.restaurant | applySelfPure: 'isBrandBusiness') ? 'heart' : 'localisation'
                                "></mat-icon>
                        </div>
                        <div class="malou-text-12--bold leading-6 text-malou-color-text-1">
                            {{ selectedRestaurantAndPost.restaurant.name }}
                        </div>
                    </div>
                    @if (!(selectedRestaurantAndPost.restaurant | applySelfPure: 'isBrandBusiness')) {
                        <div class="malou-text-12 italic leading-6">
                            {{ selectedRestaurantAndPost.restaurant.address }}
                        </div>
                    }
                </div>
            </div>
            <div class="my-2">
                <span class="malou-text-10--regular malou-color-text-2">{{ 'posts.new_post.post_legend' | translate }}</span>
                <app-text-area
                    formControlName="text"
                    textAreaId="postText"
                    [autoResizeOnContentOverflow]="true"
                    [maxLength]="DESCRIPTION_MAX_LENGTH"
                    [placeholder]="'posts.new_post.post_description_placeholder' | translate"
                    [isLoadingAnimationEnabled]="IS_LOADING_ANIMATION_ENABLED"
                    [errorMessage]="getErrorMessage | applyPure: selectedRestaurantAndPost.post.controls.text.value"
                    #text>
                </app-text-area>
            </div>
            <div class="absolute bottom-[10px] right-0 flex justify-end gap-x-3">
                <button class="malou-btn-raised--secondary--alt !h-11 md:grow" mat-raised-button (click)="closeEditText(true)">
                    {{ 'common.cancel' | translate }}
                </button>
                <button
                    class="malou-btn-raised--primary !h-11 md:grow"
                    mat-raised-button
                    [disabled]="!!(getErrorMessage | applyPure: selectedRestaurantAndPost.post.controls.text.value)"
                    (click)="closeEditText(false)">
                    {{ 'common.validate' | translate }}
                </button>
            </div>
        </form>
    }
} @else {
    <div class="mb-3">
        <app-search [debounceTime]="0" [placeholder]="'common.search' | translate" (searchChange)="onSearchChange($event)"></app-search>
    </div>
    <div class="hide-scrollbar flex h-[60vh] flex-col overflow-auto">
        <ng-container [ngTemplateOutlet]="restaurantsAndPostsTableTemplate"> </ng-container>
        <app-paginator
            [shouldBeHidden]="restaurantAndPostDataSource.filteredData.length < DEFAULT_PAGINATION_SIZE"
            [pageSize]="DEFAULT_PAGINATION_SIZE"
            [pageSizeOptions]="[DEFAULT_PAGINATION_SIZE]">
        </app-paginator>
    </div>
}

<ng-template #restaurantsAndPostsTableTemplate>
    <mat-table class="malou-mat-table" matSort matSortDisableClear [dataSource]="restaurantAndPostDataSource" #table="matTable">
        <ng-container matColumnDef="restaurant">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let restaurantAndPost; table: table" class="mb-[^2px] w-full rounded-[10px] py-1 pr-1">
                <div class="ml-2 flex w-full items-center gap-x-2">
                    <img
                        class="malou-avatar--medium h-[45px] w-[45px]"
                        [lazyLoad]="
                            (restaurantAndPost.restaurant.logo | applySelfPure: 'getMediaUrl' : 'small') ??
                            ('default-picture-grey' | imagePathResolver)
                        " />
                    <div class="ml-2">
                        <div class="flex items-center">
                            <div
                                class="mr-1 flex !h-3 !w-3 items-center justify-center rounded-[50%]"
                                [matTooltip]="
                                    ((restaurantAndPost.restaurant | applySelfPure: 'isBrandBusiness')
                                        ? 'common.brand_account'
                                        : 'common.business'
                                    ) | translate
                                "
                                [ngClass]="
                                    (restaurantAndPost.restaurant | applySelfPure: 'isBrandBusiness')
                                        ? 'bg-malou-color-state-warn'
                                        : 'bg-malou-color-chart-pink--light'
                                ">
                                <mat-icon
                                    class="!h-2 text-white"
                                    [svgIcon]="
                                        (restaurantAndPost.restaurant | applySelfPure: 'isBrandBusiness') ? 'heart' : 'localisation'
                                    "></mat-icon>
                            </div>
                            <div class="malou-text-12--bold leading-6 text-malou-color-text-1">
                                {{ restaurantAndPost.restaurant.name }}
                            </div>
                        </div>
                        @if (!(restaurantAndPost.restaurant | applySelfPure: 'isBrandBusiness')) {
                            <div class="malou-text-12 italic leading-6">
                                {{ restaurantAndPost.restaurant.address }}
                            </div>
                        }
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>{{ 'common.status' | translate }}</mat-header-cell>
            <mat-cell
                *matCellDef="let restaurantAndPost; table: table; let index = index"
                class="mb-[^2px] w-full rounded-[10px] py-1 pr-1">
                <form [formGroup]="restaurantAndPost.post">
                    <div class="malou-text-10--regular w-full">
                        <app-select
                            formControlName="status"
                            [values]="POST_DATE_STATUSES"
                            [displayWith]="displayStatus"
                            (selectChange)="onStatusChange($event, index)">
                        </app-select>
                    </div>
                </form>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef>{{ 'common.date' | translate }}</mat-header-cell>
            <mat-cell
                *matCellDef="let restaurantAndPost; table: table; let index = index"
                class="mb-[^2px] w-full rounded-[10px] py-1 pr-1">
                @if ([PostDateStatus.LATER, PostDateStatus.DRAFT] | includes: restaurantAndPost.post.controls.status.value) {
                    <form class="flex w-full gap-x-8" [formGroup]="restaurantAndPost.post">
                        <div class="grow">
                            <app-input-date-picker
                                formControlName="date"
                                [errorMessage]="
                                    (isBeforeToday | applyPure: restaurantAndPost.post.controls.date.value)
                                        ? ('social_posts.new_social_post.date_error' | translate)
                                        : null
                                "
                                [min]="MIN_DATE"
                                (inputDatePickerChange)="onDateChange()"></app-input-date-picker>
                        </div>
                    </form>
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="time">
            <mat-header-cell *matHeaderCellDef>{{ 'common.time' | translate }}</mat-header-cell>
            <mat-cell
                *matCellDef="let restaurantAndPost; table: table; let index = index"
                class="mb-[^2px] w-full rounded-[10px] py-1 pr-1">
                @if ([PostDateStatus.LATER, PostDateStatus.DRAFT] | includes: restaurantAndPost.post.controls.status.value) {
                    <form class="flex flex-col" [formGroup]="restaurantAndPost.post">
                        <mat-select
                            class="!m-0 !h-0 opacity-0"
                            formControlName="time"
                            panelClass="malou-select-panel"
                            placeholder="----"
                            [hideSingleSelectionIndicator]="true"
                            (selectionChange)="onTimeChange($event, index)">
                            @for (time of getSelectableTimes | applyPure: index; track time) {
                                <mat-option
                                    [value]="time"
                                    [disabled]="isBeforeToday | applyPure: restaurantAndPost.post.controls.date.value">
                                    {{ time | formatTime }}
                                </mat-option>
                            }
                        </mat-select>
                        <app-input-text
                            class="grow"
                            formControlName="time"
                            inputType="time"
                            [svgIcon]="SvgIcon.CLOCK"
                            [errorMessage]="
                                isPastHour({
                                    hourWithMinute: restaurantAndPost.post.controls.time.value,
                                    date: restaurantAndPost.post.controls.date.value,
                                })
                                    ? ('common.invalid_time' | translate)
                                    : ''
                            "
                            (input)="onTimeInputChange($event, index)"></app-input-text>
                    </form>
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="text">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell
                *matCellDef="let restaurantAndPost; table: table; let index = index"
                class="mb-[^2px] block w-full rounded-[10px] py-1 pr-1">
                <button class="malou-btn-flat ml-2 !h-[30px] !pr-0" mat-button (click)="selectPostAndEditText(restaurantAndPost)">
                    <span>
                        {{ 'posts.new_post.post_legend' | translate }}
                    </span>
                    <mat-icon class="ml-1 !w-3" iconPositionEnd [svgIcon]="SvgIcon.ARROW_RIGHT"></mat-icon>
                </button>

                @if (!restaurantAndPost.post.controls.text.value) {
                    <mat-error class="w-100 malou-text-10 malou-color-state-error text-center italic">{{
                        'common.missing_value' | translate
                    }}</mat-error>
                }
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let restaurantAndPost; columns: displayedColumns; table: table; let i = index" class="row"></mat-row>
    </mat-table>
</ng-template>
