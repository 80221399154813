@if ({ size: screenSizeService.resize$ | async }; as screenSizeData) {
    <div class="flex h-full flex-col py-4">
        <ng-template [ngTemplateOutlet]="head" [ngTemplateOutletContext]="{ screenSizeData: screenSizeData }"></ng-template>
        <ng-template [ngTemplateOutlet]="list"></ng-template>
    </div>
}
<ng-template let-screenSizeData="screenSizeData" #head>
    <div class="mx-7.5 md:mx-5">
        <div class="flex w-full items-center justify-between gap-x-4 pb-2">
            <div class="grow md:hidden">
                <app-search [placeholder]="'common.search' | translate" (searchChange)="editTextFilter($event)"></app-search>
            </div>
            <div [matTooltip]="duplicationTooltip">
                <button
                    class="malou-btn-icon--secondary btn-xl"
                    mat-icon-button
                    [matMenuTriggerFor]="duplicateMenu"
                    [disabled]="!selectedPosts.length">
                    <mat-icon [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                </button>
            </div>

            <div [matTooltip]="(selectedPosts.length ? 'common.delete' : 'posts.seo.list.select_more_to_delete') | translate">
                <button
                    class="malou-btn-icon--secondary btn-xl"
                    mat-icon-button
                    [disabled]="!selectedPosts.length"
                    (click)="deleteSelectedPosts()">
                    <mat-icon [svgIcon]="SvgIcon.TRASH"></mat-icon>
                </button>
            </div>
            <div>
                <button
                    class="malou-btn-icon--secondary btn-xl"
                    mat-icon-button
                    [matMenuTriggerFor]="filtersMenu"
                    [matTooltip]="'common.filters' | translate">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.SETTINGS"></mat-icon>
                    @if (filterCount > 0) {
                        <span class="malou-status--count malou-text-10--medium absolute right-1.5 top-1.5">
                            {{ filterCount }}
                        </span>
                    }
                </button>
            </div>
            <div>
                <button
                    class="malou-btn-raised--primary btn-xl !h-12.5 md:!hidden"
                    data-testid="create-seo-post-open-modal-btn"
                    mat-raised-button
                    (click)="createPost()">
                    {{ 'posts.add_post' | translate }}
                </button>
                <button class="malou-btn-icon--primary btn-xl !hidden md:!flex" mat-icon-button (click)="createPost()">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.ADD"></mat-icon>
                </button>
            </div>
            <div>
                <button
                    class="malou-btn-icon--secondary btn-xl"
                    matTooltip="{{ 'posts.sync' | translate }}"
                    mat-icon-button
                    (click)="synchronize()">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.SYNC">sync</mat-icon>
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #list>
    <div class="min-h-0 grow">
        <div
            class="h-full overflow-y-auto p-7.5 pt-0 md:p-5"
            id="posts-list-infinite-scroll-container"
            infinite-scroll
            infiniteScrollContainer="#posts-list-infinite-scroll-container"
            [infiniteScrollDistance]="3"
            [fromRoot]="true"
            (scrolled)="onScrollDown()">
            <div class="hidden md:block">
                <app-search [placeholder]="'common.search' | translate" (searchChange)="editTextFilter($event)"></app-search>
            </div>
            @if (posts.length > 0 && !loadingKeywords) {
                <div class="gallery-grid grid gap-5 md:!grid-cols-1 md:gap-3">
                    @for (post of posts; track trackByIdFn($index, post)) {
                        <app-single-post
                            class="!border-none"
                            [post]="post"
                            [restaurantKeywords]="restaurantKeywords"
                            [ngStyle]="{ display: (hideDraft | applyPure: post : hideDraftPost) ? 'none' : 'inline' }"
                            (emptySelectedPosts)="emptySelectedPosts()"
                            (duplicate)="duplicateSinglePost($event)"
                            (delete)="deletePost($event)"
                            (open)="openPost($event)"
                            (check)="checkPost($event)">
                        </app-single-post>
                    }
                    @if (posts.length % 3 > 0) {
                        <div class="malou-card h-0 !border-none !p-0"></div>
                    }
                    @if (posts.length < (postsContext.postCount() ?? 0)) {
                        <button class="malou-btn-flat" mat-button (click)="onScrollDown()">+</button>
                    }
                </div>
            }
            @if (loading || loadingKeywords) {
                <ng-template *ngTemplateOutlet="loadingTemplate"></ng-template>
            }
            @if (posts.length === 0 && !loading) {
                @if (isGmbConnected$ | async) {
                    <ng-template *ngTemplateOutlet="gmbConnectedNoPosts"></ng-template>
                }
                @if (!(isGmbConnected$ | async)) {
                    <ng-template *ngTemplateOutlet="gmbDisconnected"></ng-template>
                }
            }
            <app-scroll-to-top class="fixed bottom-8 right-7.5" container="#posts-list-infinite-scroll-container"> </app-scroll-to-top>
        </div>
    </div>
</ng-template>

<ng-template #gmbConnectedNoPosts>
    <div class="flex w-full flex-col items-center">
        <img class="mt-8 w-[150px]" alt="Goggles illustration" [src]="'Goggles' | illustrationPathResolver" />
        <p class="malou-text-14--bold mt-4">{{ 'posts.no_post' | translate }}</p>
        <p class="malou-text-10--regular italic">
            {{ 'posts.no_post_subtitle' | translate }}
        </p>
        <br />
        <br />
        <div class="flex w-[600px] flex-col items-center">
            <div class="mb-2 flex justify-center gap-2">
                <button class="malou-btn-raised--secondary--alt !h-11" mat-raised-button (click)="synchronize()">
                    {{ 'posts.synchronize_button' | translate }}
                </button>
                <button class="malou-btn-raised--primary btn-xl !h-11" mat-raised-button (click)="createPost()">
                    {{ 'posts.add_post' | translate }}
                </button>
            </div>

            <br />
        </div>
    </div>
</ng-template>

<ng-template #gmbDisconnected>
    <div class="flex w-full flex-col items-center">
        <img class="mt-8 w-[150px]" alt="Goggles illustration" [src]="'Goggles' | illustrationPathResolver" />
        <p class="malou-text-14--bold mt-4">{{ 'posts.gmb_disconnected' | translate }}</p>
        <p class="malou-text-10--regular italic">
            {{ 'posts.gmb_disconnected_subtitle' | translate }}
        </p>
        <br />
        <br />
        <div class="flex w-[600px] flex-col items-center">
            <div class="mb-2 flex justify-center">
                <button class="malou-btn-raised--secondary !h-11" mat-raised-button (click)="createPost()">
                    {{ 'posts.create_draft' | translate }}
                </button>
                <a class="malou-btn-raised--primary btn-xl !h-11" mat-raised-button (click)="goToPlatforms()">
                    {{ 'posts.navigate_to_platforms_button' | translate }}
                </a>
            </div>
            <br />
        </div>
    </div>
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #duplicateMenu="matMenu">
    <button class="flex gap-x-3" mat-menu-item [matMenuTriggerFor]="duplicateActions">
        <mat-icon color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
        <span class="malou-text-14--regular">{{ 'posts.duplicate' | translate }}</span>
    </button>
    <button class="flex gap-x-3" mat-menu-item (click)="deleteSelectedPosts()">
        <mat-icon color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
        <span class="malou-text-14--regular">{{ 'posts.delete' | translate }}</span>
    </button>
</mat-menu>

<mat-menu class="malou-mat-menu" #duplicateActions="matMenu">
    <button mat-menu-item (click)="duplicateSelectedPosts(DuplicationDestination.HERE, platformsKeysWithSeoPost)">
        <span>{{ 'posts.duplicate_in_seo' | translate }}</span>
    </button>
    <button mat-menu-item (click)="duplicateSelectedPosts(DuplicationDestination.HERE, platformsKeysWithSocialPost)">
        <span>{{ 'posts.duplicate_in_social' | translate }}</span>
    </button>
    <button mat-menu-item (click)="duplicateSelectedPosts(DuplicationDestination.OUT, platformsKeysWithSeoPost)">
        <span>{{ 'common.to_other_venues' | translate }}</span>
    </button>
</mat-menu>

<mat-menu class="malou-mat-menu malou-box-shadow !min-w-fit !rounded-[10px] md:w-screen md:!max-w-none" #filtersMenu="matMenu">
    <div class="w-[440px] p-4 md:w-full" (click)="$event.stopPropagation()">
        <div class="flex w-full justify-between">
            <div class="malou-text-18--bold text-malou-color-text-1">
                {{ 'common.filters' | translate }}
            </div>

            <button class="malou-btn-flat" mat-button [disabled]="filterCount === 0" (click)="resetFilters(); reload()">
                {{ 'common.reset' | translate }}
            </button>
        </div>

        <div class="mt-4 flex flex-col gap-y-5">
            <ng-container [ngTemplateOutlet]="periodFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="statusFilterTemplate"></ng-container>
        </div>
    </div>
</mat-menu>

<ng-template #periodFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.period' | translate }}
        </div>
        <app-grouped-date-filters
            [startDate]="(start$ | async) ?? null"
            [endDate]="(end$ | async) ?? null"
            [periodOptions]="periodOptionKeys"
            [defaultPeriod]="(period$ | async) ?? MalouPeriod.DEFAULT"
            [withLabel]="false"
            (chooseBoundaryDate)="editPostsFilters($event)">
        </app-grouped-date-filters>
    </div>
</ng-template>

<ng-template #statusFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.status' | translate }}
        </div>
        <app-is-published-filters [initialValue$]="postFilterStatus$" (changeOption)="editPostsFilters({ publicationStatus: $event })">
        </app-is-published-filters>
    </div>
</ng-template>

<ng-template #loadingTemplate>
    <div class="h-full w-full px-7.5 pb-7.5">
        <div class="mt-5 grid h-full grid-cols-4 gap-5 md:grid-cols-2">
            @for (item of [0, 1, 2, 3, 4, 5, 6, 7]; track item) {
                <app-skeleton skeletonClass="h-[430px] md:h-[330px] w-full secondary-bg p-6" [animate]="false">
                    <ng-template #nestedSkeletonTemplate>
                        <div class="flex items-center justify-between">
                            <div class="title-subtitle grow">
                                <app-skeleton skeletonClass="w-full h-[18px] mb-2"></app-skeleton>
                                <app-skeleton skeletonClass="w-full h-[18px]"></app-skeleton>
                            </div>
                            <div class="ml-2">
                                <app-skeleton skeletonClass="h-[50px] w-[50px]"></app-skeleton>
                            </div>
                        </div>
                        <app-skeleton skeletonClass="w-full h-[250px] md:h-[150px] mt-5"></app-skeleton>
                        <app-skeleton skeletonClass="w-[50%] md:w-full h-[35px] mt-5"></app-skeleton>
                    </ng-template>
                </app-skeleton>
            }
        </div>
    </div>
</ng-template>
