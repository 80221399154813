@if (restaurantAiSettings(); as restaurantAiSettings) {
    <div class="flex flex-col gap-y-4 pr-1">
        <!-- Restaurant name -->
        <app-input-text
            [testId]="'general-ai-settings-restaurant-name-input'"
            [title]="'restaurant_ai_settings.modals.upsert.tabs.general.restaurant_name.title' | translate"
            [placeholder]="''"
            [defaultValue]="restaurantAiSettings.restaurantName"
            [showRequiredStar]="true"
            [disabled]="disabled()"
            [errorMessage]="restaurantNameError()"
            (inputTextChange)="updateRestaurantName($event)">
        </app-input-text>

        <!-- Reply tone -->
        <div>
            <div class="malou-text-10--regular malou-color-text-2 mb-3">
                {{ 'restaurant_ai_settings.modals.upsert.tabs.general.reply_tone.title' | translate }} *
            </div>

            <mat-radio-group
                class="flex gap-x-5"
                color="primary"
                [disabled]="disabled()"
                [value]="restaurantAiSettings.replyTone"
                (change)="updateReplyTone($event)">
                <mat-radio-button
                    class="malou-small-radio-button"
                    data-testid="general-ai-settings-formal-input"
                    [value]="ReplyTone.FORMAL">
                    <span class="malou-text-10--semibold">
                        {{ ReplyTone.FORMAL | enumTranslate: 'reply_tone' }}
                    </span>
                </mat-radio-button>
                <mat-radio-button
                    class="malou-small-radio-button"
                    data-testid="general-ai-settings-informal-input"
                    [value]="ReplyTone.INFORMAL">
                    <span class="malou-text-10--semibold">
                        {{ ReplyTone.INFORMAL | enumTranslate: 'reply_tone' }}
                    </span>
                </mat-radio-button>
                <mat-radio-button
                    class="malou-small-radio-button"
                    data-testid="general-ai-settings-does-not-matter-input"
                    [value]="ReplyTone.DOES_NOT_MATTER">
                    <span class="malou-text-10--semibold">
                        {{ ReplyTone.DOES_NOT_MATTER | enumTranslate: 'reply_tone' }}
                    </span>
                </mat-radio-button>
            </mat-radio-group>

            @if (replyToneError()) {
                <mat-error>
                    {{ replyToneError() }}
                </mat-error>
            }
        </div>

        <div class="flex flex-col gap-3 rounded bg-malou-color-background-light p-4">
            <div class="malou-text-12--semibold malou-color-text-1">
                {{ 'restaurant_ai_settings.modals.upsert.tabs.general.catchphrase.title' | translate }}
            </div>
            <div class="flex gap-x-3 p-1 md:flex-col md:gap-y-4">
                <div class="flex-1">
                    <app-input-text
                        placeholder="{{ 'restaurant_ai_settings.modals.upsert.tabs.advanced.catchphrase.placeholder' | translate }}"
                        [required]="false"
                        [defaultValue]="restaurantAiSettings.catchphrase"
                        (inputTextChange)="updateCatchphrase($event)">
                    </app-input-text>
                </div>
                <div class="flex-1 rounded-lg bg-malou-color-background-white">
                    <app-simple-select
                        [testId]="'general-ai-settings-customer-name-input'"
                        [values]="CUSTOMER_NAMING_OPTIONS"
                        [defaultValue]="restaurantAiSettings.customerNaming"
                        [disabled]="disabled()"
                        [errorMessage]="customerNamingError()"
                        (simpleSelectChange)="updateCustomerNaming($event)">
                        <ng-template let-value="value" #selectedValueTemplate>
                            <div class="malou-text-12--medium text-malou-color-text-1">
                                {{ value | enumTranslate: 'customer_naming' }}
                            </div>
                        </ng-template>

                        <ng-template let-option="option" #optionTemplate>
                            <div class="malou-text-13--regular text-malou-color-text-2">
                                {{ option | enumTranslate: 'customer_naming' }}
                            </div>
                        </ng-template>
                    </app-simple-select>
                </div>
            </div>
            <div class="flex items-center gap-x-2">
                <mat-checkbox
                    color="primary"
                    [checked]="!restaurantAiSettings.shouldTranslateCatchphrase"
                    (change)="onToggleShouldTranslateCatchphrase()"></mat-checkbox>
                <span class="malou-text-10--regular malou-color-text-2">
                    {{ 'restaurant_ai_settings.modals.upsert.tabs.general.do_not_translate' | translate }}</span
                >
            </div>
        </div>

        <!-- Signature -->
        <div class="flex flex-col gap-3 rounded bg-malou-color-background-light p-4">
            <div class="flex flex-col">
                <span class="malou-text-12--semibold malou-color-text-1">{{
                    'restaurant_ai_settings.modals.upsert.tabs.general.signature.title' | translate
                }}</span>
                <span class="malou-text-10--regular malou-color-text-2 mt-1 italic">{{
                    'restaurant_ai_settings.modals.upsert.tabs.general.signature.add_one_or_several' | translate
                }}</span>
            </div>
            <div class="flex flex-col gap-y-2">
                @for (signature of restaurantAiSettings.signaturesForForm; track signature.id; let last = $last) {
                    <div class="flex w-full items-center">
                        <app-input-text
                            class="w-full"
                            [testId]="'general-ai-settings-signature-input'"
                            [defaultValue]="signature.text"
                            [showRequiredStar]="false"
                            [disabled]="disabled()"
                            [placeholder]="
                                'restaurant_ai_settings.modals.upsert.tabs.general.signature.placeholder'
                                    | translate: { restaurantName: restaurantAiSettings.restaurantName }
                            "
                            (inputTextChange)="updateSignature($event, signature.id)">
                        </app-input-text>
                        <mat-icon
                            class="ml-2 !h-4 !w-4 cursor-pointer text-malou-color-state-error"
                            [svgIcon]="SvgIcon.ROUNDED_MINUS"
                            (click)="removeSignature(signature.id)"></mat-icon>
                        @if (last) {
                            <mat-icon
                                class="ml-2 !h-4 !w-4 cursor-pointer"
                                color="primary"
                                [svgIcon]="SvgIcon.ADD"
                                (click)="addSignature()"></mat-icon>
                        } @else {
                            <div class="ml-2 !h-4 !w-4"></div>
                        }
                    </div>
                }
            </div>
            <div class="flex items-center gap-x-2">
                <mat-checkbox
                    color="primary"
                    [checked]="!restaurantAiSettings.shouldTranslateSignature"
                    (change)="onToggleShouldTranslateSignatures()"></mat-checkbox>
                <span class="malou-text-10--regular malou-color-text-2">
                    {{ 'restaurant_ai_settings.modals.upsert.tabs.general.do_not_translate' | translate }}</span
                >
            </div>
        </div>
    </div>
}
