import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { HttpResponseBase } from '@angular/common/http';
import { Component, effect, EventEmitter, Input, OnInit, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { GetRestaurantsRankingsV3ResponseDto } from '@malou-io/package-dto';
import { BusinessCategory, GeoSamplePlatform } from '@malou-io/package-utils';

import { KeywordsService } from ':core/services/keywords.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { getMostRecentRank } from ':modules/statistics/seo/statistics-seo-keywords/statistics-seo-keywords-v3.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { FilterOption, SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { getStatisticsError, isDateSetOrGenericPeriod } from ':shared/helpers';
import { Restaurant } from ':shared/models';
import { ValueError } from ':shared/models/value-error';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import * as AggregatedStatisticsSelector from '../../store/aggregated-statistics.selectors';

class RestaurantWithKeywordsAndTop {
    restaurant: { _id: string; name: string };
    keywords: string[];
    keywordsTop20: string[];
    keywordsTop3: string[];
    top20: number;
    top3: number;

    constructor(init?: Partial<RestaurantWithKeywordsAndTop>) {
        Object.assign(this, init);
        this.keywords = [];
        this.keywordsTop20 = [];
        this.keywordsTop3 = [];
        this.top20 = 0;
        this.top3 = 0;
    }

    incrementTop20(): void {
        this.top20++;
    }

    incrementTop3(): void {
        this.top3++;
    }

    addKeyword(keyword: string): void {
        this.keywords.push(keyword);
    }

    addKeywordTop20(keyword: string): void {
        this.keywordsTop20.push(keyword);
    }

    addKeywordTop3(keyword: string): void {
        this.keywordsTop3.push(keyword);
    }
}

class KeywordTableDataRow {
    chartAxisName: string;
    restaurantId: string;
    restaurantName: string;
    keywords: string[];
    keywordsTop20: string[];
    keywordsTop3: string[];
    currentTop20: number;
    currentTop3: number;
    previousTop20: number;
    previousTop3: number;
    type: string;
    error?: string;

    constructor(
        init?: Partial<KeywordTableDataRow>,
        private _translationService?: TranslateService
    ) {
        if (init) {
            this.chartAxisName = init.restaurantName + ':ID:' + init.restaurantId;
            this.restaurantId = init.restaurantId ?? '';
            this.restaurantName = init.restaurantName ?? '';
            this.keywords = init.keywords ?? [];
            this.keywordsTop20 = init.keywordsTop20 ?? [];
            this.keywordsTop3 = init.keywordsTop3 ?? [];
            this.currentTop20 = init.currentTop20 ?? 0;
            this.currentTop3 = init.currentTop3 ?? 0;
            this.previousTop20 = init.previousTop20 ?? 0;
            this.previousTop3 = init.previousTop3 ?? 0;
            this.type = init.type ?? '';
        }
        this.setError(this.translateError());
    }

    getDiffTop20(): number {
        return this.currentTop20 - this.previousTop20;
    }

    getDiffTop3(): number {
        return this.currentTop3 - this.previousTop3;
    }

    getKeywordsToString(): string {
        return this.keywords?.map((k) => `${k}`).join(' - ');
    }

    getKeywordsTop20ToString(): string {
        return this.keywordsTop20?.map((k) => `- ${k}`).join('\n');
    }

    getKeywordsTop3ToString(): string {
        return this.keywordsTop3?.map((k) => `- ${k}`).join('\n');
    }

    hasError(): boolean {
        return !!this.error;
    }

    setError(error?: string): void {
        this.error = error;
    }

    getError(): string | undefined {
        return this.error;
    }

    isBrandBusiness(): boolean {
        return this.type === BusinessCategory.BRAND;
    }

    translateError(): string | undefined {
        if (this.error?.match(/The provided Place ID is no longer valid/)) {
            return this._translationService?.instant('aggregated_statistics.seo.keywords.cant_get_data');
        }
        return;
    }
}

enum Column {
    RESTAURANT_NAME = 'restaurantName',
    CURRENT_TOP_20 = 'currentTop20',
    CURRENT_TOP_3 = 'currentTop3',
    VIEW_KEYWORD_LIST = 'view_keywords_list',
}

@Component({
    selector: 'app-keywords-v3',
    templateUrl: './keywords-v3.component.html',
    styleUrls: ['./keywords-v3.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatTooltipModule,
        MatIconModule,
        MatButtonToggleModule,
        SortByFiltersComponent,
        MatTableModule,
        MatSortModule,
        SkeletonComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        ApplySelfPurePipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
})
export class KeywordsV3Component implements OnInit {
    @Input() shouldHideTableClickableElements = false;
    @Input() tableSortOptions: Sort | undefined = undefined;
    @Output() tableSortOptionsChange = new EventEmitter<Sort>();
    @Output() hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;
    readonly SORT_OPTIONS: FilterOption[] = [
        { key: 'restaurantName', label: this._translate.instant('aggregated_statistics.seo.keywords.restaurant') },
        { key: 'top20', label: this._translate.instant('aggregated_statistics.seo.keywords.top20') },
        { key: 'top3', label: this._translate.instant('aggregated_statistics.seo.keywords.top3') },
    ];

    defaultSort: Sort = { active: 'restaurantName', direction: 'asc' };

    dataSource = new MatTableDataSource<KeywordTableDataRow>();

    readonly Column = Column;
    DISPLAYED_COLUMNS: Column[];

    readonly dates$ = this._store.select(AggregatedStatisticsSelector.selectDatesFilter);
    readonly selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;

    restaurantsStats$: Observable<ValueError<GetRestaurantsRankingsV3ResponseDto, string>>;
    restaurantsStats: GetRestaurantsRankingsV3ResponseDto | undefined;

    readonly isLoading$ = new BehaviorSubject(true);

    warningTooltip?: string;

    readonly commonKeywordsOnly: WritableSignal<boolean> = signal(false);

    constructor(
        private readonly _store: Store,
        private readonly _keywordsService: KeywordsService,
        private readonly _router: Router,
        private readonly _translate: TranslateService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext,
        public screenSizeService: ScreenSizeService
    ) {
        this.isLoading$.subscribe((isLoading) => this.isLoadingEvent.emit(isLoading));

        effect(() => this._setDatasource(this.commonKeywordsOnly()));
    }

    @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    ngOnInit(): void {
        this.restaurantsStats$ = this._loadRestaurantsStats$();
        if (this.tableSortOptions) {
            this.defaultSort = this.tableSortOptions;
        }
    }

    onSortChange(sort: Sort): void {
        if (sort.direction?.length) {
            this.tableSortOptionsChange.emit(sort);
        }
    }

    private _loadRestaurantsStats$(): Observable<ValueError<GetRestaurantsRankingsV3ResponseDto, string>> {
        this.DISPLAYED_COLUMNS = this._getDisplayedColumns();
        return combineLatest([this.dates$, this.selectedRestaurants$]).pipe(
            filter(([dates]) => isDateSetOrGenericPeriod(dates)),
            tap(() => {
                this.isLoading$.next(true);
            }),
            switchMap((values) => {
                const [_dates, selectedRestaurants] = values;
                if (selectedRestaurants.length === 0) {
                    return EMPTY;
                }
                return of(values);
            }),
            filter(([dates, _restaurants]) => {
                const { startDate, endDate } = dates;
                return !!startDate && !!endDate;
            }),
            switchMap(([dates, restaurants]) => {
                const endDate = dates.endDate as Date;
                const startDate = DateTime.fromJSDate(endDate).minus({ weeks: 2 }).toJSDate();
                const businessRestaurants = restaurants.filter((r) => r.isBrandBusiness());
                if (businessRestaurants.length) {
                    this.warningTooltip = this._computeWarningTooltip(businessRestaurants);
                }
                const restaurantIds = restaurants.filter((r) => !r.isBrandBusiness()).map((r) => r._id);

                return this._keywordsService.handleGetKeywordRankingsForManyRestaurantsV3({
                    restaurantIds,
                    startDate,
                    endDate,
                    platformKey: GeoSamplePlatform.GMAPS,
                });
            }),
            tap((restaurantsStats) => {
                this.restaurantsStats = restaurantsStats;
                this.isLoading$.next(false);
            }),
            map((values) => ({ value: values })),
            catchError((error) =>
                of({
                    error: error instanceof HttpResponseBase ? getStatisticsError(error) : error.message,
                })
            ),
            tap(() => {
                this._setDatasource(this.commonKeywordsOnly());
                this.isLoading$.next(false);
            })
        );
    }

    getRestaurantsWithKeywordsAndTop(
        restaurantsStats: GetRestaurantsRankingsV3ResponseDto,
        commonKeywordsOnly: boolean
    ): RestaurantWithKeywordsAndTop[] {
        const allKeywords = restaurantsStats.restaurants.flatMap((r) => r.keywords.map((k) => k.name));
        const commonKeywords = allKeywords.filter((k) =>
            restaurantsStats.restaurants.every((r) => r.keywords.some((restaurantKeyword) => restaurantKeyword.name === k))
        );

        const result: RestaurantWithKeywordsAndTop[] = [];
        for (const restaurantStats of restaurantsStats.restaurants) {
            const restaurantWithKeywordsAndTop = new RestaurantWithKeywordsAndTop({
                restaurant: {
                    _id: restaurantStats.restaurantInfo.id,
                    name: restaurantStats.restaurantInfo.internalName ?? restaurantStats.restaurantInfo.name,
                },
            });

            for (const keywordStats of restaurantStats.keywords) {
                if (commonKeywordsOnly && !commonKeywords.includes(keywordStats.name)) {
                    continue;
                }

                restaurantWithKeywordsAndTop.addKeyword(keywordStats.name);
                const rank = getMostRecentRank(keywordStats)?.position ?? undefined;
                if (rank !== undefined) {
                    if (rank.rank <= 3) {
                        restaurantWithKeywordsAndTop.incrementTop3();
                        restaurantWithKeywordsAndTop.addKeywordTop3(keywordStats.name);
                    }

                    if (rank.rank <= 20) {
                        restaurantWithKeywordsAndTop.incrementTop20();
                        restaurantWithKeywordsAndTop.addKeywordTop20(keywordStats.name);
                    }
                }
            }

            result.push(restaurantWithKeywordsAndTop);
        }

        return result;
    }

    goToKeywordsStats(keywordTableDataRow: KeywordTableDataRow): void {
        void this._router.navigate([`/restaurants/${keywordTableDataRow.restaurantId}/statistics/seo`]);
    }

    onCommonKeywordsOnlyChange(event: MatButtonToggleChange): void {
        this.commonKeywordsOnly.set(event.value);
    }

    onSortByChange(sortBy: string): void {
        this.dataSource.sort?.sort({ id: sortBy, start: this.dataSource.sort.direction || 'asc', disableClear: true });
    }

    onSortOrderChange(): void {
        const direction = this.dataSource.sort?.direction === 'asc' ? 'desc' : 'asc';
        this.dataSource.sort?.sort({ id: this.dataSource.sort.active, start: direction, disableClear: true });
    }

    private _setDatasource(commonKeywordsOnly: boolean): void {
        if (!this.restaurantsStats) {
            return;
        }

        const restaurantsWithKeywordsAndTop = this.getRestaurantsWithKeywordsAndTop(this.restaurantsStats, commonKeywordsOnly);

        const keywordTableDataRow: KeywordTableDataRow[] = this.restaurantsStats.restaurants.map((restaurant) => {
            const { id } = restaurant.restaurantInfo;
            const keywords = restaurantsWithKeywordsAndTop?.find((rest) => rest.restaurant._id === id)?.keywords || [];
            const keywordsTop20 = restaurantsWithKeywordsAndTop?.find((rest) => rest.restaurant._id === id)?.keywordsTop20 || [];
            const keywordsTop3 = restaurantsWithKeywordsAndTop?.find((rest) => rest.restaurant._id === id)?.keywordsTop3 || [];
            const currentTop20 = restaurantsWithKeywordsAndTop?.find((rest) => rest.restaurant._id === id)?.top20 || 0;
            const currentTop3 = restaurantsWithKeywordsAndTop?.find((rest) => rest.restaurant._id === id)?.top3 || 0;

            return new KeywordTableDataRow(
                {
                    restaurantId: id,
                    restaurantName: restaurant.restaurantInfo.internalName ?? restaurant.restaurantInfo.name,
                    keywords,
                    keywordsTop20,
                    keywordsTop3,
                    currentTop20,
                    currentTop3,
                },
                this._translate
            );
        });
        this.dataSource = new MatTableDataSource<KeywordTableDataRow>(keywordTableDataRow);
        if (!keywordTableDataRow.length) {
            this.hasDataChange.emit();
        }
    }

    private _computeWarningTooltip(restaurants: Restaurant[] | undefined): string | undefined {
        if (restaurants) {
            const restaurantsLabel = restaurants.map((e) => e.name).join(', ');
            return this._translate.instant('aggregated_statistics.errors.gmb_data_does_not_exist_for_business_restaurants', {
                restaurants: restaurantsLabel,
            });
        }
    }

    private _getDisplayedColumns(): Column[] {
        return this.shouldHideTableClickableElements
            ? [Column.RESTAURANT_NAME, Column.CURRENT_TOP_20, Column.CURRENT_TOP_3]
            : Object.values(Column);
    }
}
