import { inject, Injectable } from '@angular/core';
import { compact } from 'lodash';
import { catchError, filter, forkJoin, Observable, of, switchMap, tap } from 'rxjs';

import { PlatformAccessStatus, PlatformAccessType, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { PhotoCategory } from ':core/constants';
import { GoogleService } from ':core/services/google.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { MediaService } from ':modules/media/media.service';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { ApiResult, PlatformSearch, Restaurant } from ':shared/models';

type AuthorizedPlatformKeys = PlatformKey.GMB | PlatformKey.FACEBOOK | PlatformKey.INSTAGRAM | PlatformKey.ZENCHEF | PlatformKey.DELIVEROO;

@Injectable({
    providedIn: 'root',
})
export class UpdateCredentialsBasedPlatformService {
    private readonly _platformsService = inject(PlatformsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _reviewsService = inject(ReviewsService);
    private readonly _googleService = inject(GoogleService);
    private readonly _mediaService = inject(MediaService);

    execute(
        data:
            | { platformKey: AuthorizedPlatformKeys; platformSearch: PlatformSearch; credentialId: string }
            | { platformKey: PlatformKey.UBEREATS | PlatformKey.OPENTABLE; platformSearch: PlatformSearch; credentialId: undefined }
    ): Observable<ApiResult<Restaurant>> {
        const { platformSearch, credentialId, platformKey } = data;
        const restaurant = this._restaurantsService.currentRestaurant;
        const platformToUpsert: any = {
            socialId: platformSearch.socialId,
            key: platformKey,
            restaurantId: restaurant,
            parentSocialId: platformSearch.parentSocialId,
            apiEndpointV2: platformSearch.apiEndpointV2,
            apiEndpoint: platformSearch.apiEndpointV2 ? `${platformSearch.accountId}/${platformSearch.apiEndpointV2}` : null,
            hasTransitionedToNewPageExperience: platformSearch.hasTransitionedToNewPageExperience,
        };
        return this._platformsService.upsert(restaurant._id, platformToUpsert, credentialId).pipe(
            tap((res) => {
                platformToUpsert.id = res.data.id ?? res.data._id;
            }),
            switchMap(() =>
                PlatformDefinitions.getPlatformDefinition(platformKey)?.isApiConnectedForPlatformConnection
                    ? of(null)
                    : this._platformsService.pullOverview(restaurant._id, platformKey, true)
            ),
            switchMap(() => this._reviewsService.deleteRestaurantReviewsForPlatform(platformKey, restaurant._id)),
            switchMap(() =>
                platformKey === PlatformKey.GMB && credentialId
                    ? this._updateRestaurantFromGmb$(credentialId, platformSearch, restaurant)
                    : of(null)
            ),
            catchError(() => this._rollbackPlatformCreation$(restaurant._id, platformKey, platformToUpsert.id)),
            switchMap(() => {
                const autoAccess = {
                    lastVerified: undefined,
                    active: true,
                    platformKey: platformKey,
                    accessType: PlatformAccessType.AUTO,
                    data: undefined,
                    status: PlatformAccessStatus.VERIFIED,
                    lastUpdated: new Date(),
                };
                return this._restaurantsService.createPlatformAccess(restaurant._id, autoAccess);
            })
        );
    }

    private _updateRestaurantFromGmb$(
        credentialId: string,
        platformSearch: PlatformSearch,
        restaurant: Restaurant
    ): Observable<ApiResult<Restaurant>> {
        const mediasIds = compact([restaurant.logo?.id, restaurant.cover?.id]);
        return this._googleService.getLocationAttributes(platformSearch.locationId, credentialId).pipe(
            switchMap((res) =>
                this._restaurantsService.fetchPlatformAndUpsertRestaurant(restaurant._id, {
                    ...platformSearch,
                    attributes: res.data.attributes,
                    credentialId: credentialId,
                })
            ),
            switchMap(() =>
                mediasIds.length > 0
                    ? forkJoin(mediasIds?.map((mediaId) => this._mediaService.updateMediaById(mediaId, { category: 'additional' })))
                    : of(null)
            ),
            switchMap(() =>
                platformSearch.accountId?.split('/')[1]
                    ? this._googleService.listMediasByLocId(platformSearch.accountId?.split('/')[1], platformSearch.locationId, false)
                    : of(null)
            ),
            filter(Boolean),
            switchMap((mediasGmb) =>
                this._mediaService.uploadPlatformMedias(restaurant._id, mediasGmb.data).pipe(catchError(() => of(null)))
            ),
            switchMap((medias) => {
                const update = {};
                const logo = medias?.find((media) => media.category === PhotoCategory.PROFILE);
                const cover = medias?.find((media) => media.category === PhotoCategory.COVER);
                if (logo) {
                    update['logo'] = logo.id;
                    update['logoChanged'] = false;
                }
                if (cover) {
                    update['cover'] = cover.id;
                    update['coverChanged'] = false;
                }
                return this._restaurantsService.update(restaurant._id, update);
            }),
            filter(Boolean)
        );
    }

    private _rollbackPlatformCreation$(
        restaurantId: string,
        platformKey: PlatformKey,
        platformId: string
    ): Observable<ApiResult<Restaurant>> {
        return this._reviewsService.deleteRestaurantReviewsForPlatform(platformKey, restaurantId).pipe(
            switchMap(() => this._platformsService.delete(platformId)),
            tap(() => {
                throw new Error(`Platform creation failed for ${platformKey} and restaurant ${restaurantId}`);
            })
        );
    }
}
