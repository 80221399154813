import { DatePipe, NgClass, NgFor, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, Signal, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName } from '@malou-io/package-utils';

import { NotificationItemComponent } from ':core/components/notification-center/components/notification-item/notification-item.component';
import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { CommentNotification } from ':core/components/notification-center/models/comment-notification.model';
import { RestaurantsService } from ':core/services/restaurants.service';
import { Restaurant } from ':shared/models';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

interface CommentsByRestaurant {
    restaurantId: string;
    restaurantName?: string;
    commentsCount: number;
}

@Component({
    selector: 'app-comment-notification-item',
    standalone: true,
    imports: [
        NgClass,
        TranslateModule,
        DatePipe,
        ImagePathResolverPipe,
        PluralTranslatePipe,
        MatButtonModule,
        MatIconModule,
        NgTemplateOutlet,
        NgFor,
        ShortTextPipe,
    ],
    templateUrl: './comment-notification-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentNotificationItemComponent extends NotificationItemComponent {
    readonly SHORT_TEXT_LENGTH = 60;

    private readonly _restaurantService = inject(RestaurantsService);
    private readonly _router = inject(Router);
    private readonly _notificationsCenterContext = inject(NotificationCenterContext);

    readonly notification = computed<CommentNotification>(() => this.initialNotification());
    readonly restaurant = computed(() =>
        this._restaurantService
            .restaurants()
            .find((restaurant: Restaurant) => restaurant.id === this.notification()?.data?.comments?.[0].restaurantId)
    );

    readonly firstComment = computed(() => this.notification().data.comments[0]);

    readonly hasMultipleRestaurantsInNotification = computed(() => this.notification().data.restaurantIds.length > 1);
    readonly isListVisible: WritableSignal<boolean> = signal(false);

    readonly commentsByRestaurants: Signal<CommentsByRestaurant[]> = computed(() =>
        this.notification().data.restaurantIds.map((restaurantId: string) => {
            const restaurant = this._restaurantService.restaurants().find((r: Restaurant) => r._id === restaurantId);
            const restaurantCommentsCount = this.notification().data.comments.filter((c) => c.restaurantId === restaurantId).length;
            return {
                restaurantId,
                restaurantName: restaurant?.name,
                commentsCount: restaurantCommentsCount,
            };
        })
    );

    onNotificationClick(restaurantId?: string): void {
        this.markAsRead();
        const restId = restaurantId ?? this.firstComment().restaurantId;
        this._notificationsCenterContext.trackNotification({
            heapEventName: HeapEventName.NOTIFICATION_COMMENT_TRACKING_WEB_REDIRECT,
            notificationId: this.notification().id,
            properties: {
                restaurantId: restId,
                notificationType: this.notification().type,
            },
        });
        this._navigateToInteractions(restId);
        this._notificationsCenterContext.close();
    }

    toggleList(): void {
        this.isListVisible.set(!this.isListVisible());
    }

    private _navigateToInteractions(restaurantId: string): void {
        this._router.navigate(['restaurants', restaurantId, 'interactions', 'comments']);
    }
}
