/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, filter, interval, map, Observable, Subscription, switchMap, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import {
    AggregatedRoiAdditionalCustomersDto,
    RoiAdditionalCustomersByMonthDto,
    RoiAdditionalCustomersDto,
    RoiAggregatedPerformanceScoreDto,
    RoiPerformanceScoreDto,
    RoiSavedTimeDto,
    RoiTipsResponseDto,
    WatchRoiInsightsCreationResponseDto,
} from '@malou-io/package-dto';
import { ApiResultV2, TimeInMilliseconds, WatcherStatus } from '@malou-io/package-utils';

import { ToastService } from ':core/services/toast.service';
import { environment } from ':environments/environment';
import { ToastDuration } from ':shared/components-v3/toast/toast-item/toast-item.component';
import { objectToQueryParams } from ':shared/helpers/query-params';

export interface RoiInsightsCreationState {
    wasLastResultSeen: boolean;
    creationStartDate: Date | null;
    creationEstimatedTime: number;
}

@Injectable({
    providedIn: 'root',
})
export class RoiService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/roi`;

    readonly roiInsightsCreationState$ = new BehaviorSubject<{ [restaurantId: string]: RoiInsightsCreationState }>({});
    readonly roiInsightsCreationWatchers = signal<Record<string, BehaviorSubject<number>>>({});
    readonly killRoiInsightsCreationWatchers = computed(() => {
        const watchers: Record<string, Observable<number>> = {};
        Object.keys(this.roiInsightsCreationWatchers()).forEach(
            (key) => (watchers[key] = this.roiInsightsCreationWatchers()[key].pipe(filter((value) => !value)))
        );
        return watchers;
    });
    readonly ROI_INSIGHTS_CREATION_WATCHER_INTERVAL_IN_MS = 3000;
    readonly ROI_INSIGHTS_CREATION_ESTIMATED_TIME = TimeInMilliseconds.MINUTE;

    private readonly _http = inject(HttpClient);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);

    checkHasInsights(restaurantId: string): Observable<ApiResultV2<boolean>> {
        return this._http.get<ApiResultV2<boolean>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/check-insights`);
    }

    getSavedTimeForNLastMonths(
        restaurantId: string,
        nbMonths: number,
        filters = { previousPeriod: false }
    ): Observable<ApiResultV2<RoiSavedTimeDto>> {
        const cleanFilters = objectToQueryParams(filters);
        return this._http.get<ApiResultV2<RoiSavedTimeDto>>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/months/${nbMonths}/saved-time`,
            { params: cleanFilters }
        );
    }

    getSavedTimeForRestaurantsForNLastMonths(
        restaurantIds: string[],
        nbMonths: number,
        filters = { previousPeriod: false }
    ): Observable<ApiResultV2<RoiSavedTimeDto>> {
        const cleanFilters = objectToQueryParams({ ...filters, restaurantIds });
        return this._http.get<ApiResultV2<RoiSavedTimeDto>>(`${this.API_BASE_URL}/restaurants/months/${nbMonths}/saved-time`, {
            params: cleanFilters,
        });
    }

    getPerformanceScoreForNLastMonths(
        restaurantId: string,
        nbMonths: number,
        filters = { similarLocations: false }
    ): Observable<ApiResultV2<RoiPerformanceScoreDto>> {
        const cleanFilters = objectToQueryParams({ ...filters });
        return this._http.get<ApiResultV2<RoiPerformanceScoreDto>>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/months/${nbMonths}/performance-score`,
            { params: cleanFilters }
        );
    }

    getPerformanceScoreForRestaurantsForNLastMonths(
        restaurantIds: string[] = [],
        nbMonths: number
    ): Observable<ApiResultV2<RoiAggregatedPerformanceScoreDto[]>> {
        const cleanFilters = objectToQueryParams({ restaurantIds });
        return this._http.get<ApiResultV2<RoiAggregatedPerformanceScoreDto[]>>(
            `${this.API_BASE_URL}/restaurants/months/${nbMonths}/performance-score`,
            {
                params: cleanFilters,
            }
        );
    }

    getTipsForRestaurant(restaurantId: string): Observable<ApiResultV2<RoiTipsResponseDto>> {
        return this._http.get<ApiResultV2<RoiTipsResponseDto>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/tips`);
    }

    getEstimatedCustomersForNLastMonths(
        restaurantId: string,
        nbMonths: number,
        filters = { similarLocations: false }
    ): Observable<ApiResultV2<RoiAdditionalCustomersDto>> {
        const cleanFilters = objectToQueryParams({ ...filters });
        return this._http.get<ApiResultV2<RoiAdditionalCustomersDto>>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/months/${nbMonths}/additional-customers`,
            {
                params: cleanFilters,
            }
        );
    }

    getAggregatedEstimatedCustomersForRestaurantsForNLastMonths(
        restaurantIds: string[] = [],
        nbMonths: number
    ): Observable<ApiResultV2<AggregatedRoiAdditionalCustomersDto>> {
        const cleanFilters = objectToQueryParams({ restaurantIds });
        return this._http.get<ApiResultV2<AggregatedRoiAdditionalCustomersDto>>(
            `${this.API_BASE_URL}/restaurants/months/${nbMonths}/aggregated-additional-customers`,
            {
                params: cleanFilters,
            }
        );
    }

    getEstimatedCustomersForRestaurantsForNLastMonths(
        restaurantIds: string[],
        nbMonths: number,
        filters = { similarLocations: false }
    ): Observable<ApiResultV2<RoiAdditionalCustomersByMonthDto[]>> {
        const cleanFilters = objectToQueryParams({ restaurantIds, ...filters });
        return this._http.get<ApiResultV2<RoiAdditionalCustomersByMonthDto[]>>(
            `${this.API_BASE_URL}/restaurants/months/${nbMonths}/additional-customers`,
            {
                params: cleanFilters,
            }
        );
    }

    startInsightsWatcher(restaurantId: string): void {
        this._startCreationByRestaurantId(restaurantId);
        const uuid = this._newRoiInsightsCreationWatcher();
        this._startWatcher(uuid, restaurantId);
    }

    private _startCreationByRestaurantId(restaurantId: string): void {
        const currentRoiInsightsCreationState = this.roiInsightsCreationState$.value;
        const newRoiInsightsCreationState = {
            ...currentRoiInsightsCreationState,
            [restaurantId]: {
                wasLastResultSeen: true,
                creationStartDate: new Date(),
                creationEstimatedTime: this.ROI_INSIGHTS_CREATION_ESTIMATED_TIME,
            },
        };
        this.roiInsightsCreationState$.next(newRoiInsightsCreationState);
    }

    private _newRoiInsightsCreationWatcher(): string {
        const uuid = uuidv4().toString();

        this.roiInsightsCreationWatchers.update((watchers) => {
            for (const key in watchers) {
                if (watchers[key].value) {
                    delete watchers[key];
                }
            }

            watchers[uuid] = new BehaviorSubject<number>(new Date().getTime());
            return { ...watchers };
        });

        return uuid;
    }

    private _startWatcher(uuid: string, restaurantId: string): void {
        const creationInterval = interval(this.ROI_INSIGHTS_CREATION_WATCHER_INTERVAL_IN_MS)
            .pipe(
                switchMap(() => this._watchRoiInsightsCreation(restaurantId)),
                takeUntil(this.killRoiInsightsCreationWatchers()[uuid])
            )
            .subscribe({
                next: (res) => {
                    switch (res.status) {
                        case WatcherStatus.FAILED:
                            this._endWatcherInterval({ creationInterval, restaurantId, uuid });
                            const errorMessage = res.error ?? this._translateService.instant('common.error');
                            this._toastService.openErrorToast(errorMessage, ToastDuration.MEDIUM);
                            return;
                        case WatcherStatus.FINISHED:
                            this._endWatcherInterval({ creationInterval, restaurantId, uuid });
                            return;
                        case WatcherStatus.RUNNING:
                        default:
                            const now = new Date().getTime();
                            const startTimestamp = this.roiInsightsCreationWatchers()[uuid].value;
                            if (now - startTimestamp >= 2 * this.ROI_INSIGHTS_CREATION_ESTIMATED_TIME) {
                                this._endWatcherInterval({ creationInterval, restaurantId, uuid });
                            }
                            return;
                    }
                },
                error: (error) => {
                    if ([502, 504].includes(error.status)) {
                        return this._startWatcher(uuid, restaurantId);
                    }
                    this._toastService.openErrorToast(this._translateService.instant('common.error'), ToastDuration.MEDIUM);
                    this._creationCompletedByRestaurantId(restaurantId);
                },
            });
    }

    private _watchRoiInsightsCreation(restaurantId: string): Observable<WatchRoiInsightsCreationResponseDto> {
        return this._http
            .get<ApiResultV2<WatchRoiInsightsCreationResponseDto>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/create/watch`)
            .pipe(map((res) => res.data));
    }

    private _endWatcherInterval({
        creationInterval,
        restaurantId,
        uuid,
    }: {
        creationInterval: Subscription;
        restaurantId: string;
        uuid: string;
    }): void {
        this.roiInsightsCreationWatchers()[uuid].next(0);
        this._creationCompletedByRestaurantId(restaurantId);
        creationInterval.unsubscribe();
    }

    private _creationCompletedByRestaurantId(restaurantId: string): void {
        const currentRoiInsightsCreationState = this.roiInsightsCreationState$.value;
        const newRoiInsightsCreationState = {
            ...currentRoiInsightsCreationState,
            [restaurantId]: {
                wasLastResultSeen: false,
                creationStartDate: null,
                creationEstimatedTime: currentRoiInsightsCreationState[restaurantId].creationEstimatedTime ?? Number.MAX_SAFE_INTEGER,
            },
        };
        this.roiInsightsCreationState$.next(newRoiInsightsCreationState);
    }
}
