import { Injectable } from '@angular/core';

import { GalleryImportMediaComponent } from './gallery-import-media.component';

/**
 * This service shares GalleryImportMediaComponent across the whole application
 * (these is one GalleryImportMediaComponent for the whole app).
 */
@Injectable({ providedIn: 'root' })
export class GalleryImportMediaService {
    public importElement: GalleryImportMediaComponent | undefined;

    public constructor() {}
}
