import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { Component, effect, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { compact, isNumber } from 'lodash';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, Subject, timer } from 'rxjs';
import { catchError, debounceTime, filter, map, retry, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AggregationTimeScale, MalouMetric, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import * as fromPlatformsStore from ':modules/platforms/store/platforms.reducer';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { ViewBy } from ':shared/enums/view-by.enum';
import {
    createDateWithClientTimeZoneDifference,
    getDaysFromCurrentRange,
    getMonthsFromPeriod,
    getWeeksFromCurrentRange,
    isDateSetOrGenericPeriod,
    Month,
    WeekRange,
} from ':shared/helpers';
import { KillSubscriptions } from ':shared/interfaces';
import {
    DailyValue,
    DatesAndPeriod,
    getInsightsErrorText,
    InsightsByPlatform,
    MetricToDataValues,
    MonthlyValue,
    Restaurant,
    WeeklyValue,
} from ':shared/models';
import { GmbInsights } from ':shared/models/gmb-insight';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { InsightsService } from '../../insights.service';
import { StatisticsHttpErrorPipe } from '../../statistics-http-error.pipe';
import * as StatisticsActions from '../../store/statistics.actions';
import * as StatisticsSelector from '../../store/statistics.selectors';
import { GmbActionsChartComponent, GmbActionsData } from './gmb-actions-chart/gmb-actions-chart.component';

interface MetricAndKey {
    metric: MalouMetric;
    key: string;
}

export const GMB_METRICS: MetricAndKey[] = [
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_MAPS,
        key: 'impressionsDesktopMaps',
    },
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH,
        key: 'impressionsDesktopSearch',
    },
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_MAPS,
        key: 'impressionsMobileMaps',
    },
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_SEARCH,
        key: 'impressionsMobileSearch',
    },
    {
        metric: MalouMetric.ACTIONS_WEBSITE,
        key: 'websiteClicks',
    },
    {
        metric: MalouMetric.ACTIONS_PHONE,
        key: 'phoneClicks',
    },
    {
        metric: MalouMetric.ACTIONS_DRIVING_DIRECTIONS,
        key: 'drivingClicks',
    },
    {
        metric: MalouMetric.ACTIONS_MENU_CLICK,
        key: 'menuClicks',
    },
    {
        metric: MalouMetric.ACTIONS_BOOKING_CLICK,
        key: 'bookingClicks',
    },
    {
        metric: MalouMetric.BUSINESS_FOOD_ORDERS,
        key: 'foodOrderClicks',
    },
];

@Component({
    selector: 'app-statistics-seo-gmb-actions',
    templateUrl: './gmb-actions.component.html',
    styleUrls: ['./gmb-actions.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        GmbActionsChartComponent,
        MatTooltipModule,
        NumberEvolutionComponent,
        MatProgressSpinnerModule,
        AsyncPipe,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        StatisticsHttpErrorPipe,
        EnumTranslatePipe,
        LowerCasePipe,
        ApplyPurePipe,
    ],
    providers: [EnumTranslatePipe],
})
@AutoUnsubscribeOnDestroy()
export class GmbActionsComponent implements OnInit, KillSubscriptions {
    @Input() showViewByTextInsteadOfSelector = false;
    @Input() viewBy?: ViewBy;
    @Input() hiddenDatasetIndexes: number[] = [];
    @Output() readonly viewByChange = new EventEmitter<ViewBy>();
    @Output() readonly hiddenDatasetIndexesChange = new EventEmitter<number[]>();
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelector.selectDatesFilter);

    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly viewByFilterSubject$: BehaviorSubject<ViewBy> = new BehaviorSubject(ViewBy.DAY);
    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.DAY) as FormControl<ViewBy>;
    readonly ViewBy = ViewBy;

    httpError: any;
    isLoading = signal(true);
    insightsError: string | null = null;

    currentGmbInsights: GmbInsights | null = null;
    previousGmbInsights: GmbInsights | null = null;
    actionsEvolutionPercentage: number | null = null;
    conversionRateEvolution: number | null = null;

    isGmbConnected$ = of(true);

    gmbActionsData: GmbActionsData;
    dateLabels: Date[] = [];

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _insightsService: InsightsService,
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        private readonly _enumTranslate: EnumTranslatePipe
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        if (this.viewBy) {
            this.viewByFilterSubject$.next(this.viewBy);
        }

        this.isGmbConnected$ = this._store
            .select(fromPlatformsStore.selectCurrentPlatform({ platformKey: PlatformKey.GMB }))
            .pipe(map((platform) => !!platform?.credentials?.length && platform.credentials.length > 0));

        combineLatest([this._restaurantsService.restaurantSelected$, this.dates$, this.viewByFilterSubject$])
            .pipe(
                filter(
                    ([_restaurant, dates, actionsViewBy]) =>
                        isDateSetOrGenericPeriod(dates) && Object.values(ViewBy).includes(actionsViewBy)
                ),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(([restaurant, dates, actionsViewBy]: [Restaurant, DatesAndPeriod, ViewBy]) => {
                    this.viewByChange.emit(actionsViewBy);
                    const { startDate, endDate } = dates;
                    const insightAggregator = this._computeInsightAggregatorFromViewByFilter(actionsViewBy);
                    const { _id: restaurantId } = restaurant;
                    return forkJoin([
                        this._insightsService
                            .getInsights({
                                restaurantIds: [restaurantId],
                                platformsKeys: [PlatformKey.GMB],
                                metrics: GMB_METRICS.map((m) => m.metric),
                                aggregators: compact([insightAggregator]),
                                startDate,
                                endDate,
                            })
                            .pipe(
                                retry({
                                    count: 2,
                                    delay: () => timer(1000),
                                }),
                                map((res) => res.data[restaurantId]),
                                catchError((error) => {
                                    this.httpError = error;
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                        this._insightsService
                            .getInsights({
                                restaurantIds: [restaurantId],
                                platformsKeys: [PlatformKey.GMB],
                                metrics: GMB_METRICS.map((m) => m.metric),
                                aggregators: [AggregationTimeScale.TOTAL],
                                startDate,
                                endDate,
                                previousPeriod: true,
                            })
                            .pipe(
                                retry({
                                    count: 2,
                                    delay: () => timer(1000),
                                }),
                                map((res) => res.data[restaurantId]),
                                catchError((error) => {
                                    if (!error.error?.message?.match(/Time range too long. Maximum start time is 18 months ago/)) {
                                        this.httpError = error;
                                        this.hasDataChange.emit(false);
                                        this.isLoading.set(false);
                                        return EMPTY;
                                    }
                                    return of({});
                                })
                            ),
                        of(startDate),
                        of(endDate),
                        of(actionsViewBy),
                    ]);
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe(
                ([currentInsights, previousInsights, startDate, endDate, actionsViewBy]: [
                    InsightsByPlatform,
                    InsightsByPlatform,
                    Date,
                    Date,
                    ViewBy,
                ]) => {
                    const currentPlatformInsights = currentInsights[PlatformKey.GMB];
                    this._store.dispatch(StatisticsActions.editActionsRawData({ data: currentPlatformInsights }));
                    // Data and Labels computing for chart
                    if (actionsViewBy === ViewBy.DAY) {
                        const gmbInsightsByDay = currentPlatformInsights?.by_day;
                        if (!gmbInsightsByDay) {
                            this._setInsightsError(currentPlatformInsights?.message);
                            this.hasDataChange.emit(false);
                            this.isLoading.set(false);
                            return;
                        }
                        const days = getDaysFromCurrentRange(startDate, endDate);
                        this.dateLabels = [...days];
                        this.currentGmbInsights = this._mapDailyInsightsToChart(gmbInsightsByDay, days);
                    } else if (actionsViewBy === ViewBy.WEEK) {
                        const gmbInsightsByWeek = currentPlatformInsights?.by_week;
                        if (!gmbInsightsByWeek) {
                            this._setInsightsError(currentPlatformInsights?.message);
                            this.hasDataChange.emit(false);
                            this.isLoading.set(false);
                            return;
                        }
                        const weeks = getWeeksFromCurrentRange(startDate, endDate);
                        this.dateLabels = weeks.map((week) => week.start);
                        this.currentGmbInsights = this._mapWeeklyInsightsToChart(gmbInsightsByWeek, weeks);
                    } else if (actionsViewBy === ViewBy.MONTH) {
                        const gmbInsightsByMonth = currentPlatformInsights?.by_month;
                        if (!gmbInsightsByMonth) {
                            this._setInsightsError(currentPlatformInsights?.message);
                            this.hasDataChange.emit(false);
                            this.isLoading.set(false);
                            return;
                        }
                        const months: Month[] = getMonthsFromPeriod(startDate, endDate);
                        this.dateLabels = months.map((e) => e.start);
                        this.currentGmbInsights = this._mapMonthlyInsightsToChart(gmbInsightsByMonth, months);
                    }

                    this.gmbActionsData = {
                        websiteClicks: this.currentGmbInsights?.websiteClicks || [],
                        phoneClicks: this.currentGmbInsights?.phoneClicks || [],
                        drivingClicks: this.currentGmbInsights?.drivingClicks || [],
                        menuClicks: this.currentGmbInsights?.menuClicks || [],
                        bookingClicks: this.currentGmbInsights?.bookingClicks || [],
                        foodOrderClicks: this.currentGmbInsights?.foodOrderClicks || [],
                    };
                    if (previousInsights) {
                        this.previousGmbInsights = this._mapTotalInsights(previousInsights);
                    }

                    // Value computing for Actions card
                    const currentActions = this.currentGmbInsights?.totalActions;
                    const previousActions = this.previousGmbInsights?.totalActions;
                    if (currentActions === undefined || previousActions === undefined || previousActions === 0) {
                        this.actionsEvolutionPercentage = null;
                    } else {
                        this.actionsEvolutionPercentage = ((currentActions - previousActions) / previousActions) * 100;
                    }

                    if (this.currentGmbInsights && this.previousGmbInsights) {
                        const currentConversionRate = this.currentGmbInsights.ratioActionsOverImpressions;
                        const previousConversionRate = this.previousGmbInsights.ratioActionsOverImpressions;
                        if (currentConversionRate === null || previousConversionRate === null) {
                            this.conversionRateEvolution = null;
                        } else {
                            this.conversionRateEvolution = currentConversionRate - previousConversionRate;
                        }
                    }

                    this.isLoading.set(false);
                }
            );
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslate.transform(option, 'view_by');

    private _setInsightsError(message?: string): void {
        this.insightsError = this._translate.instant(getInsightsErrorText(message), {
            platformName: this._enumTranslate.transform(PlatformKey.GMB, 'platform_key'),
        });
    }

    private _mapMonthlyInsightsToChart(gmbInsightsByMonth: MetricToDataValues<MonthlyValue>, months: Month[]): GmbInsights {
        const partialGmbInsights: Partial<GmbInsights> = {
            impressionsDesktopMaps: [],
            impressionsDesktopSearch: [],
            impressionsMobileMaps: [],
            impressionsMobileSearch: [],
            websiteClicks: [],
            phoneClicks: [],
            drivingClicks: [],
            menuClicks: [],
            bookingClicks: [],
            foodOrderClicks: [],
        };
        if (gmbInsightsByMonth.error) {
            return new GmbInsights(partialGmbInsights);
        }

        const gmbInsightsByMonthByGmbDate: Partial<Record<MalouMetric, Record<string, MonthlyValue>>> = {}; // { [metric]: { [2024-04-08]: value } }

        Object.keys(gmbInsightsByMonth).forEach((key) => {
            gmbInsightsByMonthByGmbDate[key] = (gmbInsightsByMonth[key] as MonthlyValue[]).reduce(
                (acc, value) => ({
                    ...acc,
                    [createDateWithClientTimeZoneDifference(new Date(value.monthStart)).toISOString().slice(0, 10)]: value,
                }),
                {}
            );
        });

        for (let index = 0; index < months.length; index++) {
            const monthStart = months[index].start;
            const monthStartKey = monthStart.toISOString().slice(0, 10);
            for (const { metric, key: metricKey } of GMB_METRICS) {
                const value = gmbInsightsByMonthByGmbDate[metric]?.[monthStartKey]?.value;
                partialGmbInsights[metricKey].push(isNumber(value) ? value : 0);
            }
        }
        return new GmbInsights(partialGmbInsights);
    }

    private _mapWeeklyInsightsToChart(gmbInsightsByWeek: MetricToDataValues<WeeklyValue>, weeks: WeekRange[]): GmbInsights {
        const partialGmbInsights: Partial<GmbInsights> = {
            impressionsDesktopMaps: [],
            impressionsDesktopSearch: [],
            impressionsMobileMaps: [],
            impressionsMobileSearch: [],
            websiteClicks: [],
            phoneClicks: [],
            drivingClicks: [],
            menuClicks: [],
            bookingClicks: [],
            foodOrderClicks: [],
        };

        if (gmbInsightsByWeek.error) {
            return new GmbInsights(partialGmbInsights);
        }

        const gmbInsightsByWeekByGmbDate: Partial<Record<MalouMetric, Record<string, WeeklyValue>>> = {}; // { [metric]: { [2024-04-08]: value } }

        Object.keys(gmbInsightsByWeek).forEach((key) => {
            gmbInsightsByWeekByGmbDate[key] = (gmbInsightsByWeek[key] as WeeklyValue[]).reduce(
                (acc, value) => ({
                    ...acc,
                    [createDateWithClientTimeZoneDifference(new Date(value.weekStart)).toISOString().slice(0, 10)]: value,
                }),
                {}
            );
        });

        for (let index = 0; index < weeks.length; index++) {
            const weekStart = weeks[index].start;
            const weekStartKey = weekStart.toISOString().slice(0, 10);
            for (const { metric, key: metricKey } of GMB_METRICS) {
                const value = gmbInsightsByWeekByGmbDate[metric]?.[weekStartKey]?.value;
                partialGmbInsights[metricKey].push(isNumber(value) ? value : 0);
            }
        }

        return new GmbInsights(partialGmbInsights);
    }

    private _mapDailyInsightsToChart(gmbInsightsByDay: MetricToDataValues<DailyValue>, days: Date[]): GmbInsights {
        const partialGmbInsights: Partial<GmbInsights> = {
            impressionsDesktopMaps: [],
            impressionsDesktopSearch: [],
            impressionsMobileMaps: [],
            impressionsMobileSearch: [],
            websiteClicks: [],
            phoneClicks: [],
            drivingClicks: [],
            menuClicks: [],
            bookingClicks: [],
            foodOrderClicks: [],
        };

        if (gmbInsightsByDay.error) {
            return new GmbInsights(partialGmbInsights);
        }

        const gmbInsightsByDayByGmbDate: Partial<Record<MalouMetric, Record<string, DailyValue>>> = {}; // { [metric]: { [2024-04-08]: value } }

        Object.keys(gmbInsightsByDay).forEach((key) => {
            gmbInsightsByDayByGmbDate[key] = (gmbInsightsByDay[key] as DailyValue[]).reduce(
                (acc, value) => ({
                    ...acc,
                    [createDateWithClientTimeZoneDifference(new Date(value.date)).toISOString().slice(0, 10)]: value,
                }),
                {}
            );
        });

        for (let index = 0; index < days.length; index++) {
            const day = days[index];
            const dayKey = day.toISOString().slice(0, 10);
            for (const { metric, key: metricKey } of GMB_METRICS) {
                const value = gmbInsightsByDayByGmbDate[metric]?.[dayKey]?.value;
                partialGmbInsights[metricKey].push(isNumber(value) ? value : 0);
            }
        }

        return new GmbInsights(partialGmbInsights);
    }

    private _mapTotalInsights(insights: InsightsByPlatform): GmbInsights | null {
        const totalInsights = insights[PlatformKey.GMB]?.total;
        if (!totalInsights) {
            return null;
        }

        const partialGmbInsights: Partial<GmbInsights> = {
            websiteClicks: [],
            phoneClicks: [],
            drivingClicks: [],
            menuClicks: [],
            bookingClicks: [],
        };

        for (const { metric, key: metricKey } of GMB_METRICS) {
            const metricByWeek = totalInsights[metric];
            partialGmbInsights[metricKey] = [metricByWeek?.value];
            if (!isNumber(partialGmbInsights[metricKey]?.[0])) {
                partialGmbInsights[metricKey] = [0];
            }
        }
        return new GmbInsights(partialGmbInsights);
    }

    private _reset(): void {
        this.httpError = null;
        this.insightsError = null;
        this.isLoading.set(true);
        this.currentGmbInsights = null;
        this.previousGmbInsights = null;
        this.dateLabels = [];
    }

    private _computeInsightAggregatorFromViewByFilter(viewBy: ViewBy): AggregationTimeScale | undefined {
        if (viewBy === ViewBy.DAY) {
            return AggregationTimeScale.BY_DAY;
        }
        if (viewBy === ViewBy.WEEK) {
            return AggregationTimeScale.BY_WEEK;
        }
        if (viewBy === ViewBy.MONTH) {
            return AggregationTimeScale.BY_MONTH;
        }
    }
}
